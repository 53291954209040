import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';

import api from 'utils/api';
import queryClient from 'utils/queries/client';
import { useQueryDelete } from 'utils/queries/delete';
import { useQueryUpdate } from 'utils/queries/update';
import { parseReportRevision } from 'utils/types/ReportRevision';

export const useReportRevision = (
  reportId?: string,
  reportRevisionId?: string,
  { signReport } = { signReport: true },
) => {
  const queryKey = ['reportRevisions', reportRevisionId];

  // Get
  const {
    data: reportRevision,
    isPending,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/reports/${reportId}/revisions/${reportRevisionId}`, {
        params: { sign_report: signReport },
      }),
    select: (reportRevisionRaw: any) => parseReportRevision(reportRevisionRaw),
    enabled: !!reportId && !!reportRevisionId,
    meta: {
      disableLoading: true,
    },
  });

  useEffect(() => {
    if (!reportId) return;
    if (!reportRevisionId) return;
    if (!reportRevision?.id) return;

    const interval = setInterval(() => {
      if (
        reportId &&
        reportRevisionId &&
        !reportRevision?.storage_url &&
        !reportRevision?.failed_reason
      ) {
        refetch().then(() => {
          queryClient.refetchQueries({ queryKey: ['reportRevisions', reportId] });
        });
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [reportRevision, reportRevisionId, reportId, isPending]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update
  const { mutateAsync: updateReportRevision } = useQueryUpdate({
    api,
    queryKey,
    endpoint: `/reports/${reportId}/revisions/${reportRevisionId}`,
    invalidateQueryKeys: [queryKey.slice(0, 1)],
  });

  // Delete
  const { mutateAsync: deleteReportRevision } = useQueryDelete({
    api,
    queryKey,
    endpoint: `/reports/${reportId}/revisions/${reportRevisionId}`,
    invalidateQueryKeys: [queryKey.slice(0, 1), ['reportRevisions', reportId]],
  });

  return {
    reportRevision,
    updateReportRevision,
    deleteReportRevision,
    isPending,
  };
};
