enum MoistureState {
  MOISTURE_0_10 = 'MOISTURE_0_10',
  MOISTURE_10_15 = 'MOISTURE_10_15',
  MOISTURE_15_20 = 'MOISTURE_15_20',
  MOISTURE_20_25 = 'MOISTURE_20_25',
  MOISTURE_25_30 = 'MOISTURE_25_30',
  MOISTURE_30_35 = 'MOISTURE_30_35',
  MOISTURE_35_40 = 'MOISTURE_35_40',
  MOISTURE_40_100 = 'MOISTURE_40_100',

  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE', // Legacy
  INVALID = 'INVALID',
  CREATED = 'CREATED',

  UNKNOWN = 'UNKNOWN',
}

export const sortOrderSensorState: { [key: string]: number } = [
  MoistureState.UNKNOWN,
  MoistureState.CREATED,
  MoistureState.INACTIVE,
  MoistureState.INVALID,
  MoistureState.ACTIVE,
  MoistureState.MOISTURE_0_10,
  MoistureState.MOISTURE_10_15,
  MoistureState.MOISTURE_15_20,
  MoistureState.MOISTURE_20_25,
  MoistureState.MOISTURE_25_30,
  MoistureState.MOISTURE_30_35,
  MoistureState.MOISTURE_35_40,
  MoistureState.MOISTURE_40_100,
]
  .map((state, index) => [state, index])
  .reduce((o: any, [state, index]) => ({ ...o, [state]: index }), {});

export default MoistureState;
