import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, Dispatch } from 'react';

import { CalendarInput, RangeType } from 'components/inputs/CalendarInput';
import Modal from 'components/modals/Modal';

export const DateRangePicker: React.FC<{
  startDate: Date;
  endDate: Date;
  setStartDate: any;
  setEndDate: any;
}> = ({ startDate, endDate, setStartDate, setEndDate }) => {
  const [initialEndDate] = useState<Date>(endDate);
  const [showCalendar, setShow] = useState(false);

  return (
    <>
      <div
        className="flex items-center justify-center cursor-pointer bg-brand-blue border-brand-blue text-white hover:bg-brand-blue-light-1 hover:border-brand-blue-light-1"
        onClick={() => setShow(!showCalendar)}
      >
        <div className="p-2 w-fit">{startDate.toDateString()}</div>
        <FontAwesomeIcon className="mx-2" icon={faArrowRight} />
        <div className="p-2 w-fit">{endDate.toDateString()}</div>
      </div>
      <div>
        <SensorCalendarModal
          show={showCalendar}
          setShow={setShow}
          onSelect={(dateRange: RangeType) => {
            setStartDate(dateRange.start as Date);
            setEndDate(dateRange.end as Date);
          }}
          maxDate={initialEndDate}
        />
      </div>
    </>
  );
};

const SensorCalendarModal: React.FC<{
  show: boolean;
  setShow: Dispatch<boolean>;
  onSelect: (dateRange: RangeType) => void;
  initialDate?: Date;
  minDate?: Date;
  maxDate?: Date;
}> = ({ show, setShow, onSelect, initialDate, minDate, maxDate }) => (
  <Modal title="Date range" show={show} setShow={setShow}>
    <CalendarInput
      onSelect={(date: Date | RangeType) => {
        setShow(false);
        onSelect(date as RangeType);
      }}
      initialDate={initialDate}
      minDate={minDate}
      maxDate={maxDate}
      mode="range"
    />
  </Modal>
);
