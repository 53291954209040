import { RawNodeDatum } from 'react-d3-tree';

type GroupHierarchyTree = {
  id: string;
  name: string;
  parent_group: string;
  tree_depth: number;
  subgroups: GroupHierarchyTree[];
};

export const parseGroupHierarchyTree = (groupHierarchyTree: any): GroupHierarchyTree =>
  groupHierarchyTree as GroupHierarchyTree;

export const parseGroupHierarchyD3Tree = (node: GroupHierarchyTree): RawNodeDatum => ({
  name: node.name,
  attributes: {
    id: node.id,
    parent_group: node.parent_group,
    tree_depth: node.tree_depth,
  },
  children:
    node.subgroups && node.subgroups.length > 0
      ? node.subgroups.map(parseGroupHierarchyD3Tree)
      : undefined,
});

export default GroupHierarchyTree;
