import { Dispatch, HTMLAttributes, SetStateAction } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import tectorIconBlack from 'images/tector-icon-black.png';
import GatewayFile from 'utils/types/GatewayFile';
import SensorFile from 'utils/types/SensorFile';
import SensorGroupFile from 'utils/types/SensorGroupFile';

export type ThumbnailProps = {
  linkToOriginal?: boolean;
  place?: 'top' | 'right' | 'bottom' | 'left';
  height?: number;
} & HTMLAttributes<HTMLImageElement>;

const Thumbnail: React.FC<
  ThumbnailProps & {
    tooltipId: string;
    loadOriginal: boolean;
    setLoadingOriginal: Dispatch<SetStateAction<boolean>>;
    file?: SensorFile | GatewayFile | SensorGroupFile | null;
    fileOriginal?: SensorFile | GatewayFile | SensorGroupFile | null;
  }
> = ({
  loadOriginal,
  setLoadingOriginal,
  tooltipId,
  place,
  file,
  fileOriginal,
  linkToOriginal = false,
  height = 40,
  ...props
}) => {
  const urlThumbnail = file?.storage_url_thumbnail ?? tectorIconBlack;
  const urlOriginal = fileOriginal?.storage_url ?? urlThumbnail;

  const ImgElement = (
    <img
      src={urlThumbnail ?? tectorIconBlack}
      alt="thumbnail"
      className="rounded-md object-cover"
      data-tooltip-id={tooltipId}
      style={{ height, width: 71 }}
      {...props}
    />
  );

  return (
    <>
      {linkToOriginal ? (
        <a href={urlOriginal} target="_blank" rel="noreferrer">
          {ImgElement}
        </a>
      ) : (
        ImgElement
      )}

      <ReactTooltip
        id={tooltipId}
        className="z-50"
        aria-haspopup="true"
        afterShow={() => {
          if (!loadOriginal) {
            setLoadingOriginal(true);
          }
        }}
        place={place}
        closeOnScroll
        data-tooltip-position-strategy="fixed"
      >
        <img src={urlOriginal} alt="full size" className="max-w-xs h-auto" />
      </ReactTooltip>
    </>
  );
};

export default Thumbnail;
