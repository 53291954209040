import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryCreate } from 'utils/queries/create';

export const useFiles = <FileType>({
  queryKey,
  endpoint,
  enabled,
  parseFn,
  invalidateQueryKeys = [],
}: {
  queryKey: any;
  endpoint: string;
  enabled: boolean;
  parseFn: (x: any) => FileType;
  invalidateQueryKeys?: any[][];
}) => {
  // Get
  const { data: files, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(endpoint),
    select: (files: any[]) => files.map(file => parseFn(file)),
    enabled,
  });

  // Create
  const { mutateAsync: createFile } = useQueryCreate({
    queryKey,
    api,
    endpoint,
    invalidateQueryKeys,
    parseFn,
  });

  return {
    files,
    createFile,
    isPending,
  };
};
