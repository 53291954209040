import { faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray, useField } from 'formik';
import { useTranslation } from 'react-i18next';

import { FormTextInput } from 'components';
import ErrorMessageDisplay from 'components/forms/helpers/ErrorMessageDisplay';

export const EmailListFormField: React.FC<{
  fieldName: string;
  addEmailText: string;
  onBlur?: (emails: string[]) => void;
}> = ({ fieldName, addEmailText, onBlur }) => {
  const field = useField<string[]>(fieldName);
  const { value: emails } = field[0];
  const { setValue, setTouched } = field[2];

  const { t } = useTranslation('components');

  return (
    <div className="mb-8">
      <FieldArray
        name={fieldName}
        render={arrayHelpers => (
          <div>
            {emails &&
              emails.length > 0 &&
              emails.map((email, index) => (
                <div key={index} className="flex mb-2">
                  <FormTextInput
                    className="mb-0 grow"
                    name={`${fieldName}.${index}`}
                    type="email"
                    placeholder={t('EmailListFormField.placeholder')}
                    prepend="@"
                    onBlur={e => {
                      setTouched(true);
                      if (onBlur) onBlur(arrayHelpers.form.values[fieldName]);
                    }}
                  />

                  <div className="flex-none">
                    <FontAwesomeIcon
                      className="text-brand-orange float-right cursor-pointer ml-2 top-2 relative"
                      icon={faMinusSquare}
                      size="lg"
                      onClick={() => arrayHelpers.remove(index)}
                    />
                  </div>
                </div>
              ))}
          </div>
        )}
      />

      <ErrorMessageDisplay showFieldsOnly={[fieldName]} />

      <button className="mt-2 mb-5" type="button" onClick={() => setValue([...emails, ''])}>
        <FontAwesomeIcon className="text-brand-green-light-2 mr-2" icon={faPlusSquare} size="lg" />
        {addEmailText}
      </button>
    </div>
  );
};

export default EmailListFormField;
