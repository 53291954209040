import { t } from 'translations';

const enum AccessLinkType {
  User = 10,
  Customer = 20,
}

export const getAccessLinkTypeText = (accessLinkType: AccessLinkType): string => {
  switch (accessLinkType) {
    case AccessLinkType.Customer:
      return t('utils:enums.AccessLinkType.texts.Customer');
    case AccessLinkType.User:
      return t('utils:enums.AccessLinkType.texts.User');
    default:
      return 'n/a';
  }
};

export default AccessLinkType;
