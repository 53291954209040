import React, { HTMLAttributes } from 'react';

import {
  SensorTagComplementaryElementBadge,
  SensorTagPrimaryElementCategoryBadge,
  SensorTagPrimaryElementTypeBadge,
} from 'components/badges/SensorTagBadges';
import {
  SensorTagComplementaryElement,
  SensorTagPrimaryElementCategory,
  SensorTagPrimaryElementType,
} from 'utils/enums';
import { SensorTag } from 'utils/types/SensorTags';

const SensorTagsPrimaryPlacement: React.FC<
  {
    sensorTagPrimaryElementCategory?: SensorTagPrimaryElementCategory;
    sensorTagPrimaryElementType?: SensorTagPrimaryElementType;
    sensorTagComplementaryElement?: SensorTagComplementaryElement;
    onClick?: (sensorTag: SensorTag) => Promise<void>;
    useColors?: boolean;
  } & Omit<HTMLAttributes<HTMLSpanElement>, 'onClick'>
> = ({
  sensorTagPrimaryElementCategory,
  sensorTagPrimaryElementType,
  sensorTagComplementaryElement,
  className,
  onClick,
  ...props
}) => (
  <>
    {sensorTagComplementaryElement && (
      <SensorTagComplementaryElementBadge
        className={className}
        sensorTagComplementaryElement={sensorTagComplementaryElement}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagPrimaryElementType && (
      <SensorTagPrimaryElementTypeBadge
        className={className}
        sensorTagPrimaryElementType={sensorTagPrimaryElementType}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagPrimaryElementCategory && (
      <SensorTagPrimaryElementCategoryBadge
        className={className}
        sensorTagPrimaryElementCategory={sensorTagPrimaryElementCategory}
        onClick={onClick}
        {...props}
      />
    )}
  </>
);

export default SensorTagsPrimaryPlacement;
