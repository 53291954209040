import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { Link } from 'react-router-dom';

export const TableLink: React.FC<
  {
    name?: string | JSX.Element;
    url?: string;
    datatip?: string;
    externalDatatip?: string;
    className?: string;
  } & HTMLAttributes<HTMLDivElement>
> = ({ name, url, datatip, externalDatatip, className, ...props }) => {
  if (!name || !url) return <>n/a</>;

  const _onClick = (event: React.MouseEvent<HTMLElement>) => event.stopPropagation();

  return (
    <div className="flex gap-x-2 items-center" {...props}>
      <Link
        className={classNames('underline text-brand-green hover:text-blue-500', className)}
        to={url}
        onClick={_onClick}
        data-tooltip-content={datatip}
        data-tooltip-id="route-tooltip"
      >
        {name}
      </Link>
      {externalDatatip && (
        <Link
          className={classNames('underline text-brand-green hover:text-blue-500', className)}
          target="_blank"
          onClick={_onClick}
          to={url}
          data-tooltip-content={externalDatatip}
          data-tooltip-id="route-tooltip"
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Link>
      )}
    </div>
  );
};
