import GeographicLocation, { parseGeographicLocation } from 'utils/types/GeographicLocation';

export type Blueprint = {
  address?: string;
  bucket: string;
  customer_id?: string;
  project_group_id?: string;
  description?: string;
  filename: string;
  id: string;
  tags?: string;
  marked_as_primary: boolean;
  mime_type: string;
  storage_path: string;
  storage_path_thumbnail: string;
  storage_url_thumbnail: string;
  storage_url: string;

  // Relations
  geographic_location?: GeographicLocation;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;

  // Custom properties not saved in database
  descriptionTruncated: string;
};

export const parseBlueprint = (blueprint: any): Blueprint => {
  blueprint.created_date = new Date(blueprint.created_date);
  if (blueprint.updated_date) {
    blueprint.updated_date = new Date(blueprint.updated_date);
  }

  blueprint.descriptionTruncated = blueprint.description
    ? blueprint.description.length > 100
      ? `${blueprint.description.substring(0, 97)}...`
      : blueprint.description
    : blueprint.filename;

  if (blueprint.geographic_location) {
    blueprint.geographic_location = parseGeographicLocation(blueprint.geographic_location);
  }

  return blueprint as Blueprint;
};
