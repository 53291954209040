import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import { parseGateway } from 'utils/types/Gateway';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  includeGeographicLocation?: boolean;
};

export const useCustomerGateways = (
  customerId?: string,
  {
    enableGet = true,
    ignoreAdminCustomerOverwrite = false,
    includeGeographicLocation = true,
  }: Options = {},
) => {
  const queryKey = ['customerGateways', customerId, includeGeographicLocation];

  const { showAllCustomers } = useSelectedCustomer();
  const url =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? '/admin/gateways'
      : `/customers/${customerId}/gateways`;

  // Get
  const {
    data: gateways,
    isPending,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(url, {
        params: {
          include_geographic_location: includeGeographicLocation,
        },
      }),
    select: (gateways: any[]) => gateways.map(gateway => parseGateway(gateway)),
    enabled: !!customerId && enableGet,
  });

  return {
    gateways,
    isPending: isPending || isLoading || isFetching,
    refetch,
  };
};
