import { t } from 'translations';

const enum DownlinkStatus {
  Submitted = 0,
  Queued = 10,
  Cancelled = 20,
  Sent = 30,
  Confirmed = 40,
}

export const getDownlinkStatusText = (downlinkStatus: DownlinkStatus): string => {
  switch (downlinkStatus) {
    case DownlinkStatus.Submitted:
      return t('utils:enums.DownlinkStatus.texts.Submitted');
    case DownlinkStatus.Queued:
      return t('utils:enums.DownlinkStatus.texts.Queued');
    case DownlinkStatus.Cancelled:
      return t('utils:enums.DownlinkStatus.texts.Cancelled');
    case DownlinkStatus.Sent:
      return t('utils:enums.DownlinkStatus.texts.Sent');
    case DownlinkStatus.Confirmed:
      return t('utils:enums.DownlinkStatus.texts.Confirmed');
    default:
      return 'n/a';
  }
};

export default DownlinkStatus;
