import React, { Dispatch, SetStateAction } from 'react';

import { BlueprintPosition } from 'utils/types';

type BlueprintCanvasContextProps = {
  hours: number;
  setHours: Dispatch<SetStateAction<number>>;
  lockSensors: boolean;
  setLockSensors: Dispatch<SetStateAction<boolean>>;
  activeBlueprintPosition?: BlueprintPosition;
  setActiveBlueprintPosition: Dispatch<SetStateAction<BlueprintPosition | undefined>>;
};

export const BlueprintCanvasContext = React.createContext<BlueprintCanvasContextProps>({
  hours: 0,
  setHours: () => {},
  lockSensors: true,
  setLockSensors: () => {},
  activeBlueprintPosition: undefined,
  setActiveBlueprintPosition: () => {},
});
