import { faStopwatch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/modals/Modal';
import SensorDownlinks from 'components/sensors/SensorDownlinks';
import SensorFrequencyUpdate from 'components/sensors/SensorFrequencyUpdate';
import { useSensorDownlinks } from 'utils/hooks/data';

const SensorDownlinksModal: React.FC<{
  sensorId: string;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  defaultValue?: number;
  onSubmit?: (frequency: number) => Promise<void>;
}> = ({ sensorId, show, setShow, defaultValue, onSubmit }) => {
  const { t } = useTranslation('components');

  const { downlinks } = useSensorDownlinks(sensorId, { enableGet: show });

  return (
    <Modal
      title={
        <>
          <FontAwesomeIcon className="mr-3" icon={faStopwatch} />
          {t('modals.SensorDownlinksModal.title')}
        </>
      }
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      {sensorId && (
        <>
          {onSubmit && (
            <div className="mb-5">
              <h2>{t('modals.SensorDownlinksModal.SensorFrequencyUpdate.title')}</h2>
              <SensorFrequencyUpdate value={defaultValue} onSubmit={onSubmit} />
            </div>
          )}

          {(downlinks || []).length > 0 && (
            <>
              <h2>{t('modals.SensorDownlinksModal.SensorDownlinks.title')}</h2>
              <SensorDownlinks
                sensorId={sensorId}
                showModal={show}
                setShowModal={setShow}
                hideSubmitButton
              />
            </>
          )}
        </>
      )}
    </Modal>
  );
};

export default SensorDownlinksModal;
