import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import GatewayFile, { parseGatewayFile } from 'utils/types/GatewayFile';

export const useGatewayPrimaryFile = (
  gatewayId?: string,
  useThumbnail: boolean = false,
  enableGet: boolean = true,
) => {
  const queryKey = ['gatewayPrimaryFile', gatewayId, useThumbnail];

  // Gateway File
  const { data: file, isPending } = useQuery<GatewayFile | null>({
    queryKey,
    queryFn: () =>
      api.get(`/gateways/${gatewayId}/files/primary/`, {
        params: { use_thumbnail: useThumbnail },
        _disableErrorNotifications: true,
        _disableRedirects: true,
      }),
    select: (file: any) => {
      if (!file) return null;

      return parseGatewayFile(file);
    },
    enabled: enableGet && !!gatewayId,
    meta: {
      disableLoading: true,
    },
  });

  return {
    file,
    isPending,
  };
};
