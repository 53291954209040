import { useMutation } from '@tanstack/react-query';

import api from 'utils/api';
import queryClient from 'utils/queries/client';
import { useQueryCreate } from 'utils/queries/create';
import { parseCustomer } from 'utils/types/Customer';

export const useCustomers = () => {
  const queryKey = ['customers'];

  // Create
  const { mutateAsync: createCustomer } = useQueryCreate({
    api,
    queryKey,
    endpoint: '/customers',
    parseFn: parseCustomer,
    invalidateQueryKeys: [queryKey, ['userCustomers']],
    invalidateExact: false,
  });

  // Delete
  const { mutateAsync: deleteCustomerById } = useMutation({
    mutationFn: (customerId: string) => api.delete(`/customers/${customerId}`),
    onSuccess: (result, customerId, context) => {
      queryClient.invalidateQueries({ queryKey, exact: true });
      queryClient.removeQueries({ queryKey: ['customers', customerId] });
    },
  });

  return {
    createCustomer,
    deleteCustomerById,
  };
};
