import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HTMLAttributes } from 'react';

const Tooltip: React.FC<{ tooltip: string } & HTMLAttributes<HTMLParagraphElement>> = ({
  tooltip,
  ...props
}) => (
  <p data-tooltip-content={tooltip} data-tooltip-id="route-tooltip" {...props}>
    <FontAwesomeIcon className="text-brand-orange text-lg" icon={faInfo} />
  </p>
);

export default Tooltip;
