import { MouseEventHandler } from 'react';

export const SliderInput = ({
  id,
  onClick,
}: {
  id: string;
  onClick: MouseEventHandler<HTMLInputElement>;
}) => (
  <input
    className="absolute inset-0 w-full h-full cursor-pointer opacity-0"
    onClick={onClick}
    id={id}
    type="radio"
    name="slider_input"
  />
);
