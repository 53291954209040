import { AxiosRequestConfig } from 'axios'; //@ts-ignore
import NProgress from 'nprogress';

import debounce from 'utils/debounce';
import throttle from 'utils/throttle';

const ACTIVE_API_REQUESTS: AxiosRequestConfig[] = [];

const throttledStart = throttle(() => {
  NProgress.start();
}, 200);
const throttledInc = throttle(() => {
  NProgress.inc();
}, 200);
const debouncedDone = debounce(() => {
  NProgress.done();
}, 200);

const onStartingRequest = (config: AxiosRequestConfig) => {
  if (config?._disableProgressBar) return;

  if (ACTIVE_API_REQUESTS.length > 0) {
    throttledInc();
  } else {
    throttledStart();
  }
  ACTIVE_API_REQUESTS.push(config);
};

const onFinalizedRequest = (config?: AxiosRequestConfig) => {
  if (config?._disableProgressBar) return;

  // Stop NProgress bar if no more requests ongoing
  ACTIVE_API_REQUESTS.pop();
  if (ACTIVE_API_REQUESTS.length > 0) {
    throttledInc();
  } else {
    debouncedDone();
  }
};

export { onStartingRequest, onFinalizedRequest };
