import { faCheck, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import Spinner from 'components/Spinner';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import DemoApplication from 'utils/types/DemoApplication';

interface DemoApplicationTableProps {
  tableIdentifier: string;
  data: DemoApplication[];
  onClick?: (row?: DemoApplication) => void;
  onDelete?: (row?: DemoApplication) => void;
}

export const DemoApplicationsTable: React.FC<DemoApplicationTableProps> = ({
  tableIdentifier,
  data,
  onClick,
  onDelete,
  ...props
}) => {
  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<DemoApplication>();

    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('tables.DemoApplicationsTable.columns.name.text'),
        cell: ({ getValue, row: { original } }) => (
          <TableLink
            name={getValue()}
            url={`/admin/demo_applications/${original.id}`}
            datatip={t('tables.DemoApplicationsTable.columns.name.dataTip.1')}
            externalDatatip={t('tables.DemoApplicationsTable.columns.name.dataTip.2')}
          />
        ),
        enableColumnFilter: true,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),

      columnHelper.accessor('setup_finished_at', {
        id: 'setup_finished_at',
        header: () => t('tables.DemoApplicationsTable.columns.setup_finished_at.text'),
        enableColumnFilter: false,
        sortingFn: 'datetime',
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) {
            return (
              <>
                <Spinner className="mr-2" role="status" aria-hidden="true" />
                <span
                  data-tooltip-content={t(
                    'tables.DemoApplicationsTable.columns.setup_finished_at.dataTip.1',
                  )}
                  data-tooltip-id="route-tooltip"
                >
                  {t('tables.DemoApplicationsTable.columns.setup_finished_at.html.1')}
                </span>
              </>
            );
          }

          return (
            <p className="flex justify-between">
              <span
                data-tooltip-content={t(
                  'tables.DemoApplicationsTable.columns.setup_finished_at.dataTip.2',
                  {
                    date: dateToLocaleString(value),
                  },
                )}
                data-tooltip-id="route-tooltip"
              >
                {t('tables.DemoApplicationsTable.columns.setup_finished_at.html.2')}
              </span>

              <FontAwesomeIcon
                className="text-brand-green"
                data-tooltip-content={t(
                  'tables.DemoApplicationsTable.columns.setup_finished_at.dataTip.3',
                )}
                data-tooltip-id="route-tooltip"
                icon={faCheck}
                size="lg"
              />
            </p>
          );
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.DemoApplicationsTable.columns.created_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <>
            <span
              data-tooltip-content={dateToLocaleString(getValue())}
              data-tooltip-id="route-tooltip"
            >
              {dateToPrettyDate(getValue())}
            </span>
            {onDelete && (
              <FontAwesomeIcon
                className="text-brand-orange float-right cursor-pointer"
                icon={faMinusSquare}
                size="lg"
                onClick={event => {
                  event.stopPropagation();
                  onDelete(original);
                }}
              />
            )}
          </>
        ),
      }),
    ];
  }, [t, onDelete]);

  return (
    <Table
      tableIdentifier={`demo-applications-table-${tableIdentifier}`}
      data={data}
      columns={columns}
      showSearchBar={true}
      showTogglingFilters={true}
      placeholder={t('tables.DemoApplicationsTable.search.placeholder')}
      pageSize={10}
      onClick={onClick}
      noDataPlaceholder={t('tables.DemoApplicationsTable.noDataIndication')}
      {...props}
    />
  );
};
