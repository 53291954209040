import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';

const Spinner: React.FC<ComponentPropsWithoutRef<'span'>> = ({ className, ...props }) => (
  <span
    {...props}
    className={classNames(
      'w-4 inline-block h-4 border-[3px] border-r-transparent animate-spin will-change-transform duration-75 ease-linear rounded-full',
      className,
    )}
  ></span>
);

export default Spinner;
