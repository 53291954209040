import React, { HTMLAttributes } from 'react';

import {
  SensorTagCasingBadge,
  SensorTagScrewTypeBadge,
  SensorTagUsageBadge,
} from 'components/badges/SensorTagBadges';
import { SensorTagCasing, SensorTagScrewType, SensorTagUsage } from 'utils/enums';
import { SensorTag } from 'utils/types/SensorTags';

const SensorTagsTheSensor: React.FC<
  {
    sensorTagCasing?: SensorTagCasing;
    sensorTagScrewType?: SensorTagScrewType;
    sensorTagUsage?: SensorTagUsage;
    onClick?: (sensorTag: SensorTag) => Promise<void>;
    useColors?: boolean;
  } & Omit<HTMLAttributes<HTMLSpanElement>, 'onClick'>
> = ({ sensorTagCasing, sensorTagScrewType, sensorTagUsage, className, onClick, ...props }) => (
  <>
    {sensorTagScrewType && (
      <SensorTagScrewTypeBadge
        className={className}
        sensorTagScrewType={sensorTagScrewType}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagCasing && (
      <SensorTagCasingBadge
        className={className}
        sensorTagCasing={sensorTagCasing}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagUsage && (
      <SensorTagUsageBadge
        className={className}
        sensorTagUsage={sensorTagUsage}
        onClick={onClick}
        {...props}
      />
    )}
  </>
);

export default SensorTagsTheSensor;
