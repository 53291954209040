import { HTMLAttributes } from 'react';

import ExternalLink from 'components/ExternalLink';

export const HubSpotDealLink: React.FC<
  {
    hubSpotDealId?: string;
    className?: string;
    text?: string;
  } & HTMLAttributes<HTMLAnchorElement>
> = ({ hubSpotDealId, text, ...props }) =>
  hubSpotDealId ? (
    <ExternalLink
      url={`https://app-eu1.hubspot.com/contacts/144490397/record/0-3/${hubSpotDealId}/`}
      text={text || hubSpotDealId}
      {...props}
    />
  ) : (
    'n/a'
  );
