import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { extractMatchedId } from 'utils/route-helpers';

const AdminBlueprints = lazy(
  () => import(/* webpackChunkName: "AdminBlueprints" */ 'pages/admin/AdminBlueprints'),
);
const AdminCustomer = lazy(
  () => import(/* webpackChunkName: "AdminCustomer" */ 'pages/admin/AdminCustomer'),
);
const AdminCustomers = lazy(
  () => import(/* webpackChunkName: "AdminCustomers" */ 'pages/admin/AdminCustomers'),
);
const AdminDeliveryNotesReports = lazy(
  () =>
    import(
      /* webpackChunkName: "AdminDeliveryNotesReports" */ 'pages/admin/AdminDeliveryNotesReports'
    ),
);
const AdminDemoApplication = lazy(
  () => import(/* webpackChunkName: "AdminDemoApplication" */ 'pages/admin/AdminDemoApplication'),
);
const AdminDemoApplications = lazy(
  () => import(/* webpackChunkName: "AdminDemoApplications" */ 'pages/admin/AdminDemoApplications'),
);
const AdminDocumentChanges = lazy(
  () => import(/* webpackChunkName: "AdminDocumentChanges" */ 'pages/admin/AdminDocumentChanges'),
);
const AdminDashboardCustomerOverview = lazy(
  () =>
    import(
      /* webpackChunkName: "AdminDashboardCustomerOverview" */ 'pages/admin/AdminDashboardCustomerOverview'
    ),
);
const AdminDashboardAnomalyReport = lazy(
  () =>
    import(
      /* webpackChunkName: "AdminDashboardAnomalyReport" */ 'pages/admin/AdminDashboardAnomalyReport'
    ),
);
const AdminGateways = lazy(
  () => import(/* webpackChunkName: "AdminGateways" */ 'pages/admin/AdminGateways'),
);
const AdminImages = lazy(
  () => import(/* webpackChunkName: "AdminImages" */ 'pages/admin/AdminImages'),
);
const AdminInsights = lazy(
  () => import(/* webpackChunkName: "AdminInsights" */ 'pages/admin/AdminInsights'),
);
const AdminSalesOrders = lazy(
  () => import(/* webpackChunkName: "AdminSalesOrders" */ 'pages/admin/AdminSalesOrders/index'),
);
const AdminSensors = lazy(
  () => import(/* webpackChunkName: "AdminSensors" */ 'pages/admin/AdminSensors'),
);
const AdminUserLogin = lazy(
  () => import(/* webpackChunkName: "AdminUserLogin" */ 'pages/admin/AdminUserLogin'),
);
const AdminUsers = lazy(
  () => import(/* webpackChunkName: "AdminUsers" */ 'pages/admin/AdminUsers'),
);
const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */ 'pages/Page404'));

const routes: RouteObject[] = [
  {
    path: '/admin/',
    Component: () => <Navigate to="/admin/sales_orders/new" replace={true} />,
  },
  {
    path: '/admin/dashboard',
    Component: () => <Navigate to="/admin/sales_orders/new" replace={true} />,
  },
  // {
  //   path: '/admin/alarms',
  //   Component: () => <Navigate to="/admin/alarms/overview" replace={true} />,
  // },
  // {
  //   path: '/admin/alarms/*',
  //   Component: AdminAlarmsPage,
  // },
  {
    path: '/admin/blueprints',
    Component: AdminBlueprints,
  },
  {
    path: '/admin/customers',
    Component: AdminCustomers,
  },
  {
    path: '/admin/customers/:id/*',
    Component: AdminCustomer,
  },
  {
    path: '/admin/insights/*',
    Component: AdminInsights,
  },
  {
    path: '/admin/users',
    Component: AdminUsers,
  },
  {
    path: '/admin/users/:id',
    // TODO: Does this work?
    Component: (props: any) => (
      <Navigate to={`/user/organization/users/${extractMatchedId(props)}`} replace={true} />
    ),
  },
  {
    path: '/admin/users/login',
    Component: AdminUserLogin,
  },
  {
    path: '/admin/gateways',
    Component: AdminGateways,
  },
  {
    path: '/admin/sales_orders/*',
    Component: AdminSalesOrders,
  },
  {
    path: '/admin/delivery_notes_reports',
    Component: AdminDeliveryNotesReports,
  },
  {
    path: '/admin/sensors',
    Component: AdminSensors,
  },
  {
    path: '/admin/demo_applications',
    Component: AdminDemoApplications,
  },
  {
    path: '/admin/demo_applications/:id',
    Component: AdminDemoApplication,
  },
  {
    path: '/admin/document_changes',
    Component: AdminDocumentChanges,
  },
  {
    path: '/admin/customer_overview',
    Component: AdminDashboardCustomerOverview,
  },
  {
    path: '/admin/anomaly_report',
    Component: AdminDashboardAnomalyReport,
  },
  {
    path: '/admin/images',
    Component: AdminImages,
  },
  {
    path: '*',
    Component: Page404,
  },
];

export default routes;
