import { LatLngExpression, LeafletMouseEventHandlerFn } from 'leaflet/index';
import { CircleMarker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import GatewayState from 'utils/enums/GatewayState';
import MoistureState from 'utils/enums/MoistureState';
import Position from 'utils/types/Position';

const PointMarker: React.FC<{
  state: GatewayState | MoistureState;
  position: Position;
  onClick: LeafletMouseEventHandlerFn;
}> = ({ state, position, onClick }) => (
  <CircleMarker
    className="ws-map-marker"
    pathOptions={{
      className: state,
    }}
    center={[...position, 0.0] as LatLngExpression}
    color="#0095ff"
    stroke={false}
    fillOpacity={1.0}
    radius={16}
    eventHandlers={{
      click: onClick,
    }}
  />
);

export default PointMarker;
