import classNames from 'classnames';
import { useField } from 'formik';
import { ComponentPropsWithRef, useMemo } from 'react';

import Tooltip from 'components/Tooltip';

export interface FormTextInputProps {
  prepend?: JSX.Element | string;
  append?: JSX.Element | string;
  prependTooltip?: string;
  appendTooltip?: string;
  label?: string;
  tooltip?: string;
  text?: JSX.Element | string;
  highlightChanges?: boolean;
  labelClassName?: string;
  inputClassName?: string;
  prependClassName?: string;
  appendClassName?: string;
}

export const FormTextInput: React.FC<FormTextInputProps & ComponentPropsWithRef<'input'>> = ({
  name,
  prepend,
  append,
  prependTooltip,
  appendTooltip,
  label,
  value,
  tooltip,
  text,
  type = 'text',
  highlightChanges = false,
  required = false,
  className,
  labelClassName,
  ref,
  inputClassName,
  prependClassName,
  appendClassName,
  ...props
}) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);
  const [field, meta] = useField(String(name));

  // helpers: {setValue, setTouched, setError}
  // props:   {name, disabled}
  // field:   {name, value, onChange, onBlur}

  // Define if the value is valid
  // const isInvalid = (!props.disabled && meta.error && meta.touched);
  const isInvalid = !!meta.error;
  /* const isValid = !meta.error && (!!meta.touched || !!field.value); */

  // Make sure a empty string is used if no value is defined
  if (typeof field.value === 'string') {
    field.value = field.value || '';
  }

  if (typeof field.value === 'boolean') {
    field.value = String(field.value);
  }

  if (field.value instanceof Date) {
    field.value = field.value.toLocaleDateString();
  }
  return (
    <div className={classNames(className, { 'mb-3': !className })}>
      {(label || required || tooltip) && (
        <div className="flex justify-between">
          <span>
            {label && (
              <label
                className={classNames(labelClassName, { inline: required })}
                htmlFor={controlId}
              >
                {label}
              </label>
            )}
            {required && (
              <span
                className="inline"
                data-tooltip-content="This is a required field"
                data-tooltip-id="route-tooltip"
              >
                *
              </span>
            )}
          </span>
          {tooltip && <Tooltip className="px-2" tooltip={tooltip} />}
        </div>
      )}

      <div className="flex bg-brand-gray-light-4 rounded-lg relative">
        {prepend && (
          <span
            className={classNames(
              'absolute left-0 inset-y-0 pointer-events-none text-brand-gray flex justify-center align-middle items-center py-1.5 px-3.5',
              prependClassName,
            )}
          >
            {prepend}
          </span>
        )}
        <input
          ref={ref}
          id={controlId}
          className={classNames(
            {
              'ring-0 border-brand-orange': isInvalid,
              'pl-9': prepend,
            },
            inputClassName,
          )}
          type={type}
          {...field}
          {...props}
          value={field.value || ''}
        />
        {append && (
          <span
            className={classNames(
              'rounded-lg absolute right-5 inset-y-0 text-brand-gray pointer-events-none flex justify-center align-middle items-center py-1.5 px-3.5 rounded-l-none',
              appendClassName,
            )}
          >
            {append}
          </span>
        )}
      </div>
      {text && <p>{text}</p>}
      {meta.error && <p className="text-brand-orange mb-1.5">{meta.error}</p>}
    </div>
  );
};
