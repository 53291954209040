import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { AlertWithIcon, AlertWithIconProps } from 'components/alerts/AlertWithIcon';

export const Danger: React.FC<Omit<AlertWithIconProps, 'variant' | 'icon'>> = ({
  className,
  children,
  ...props
}) => (
  <AlertWithIcon
    className={classNames('text-sm', className)}
    variant="danger"
    icon={faExclamationTriangle}
    {...props}
  >
    {children}
  </AlertWithIcon>
);
