import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import HumidityState from 'utils/enums/HumidityState';
import { getHumidityStateText } from 'utils/sensor/texts';

export const HumidityStateBadge: React.FC<
  { state?: HumidityState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) =>
  state ? (
    <Badge
      {...props}
      className={classNames('text-xs font-medium text-white', className, {
        'bg-brand-gray-light-2': state === HumidityState.UNKNOWN,
        'bg-humidityState0-10': state === HumidityState.HUMIDITY_0_10,
        'bg-humidityState10-20': state === HumidityState.HUMIDITY_10_20,
        'bg-humidityState20-30': state === HumidityState.HUMIDITY_20_30,
        'bg-humidityState30-40': state === HumidityState.HUMIDITY_30_40,
        'bg-humidityState40-50': state === HumidityState.HUMIDITY_40_50,
        'bg-humidityState50-60': state === HumidityState.HUMIDITY_50_60,
        'bg-humidityState60-70': state === HumidityState.HUMIDITY_60_70,
        'bg-humidityState70-80': state === HumidityState.HUMIDITY_70_80,
        'bg-humidityState80-90': state === HumidityState.HUMIDITY_80_90,
        'bg-humidityState90-100': state === HumidityState.HUMIDITY_90_100,
      })}
    >
      {getHumidityStateText(state)}
    </Badge>
  ) : (
    <Spinner className="ml-2 text-brand-gray-light-1" />
  );
