import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import queryClient from 'utils/queries/client';

interface UseQueryUpdateOptions<T, O = T> {
  queryKey: any;
  api: AxiosInstance;
  endpoint: string;
  invalidateQueryKeys?: any[];
  onSuccess?: (data: any, variables: Partial<T>) => Promise<any> | void;
  parseFn?: (data: any) => O;
}

export const useQueryUpdate = <T, O = T>({
  queryKey,
  api,
  endpoint,
  invalidateQueryKeys = [],
  onSuccess,
}: UseQueryUpdateOptions<T, O>) =>
  useMutation({
    onMutate: () => {
      // Get current values (before update)
      const previousValue = queryClient.getQueryData(queryKey);
      return { previousValue };
    },
    mutationFn: (values: Partial<T>) => {
      queryClient.setQueryData(queryKey, (old: any) => ({ ...old, ...values }));
      return api.put(endpoint, values);
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(queryKey, data);

      invalidateQueryKeys.forEach(invalidateQueryKey => {
        queryClient.invalidateQueries({ queryKey: invalidateQueryKey, exact: false });
      });

      if (onSuccess) {
        onSuccess(data, variables);
      }
    },
    onError: (err, variables, context) => {
      // Roll back to previous values (before update)
      queryClient.setQueryData(queryKey, context?.previousValue);
    },
    // onSettled: () => {},
  });
