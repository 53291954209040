import {
  faSignal,
  faArrowRotateLeft,
  faArrowsLeftRight,
  faCalendar,
  faDownload,
  faExpandArrowsAlt,
  faFilter,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { ToolboxComponentOption } from 'echarts/components';

import { faSquirtyAnomaly, faSquirtyChartForecast, faSquirtyGateway } from 'components/icons';
import { t } from 'translations';
import { brandOrange, brandGrayLight2 } from 'utils/colors';
import { dateToFormattedDateString } from 'utils/date';
import TimePeriod from 'utils/types/TimePeriod';

interface Setting {
  showTitle?: boolean;
  bottom: number;
  right: number;
  feature: {
    myAnomalyFiltering?: {};
    myMarkAnomalies?: {};
    myShowForecasts?: {};
    myShowSignalStrength?: {};
    myShowGateways?: {};
    myTimePeriodSelect?: {};
    myTimePeriodReset?: {};
    brush?: {};
    saveAsImage?: {};
    dataZoom?: {};
  };
  tooltip: Tooltip;
  itemSize: number;
  itemGap: number;
}

interface Tooltip {
  show?: boolean;
  formatter: (param: any) => string;
}

const toolboxSettings = ({
  saveAsImageFilename,
  onTimePeriodSelectClick,
  onTimePeriodResetClick,
  onShowForecastsClick,
  onShowSignalStrengthClick,
  onShowGatewaysClick,
  onMarkAnomaliesClick,
  onShowAnomalyFilteringClick,
  showForecasts,
  showBrush = true,
  showZoom = true,
  hardwareId,
  timePeriod,
  highlightPeriod,
}: {
  saveAsImageFilename?: string;
  onTimePeriodSelectClick?: () => void;
  onTimePeriodResetClick?: () => void;
  onShowForecastsClick?: () => void;
  onShowSignalStrengthClick?: () => void;
  onShowGatewaysClick?: () => void;
  onMarkAnomaliesClick?: (timePeriod: TimePeriod) => void;
  onShowAnomalyFilteringClick?: () => void;
  showForecasts?: boolean;
  showBrush?: boolean;
  showZoom?: boolean;
  hardwareId?: string;
  timePeriod?: TimePeriod;
  highlightPeriod?: TimePeriod;
}): ToolboxComponentOption => {
  const settings: Setting = {
    showTitle: false,
    bottom: 0,
    right: 25,
    feature: {},
    tooltip: {
      show: true,
      formatter: (param: any) => `<div>${param.title}</div>`,
    },
    itemSize: 20,
    itemGap: 11,
  };

  // User-defined tool name could only start with my
  // https://echarts.apache.org/en/option.html#toolbox.feature
  if (onMarkAnomaliesClick && highlightPeriod?.[0] && highlightPeriod?.[1]) {
    settings.feature.myMarkAnomalies = {
      show: true,
      title: t('utils:plots.toolbox.markAnomalies.title'),
      icon: faSquirtyAnomaly.icon[4],
      iconStyle: {
        color: brandOrange,
        borderColor: brandOrange,
      },
      onclick: () => {
        onMarkAnomaliesClick(highlightPeriod);
      },
    };
  }

  if (onShowAnomalyFilteringClick) {
    settings.feature.myAnomalyFiltering = {
      show: true,
      title: t('utils:plots.toolbox.anomalyFiltering.title'),
      icon: faFilter.icon[4],
      iconStyle: {
        color: brandGrayLight2,
        borderColor: '#fff',
      },
      onclick: () => {
        onShowAnomalyFilteringClick();
      },
    };
  }

  if (onShowForecastsClick) {
    settings.feature.myShowForecasts = {
      show: true,
      title: t('utils:plots.toolbox.showForecasts.title'),
      icon: faSquirtyChartForecast.icon[4],
      iconStyle: {
        color: brandGrayLight2,
        borderColor: showForecasts ? brandOrange : brandGrayLight2,
      },
      onclick: onShowForecastsClick,
    };
  }

  if (onShowSignalStrengthClick) {
    settings.feature.myShowSignalStrength = {
      show: true,
      title: t('utils:plots.toolbox.showSignalStrength.title'),
      icon: faSignal.icon[4],
      iconStyle: {
        color: brandGrayLight2,
      },
      onclick: onShowSignalStrengthClick,
    };
  }

  if (onShowGatewaysClick) {
    settings.feature.myShowGateways = {
      show: true,
      title: t('utils:plots.toolbox.showGateways.title'),
      icon: faSquirtyGateway.icon[4],
      iconStyle: {
        color: brandGrayLight2,
      },
      onclick: onShowGatewaysClick,
    };
  }

  if (onTimePeriodSelectClick) {
    settings.feature.myTimePeriodSelect = {
      show: true,
      title: t('utils:plots.toolbox.timePeriodSelect.title'),
      icon: faCalendar.icon[4],
      iconStyle: {
        color: brandGrayLight2,
        borderColor: '#fff',
      },
      onclick: () => {
        onTimePeriodSelectClick();
      },
    };
  }

  if (onTimePeriodResetClick) {
    settings.feature.myTimePeriodReset = {
      show: true,
      title: t('utils:plots.toolbox.timePeriodReset.title'),
      icon: faArrowRotateLeft.icon[4],
      iconStyle: {
        color: brandGrayLight2,
        borderColor: '#fff',
      },
      onclick: () => {
        onTimePeriodResetClick();
      },
    };
  }

  if (showBrush) {
    settings.feature.brush = {
      title: {
        lineX: t('utils:plots.toolbox.brush.title'),
      },
      iconStyle: {
        color: brandGrayLight2,
        borderColor: '#fff',
      },
      icon: {
        lineX: faArrowsLeftRight.icon[4],
      },
    };
  }

  if (saveAsImageFilename) {
    const filename =
      hardwareId && timePeriod?.[0] && timePeriod?.[1]
        ? `${saveAsImageFilename}-${dateToFormattedDateString(
            timePeriod[0],
          )}-${dateToFormattedDateString(timePeriod[1])}-${hardwareId}`
        : saveAsImageFilename;

    settings.feature.saveAsImage = {
      title: t('utils:plots.toolbox.saveAsImage.title'),
      iconStyle: {
        color: brandGrayLight2,
        borderColor: '#fff',
      },
      name: filename,
      icon: faDownload.icon[4],
    };
  }

  if (showZoom) {
    settings.feature.dataZoom = {
      title: {
        zoom: t('utils:plots.toolbox.dataZoom.title.zoom'),
        back: t('utils:plots.toolbox.dataZoom.title.back'),
      },
      iconStyle: {
        color: brandGrayLight2,
        borderColor: '#fff',
      },
      icon: {
        zoom: faSearch.icon[4],
        back: faExpandArrowsAlt.icon[4],
      },
    };
  }

  return settings;
};

export default toolboxSettings;
