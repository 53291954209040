import { useState } from 'react';

import Thumbnail, { ThumbnailProps } from 'components/thumbnails/Thumbnail';
import { useSensorPrimaryFile } from 'utils/hooks/data';

const SensorThumbnail: React.FC<
  {
    sensorId: string;
    sensorFileId?: string;
  } & ThumbnailProps
> = ({ sensorId, sensorFileId, ...props }) => {
  const [loadOriginal, setLoadingOriginal] = useState(false);

  const { file } = useSensorPrimaryFile(sensorId, true, !!sensorFileId);
  const { file: fileOriginal } = useSensorPrimaryFile(
    sensorId,
    false,
    !!sensorFileId && loadOriginal
  );

  return (
    <Thumbnail
      tooltipId={`tooltip-thumbnail-${sensorId}`}
      loadOriginal={loadOriginal}
      setLoadingOriginal={setLoadingOriginal}
      file={file}
      fileOriginal={fileOriginal}
      {...props}
    />
  );
};

export default SensorThumbnail;
