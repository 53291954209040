import * as Sentry from '@sentry/react';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { t, i18 } from 'translations';
import { notificationError } from 'utils/notifications';
import { onFinalizedRequest, onStartingRequest } from 'utils/progress-bar';

// Define config
const config: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BASE_URL || 'https://staging-dev.tector.com/api/v1',
  timeout: 60000,
};

// Initialize api
const api: AxiosInstance = axios.create(config);

// Add a request interceptor
api.interceptors.request.use(
  config => {
    onStartingRequest(config);
    config.withCredentials = true;
    config.headers['Accept-Language'] = i18.language;
    return config;
  },
  (err: Error & { config?: AxiosRequestConfig }) => {
    // Capture error with sentry
    if (!err.config?._disableSentryErrorCapture) Sentry.captureException(err);
    return Promise.reject(err);
  },
);

// Add a response interceptor
api.interceptors.response.use(
  ({ config, data }) => {
    onFinalizedRequest(config);
    return data;
  },
  (err: Error & { config?: AxiosRequestConfig }) => {
    onFinalizedRequest();

    // Check if we should show error message or not
    const showError = !err.config?._disableErrorNotifications;
    const allowRedirects = !err.config?._disableRedirects;
    const allowSentryErrorCapture = !err.config?._disableSentryErrorCapture;
    const unknownErrorMessage = t('utils:sessions.UserSession.unkownError');

    if (axios.isAxiosError(err)) {
      // Get error message from API (fallback to unknown error)
      const errorMessage = err.response?.data.detail
        ? err.response.data.detail
        : unknownErrorMessage;

      // Show error
      if (showError) notificationError(errorMessage);

      // Extract http status code
      const statusCode = err.response?.status;

      if (statusCode === 401 && allowRedirects) {
        // Redirect user to login page
        window.location.replace('/login');

        return Promise.reject(err);
      }

      // Capture error with sentry
      if (allowSentryErrorCapture) Sentry.captureException(err);

      return Promise.reject(err);
    }

    // Capture error with sentry
    if (allowSentryErrorCapture) {
      Sentry.captureException(err);
    }

    // Show unknown error
    if (showError) notificationError(unknownErrorMessage);

    return Promise.reject(err);
  },
);

export default api;
