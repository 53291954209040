import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import ActivityState from 'utils/enums/ActivityState';
import { getActivityStateText } from 'utils/sensor/texts';

export const ActivityStateBadge: React.FC<
  { state?: ActivityState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) =>
  state ? (
    <Badge
      {...props}
      className={classNames('text-xs font-medium text-white', className, {
        'bg-brand-green': state === ActivityState.ACTIVE,
        'bg-brand-gray-light-2': state === ActivityState.INACTIVE,
        'bg-brand-orange': state === ActivityState.DEREGISTERED,
      })}
    >
      {getActivityStateText(state)}
    </Badge>
  ) : (
    <Spinner className="ml-2 text-brand-gray-light-1" />
  );
