import { faLinkSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSensorGroupParentGroup } from 'utils/hooks/data';
import { notificationSuccess, notificationWarningConfirm } from 'utils/notifications';

export const DetachButton: React.FC<{
  groupId: string;
  className?: string;
  onClick?: (e: MouseEvent<HTMLElement>) => void;
}> = ({ className, groupId, onClick }) => {
  const [isHovering, setIsHovering] = useState(false);

  const { t } = useTranslation('components');

  const { releaseGroupFromParent } = useSensorGroupParentGroup(groupId, {
    enableGet: false,
  });

  return (
    <button
      className={classNames(
        'cursor-pointer',
        {
          'text-brand-gray': !isHovering,
          'text-brand-orange': isHovering,
        },
        className,
      )}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={async e => {
        e.stopPropagation();
        e.preventDefault();

        const confirmed = await notificationWarningConfirm({
          text: t('GroupHierarchy.GroupHierarchyNode.DetachButton.warning.text'),
          title: t('GroupHierarchy.GroupHierarchyNode.DetachButton.warning.title'),
          cancelButtonText: t(
            'GroupHierarchy.GroupHierarchyNode.DetachButton.warning.cancelButtonText',
          ),
          confirmButtonText: t(
            'GroupHierarchy.GroupHierarchyNode.DetachButton.warning.confirmButtonText',
          ),
        });
        if (!confirmed) return;
        await releaseGroupFromParent();
        notificationSuccess(
          t('GroupHierarchy.GroupHierarchyNode.DetachButton.warning.successText'),
        );

        onClick?.(e);
      }}
      data-tooltip-content={t('GroupHierarchy.GroupHierarchyNode.DetachButton.text')}
      data-tooltip-id="route-tooltip"
    >
      <FontAwesomeIcon icon={faLinkSlash} />
    </button>
  );
};
