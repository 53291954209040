import { t } from 'translations';
import { SensorTagConstructionPhase } from 'utils/enums';

export const getSensorTagConstructionPhaseText = (
  sensorTagConstructionPhase: SensorTagConstructionPhase,
) => {
  switch (sensorTagConstructionPhase) {
    case SensorTagConstructionPhase.DuringConstruction:
      return t('utils:texts.sensor-tags.getSensorTagConstructionPhaseText.DuringConstruction');
    case SensorTagConstructionPhase.AfterConstruction:
      return t('utils:texts.sensor-tags.getSensorTagConstructionPhaseText.AfterConstruction');
    default:
      return 'n/a';
  }
};

export const getSensorTagConstructionPhaseTextShort = (
  sensorTagConstructionPhase: SensorTagConstructionPhase,
) => {
  switch (sensorTagConstructionPhase) {
    case SensorTagConstructionPhase.DuringConstruction:
      return t('utils:texts.sensor-tags.getSensorTagConstructionPhaseTextShort.DuringConstruction');
    case SensorTagConstructionPhase.AfterConstruction:
      return t('utils:texts.sensor-tags.getSensorTagConstructionPhaseTextShort.AfterConstruction');
    default:
      return 'n/a';
  }
};
