const enum ActivityState {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DEREGISTERED = 'DEREGISTERED', // Not registered in any LoRa server
}

export const sortOrderActivityState: { [key: string]: number } = [
  ActivityState.DEREGISTERED,
  ActivityState.INACTIVE,
  ActivityState.ACTIVE,
]
  .map((state, index) => [state, index])
  .reduce((o: any, [state, index]) => ({ ...o, [state]: index }), {});

export default ActivityState;
