import { forwardRef, useEffect, useRef } from 'react';

export const IndeterminateCheckbox = forwardRef<HTMLInputElement, { indeterminate?: boolean }>(
  ({ indeterminate, ...props }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      //@ts-ignore
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type="checkbox"
        className="text-amber form-checkbox cursor-pointer w-4 h-4 border focus:ring-transparent focus:outline outline-blue-500 focus:ring-opacity-25 rounded ease-in-out duration-150 transition-all" //@ts-ignore
        ref={resolvedRef}
        {...props}
      />
    );
  },
);
