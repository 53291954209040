import { faLinkSlash, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { differenceInDays } from 'date-fns/differenceInDays';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Table, TableLink } from 'components';
import ExternalLink from 'components/ExternalLink';
import ttnLogo from 'images/icons/ttn.svg';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { useAdminSensorLoRaDetails, useAdminSensors } from 'utils/hooks/data';
import Sensor from 'utils/types/Sensor';

type SensorTableData = Sensor & { customer_name?: string };

export const AdminSensorsTable: React.FC<{
  tableIdentifier: string;
  sensors?: Sensor[];
  isPending: boolean;
}> = ({ tableIdentifier, sensors, isPending }) => {
  const { t } = useTranslation('components');

  const { registerDeviceInTTNById, unregisterDeviceFromTTNById } = useAdminSensorLoRaDetails();
  const { deleteSensorById } = useAdminSensors({ enableGet: false });

  const sensorTableData: SensorTableData[] = useMemo(() => sensors || [], [sensors]);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SensorTableData>();

    return [
      columnHelper.accessor(sensor => sensor?.lora_details?.ttn_dev_id, {
        id: 'lora_details.ttn_dev_id',
        header: () => t('tables.SensorsTable.columns.name.text'),
        size: 100,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <TableLink
            name={getValue() || original.ttn_dev_id || original.name}
            url={`/user/sensors/${original.id}`}
            datatip={t('tables.SensorsTable.columns.name.dataTip.1')}
            externalDatatip={t('tables.SensorsTable.columns.name.dataTip.2')}
          />
        ),
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),

      columnHelper.accessor(sensor => sensor?.lora_details?.ttn_app_id, {
        id: 'lora_details.ttn_app_id',
        header: () => 'LoRa Server',
        cell: ({
          cell: {
            row: { original },
          },
        }) => {
          const ttnServerUrl = original.lora_details?.ttn_server_url;
          if (
            !ttnServerUrl ||
            !original.lora_details ||
            !original.lora_details.ttn_app_id ||
            !original.lora_details.ttn_dev_id
          )
            return 'n/a';
          const url = `${ttnServerUrl}/console/applications/${original.lora_details.ttn_app_id}/devices/${original.lora_details.ttn_dev_id}`;
          return (
            <div>
              <ExternalLink
                className="text-xs mr-2"
                url={url}
                text={
                  <>
                    <img src={ttnLogo} alt="TTN" className="w-4 h-4 inline-block mr-1" />
                    {original.lora_details.ttn_dev_id}
                  </>
                }
              />
            </div>
          );
        },
      }),

      columnHelper.accessor(sensor => sensor?.lora_details?.ttn_server_url, {
        id: 'lora_details.ttn_server_url',
        header: () => 'LoRa Server Action',
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => {
          const ttnServerUrl = getValue();
          if (
            !ttnServerUrl ||
            !original.lora_details ||
            !original.lora_details.ttn_app_id ||
            !original.lora_details.ttn_dev_id
          ) {
            if (
              original.lora_details &&
              original.lora_details.app_eui &&
              original.lora_details.app_key &&
              original.lora_details.dev_eui
            ) {
              return (
                <Button
                  onClick={async () => await registerDeviceInTTNById(original.id)}
                  size="xs"
                  variant="primary"
                >
                  Register in TTN
                </Button>
              );
            }
            return 'n/a';
          }

          return (
            <Button
              variant="danger"
              size="xs"
              onClick={async () => await unregisterDeviceFromTTNById(original.id)}
            >
              <FontAwesomeIcon className="mr-1" icon={faLinkSlash} />
              Delete from TTN
            </Button>
          );
        },
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.SensorsTable.columns.created_date.text'),
        sortingFn: 'datetime',
        enableColumnFilter: false,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <>
            <samp
              className="text-xs"
              data-tooltip-content={dateToLocaleString(getValue())}
              data-tooltip-id="route-tooltip"
            >
              {dateToPrettyDate(getValue())}
            </samp>

            {differenceInDays(new Date(), original.created_date) < 7 && (
              <FontAwesomeIcon
                className="ml-2 text-red-500 cursor-pointer"
                icon={faTrashCan}
                data-tooltip-content="Delete recently created sensor"
                data-tooltip-id="route-tooltip"
                onClick={async () => await deleteSensorById(original.id)}
              />
            )}
          </>
        ),
      }),

      columnHelper.accessor('hardware_id', {
        id: 'hardware_id',
        header: () => 'DevEUI',
        cell: ({ getValue }) => <samp className="text-xs">{getValue()}</samp>,
      }),

      columnHelper.accessor(sensor => sensor?.lora_details?.app_eui, {
        id: 'lora_details.app_eui',
        header: () => 'JoinEUI',
        cell: ({ getValue }) => <samp className="text-xs">{getValue()}</samp>,
      }),

      columnHelper.accessor(sensor => sensor?.lora_details?.app_key, {
        id: 'lora_details.app_key',
        header: () => 'AppKey',
        cell: ({ getValue }) => <samp className="text-xs">{getValue()}</samp>,
      }),

      columnHelper.accessor('type', {
        id: 'type',
        header: () => t('tables.SensorsTable.columns.type.text'),
        cell: ({ getValue }) => <samp className="text-xs">{getValue()}</samp>,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
        },
      }),
    ];
  }, [t, registerDeviceInTTNById, unregisterDeviceFromTTNById, deleteSensorById]);

  return (
    <Table
      tableIdentifier={`admin-sensors-${tableIdentifier}`}
      data={sensorTableData}
      columns={columns}
      showSearchBar
      showTogglingFilters={true}
      loading={isPending}
      sortBy={[
        {
          id: 'created_date',
          desc: true,
        },
      ]}
    />
  );
};
