import { useAdminAirtable } from 'utils/hooks/data/admin/useAdminAirtable';

export const useAdminAirtableSalesOrder = (airtableSalesOrderId?: string) => {
  const { airtableSalesOrders, isPending } = useAdminAirtable();
  const airtableSalesOrder = airtableSalesOrders?.find(
    airtableSalesOrder => airtableSalesOrder.id === airtableSalesOrderId,
  );

  return {
    airtableSalesOrder,
    isPending,
  };
};
