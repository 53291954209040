import FileBase, { parseFileBase } from 'utils/types/FileBase';

type CustomerFile = FileBase & {
  customer_id: string;
};

export const parseCustomerFile = (customerFile: any): CustomerFile =>
  parseFileBase(customerFile) as CustomerFile;

export default CustomerFile;
