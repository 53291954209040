import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseGatewayHeartbeat } from 'utils/types/GatewayHeartbeat';
import HookOptions from 'utils/types/HookOptions';

export const useGatewayHeartbeats = (
  gatewayId: string,
  {
    fromTimestamp,
    toTimestamp,
    limit,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
    limit?: number;
  },
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['gatewayHeartbeats', gatewayId, fromTimestamp, toTimestamp, limit];

  // Get
  const { data: heartbeats, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/gateways/${gatewayId}/heartbeats`, {
        params: { from_timestamp: fromTimestamp, to_timestamp: toTimestamp, limit },
      }),
    select: (data: any[]) => data.map(parseGatewayHeartbeat),
    enabled: enableGet && !!gatewayId,
  });

  return {
    heartbeats,
    isPending,
  };
};
