import { t } from 'translations';
import { SensorTagComplementaryElement } from 'utils/enums';

export const getSensorTagComplementaryElementText = (
  sensorTagComplementaryElement: SensorTagComplementaryElement,
) => {
  switch (sensorTagComplementaryElement) {
    case SensorTagComplementaryElement.ExternalDoors:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.ExternalDoors');
    case SensorTagComplementaryElement.ExternalWindows:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.ExternalWindows');
    case SensorTagComplementaryElement.InteriorDoors:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.InteriorDoors');
    case SensorTagComplementaryElement.InteriorWindowsHatchesAndTrapdoors:
      return t(
        'utils:texts.sensor-tags.getSensorTagComplementaryElementText.InteriorWindowsHatchesAndTrapdoors',
      );
    case SensorTagComplementaryElement.Dormers:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.Dormers');
    case SensorTagComplementaryElement.SkylightsSmokeHatchesAndRoofAccessHatches:
      return t(
        'utils:texts.sensor-tags.getSensorTagComplementaryElementText.SkylightsSmokeHatchesAndRoofAccessHatches',
      );
    case SensorTagComplementaryElement.Other:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.Other');
    case SensorTagComplementaryElement.NotRelevant:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.NotRelevant');
    case SensorTagComplementaryElement.RoofOutlet:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.RoofOutlet');
    case SensorTagComplementaryElement.SoilPipeVent:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.SoilPipeVent');
    case SensorTagComplementaryElement.VentilationOpening:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.VentilationOpening');
    case SensorTagComplementaryElement.OverpressureVentilation:
      return t(
        'utils:texts.sensor-tags.getSensorTagComplementaryElementText.OverpressureVentilation',
      );
    case SensorTagComplementaryElement.BMUFitting:
      return t('utils:texts.sensor-tags.getSensorTagComplementaryElementText.BMUFitting');
    default:
      return 'n/a';
  }
};

export const getSensorTagComplementaryElementTextShort = getSensorTagComplementaryElementText;
