import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';

import { faSquirtyAdmin } from 'components/icons';
import { useCurrentUser } from 'utils/hooks';

const AdminOnlyDiv: React.FC<
  React.PropsWithChildren<
    {
      useCard?: boolean;
    } & ComponentPropsWithoutRef<'div'>
  >
> = ({ children, useCard = true, className, ...props }) => {
  const { t } = useTranslation('components');

  const { isAdmin } = useCurrentUser();

  if (!isAdmin) return null;

  return useCard ? (
    <div
      className={classNames('bg-white rounded-xl px-4 lg:px-8 py-3 lg:py-5 mb-5', className)}
      style={{ boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)' }}
    >
      <FontAwesomeIcon
        className="float-right"
        icon={faSquirtyAdmin}
        size="lg"
        data-tooltip-content={t('AdminOnlyDiv.dataTip')}
        data-tooltip-id="route-tooltip"
      />
      <div {...props}>{children}</div>
    </div>
  ) : (
    <div className={classNames('relative mb-5', className)} {...props}>
      <FontAwesomeIcon
        className="absolute z-[1] top-1 right-1"
        icon={faSquirtyAdmin}
        size="lg"
        data-tooltip-content={t('AdminOnlyDiv.dataTip')}
        data-tooltip-id="route-tooltip"
      />
      {children}
    </div>
  );
};

export default AdminOnlyDiv;
