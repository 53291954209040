import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import Note, { parseNote } from 'utils/types/Note';

export const useNote = (noteId?: string): { note?: Note; isPending: boolean } => {
  // Get
  const { data: note, isPending } = useQuery<Note, Error>({
    queryKey: ['notes', noteId],
    queryFn: () => api.get(`/notes/${noteId}`),
    select: (note: any) => parseNote(note),
    enabled: !!noteId,
    placeholderData: {} as Note,
  });

  return {
    note,
    isPending,
  };
};
