import { t } from 'translations';
import GatewaySensorsDistance from 'utils/enums/GatewaySensorsDistance';

const getGatewaySensorsDistanceText = (gatewaySensorsDistance?: GatewaySensorsDistance) => {
  switch (gatewaySensorsDistance) {
    case GatewaySensorsDistance.FROM_0_TO_10M:
      return t('utils:texts.GatewaySensorsDistance.FROM_X_TO_YM', {
        x: 0,
        y: 10,
      });
    case GatewaySensorsDistance.FROM_10_TO_20M:
      return t('utils:texts.GatewaySensorsDistance.FROM_X_TO_YM', {
        x: 10,
        y: 20,
      });
    case GatewaySensorsDistance.FROM_20_TO_50M:
      return t('utils:texts.GatewaySensorsDistance.FROM_X_TO_YM', {
        x: 20,
        y: 50,
      });
    case GatewaySensorsDistance.FROM_50_TO_100M:
      return t('utils:texts.GatewaySensorsDistance.FROM_X_TO_YM', {
        x: 50,
        y: 100,
      });
    case GatewaySensorsDistance.MORE_THAN_100M:
      return t('utils:texts.GatewaySensorsDistance.MORE_THAN_X', {
        x: 100,
      });
    default:
      return 'n/a';
  }
};

export default getGatewaySensorsDistanceText;
