type Report = {
  id: string;
  name: string;
  customer_id: string;
  description: string;
  timestamp_from: Date;
  timestamp_to: Date;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseReport = (report: any): Report => {
  report.created_date = new Date(report.created_date);
  report.timestamp_from = new Date(report.timestamp_from);
  report.timestamp_to = new Date(report.timestamp_to);

  if (report.updated_date) {
    report.updated_date = new Date(report.updated_date);
  }

  return report;
};

export default Report;
