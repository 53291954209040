import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faSquirtyKitchen: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    57, // width
    57, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M56,12.5H42c-0.553,0-1,0.447-1,1v12v2v5H28H16H1c-0.553,0-1,0.447-1,1v12c0,0.553,0.447,1,1,1h15h12h14h14 c0.553,0,1-0.447,1-1v-18v-2v-12C57,12.947,56.553,12.5,56,12.5z M41,38.5H29v-4h12V38.5z M2,34.5h13v10H2V34.5z M17,34.5h10v4 h-0.036v2H27v4H17V34.5z M29,40.5h12v4H29V40.5z M55,44.5H43v-4v-2v-5v-6h12V44.5z M43,25.5v-11h12v11H43z M25,35.5h-6c-0.553,0-1,0.447-1,1v5c0,0.553,0.447,1,1,1h6c0.553,0,1-0.447,1-1v-5C26,35.947,25.553,35.5,25,35.5z M24,40.5h-4v-3h4V40.5z M27,24.5h4v-2h-3.21l-2.674-11.231c-0.107-0.45-0.51-0.769-0.973-0.769h-4.285c-0.463,0-0.865,0.318-0.973,0.769 L16.21,22.5H13v2h4H27z M20.647,12.5h2.705l2.382,10h-7.469L20.647,12.5z',
  ],
};

export default faSquirtyKitchen;
