function applyFixes() {
  // NOTE: This is a bug caused by the Google Translate plugin
  // Steps to reproduce:
  // 1. Open a sensor page in danish
  // 2. Ask Google Translate plugin to translate page to english
  // 3. Switch page language to English
  // 4. Error: "failed to execute removechild on node the node to be removed is not a child of this node"
  if (typeof Node === 'function' && Node.prototype) {
    const originalRemoveChild = Node.prototype.removeChild;
    // @ts-ignore
    Node.prototype.removeChild = function (child) {
      if (child.parentNode !== this) {
        if (console) {
          console.warn('Cannot remove a child from a different parent', child, this);
        }
        return child;
      }
      // @ts-ignore
      return originalRemoveChild.apply(this, arguments);
    };

    const originalInsertBefore = Node.prototype.insertBefore;
    // @ts-ignore
    Node.prototype.insertBefore = function (newNode, referenceNode) {
      if (referenceNode && referenceNode.parentNode !== this) {
        if (console) {
          console.warn(
            'Cannot insert before a reference node from a different parent',
            referenceNode,
            this
          );
        }
        return newNode;
      }
      // @ts-ignore
      return originalInsertBefore.apply(this, arguments);
    };
  }
}

export default applyFixes;
