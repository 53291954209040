import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryCreate } from 'utils/queries/create';
import { parseNote } from 'utils/types/Note';

export const useSupportTickets = ({
  queryKey,
  endpoint,
  enabled,
}: {
  queryKey: any;
  endpoint: string;
  enabled: boolean;
}) => {
  // Get
  const { data: supportTickets, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(endpoint),
    select: (supportTickets: any[]) => supportTickets.map(parseNote),
    enabled,
  });

  // Create
  const { mutateAsync: createSupportTicket } = useQueryCreate({
    queryKey,
    api,
    endpoint,
    invalidateQueryKeys: [],
    parseFn: parseNote,
  });

  return {
    supportTickets,
    createSupportTicket,
    isPending,
  };
};
