import classNames from 'classnames';
import { useMemo, Dispatch, SetStateAction, ComponentPropsWithoutRef } from 'react';

export const ControlledSwitch: React.FC<
  {
    label?: string | JSX.Element;
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
  } & Omit<ComponentPropsWithoutRef<'input'>, 'checked'>
> = ({ label, isChecked, setIsChecked, onChange, className, ...props }) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);

  return (
    <label
      htmlFor={controlId}
      className={classNames('flex items-center cursor-pointer', className)}
    >
      <div className="relative">
        <input
          onChange={e => {
            setIsChecked(checked => !checked);
            if (typeof onChange === 'function') {
              onChange(e);
            }
          }}
          type="checkbox"
          id={controlId}
          checked={isChecked}
          className="sr-only"
          {...props}
        />

        <div
          className={classNames('w-9 h-4 rounded-full shadow-inner', {
            'bg-brand-green': isChecked,
            'bg-brand-gray-light-3': !isChecked,
          })}
        ></div>

        <div
          className={classNames(
            'bg-white absolute w-4 h-4 rounded-full shadow left-0 top-0 transition duration-150 ease-linear',
            { 'translate-x-6': isChecked },
          )}
        ></div>
      </div>

      <div className="ml-3 text-brand-gray-light-1 font-medium">{label}</div>
    </label>
  );
};
