const enum HumidityState {
  HUMIDITY_0_10 = 'HUMIDITY_0_10',
  HUMIDITY_10_20 = 'HUMIDITY_10_20',
  HUMIDITY_20_30 = 'HUMIDITY_20_30',
  HUMIDITY_30_40 = 'HUMIDITY_30_40',
  HUMIDITY_40_50 = 'HUMIDITY_40_50',
  HUMIDITY_50_60 = 'HUMIDITY_50_60',
  HUMIDITY_60_70 = 'HUMIDITY_60_70',
  HUMIDITY_70_80 = 'HUMIDITY_70_80',
  HUMIDITY_80_90 = 'HUMIDITY_80_90',
  HUMIDITY_90_100 = 'HUMIDITY_90_100',

  UNKNOWN = 'UNKNOWN',
}

export const sortOrderHumidityState: { [key: string]: number } = [
  HumidityState.UNKNOWN,
  HumidityState.HUMIDITY_0_10,
  HumidityState.HUMIDITY_10_20,
  HumidityState.HUMIDITY_20_30,
  HumidityState.HUMIDITY_30_40,
  HumidityState.HUMIDITY_40_50,
  HumidityState.HUMIDITY_50_60,
  HumidityState.HUMIDITY_60_70,
  HumidityState.HUMIDITY_70_80,
  HumidityState.HUMIDITY_80_90,
  HumidityState.HUMIDITY_90_100,
]
  .map((state, index) => [state, index])
  .reduce((o: any, [state, index]) => ({ ...o, [state]: index }), {});

export default HumidityState;
