import { Formik, FormikHelpers, FormikProps } from 'formik';
import { Ref } from 'react';
import * as yup from 'yup';

export interface FormProps<T> {
  body: JSX.Element | ((props: FormikProps<T>) => JSX.Element);
  schemaObj: {};
  onSubmit: (values: T, helpers: FormikHelpers<T>) => void | Promise<any>;
  values?: Partial<T>;
  enableReinitialize?: boolean;
  validateOnMount?: boolean;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  innerRef?: Ref<FormikProps<T>>;
  resetFormAfterSubmission?: boolean;
  castValuesOnSubmit?: boolean;
}

const Form = <T extends {}>({
  body,
  schemaObj,
  onSubmit,
  values = {} as T,
  enableReinitialize = true,
  validateOnMount = false,
  validateOnBlur = false,
  validateOnChange = false,
  resetFormAfterSubmission,
  castValuesOnSubmit = false, // TODO: Default this to true
  ...props
}: FormProps<T>) => {
  const schema = yup.object(schemaObj);

  const initialValues = schema.cast(values, { assert: false, stripUnknown: true }) as T;

  return (
    <Formik<T>
      enableReinitialize={enableReinitialize}
      validationSchema={schema}
      onSubmit={async (values, formikHelpers) => {
        if (castValuesOnSubmit) {
          values = schema.cast(values) as T;
        }

        await onSubmit(values, formikHelpers);
        formikHelpers.setSubmitting(false);
        if (resetFormAfterSubmission) {
          formikHelpers.resetForm();
        }
      }}
      initialValues={initialValues as T}
      validateOnMount={validateOnMount}
      validateOnBlur={validateOnBlur}
      validateOnChange={validateOnChange}
      {...props}
    >
      {body}
    </Formik>
  );
};

export default Form;
