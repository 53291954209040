type GeographicLocation = {
  id?: string;

  description?: string;
  address?: string;
  latitude?: number;
  longitude?: number;

  created_date?: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
};

export type GeographicLocationFields = Pick<
  GeographicLocation,
  'description' | 'address' | 'latitude' | 'longitude'
>;

export const parseGeographicLocation = (geographicLocation: any): GeographicLocation => {
  if (geographicLocation.created_date)
    geographicLocation.created_date = new Date(geographicLocation.created_date);
  if (geographicLocation.updated_date)
    geographicLocation.updated_date = new Date(geographicLocation.updated_date);

  return geographicLocation as GeographicLocation;
};

export default GeographicLocation;
