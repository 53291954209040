import { IconDefinition, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

const InfoText: React.FC<
  {
    text: JSX.Element | string;
    icon?: IconDefinition;
    dataTipIcon?: string;
  } & HTMLAttributes<HTMLParagraphElement>
> = ({ text, className, icon = faInfo, dataTipIcon, ...props }) => (
  <p className={classNames(className)} {...props}>
    <FontAwesomeIcon
      className="mr-2 text-brand-green"
      icon={icon}
      data-tooltip-content={dataTipIcon}
      data-tooltip-id="route-tooltip"
    />
    {text}
  </p>
);

export default InfoText;
