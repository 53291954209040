import Role from 'utils/enums/Role';

type UserInvitation = {
  id: string;
  secret: string;
  email: string;
  customer_id?: string;
  sensor_id?: string;
  gateway_id?: string;
  group_id?: string;
  invited_by_user_id?: string;
  created_date: Date;
  redeemed_date?: Date;
};

export const parseUserInvitation = (userInvitation: any): UserInvitation => {
  userInvitation.created_date = new Date(userInvitation.created_date);
  if (userInvitation.redeemed_date)
    userInvitation.redeemed_date = new Date(userInvitation.redeemed_date);

  return userInvitation as UserInvitation;
};

export type UserInvitationFormValues = Pick<UserInvitation, 'email'> & {
  name?: string;
  custom_text?: string;
  role: Role;
};

export default UserInvitation;
