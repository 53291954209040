import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components';

export const NewFeatureBadge: React.FC<
  { size?: 'sm' | 'md' } & HTMLAttributes<HTMLSpanElement>
> = ({ className, size = 'md', ...props }) => {
  const { t } = useTranslation('components');
  return (
    <Badge
      className={classNames('text-white bg-blue-500', className)}
      size={size}
      data-tooltip-content={t('Badges.NewFeatureBadge.dataTip')}
      data-tooltip-id="route-tooltip"
      {...props}
    >
      {t('Badges.NewFeatureBadge.text')}
    </Badge>
  );
};
