import classNames from 'classnames';
import { useRef, useEffect, useState } from 'react';

const Sticky: React.FC<
  React.PropsWithChildren<{
    top: number;
    activeClassName?: string;
    children: JSX.Element | React.ReactNode;
  }>
> = ({ top, activeClassName, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [isSticky, setIsSticky] = useState(false);
  const [height, setHeight] = useState<number>();
  const [width, setWidth] = useState<number>();

  useEffect(() => {
    const onScroll = () => {
      const element = ref.current;
      if (!element) return;

      // Get size and position
      const rect = element.getBoundingClientRect();

      // Set height
      if (rect.height !== height) {
        setHeight(rect.height);
      }

      // Define width from parent
      const parent = element.parentElement?.parentElement?.parentElement; //todo: this is probably not a good idea. Find a better way to get the correct width
      if (parent) {
        const rectParent = parent.getBoundingClientRect();
        if (rectParent.width !== width) {
          setWidth(rectParent.width);
        }
      }

      // Not sure where the `4` pixels are coming from however
      // without them the sticky transition is not very smooth.
      const scrollY = window.pageYOffset - 4;
      if (scrollY > top && !isSticky) {
        setIsSticky(true);
      } else if (scrollY <= top && isSticky) {
        setIsSticky(false);
      }
    };

    // Clean up code
    // From https://stackoverflow.com/a/61018017/2538589
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, [isSticky, height, width, top]);

  return (
    <>
      <div
        ref={ref}
        className={classNames({
          fixed: isSticky,
          [`${activeClassName}`]: isSticky && activeClassName,
        })}
        style={{
          top: isSticky ? top : undefined,
          width: isSticky ? width : undefined,
        }}
      >
        <div className={classNames({ 'px-8': isSticky })}>{children}</div>
      </div>
      <div
        className={classNames({
          hidden: !isSticky,
        })}
        style={{
          height,
        }}
      ></div>
    </>
  );
};

export default Sticky;
