import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export const DeleteButton = ({ onDeleteGroup }: { onDeleteGroup: () => Promise<void> }) => {
  const { t } = useTranslation('components');

  return (
    <button
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();

        onDeleteGroup();
      }}
      className="cursor-pointer"
      data-tooltip-content={t('GroupHierarchy.GroupHierarchyNode.DeleteButton.text')}
      data-tooltip-id="route-tooltip"
    >
      <FontAwesomeIcon icon={faTrashCan} />
    </button>
  );
};
