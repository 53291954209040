type DocumentChange = {
  id: string;

  collection: string;
  document_id: string;
  field: string;
  value: string;

  created_date: Date;
  changed_by: string;
};

export const parseDocumentChange = (documentChange: any): DocumentChange => {
  documentChange.created_date = new Date(documentChange.created_date);
  return documentChange as DocumentChange;
};

export default DocumentChange;
