import { useFile } from 'utils/hooks/data/useFile';
import GatewayFile, { parseGatewayFile } from 'utils/types/GatewayFile';

export const useGatewayFile = (gatewayId: string, fileId?: string) =>
  useFile<GatewayFile>({
    queryKey: ['sensorFiles', gatewayId, fileId],
    endpoint: `/gateways/${gatewayId}/files/${fileId}`,
    enabled: !!gatewayId && !!fileId,
    parseFn: parseGatewayFile,
    invalidateQueryKeys: [
      ['gatewayFiles', gatewayId],
      ['gatewayPrimaryFile', gatewayId, false],
      ['gatewayPrimaryFile', gatewayId, true],
    ],
  });
