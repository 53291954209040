import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import React, { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SettingsDropdown, EditBlueprintModal } from 'components';
import { useBlueprint } from 'utils/hooks/data';
import { notificationSuccess, notificationWarningConfirm } from 'utils/notifications';

export const BlueprintSettingsDropdown: React.FC<
  {
    blueprintId: string;
    size?: SizeProp;
    onDeleteRedirectPath?: String | undefined;
  } & Pick<HTMLAttributes<HTMLDivElement>, 'className'>
> = ({ blueprintId, size = 'sm', onDeleteRedirectPath, className }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const { t } = useTranslation('components');
  const navigate = useNavigate();

  const { blueprint, deleteBlueprint, updateBlueprint } = useBlueprint(blueprintId);

  const onDeleteBlueprint = async () => {
    const confirmed = await notificationWarningConfirm();
    if (!confirmed) return;

    await deleteBlueprint();
    notificationSuccess(t('dropdowns.BlueprintDropdownSettings.onDeleteBlueprint.successText'));

    if (onDeleteRedirectPath !== undefined) {
      navigate(`/${onDeleteRedirectPath}`);
    }
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  return (
    <>
      <SettingsDropdown
        size={size}
        className={className}
        onEdit={handleEdit}
        onDelete={onDeleteBlueprint}
      />
      {blueprint && (
        <EditBlueprintModal
          blueprint={blueprint}
          updateBlueprint={updateBlueprint}
          show={showEditModal}
          setShow={setShowEditModal}
        />
      )}
    </>
  );
};
