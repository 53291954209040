import { useState, HTMLAttributes, PropsWithChildren } from 'react';

import { ExpandButton } from 'components';

const ExpandableDiv: React.FC<
  PropsWithChildren<
    {
      title: string | JSX.Element;
      expandedInitially?: boolean;
      tooltipExpand?: string;
      tooltipCollapse?: string;
      elementNextToTitle?: JSX.Element;
    } & Omit<HTMLAttributes<HTMLDivElement>, 'title'>
  >
> = ({
  title,
  expandedInitially = false,
  tooltipExpand,
  tooltipCollapse,
  elementNextToTitle,
  children,
  ...props
}) => {
  const [expanded, setExpanded] = useState(expandedInitially);

  return (
    <div {...props}>
      <div className="mb-2 flex lg:flex-row lg:gap-1.5 justify-between lg:items-center">
        <h3 className="mb-1">
          {title}

          <ExpandButton
            className="ml-2"
            expanded={expanded}
            setExpanded={setExpanded}
            data-tooltip-content={expanded ? tooltipExpand : tooltipCollapse}
            data-tooltip-id="route-tooltip"
            data-testid="expand-button"
          />
        </h3>

        {expanded && elementNextToTitle}
      </div>

      {expanded && <>{children}</>}
    </div>
  );
};

export default ExpandableDiv;
