import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseGateway } from 'utils/types/Gateway';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  includeGeographicLocation?: boolean;
};

export const useUserGateways = (
  userId?: string,
  { enableGet = true, includeGeographicLocation = true }: Options = {},
) => {
  const queryKey = ['userGateways', userId];

  // Get
  const {
    data: gateways,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/users/${userId}/gateways`, {
        params: {
          include_geographic_location: includeGeographicLocation,
        },
      }),
    select: (gateways: any[]) => gateways.map(parseGateway),
    enabled: !!userId && enableGet,
  });

  return {
    gateways,
    isPending: isPending || isLoading || isFetching,
  };
};
