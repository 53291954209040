import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ExternalLink from 'components/ExternalLink';
import { SIMSource } from 'utils/types/Gateway';

const SimStatus: React.FC<
  {
    simId?: string;
    simSource?: SIMSource;
    simIpAddress?: string;
    simOnline?: boolean;
    classNameText?: string;
  } & Omit<FontAwesomeIconProps, 'icon'>
> = ({ simId, simSource, simIpAddress, simOnline, className, classNameText, ...props }) => {
  const { t } = useTranslation('components');

  const url = simSource === 'Onomondo' ? `https://app.onomondo.com/sims/${simId}` : undefined;

  return (
    <div className="flex">
      <FontAwesomeIcon
        className={classNames('mr-2', className, {
          'text-brand-orange': !simOnline,
          'text-brand-green-light-1': simOnline,
        })}
        icon={faSignal}
        data-tooltip-content={
          simOnline ? t('signals.SimStatus.dataTip.online') : t('signals.SimStatus.dataTip.offline')
        }
        data-tooltip-id="route-tooltip"
        {...props}
      />
      {simId ? (
        <ExternalLink
          className={classNameText}
          text={simId}
          url={url}
          data-tooltip-content={t('signals.SimStatus.ExternalLink.dataTip')}
          data-tooltip-id="route-tooltip"
        />
      ) : (
        <p className={classNameText}>{simSource}</p>
      )}
    </div>
  );
};

export default SimStatus;
