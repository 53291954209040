import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import React from 'react';

import User from 'utils/types/User';

export type ContextProps = {
  isAdmin: boolean;
  isLoggedIn: boolean;
  isFetched: boolean;
  setUser: (user?: User) => void;
  user?: User;
  userId?: string;
  refetchUser: (options?: RefetchOptions) => Promise<QueryObserverResult<User | undefined, Error>>;
};

export const CurrentUserSessionContext = React.createContext<ContextProps>({} as ContextProps);
export const CurrentUserSessionProvider = CurrentUserSessionContext.Provider;
