import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { SensorGroupUpdate, parseSensorGroup } from 'utils/types/SensorGroup';

export const useSensorGroupSubgroups = (
  sensorGroupId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['sensorGroupSubgroups', sensorGroupId];

  const queryClient = useQueryClient();

  // Get
  const { data: subgroups, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/subgroups`),
    select: (sensorGroups: any[]) => sensorGroups.map(parseSensorGroup),
    enabled: enableGet && !!sensorGroupId,
  });

  // Create new subgroup
  const { mutateAsync: createSubgroup } = useMutation({
    mutationFn: async (values: SensorGroupUpdate) => {
      const sensorGroups: any[] = await api.post(
        `/sensor_groups/${sensorGroupId}/subgroups`,
        values,
      );
      return sensorGroups.map(parseSensorGroup);
    },
    onSuccess: async sensorGroups => {
      queryClient.setQueryData(queryKey, sensorGroups);
      await queryClient.invalidateQueries({
        queryKey: ['sensorGroupsHierarchyTree'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['customerSensorGroups'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['userSensorGroups'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['sensorGroups'],
      });
    },
  });

  // Add existing group as subgroup
  const { mutateAsync: addGroupAsSubgroup } = useMutation({
    mutationFn: async (groupId: string) => {
      const sensorGroups: any[] = await api.post(
        `/sensor_groups/${sensorGroupId}/subgroups/${groupId}`,
      );
      return sensorGroups.map(parseSensorGroup);
    },
    onSuccess: async sensorGroups => {
      queryClient.setQueryData(queryKey, sensorGroups);
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['sensorGroupParentGroups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['sensorGroupParentGroup'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['sensorGroupsHierarchyTree'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['sensorGroups'],
        }),
      ]);
    },
  });

  return {
    addGroupAsSubgroup,
    createSubgroup,
    isPending,
    subgroups,
  };
};
