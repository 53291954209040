enum MoldGrowthRiskState {
  NO_RISK = 'NO_RISK',
  LOW_RISK = 'LOW_RISK',
  MODERATE_RISK = 'MODERATE_RISK',
  HIGH_RISK = 'HIGH_RISK',
  VERY_HIGH_RISK = 'VERY_HIGH_RISK',

  UNKNOWN = 'UNKNOWN',
}

export const sortOrderMoldGrowthRiskState: { [key: string]: number } = [
  MoldGrowthRiskState.UNKNOWN,
  MoldGrowthRiskState.NO_RISK,
  MoldGrowthRiskState.LOW_RISK,
  MoldGrowthRiskState.MODERATE_RISK,
  MoldGrowthRiskState.HIGH_RISK,
  MoldGrowthRiskState.VERY_HIGH_RISK,
]
  .map((state, index) => [state, index])
  .reduce((o: any, [state, index]) => ({ ...o, [state]: index }), {});

export default MoldGrowthRiskState;
