import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { BlueprintCanvasContext } from 'components/BlueprintCanvas/components/BlueprintCanvasContext';
import Carousel from 'components/Carousel';
import InfoText from 'components/InfoText';
import SensorIcon from 'images/tector-icon-black.png';
import { chunkify } from 'utils/arrays';
import { sm, md } from 'utils/breakpoints';
import { sensorId2NameFunc, sensorIdToDescriptionFunc } from 'utils/formatting';
import { useSortedSensorIds, useWindowSize } from 'utils/hooks';
import { useBlueprintPositions, useBlueprintSensors } from 'utils/hooks/data';

export const SensorsCarousel: React.FC<{
  blueprintId: string;
  onlyEnableSensorIds?: string[];
}> = ({ blueprintId, onlyEnableSensorIds }) => {
  const { t } = useTranslation('components');

  const [windowWidth] = useWindowSize();

  const { activeBlueprintPosition, setActiveBlueprintPosition } =
    useContext(BlueprintCanvasContext);

  const { sensors } = useBlueprintSensors(blueprintId);
  const { blueprintPositions } = useBlueprintPositions(blueprintId);

  const sensorId2Name = sensorId2NameFunc(sensors);
  const sensorIdToDescription = sensorIdToDescriptionFunc(sensors);

  const sortedSensorsIds = useSortedSensorIds(sensors);

  const blueprintPositionChunks = useMemo(() => {
    const sensorIdsToPutFirst = onlyEnableSensorIds ? onlyEnableSensorIds : [];

    const positions = (blueprintPositions || []).sort((a, b) => {
      // Sensors in the onlyEnableSensorIds list should be placed at the beginning of the carousel
      const aIsInSensorIdsToPutFirst = sensorIdsToPutFirst.includes(a.sensor_id);
      const bIsInSensorIdsToPutFirst = sensorIdsToPutFirst.includes(b.sensor_id);

      if (aIsInSensorIdsToPutFirst && !bIsInSensorIdsToPutFirst) return -1;
      if (!aIsInSensorIdsToPutFirst && bIsInSensorIdsToPutFirst) return 1;

      // Sensors not yet placed on the blueprint should be placed at the beginning of the carousel
      const aHasBlueprintPosition = a.position_x !== null && a.position_y !== null;
      const bHasBlueprintPosition = b.position_x !== null && b.position_y !== null;

      if (!aHasBlueprintPosition && bHasBlueprintPosition) return -1;
      if (aHasBlueprintPosition && !bHasBlueprintPosition) return 1;

      // The rest of the sesors will be ordered based on the order of the sortedSensorsIds
      return sortedSensorsIds.indexOf(a.sensor_id) - sortedSensorsIds.indexOf(b.sensor_id);
    });

    const chunkSize = windowWidth < sm ? 3 : windowWidth < md ? 6 : 8;
    return chunkify(positions, chunkSize);
  }, [blueprintPositions, onlyEnableSensorIds, sortedSensorsIds, windowWidth]);

  const enableSensorIds = Array.isArray(onlyEnableSensorIds)
    ? onlyEnableSensorIds
    : (sensors || []).map(sensor => sensor.id);

  return (
    <>
      {blueprintPositions && blueprintPositions.length > 0 ? (
        <>
          <InfoText
            className="text-center my-2"
            text={t('blueprints.BlueprintSensorsBox.subTitle')}
            data-tooltip-content={t('blueprints.BlueprintSensorsBox.infoIcon')}
            data-tooltip-id="route-tooltip"
          />
        </>
      ) : (
        <InfoText
          className="text-center my-3"
          text={t('blueprints.BlueprintSensorsBox.noSensorsSubTitle')}
          data-tooltip-content={t('blueprints.BlueprintSensorsBox.noSensorsInfoIcon')}
          data-tooltip-id="route-tooltip"
        />
      )}

      <Carousel
        wrapperClassName="h-28"
        elements={blueprintPositionChunks.map((blueprintPositionChunk, indexOuter) => (
          <div key={indexOuter} className="flex justify-center gap-x-5">
            {blueprintPositionChunk.map((blueprintPosition, index) => {
              const sensorName = sensorId2Name[blueprintPosition.sensor_id];
              const sensorDescription = sensorIdToDescription[blueprintPosition.sensor_id];
              const enabled = enableSensorIds.includes(blueprintPosition.sensor_id);
              const active = activeBlueprintPosition?.id === blueprintPosition.id;

              return (
                <div
                  key={index}
                  className={classNames('flex flex-col gap-1 p-3 rounded', {
                    shadow: active,
                    'opacity-50': !enabled,
                  })}
                >
                  <img
                    className={classNames(
                      'block mx-auto w-10 h-auto xl:w-16 justify-start items-start',
                      {
                        'cursor-grab': enabled && !blueprintPosition.isPositionDefined,
                        'cursor-grabing': active,
                      },
                    )}
                    src={SensorIcon}
                    alt={`${sensorName}: ${sensorDescription}`}
                    data-tooltip-content={`${sensorName}: ${sensorDescription}`}
                    data-tooltip-id="route-tooltip"
                    draggable={enabled}
                    onDragStart={event => {
                      event.dataTransfer.setData('blueprint-position-id', blueprintPosition.id);
                      event.dataTransfer.setData(
                        'blueprint-sensor-id',
                        blueprintPosition.sensor_id,
                      );
                      setActiveBlueprintPosition(blueprintPosition);
                    }}
                    onDragEnd={() => setActiveBlueprintPosition(undefined)}
                    onMouseEnter={() => setActiveBlueprintPosition(blueprintPosition)}
                    onMouseLeave={() => setActiveBlueprintPosition(undefined)}
                  />

                  <div className="flex flex-col xl:flex-row items-center justify-center gap-1 w-10 xl:w-24">
                    {blueprintPosition.isPositionDefined ? (
                      <FontAwesomeIcon
                        className="relative text-brand-green-light-1"
                        icon={faCheckCircle}
                        data-tooltip-content={t(
                          'blueprints.BlueprintSensorsBox.dataTip.withPosition',
                        )}
                        data-tooltip-id="route-tooltip"
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="relative text-brand-green"
                        icon={faExclamationCircle}
                        data-tooltip-content={t(
                          'blueprints.BlueprintSensorsBox.dataTip.withoutPosition',
                        )}
                        data-tooltip-id="route-tooltip"
                      />
                    )}

                    <div className="w-12 xl:w-24 text-xs overflow-hidden">
                      <span className="truncate">{sensorName}</span>
                    </div>
                  </div>
                  <div
                    style={{ fontSize: '0.60rem' }}
                    className="flex w-12 xl:w-24 h-auto justify-center items-start overflow-hidden text-brand-gray-light-2 text-left"
                  >
                    <span className="truncate">{sensorDescription}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      />
    </>
  );
};
