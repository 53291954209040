import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components';
import AlarmRuleComparisonOperator from 'utils/enums/AlarmRuleComparisonOperator';
import AlarmRuleCriterionType from 'utils/enums/AlarmRuleCriterionType';
import AlarmRuleType from 'utils/enums/AlarmRuleType';
import { useAlarmRule } from 'utils/hooks/data';
import { notificationWarningConfirm } from 'utils/notifications';
import { getAlarmRuleComparisonOperatorText } from 'utils/texts/AlarmRuleComparisonOperator';
import { getAlarmRuleParameterText, getParameterUnit } from 'utils/texts/AlarmRuleParameter';
import AlarmRule from 'utils/types/AlarmRule';

export const AlarmRuleCriterionList: React.FC<{ alarmRule?: AlarmRule }> = ({ alarmRule }) => {
  const { t } = useTranslation('components');

  const { deleteAlarmRuleCriterion } = useAlarmRule(alarmRule?.id);

  const alarmRuleCriteria = alarmRule?.alarm_rule_criteria || [];

  return (
    <div className="mb-3">
      <p className="text-brand-gray font-semibold">{t('alarmRulesTabSubPage.criteria')}:</p>

      {alarmRuleCriteria.map(criteria => (
        <div key={criteria.id} className="flex justify-between my-2">
          <div className="flex gap-x-2.5 items-center">
            <span className="bg-brand-gray rounded-full w-2.5 h-2.5 inline-block"></span>
            <p>
              <span className="font-bold">{getAlarmRuleParameterText(criteria.parameter)}</span>{' '}
              {getAlarmRuleComparisonOperatorText(criteria.comparison_operator).toLocaleLowerCase()}{' '}
              <span className="font-bold">
                {criteria.value}
                {getParameterUnit(criteria.parameter)}
              </span>{' '}
              {[
                AlarmRuleComparisonOperator.SubsequentDecrease,
                AlarmRuleComparisonOperator.SubsequentIncrease,
              ].includes(criteria.comparison_operator) ? (
                <span>{t('AlarmRuleCriterionDetails.subsequentValues')} </span>
              ) : (
                criteria.type === AlarmRuleCriterionType.Delayed && (
                  <span>
                    {t('AlarmRuleCriterionDetails.afterNDays', {
                      nDays: criteria.delayed_days,
                    })}{' '}
                  </span>
                )
              )}
              {[
                AlarmRuleComparisonOperator.GreaterThan,
                AlarmRuleComparisonOperator.LessThan,
              ].includes(criteria.comparison_operator) &&
                criteria.type === AlarmRuleCriterionType.Former && (
                  <span>{t('AlarmRuleCriterionDetails.former')} </span>
                )}
            </p>

            {criteria.type === AlarmRuleCriterionType.Delayed && (
              <Badge
                data-tooltip-content={t('AlarmRuleCriterionDetails.tooltip', {
                  nDays: criteria.delayed_days,
                })}
                data-tooltip-id="route-tooltip"
                size="sm"
                className="bg-brand-green text-white cursor-help"
              >
                {t('AlarmRuleCriterionDetails.delayed')}
              </Badge>
            )}
          </div>

          {alarmRule?.type === AlarmRuleType.Custom && (
            <FontAwesomeIcon
              onClick={async () => {
                const confirmed = await notificationWarningConfirm();
                if (!confirmed) return;
                await deleteAlarmRuleCriterion(criteria.id);
              }}
              icon={faTrashAlt}
              className="w-4 h-4 lg:w-6 lg:h-6 text-brand-gray cursor-pointer"
            />
          )}
        </div>
      ))}

      {alarmRuleCriteria.length === 0 && <p>{t('alarmRulesTabSubPage.noCriteria')}</p>}
    </div>
  );
};
