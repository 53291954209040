import { Environment } from 'utils/isFeatureEnabled';
import { FeatureFlagNotFoundError } from 'utils/isFeatureEnabled/exceptions';
import { getFeatureFlags } from 'utils/isFeatureEnabled/getFeatureFlags';

export const isFeatureEnabled = (featureFlag: string, environment: Environment) => {
  const featureFlags = getFeatureFlags();
  const flag = featureFlags[featureFlag];
  if (flag === undefined) throw new FeatureFlagNotFoundError(featureFlag);
  if (environment === 'locally') {
    return flag.locally;
  } else if (environment === 'development') {
    return flag.development;
  } else if (environment === 'production') {
    return flag.production;
  }
};
