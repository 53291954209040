import { t } from 'translations';
import Role from 'utils/enums/Role';

export const getRoleText = (role: Role) => {
  switch (role) {
    case Role.Guest:
      return t('utils:texts.Role.getRoleText.Guest');
    case Role.Member:
      return t('utils:texts.Role.getRoleText.Member');
    case Role.Owner:
      return t('utils:texts.Role.getRoleText.Owner');
    default:
      return 'n/a';
  }
};
