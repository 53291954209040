import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction, HTMLAttributes } from 'react';

import { IconSwitch } from 'components';

export const LockSwitch: React.FC<
  {
    label?: string;
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
    onChange?: (checked: boolean) => Promise<void>;
  } & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>
> = ({ ...props }) => (
  <IconSwitch
    isCheckedIcon={faLockOpen}
    isNotCheckedIcon={faLock}
    isCheckedClassNames="text-woody"
    {...props}
  />
);
