import { type CSSProperties } from 'react';

import PointsMap from 'components/maps/PointsMap';

const SinglePointMap: React.FC<{
  latitude?: number;
  longitude?: number;
  initialZoom?: number;
  dragging?: boolean;
  zoomControl?: boolean;
  style?: CSSProperties;
  scrollWheelZoom?: boolean;
}> = ({
  latitude,
  longitude,
  initialZoom = 12,
  dragging = false,
  zoomControl = false,
  scrollWheelZoom = false,
  style,
}) => {
  if (!latitude || !longitude) {
    return null;
  }

  return (
    <PointsMap
      points={[
        {
          latitude,
          longitude,
        },
      ]}
      initialPosition={[latitude, longitude]}
      initialZoom={initialZoom}
      dragging={dragging}
      zoomControl={zoomControl}
      scrollWheelZoom={scrollWheelZoom}
      style={style}
    />
  );
};

export default SinglePointMap;
