import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faSquirtySwitch: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    16, // width
    16, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M9.19 3.75H8a.75.75 0 010-1.5h3a.75.75 0 01.75.75v3a.75.75 0 11-1.5 0V4.81L7.53 7.53a.75.75 0 01-1.06-1.06l2.72-2.72zM8 12.25a.75.75 0 110 1.5H5a.75.75 0 01-.75-.75v-3a.75.75 0 011.5 0v1.19l2.72-2.72a.75.75 0 011.06 1.06l-2.72 2.72H8z',
  ],
};

export default faSquirtySwitch;
