import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseGatewayHeartbeat } from 'utils/types/GatewayHeartbeat';

export const useSensorGroupGatewayHeartbeats = (
  sensorGroupId: string,
  {
    fromTimestamp,
    toTimestamp,
    limit,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
    limit?: number;
  },
) => {
  const queryKey = [
    'sensorGroupGatewayHeartbeats',
    sensorGroupId,
    fromTimestamp,
    toTimestamp,
    limit,
  ];

  // Get
  const { data: heartbeats, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensor_groups/${sensorGroupId}/gateway_heartbeats`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
          limit,
        },
      }),
    select: (data: any[]) => data.map(parseGatewayHeartbeat),
  });

  return {
    heartbeats,
    isPending,
  };
};
