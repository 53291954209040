import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY, USE_USER_GROUPS_QUERY_KEY } from 'utils/hooks/data';
import HookOptions from 'utils/types/HookOptions';
import { parseSensorGroup } from 'utils/types/SensorGroup';

export const useSensorGroupParentGroup = (
  sensorGroupId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['sensorGroupParentGroup', sensorGroupId];

  const queryClient = useQueryClient();

  // We don't want to handle 404 errors here as an error, but as an expected result. Hence we
  // don't want it to try and refetch on every render/focus either.
  // From documentation:
  // Must return a promise that will either resolve data or throw an error. The data cannot be undefined.
  //
  // NOTE: When query key is changed without the component unmounting, the value of `parentGroup` will
  // remain the value from the previous query key, hence we use the `isSuccess` to determine whether we
  // want to use the value or return `undefined` (see the returned values of this function).
  const {
    data: parentGroup,
    isPending,
    isSuccess,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensor_groups/${sensorGroupId}/parent_group`, {
        _disableErrorNotifications: true,
        _disableSentryErrorCapture: true,
        _disableRedirects: true,
      }),
    select: (data: any) => parseSensorGroup(data),
    enabled: enableGet && !!sensorGroupId,
  });

  // Delete
  const { mutateAsync: releaseGroupFromParent } = useMutation({
    mutationFn: () => api.delete(`/sensor_groups/${sensorGroupId}/parent_group`),
    onSuccess: async () => {
      queryClient.setQueriesData({ queryKey }, undefined);
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ['sensorGroups', sensorGroupId],
        }),
        queryClient.invalidateQueries({
          queryKey: ['sensorGroupSubgroups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['customerSensorGroups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['sensorGroupParentGroups'],
        }),
        queryClient.invalidateQueries({
          queryKey: ['sensorGroupsHierarchyTree'],
        }),
        queryClient.invalidateQueries({
          queryKey: [USE_USER_GROUPS_QUERY_KEY],
        }),
        queryClient.invalidateQueries({
          queryKey: [USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY],
        }),
      ]);
    },
  });

  return {
    parentGroup: isSuccess ? parentGroup : undefined,
    isPending,
    releaseGroupFromParent,
  };
};
