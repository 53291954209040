import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import Role from 'utils/enums/Role';
import HookOptions from 'utils/types/HookOptions';
import { parseUserInvitation } from 'utils/types/UserInvitation';

export const useSensorUserInvitations = (
  sensorId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['sensorUserInvitations', sensorId];

  const queryClient = useQueryClient();

  // User Invitations
  const { data: userInvitations, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/user_invitations`),
    select: (data: any[]) => data.map(data => parseUserInvitation(data)),
    enabled: !!sensorId && enableGet,
  });

  // Create
  const { mutateAsync: createUserInvitation } = useMutation({
    mutationFn: async (values: { email: string; role: Role }) => {
      const userInvitationsRaw: any[] = await api.post(
        `/sensors/${sensorId}/user_invitations`,
        values,
      );
      return userInvitationsRaw.map(parseUserInvitation);
    },
    onSuccess: userInvitations => {
      queryClient.setQueryData(queryKey, userInvitations);
    },
  });

  return {
    userInvitations,
    createUserInvitation,
    isPending,
  };
};
