import { t } from 'translations';
import AnomalyType from 'utils/enums/AnomalyType';

export const getAnomalyTypeText = (anomalyType: AnomalyType) => {
  switch (anomalyType) {
    case AnomalyType.Undetermined:
      return t('utils:texts.AnomalyType.getAnomalyTypeText.Undetermined');
    case AnomalyType.IncreaseInMoisture:
      return t('utils:texts.AnomalyType.getAnomalyTypeText.IncreaseInMoisture');
    case AnomalyType.DecreaseInMoisture:
      return t('utils:texts.AnomalyType.getAnomalyTypeText.DecreaseInMoisture');
    case AnomalyType.AbnormalBehaviour:
      return t('utils:texts.AnomalyType.getAnomalyTypeText.AbnormalBehaviour');
    default:
      return 'n/a';
  }
};

export const getAnomalyTypeDescriptionText = (anomalyType: AnomalyType) => {
  switch (anomalyType) {
    case AnomalyType.Undetermined:
      return t('utils:texts.AnomalyType.getAnomalyTypeDescriptionText.Undetermined');
    case AnomalyType.IncreaseInMoisture:
      return t('utils:texts.AnomalyType.getAnomalyTypeDescriptionText.IncreaseInMoisture');
    case AnomalyType.DecreaseInMoisture:
      return t('utils:texts.AnomalyType.getAnomalyTypeDescriptionText.DecreaseInMoisture');
    case AnomalyType.AbnormalBehaviour:
      return t('utils:texts.AnomalyType.getAnomalyTypeDescriptionText.AbnormalBehaviour');
    default:
      return 'n/a';
  }
};
