import { useTranslation } from 'react-i18next';
//@ts-ignore
import SimpleReactCalendar from 'simple-react-calendar';

import 'styles/components/_calendar.scss';

export type RangeType = {
  start?: Date | null;
  end?: Date | null;
};

type CalendarInputProps = {
  onSelect: (date: Date | RangeType) => void;
  initialDate?: Date | RangeType | null;
  maxMonthsBack?: number;
  minDate?: Date | null;
  maxDate?: Date | null;
  mode?: 'range' | 'single';
};

export const CalendarInput: React.FC<CalendarInputProps> = ({
  onSelect,
  initialDate,
  maxMonthsBack,
  minDate,
  maxDate,
  mode = 'single',
}) => {
  const { t } = useTranslation('components');

  const now = new Date();

  if (!minDate && maxMonthsBack !== undefined) {
    minDate = new Date(now.getTime());
    minDate.setMonth(minDate.getMonth() - maxMonthsBack);
  }

  if (minDate && maxDate && minDate > maxDate) {
    throw new Error('minDate is greater than maxDate');
  }

  if (initialDate) {
    const startDate = (initialDate as RangeType).start || (initialDate as Date);
    const endDate = (initialDate as RangeType).end || (initialDate as Date);

    if (minDate && startDate < minDate) {
      throw new Error('selected date is less than minDate');
    }

    if (maxDate && endDate > maxDate) {
      throw new Error('selected date is greater than maxDate');
    }
  }

  const CALENDAR_DAYS_OF_WEEK = [
    t('inputs.CalendarRange.Mon'), // 'Man',
    t('inputs.CalendarRange.Tue'), // 'Tir',
    t('inputs.CalendarRange.Wed'), // 'Ons',
    t('inputs.CalendarRange.Thu'), // 'Tor',
    t('inputs.CalendarRange.Fri'), // 'Fre',
    t('inputs.CalendarRange.Sat'), // 'Lør',
    t('inputs.CalendarRange.Sun'), // 'Søn',
  ];

  return (
    <SimpleReactCalendar
      daysOfWeek={CALENDAR_DAYS_OF_WEEK}
      activeMonth={now}
      selected={initialDate}
      minDate={minDate ? minDate : undefined}
      maxDate={maxDate ? maxDate : undefined}
      mode={mode}
      onSelect={onSelect}
    />
  );
};
