import { ComponentPropsWithoutRef } from 'react';
import Skeleton from 'react-loading-skeleton';

export const SkeletonRows: React.FC<{ rows?: number } & ComponentPropsWithoutRef<'td'>> = ({
  rows = 10,
  className = 'py-2 px-4',
  ...props
}) => (
  <>
    {[...Array(rows)].map((x, i) => (
      <tr key={i}>
        <td {...props} className={className}>
          <Skeleton duration={1} />
        </td>
      </tr>
    ))}
  </>
);
