import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import queryString from 'query-string';
import { initReactI18next } from 'react-i18next';

const defaultLang = (queryString.parse(window.location.search).lang as string) || 'da';

const ns = ['common', 'components', 'layouts', 'pages', 'routes', 'utils'];

i18n
  .use(detector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    initImmediate: false,
    ns,
    debug: false,
    fallbackLng: defaultLang,
    returnEmptyString: false,
    saveMissing: true,
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

const t = i18n.t.bind(i18n);
const i18 = i18n;

export { defaultLang, i18, t, ns };

export default i18n as typeof i18n | undefined;
