import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import ExternalLink from 'components/ExternalLink';
import { NetworkServerSource } from 'utils/types/Gateway';

const NetworkServerStatus: React.FC<
  {
    gatewayId: string;
    networkServerSource?: NetworkServerSource;
    networkServerOnline?: boolean;
    classNameText?: string;
  } & Omit<FontAwesomeIconProps, 'icon'>
> = ({
  gatewayId,
  networkServerSource,
  networkServerOnline,
  className,
  classNameText,
  ...props
}) => {
  const { t } = useTranslation('components');

  const url =
    networkServerSource === 'TTS'
      ? `https://woodsense.eu1.cloud.thethings.industries/console/gateways/${gatewayId}`
      : undefined;

  return (
    <div className="flex">
      <FontAwesomeIcon
        className={classNames('mr-2', className, {
          'text-brand-orange': !networkServerOnline,
          'text-brand-green-light-1': networkServerOnline,
        })}
        icon={faSignal}
        data-tooltip-content={
          networkServerOnline
            ? t('signals.NetworkServerStatus.online.dataTip')
            : t('signals.NetworkServerStatus.offline.dataTip')
        }
        data-tooltip-id="route-tooltip"
        {...props}
      />
      {networkServerSource ? (
        <ExternalLink
          className={classNameText}
          text={networkServerSource}
          url={url}
          data-tooltip-content={t('signals.NetworkServerStatus.ExternalLink.dataTip')}
          data-tooltip-id="route-tooltip"
        />
      ) : (
        <p className={classNameText}>{networkServerSource}</p>
      )}
    </div>
  );
};

export default NetworkServerStatus;
