import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';

export const useSensorAlarms = (sensorId?: string) => {
  const queryKey = ['sensorAlarms', sensorId];

  // Sensor Notes - Get
  const { data: alarms, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/alarms`),
    select: (alarms: any[]) => alarms.map(parseAlarm),
    enabled: !!sensorId,
  });

  return {
    alarms,
    isPending,
  };
};
