import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseDocumentChange } from 'utils/types/DocumentChange';
import HookOptions from 'utils/types/HookOptions';

export const useSensorGroupChanges = (
  sensorGroupId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['sensorGroupChanges', sensorGroupId];

  // Get
  const { data: documentChanges, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/changes`),
    select: (data: any[]) => data.map(parseDocumentChange),
    enabled: enableGet && !!sensorGroupId,
  });

  return {
    documentChanges,
    isPending,
  };
};
