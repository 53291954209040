import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';

export const useAlertDescription = (alarmId: string) => {
  const queryKey = ['useAlertDescription', alarmId];

  const { data: description, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarms/${alarmId}/description`),
    select: (description: any) => description as string,
  });

  return {
    description,
    isPending,
  };
};
