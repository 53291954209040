import { faEdit, faPlus, faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  ActivityStateBadge,
  HumidityStateBadge,
  MoldGrowthRiskStateBadge,
  MoistureStateBadge,
  SignalStrengthStateBadge,
  TemperatureStateBadge,
  Table,
  TableProps,
  TableActionType,
  TableAction,
} from 'components';
import {
  SensorTagPrimaryElementCategoryBadge,
  SensorTagPrimaryElementTypeBadge,
  SensorTagClimateBadge,
  SensorTagComplementaryElementBadge,
  SensorTagConstructionPhaseBadge,
  SensorTagConstructionPrinciplesBadge,
  SensorTagInsulationBadge,
  SensorTagOrientationBadge,
  SensorTagRoomTypeBadge,
  SensorTagVentilationBadge,
  SensorTagVerticalPlacementBadge,
  SensorTagInsulationTypeBadge,
} from 'components/badges/SensorTagBadges';
import EditOnHoverText from 'components/EditOnHoverText';
import { onSubmitValues } from 'components/forms/SensorForm';
import SensorDownlinksModal from 'components/modals/SensorDownlinksModal';
import SensorTagsModal from 'components/modals/SensorTagsModal';
import UpdateSensorAlarmRulesModal from 'components/modals/UpdateSensorAlarmRulesModal';
import UpdateSensorModal from 'components/modals/UpdateSensorModal';
import { confirmSensorDownlinkSubmit } from 'components/notifications';
import SensorLink from 'components/SensorLink';
import SensorUsersInlineList from 'components/sensors/SensorUsersInlineList';
import LatestTransmissionStatus from 'components/signals/LatestTransmissionStatus';
import {
  ActionComponents,
  AlarmCell,
  BlueprintCell,
  MissingTagCell,
  RemoveFromGroupCell,
} from 'components/tables/SensorsPaginatedTable/components';
import SensorThumbnail from 'components/thumbnails/SensorThumbnail';
import { lg } from 'utils/breakpoints';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { useSelectedCustomer, useTimePeriod, useWindowSize } from 'utils/hooks';
import {
  useSensor,
  useSensorTags,
  useSensorDownlinks,
  useCustomerSensorGroups,
  useSensorGroupSensors,
} from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';
import {
  getActivityStateText,
  getHumidityStateText,
  getMoldGrowthRiskStateText,
  getMoistureStateText,
  getSignalStrengthStateText,
  getTemperatureStateText,
} from 'utils/sensor/texts';
import {
  sortingFnActivityState,
  sortingFnDateTime,
  sortingFnHumidityState,
  sortingFnMoldGrowthRiskState,
  sortingFnSensorState,
  sortingFnSignalStrengthState,
  sortingFnTemperatureState,
} from 'utils/tables/sort-functions';
import {
  getSensorTagClimateTextShort,
  getSensorTagComplementaryElementTextShort,
  getSensorTagConstructionPhaseTextShort,
  getSensorTagConstructionPrinciplesTextShort,
  getSensorTagInsulationTextShort,
  getSensorTagInsulationTypeTextShort,
  getSensorTagOrientationTextShort,
  getSensorTagPrimaryElementCategoryTextShort,
  getSensorTagPrimaryElementTypeTextShort,
  getSensorTagRoomTypeTextShort,
  getSensorTagVentilationTextShort,
  getSensorTagVerticalPlacementTextShort,
} from 'utils/texts/sensor-tags';
import Sensor from 'utils/types/Sensor';
import SensorStates from 'utils/types/SensorStates';
import SensorTags from 'utils/types/SensorTags';

type SensorTableData = Sensor &
  Omit<Partial<SensorTags>, 'sensor_id'> &
  Pick<
    SensorStates,
    | 'activity_state'
    | 'humidity_state'
    | 'mold_growth_risk_state'
    | 'moisture_state'
    | 'signal_strength_state'
    | 'temperature_state'
  > & {
    access_links?: any;
    blueprint?: any;
    description?: string;
    _remove_from_group?: boolean;
    _device_number: number;
  };

export const SensorsPaginatedTable: React.FC<
  {
    sensors: Sensor[];
    isPending?: boolean;
    fromSensorGroupId?: string;
    showSearchBar?: boolean;
    showToggling?: boolean;
    onDeleteClick?: (sensor: Sensor) => void;
    onClick?: (row?: Sensor) => void;
    actionsAvailable?: TableActionType[];
  } & Pick<TableProps<SensorTableData>, 'hideHeader' | 'tableIdentifier'>
> = ({
  tableIdentifier,
  sensors,
  isPending = false,
  fromSensorGroupId,
  showSearchBar = true,
  showToggling = true,
  onDeleteClick,
  onClick,
  actionsAvailable,
  ...props
}) => {
  const [showSensorModal, setShowUpdateModal] = useState(false);
  const [showAlarmRulesModal, setShowAlarmRulesModal] = useState(false);
  const [showSensorTagsModal, setShowSensorTagsModal] = useState(false);
  const [showSensorDownlinksModal, setShowSensorDownlinksModal] = useState(false);
  const [activeSensorId, setActiveSensorId] = useState<string>();
  const [showSensorDownlinkModal, setShowSensorDownlinkModal] = useState(false);
  const [sensorIdsToUpdate, setSensorIdsToUpdate] = useState<string[]>([]);
  const [showCustomerSelect, setShowCustomerSelect] = useState(false);
  const [showSensorTagModal, setShowSensorTagModal] = useState(false);

  const navigate = useNavigate();
  const { t } = useTranslation('components');

  const { customerId } = useSelectedCustomer();
  const [width] = useWindowSize();
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { sensor, updateSensor } = useSensor(activeSensorId);
  const { updateSensorTags } = useSensorTags(activeSensorId);
  const { createDownlink } = useSensorDownlinks(activeSensorId, { enableGet: false });
  const { createSensorGroupByCustomerId } = useCustomerSensorGroups(customerId, {
    enableGet: false,
  });
  const { addSensorsToGroupById } = useSensorGroupSensors();

  const sensorTableData: SensorTableData[] = useMemo(
    () =>
      sensors.map(sensor => ({
        ...sensor,
        ...sensor.tags,
        sensor_id: undefined,
        activity_state: sensor.states.activity_state,
        humidity_state: sensor.states.humidity_state,
        mold_growth_risk_state: sensor.states.mold_growth_risk_state,
        moisture_state: sensor.states.moisture_state,
        signal_strength_state: sensor.states.signal_strength_state,
        temperature_state: sensor.states.temperature_state,
        description: sensor.geographic_location?.description,
        _device_number:
          sensor.ttn_dev_id && sensor.ttn_dev_id.startsWith('woody')
            ? parseInt(sensor.ttn_dev_id.substring(5), 10)
            : 0,
      })),
    [sensors],
  );

  // Sensor Tags
  const tagClassNames = 'text-xs inline-block cursor-pointer hover:shadow';

  const onTagClick = useCallback(
    (sensorId: string) => {
      setActiveSensorId(sensorId);
      setShowSensorTagsModal(true);
    },
    [setActiveSensorId, setShowSensorTagsModal],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SensorTableData>();

    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('tables.SensorsPaginatedTable.columns.name'),
        size: width > lg ? 350 : 250,
        minSize: 250,
        enableColumnFilter: false,
        enableHiding: false,
        enablePinning: true,
        meta: {
          className: 'flex-grow text-left',
        },
        cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <div className="flex items-center justify-start max-h-full gap-2">
            <SensorLink
              className="min-w-[80px]"
              sensorId={original.id}
              fromSensorGroupId={fromSensorGroupId}
              timeFrom={timeFrom}
              timeTo={timeTo}
            >
              <SensorThumbnail
                sensorId={original.id}
                sensorFileId={original.sensor_file_id}
                height={34}
              />
            </SensorLink>

            <p className="text-brand-green underline hover:text-blue-500">
              <SensorLink
                sensorId={original.id}
                fromSensorGroupId={fromSensorGroupId}
                timeFrom={timeFrom}
                timeTo={timeTo}
              >
                <span className="mb-2">
                  {original.name
                    ? `${original.name}`
                    : t('tables.SensorsPaginatedTable.columns.unknown')}
                </span>
              </SensorLink>
            </p>
          </div>
        ),
      }),

      columnHelper.accessor('description', {
        id: 'description',
        header: () => t('tables.SensorsPaginatedTable.columns.description'),
        size: width > lg ? 250 : 150,
        sortingFn: 'basic',
        enableColumnFilter: false,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <EditOnHoverText
            text={getValue() || t('tables.SensorsPaginatedTable.columns.descriptionPlaceholder')}
            onClick={() => {
              setActiveSensorId(original.id);
              setShowUpdateModal(true);
            }}
          />
        ),
      }),

      columnHelper.accessor('moisture_state', {
        id: 'moisture_state',
        header: () => t('tables.SensorsPaginatedTable.columns.moisture'),
        sortingFn: sortingFnSensorState,
        filterFn: 'equalsString',
        size: 190,
        meta: {
          filterVariant: 'select',
          transform: getMoistureStateText,
          className: 'text-left',
        },
        cell: ({ getValue }) => <MoistureStateBadge state={getValue()} />,
      }),

      columnHelper.accessor('activity_state', {
        id: 'activity_state',
        header: () => t('tables.SensorsPaginatedTable.columns.activity'),
        sortingFn: sortingFnActivityState,
        filterFn: 'equalsString',
        size: 190,
        meta: {
          filterVariant: 'select',
          transform: getActivityStateText,
          className: 'text-left',
        },
        cell: ({ getValue }) => <ActivityStateBadge state={getValue()} />,
      }),

      columnHelper.accessor('humidity_state', {
        id: 'humidity_state',
        header: () => t('tables.SensorsPaginatedTable.columns.humidity'),
        sortingFn: sortingFnHumidityState,
        filterFn: 'equalsString',
        size: 190,
        meta: {
          filterVariant: 'select',
          transform: getHumidityStateText,
          className: 'text-left',
        },
        cell: ({ getValue }) => <HumidityStateBadge state={getValue()} />,
      }),

      columnHelper.accessor('mold_growth_risk_state', {
        id: 'mold_growth_risk_state',
        header: () => t('tables.SensorsPaginatedTable.columns.mold_growth_risk'),
        sortingFn: sortingFnMoldGrowthRiskState,
        filterFn: 'equalsString',
        size: 190,
        meta: {
          filterVariant: 'select',
          transform: getMoldGrowthRiskStateText,
          className: 'text-left',
        },
        cell: ({ getValue }) => <MoldGrowthRiskStateBadge state={getValue()} />,
      }),

      columnHelper.accessor('temperature_state', {
        id: 'temperature_state',
        header: () => t('tables.SensorsPaginatedTable.columns.temperature'),
        sortingFn: sortingFnTemperatureState,
        filterFn: 'equalsString',
        size: 190,
        meta: {
          filterVariant: 'select',
          transform: getTemperatureStateText,
          className: 'text-left',
        },
        cell: ({ getValue }) => <TemperatureStateBadge state={getValue()} />,
      }),

      columnHelper.accessor('signal_strength_state', {
        id: 'signal_strength_state',
        header: () => t('tables.SensorsPaginatedTable.columns.signal_strength'),
        sortingFn: sortingFnSignalStrengthState,
        size: 190,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSignalStrengthStateText,
          className: 'text-left',
        },
        cell: ({ getValue }) => <SignalStrengthStateBadge state={getValue()} />,
      }),

      columnHelper.accessor('send_alarms', {
        id: 'send_alarms',
        header: () => t('tables.SensorsPaginatedTable.columns.alarms'),
        sortingFn: 'basic',
        enableColumnFilter: false,
        meta: {
          className: 'text-left',
        },
        size: 150,
        cell: ({
          getValue,
          cell: {
            row: { original },
          },
        }) => (
          <AlarmCell
            id={original.id}
            checked={!!getValue()}
            onEditClick={() => {
              setActiveSensorId(original.id);
              setShowAlarmRulesModal(true);
            }}
            showEditButton={!!getValue()}
          />
        ),
      }),

      columnHelper.accessor('blueprint', {
        id: 'blueprint',
        header: () => t('tables.SensorsPaginatedTable.columns.blueprint'),
        enableColumnFilter: false,
        size: 200,
        cell: ({
          cell: {
            row: { original },
          },
        }) => <BlueprintCell sensorId={original.id} />,
      }),

      columnHelper.accessor('access_links', {
        id: 'access_links',
        header: () => t('tables.SensorsPaginatedTable.columns.access_links'),
        enableSorting: false,
        enableColumnFilter: false,
        size: 150,
        cell: ({
          cell: {
            row: { original },
          },
        }) => <SensorUsersInlineList sensorId={original.id} />,
      }),

      columnHelper.accessor('setup_completed_at', {
        id: 'setup_completed_at',
        header: () => t('tables.SensorsPaginatedTable.columns.setup'),
        enableColumnFilter: false,
        sortingFn: sortingFnDateTime,
        size: 200,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('primary_element_category', {
        id: 'primary_element_category',
        header: () => t('tables.SensorsPaginatedTable.columns.category'),
        size: 300,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagPrimaryElementCategoryTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagPrimaryElementCategoryBadge
              className={tagClassNames}
              sensorTagPrimaryElementCategory={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('primary_element_type', {
        id: 'primary_element_type',
        header: () => t('tables.SensorsPaginatedTable.columns.type'),
        size: 275,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagPrimaryElementTypeTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagPrimaryElementTypeBadge
              className={tagClassNames}
              sensorTagPrimaryElementType={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('complementary_element', {
        id: 'complementary_element',
        header: () => t('tables.SensorsPaginatedTable.columns.complementary'),
        size: 300,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagComplementaryElementTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagComplementaryElementBadge
              className={tagClassNames}
              sensorTagComplementaryElement={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('climate', {
        id: 'climate',
        header: () => t('tables.SensorsPaginatedTable.columns.climate'),
        size: 250,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagClimateTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagClimateBadge
              className={tagClassNames}
              sensorTagClimate={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('ventilation', {
        id: 'ventilation',
        header: () => t('tables.SensorsPaginatedTable.columns.ventilation'),
        size: 200,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagVentilationTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagVentilationBadge
              className={tagClassNames}
              sensorTagVentilation={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('construction_phase', {
        id: 'construction_phase',
        header: () => t('tables.SensorsPaginatedTable.columns.construction_phase'),
        size: 250,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagConstructionPhaseTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagConstructionPhaseBadge
              className={tagClassNames}
              sensorTagConstructionPhase={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('construction_principles', {
        id: 'construction_principles',
        header: () => t('tables.SensorsPaginatedTable.columns.construction_principles'),
        size: 250,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagConstructionPrinciplesTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagConstructionPrinciplesBadge
              className={tagClassNames}
              sensorTagConstructionPrinciples={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('insulation', {
        id: 'insulation',
        header: () => t('tables.SensorsPaginatedTable.columns.insulation'),
        size: 225,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagInsulationTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagInsulationBadge
              className={tagClassNames}
              sensorTagInsulation={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('insulation_type', {
        id: 'insulation_type',
        header: () => t('tables.SensorsPaginatedTable.columns.insulation_type'),
        size: 225,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagInsulationTypeTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagInsulationTypeBadge
              className={tagClassNames}
              sensorTagInsulationType={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('room_type', {
        id: 'room_type',
        header: () => t('tables.SensorsPaginatedTable.columns.roomType'),
        size: 250,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagRoomTypeTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagRoomTypeBadge
              className={tagClassNames}
              sensorTagRoomType={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('orientation', {
        id: 'orientation',
        header: () => t('tables.SensorsPaginatedTable.columns.orientation'),
        size: 200,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagOrientationTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagOrientationBadge
              className={tagClassNames}
              sensorTagOrientation={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('vertical_placement', {
        header: () => t('tables.SensorsPaginatedTable.columns.verticalPlacement'),
        id: 'vertical_placement',
        size: 250,
        filterFn: 'equalsString',
        meta: {
          filterVariant: 'select',
          transform: getSensorTagVerticalPlacementTextShort,
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return value ? (
            <SensorTagVerticalPlacementBadge
              className="text-xs inline-block"
              sensorTagVerticalPlacement={value}
              onClick={async () => onTagClick(original.id)}
              useColors
            />
          ) : (
            <MissingTagCell
              sensorId={original.id}
              setActiveSensorId={setActiveSensorId}
              setShowSensorTagsModal={setShowSensorTagsModal}
            />
          );
        },
      }),

      columnHelper.accessor('ttn_dev_id', {
        id: 'ttn_dev_id',
        header: () => t('tables.SensorsPaginatedTable.columns.ttn_dev_id'),
        enableColumnFilter: false,
      }),

      columnHelper.accessor('_device_number', {
        id: '_device_number',
        header: () => t('tables.SensorsPaginatedTable.columns.device_number'),
        size: 180,
        enableColumnFilter: false,
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
      }),

      columnHelper.accessor('hardware_id', {
        id: 'hardware_id',
        header: () => t('tables.SensorsPaginatedTable.columns.hardware_id'),
        size: 200,
        enableSorting: false,
        enableColumnFilter: false,
        cell: ({ getValue }) => <samp>{getValue()}</samp>,
      }),

      columnHelper.accessor('heartbeat_interval', {
        id: 'heartbeat_interval',
        header: () => t('tables.SensorsPaginatedTable.columns.heartbeat_interval.1'),
        size: 180,
        enableColumnFilter: false,
        meta: {
          className: 'text-left',
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          return typeof value === 'number' ? (
            <EditOnHoverText
              text={`${value}. min`}
              data-tooltip-content={t('tables.SensorsPaginatedTable.columns.heartbeat_interval.2', {
                getValue,
              })}
              data-tooltip-id="route-tooltip"
              onClick={() => {
                setActiveSensorId(original.id);
                setShowSensorDownlinksModal(true);
              }}
            />
          ) : (
            <></>
          );
        },
      }),

      columnHelper.accessor('latest_transmission_received_at', {
        id: 'latest_transmission_received_at',
        header: () => t('tables.SensorsPaginatedTable.columns.latest_transmission_received_at'),
        sortingFn: sortingFnDateTime,
        enableColumnFilter: false,
        size: width > lg ? 210 : 170,
        cell: ({ getValue }) => (
          <LatestTransmissionStatus latestTransmissionReceivedAt={getValue()} />
        ),
      }),

      columnHelper.accessor('_remove_from_group', {
        id: '_remove_from_group',
        header: '',
        size: 200,
        enableSorting: false,
        enableColumnFilter: false,
        enableHiding: false,
        enablePinning: true,
        enableResizing: false,
        meta: {
          className: 'text-center',
          hidden: !onDeleteClick,
          hideFromToggleModal: true,
        },
        cell: ({
          cell: {
            row: { original },
          },
        }) =>
          onDeleteClick ? (
            <RemoveFromGroupCell sensor={original} onDeleteClick={onDeleteClick} />
          ) : (
            <></>
          ),
      }),

      columnHelper.accessor('id', {
        header: '',
        id: 'id',
        enableColumnFilter: false,
        enableSorting: false,
        enableHiding: false,
        enablePinning: true,
        enableResizing: false,
        meta: {
          className: 'text-center',
          hideFromToggleModal: true,
        },
        size: 75,
        cell: ({ getValue }) => (
          <div>
            <FontAwesomeIcon
              onClick={() => {
                setActiveSensorId(getValue());
                setShowUpdateModal(true);
              }}
              data-tooltip-content={t('tables.SensorsPaginatedTable.columns.editSensor.tooltip')}
              data-tooltip-id="route-tooltip"
              className="text-brand-green-light-3 hover:text-brand-blue w-5 h-5 cursor-pointer"
              icon={faEdit}
            />
          </div>
        ),
      }),
    ];
  }, [fromSensorGroupId, onDeleteClick, onTagClick, t, timeFrom, timeTo, width]);

  const onUpdateSensorModalSubmit = async ({
    name,
    geographic_location,
    send_alarms,
    tree_type,
  }: onSubmitValues) => {
    await updateSensor({
      name,
      send_alarms,
      tree_type,
      geographic_location,
    });
    notificationSuccess(t('tables.sensorsPaginatedTable.onUpdateSensorModalSubmit.successText'));
    setActiveSensorId(undefined);
    setShowUpdateModal(false);
  };

  const onUpdateSensorTagsModalSubmit = async (sensorTags: SensorTags) => {
    await updateSensorTags(sensorTags);
    notificationSuccess(
      t('tables.sensorsPaginatedTable.onUpdateSensorTagsModalSubmit.successText'),
    );
    setActiveSensorId(undefined);
    setShowSensorTagsModal(false);
  };

  const onUpdateSensorFrequencyModalSubmit = async (frequency: number) => {
    const isConfirmed = await confirmSensorDownlinkSubmit();

    if (isConfirmed) {
      await createDownlink(frequency);
      notificationSuccess(
        t('tables.sensorsPaginatedTable.onUpdateSensorFrequencyModalSubmit.successText'),
      );
    }

    setActiveSensorId(undefined);
    setShowSensorDownlinksModal(false);
  };

  const actions = useMemo(() => {
    const actions: TableAction[] = [];

    if (actionsAvailable?.includes('create-group')) {
      actions.push({
        title: t('cards.sensor_groups.SensorGroups.createGroup'),
        action: async (sensorIds: string[]) => {
          if (!customerId) {
            throw Error('No customer selected');
          }

          // Create Sensor Group
          const values = {
            name: t('cards.sensor_groups.SensorGroupsCard.newGroupName'),
            customerId,
          };

          const group = await createSensorGroupByCustomerId(values);

          await addSensorsToGroupById({ sensorGroupId: group.id, sensorIds });
          notificationSuccess(t('cards.sensor_groups.SensorGroupsCard.onCreationText'));

          navigate(`/user/groups/${group.id}/overview`);
        },
        icon: faPlus,
      });
    }

    if (actionsAvailable?.includes('update-frequency')) {
      actions.push({
        title: t('cards.sensor_groups.SensorGroups.bulkUpdateFrequency'),
        action: (sensorIds: string[]) => {
          setShowSensorDownlinkModal(true);
          setSensorIdsToUpdate(sensorIds);
        },
        icon: faEdit,
      });
    }

    if (actionsAvailable?.includes('update-customer')) {
      actions.push({
        title: t('cards.sensor_groups.SensorGroups.bulkUpdateCustomer'),
        action: (sensorIds: string[]) => {
          setShowCustomerSelect(true);
          setSensorIdsToUpdate(sensorIds);
        },
        icon: faEdit,
      });
    }

    if (actionsAvailable?.includes('update-tags')) {
      actions.push({
        title: t('cards.sensor_groups.SensorGroups.bulkUpdateTags'),
        action: (sensorIds: string[]) => {
          setShowSensorTagModal(true);
          setSensorIdsToUpdate(sensorIds);
        },
        icon: faEdit,
      });
    }

    if (actionsAvailable?.includes('compare-sensors')) {
      actions.push({
        title: t('cards.sensor_groups.SensorGroups.compareSensors'),
        action: async (sensorIds: string[]) => {
          navigate(`/user/sensors/compare?sensorIds=${sensorIds.join(',')}`);
        },
        icon: faMagnifyingGlassChart,
      });
    }

    return actions;
  }, [
    actionsAvailable,
    addSensorsToGroupById,
    createSensorGroupByCustomerId,
    customerId,
    navigate,
    t,
  ]);

  const { initialColumnPinning, initialColumnVisibility } = useMemo(() => {
    const initialColumnPinning = {
      left: ['selection', 'name'],
      right: ['id', '_remove_from_group'],
    };

    const initialColumnVisibility = {
      name: true,
      decription: true,
      moisture_state: true,
      activity_state: false,
      humidity_state: false,
      mold_growth_risk_state: false,
      temperature_state: false,
      send_alarms: true,
      signal_strength_state: false,
      blueprint: false,
      access_links: false,
      setup_completed_at: false,
      primary_element_category: false,
      primary_element_type: false,
      complementary_element: false,
      climate: false,
      ventilation: false,
      construction_phase: false,
      construction_principles: false,
      insulation: false,
      insulation_type: false,
      room_type: false,
      orientation: false,
      vertical_placement: false,
      ttn_dev_id: false,
      _device_number: false,
      hardware_id: false,
      heartbeat_interval: false,
      latest_transmission_received_at: false,
      _remove_from_group: true,
      id: true,
    };

    return { initialColumnPinning, initialColumnVisibility };
  }, []);

  return (
    <div data-testid="sensors-paginated-table-div">
      <Table
        key={tableIdentifier}
        tableIdentifier={`sensors-paginated-table-${tableIdentifier}`}
        data={sensorTableData}
        columns={columns}
        loading={isPending}
        showSearchBar={showSearchBar}
        showTogglingColumns={showToggling}
        showTogglingFilters
        sortBy={[{ id: 'moisture_state', desc: true }]}
        placeholder={t('tables.SensorsPaginatedTable.searchBar.placeholder')}
        onClick={onClick}
        initialColumnPinning={initialColumnPinning}
        initialColumnVisibility={initialColumnVisibility}
        actions={actions}
        {...props}
      />

      {activeSensorId && (
        <>
          <UpdateSensorModal
            onSubmit={onUpdateSensorModalSubmit}
            show={showSensorModal}
            setShow={setShowUpdateModal}
            values={sensor}
            showName
            showDescription
            showTreeType
            showEnableAlarms
            useLinkAsTitle
          />
          <UpdateSensorAlarmRulesModal
            sensorId={activeSensorId}
            show={showAlarmRulesModal}
            setShow={setShowAlarmRulesModal}
            hideToggleAlarmsButton
          />
          <SensorDownlinksModal
            sensorId={activeSensorId}
            show={showSensorDownlinksModal}
            setShow={setShowSensorDownlinksModal}
            onSubmit={onUpdateSensorFrequencyModalSubmit}
          />
          {sensor?.tags && (
            <SensorTagsModal
              onSubmit={onUpdateSensorTagsModalSubmit}
              setShow={setShowSensorTagsModal}
              show={showSensorTagsModal}
              sensorTags={sensor.tags}
              sensorId={activeSensorId}
              showCopyFromSensorButton
            />
          )}
        </>
      )}

      {/* Action components */}
      <ActionComponents
        sensors={sensors}
        sensorIdsToUpdate={sensorIdsToUpdate}
        showCustomerSelect={showCustomerSelect}
        setShowCustomerSelect={setShowCustomerSelect}
        showSensorDownlinkModal={showSensorDownlinkModal}
        setShowSensorDownlinkModal={setShowSensorDownlinkModal}
        showSensorTagModal={showSensorTagModal}
        setShowSensorTagModal={setShowSensorTagModal}
      />
    </div>
  );
};
