import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import HookOptions from 'utils/types/HookOptions';
import { parseSensor } from 'utils/types/Sensor';

export const useAdminSensors = ({ enableGet = true }: HookOptions = {}) => {
  const queryKey = ['adminSensors'];

  const { isAdmin } = useCurrentUser();

  const queryClient = useQueryClient();

  // Get
  const { data: sensors, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/sensors', {
        params: { include_thumbnails: false, include_lora_details: true },
      }),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: isAdmin && enableGet,
  });

  // Delete recently created sensor by ID
  const { mutateAsync: deleteSensorById } = useMutation({
    mutationFn: async (sensorId: string) => api.delete(`/admin/sensors/${sensorId}`),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return {
    sensors,
    isPending,
    deleteSensorById,
  };
};
