import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router';

import { Button } from 'components';
import Modal from 'components/modals/Modal';

const RouterPromptModal: React.FC<{
  when?: boolean;
  title?: string;
  okText?: string;
  cancelText?: string;
}> = ({ when, title, okText, cancelText }) => {
  const [showPrompt, setShowPrompt] = useState(false);
  const { t } = useTranslation('components');

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !!when && currentLocation.pathname !== nextLocation.pathname,
  );

  useEffect(() => {
    if (blocker.state === 'blocked' && !showPrompt) {
      setShowPrompt(true);
    }
  }, [blocker, showPrompt]); //eslint-disable-line

  const handleOK = useCallback(() => {
    setShowPrompt(false);
    if (blocker.proceed) blocker.proceed();
  }, [blocker]);

  const handleCancel = useCallback(() => {
    setShowPrompt(false);
    if (blocker.reset) blocker.reset();
  }, [blocker]);

  return (
    <Modal
      title={title || t('modals.RouterPrompModal.title')}
      show={showPrompt}
      setShow={setShowPrompt}
    >
      <p className="mb-5">{t('modals.RouterPromptModal.bodyText')}</p>

      <div className="flex gap-2">
        <Button className="text-sm" onClick={handleCancel} variant="primary">
          {cancelText || t('modals.RouterPromptModal.cancelText')}
        </Button>
        <Button className="text-sm" onClick={handleOK} variant="outline-danger">
          {okText || t('modals.RouterPromptModal.okText')}
        </Button>
      </div>
    </Modal>
  );
};

export default RouterPromptModal;
