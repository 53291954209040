import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseBlueprint } from 'utils/types/Blueprint';

export const useSensorGroupBlueprints = (sensorGroupId?: string, disableLoading = true) => {
  const queryKey = ['sensorGroupBlueprints', sensorGroupId];

  // Get
  const { data: blueprints, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/blueprints`),
    select: (blueprints: any[]) => blueprints?.map(parseBlueprint),
    enabled: !!sensorGroupId,
    meta: {
      disableLoading,
    },
  });

  return {
    blueprints,
    isPending,
  };
};
