type FrequencyPlan = {
  loraMultiSFChannels: number[];
  region: string;
};

type Location = {
  latitude: number;
  longitude: number;
  altitude: number;
  accuracy: number;
};

type PublicTTNGateway = {
  antennaCount?: number;
  antennaPlacement?: string;
  clusterID: string;
  eui: string;
  frequencyPlan: FrequencyPlan;
  id: string;
  location?: Location;
  netID: string;
  online: boolean;
  rxRate: number;
  tenantID: string;
  txRate: number;
  updatedAt?: Date;
};

export const parsePublicTTNGateway = (publicTTNGateway: any): PublicTTNGateway => {
  if (publicTTNGateway.updatedAt) publicTTNGateway.updatedAt = new Date(publicTTNGateway.updatedAt);
  return publicTTNGateway;
};

export default PublicTTNGateway;
