import { faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTagsInput from 'react-tagsinput';

import { Badge } from 'components';

export const TagsInput: React.FC<{
  onUpdate: (tags: string[]) => void;
  label?: string | JSX.Element;
  initialTags: string[];
  placeholderText?: string;
}> = ({ onUpdate, initialTags = [], label, placeholderText }) => {
  const [selected, setSelected] = useState(initialTags);

  const { t } = useTranslation('components');

  useEffect(() => setSelected(initialTags), [initialTags]);
  useEffect(() => onUpdate(selected), [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {label && (
        <label className="mb-1.5" htmlFor="tags-input-input">
          {label}
        </label>
      )}

      <ReactTagsInput
        value={selected}
        inputProps={{
          id: 'tags-input-input',
          className: 'w-full',
          placeholder: placeholderText || t('Tagsinput.placeholder'),
        }}
        onChange={(tags: any[]) => {
          const uniqueTags = [...new Set(tags)];
          setSelected(uniqueTags);
        }}
        renderTag={({
          tag,
          key,
          disabled,
          onRemove,
          classNameRemove,
          getTagDisplayValue,
          ...other
        }) => (
          <Badge key={key} className="mr-1 mb-2 bg-blue-500 text-white inline-block">
            {getTagDisplayValue(tag)}
            <FontAwesomeIcon
              className="ml-1 cursor-pointer"
              icon={faMinusCircle}
              onClick={() => onRemove(key)}
            />
          </Badge>
        )}
        renderLayout={(tagElements, inputElement) => (
          <span>
            {tagElements}
            {inputElement}
          </span>
        )}
        renderInput={({ onChange, value, addTag, ...other }) => (
          <input type="text" onChange={onChange} value={value} {...other} />
        )}
      />
    </>
  );
};
