import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Danger } from 'components';
import { useSensor } from 'utils/hooks/data';

export const SensorOfflineAlert: React.FC<{
  className?: string;
  sensorId: string;
  includeLinkToSensor?: boolean;
}> = ({ className, sensorId, includeLinkToSensor = false }) => {
  const { t } = useTranslation();

  const { sensor } = useSensor(sensorId);

  return (
    <Danger className={className}>
      <span className="font-extrabold">{t('utils:notice')}</span>:{' '}
      {sensor && includeLinkToSensor ? (
        <>
          {capitalize(t('utils:sensorWord'))}{' '}
          <Link className="underline hover:text-blue-500" to={`/user/sensors/${sensorId}`}>
            {sensor.name}
          </Link>
        </>
      ) : (
        <>
          {t('utils:your')}{' '}
          {includeLinkToSensor ? (
            <Link className="underline hover:text-blue-500" to={`/user/sensors/${sensorId}`}>
              {t('utils:sensorWord')}
            </Link>
          ) : (
            t('utils:sensorWord')
          )}
        </>
      )}{' '}
      {t('components:overview.StatusAlerts.offlineSensor')}.
    </Danger>
  );
};

export default SensorOfflineAlert;
