import AnomalyType from 'utils/enums/AnomalyType';
import AnomalyValidationType from 'utils/enums/AnomalyValidationType';

type TransmissionAnomaly = {
  id: string;
  transmission_id: string;
  sensor_id: string;
  timestamp: Date;
  type: AnomalyType;
  description?: string;
  validation?: AnomalyValidationType;

  created_date: Date;
  updated_date?: Date;

  created_by: string;
  updated_by?: string;
};

export const parseTransmissionAnomaly = (transmissionAnomaly: any) => {
  transmissionAnomaly.timestamp = new Date(transmissionAnomaly.timestamp);
  transmissionAnomaly.created_date = new Date(transmissionAnomaly.created_date);

  if (transmissionAnomaly.updated_date) {
    transmissionAnomaly.updated_date = new Date(transmissionAnomaly.updated_date);
  }

  return transmissionAnomaly as TransmissionAnomaly;
};

export default TransmissionAnomaly;
