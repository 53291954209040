import { flexRender, Row } from '@tanstack/react-table';
import classNames from 'classnames';
import { SetStateAction } from 'react';

import { getColumnPinningStyles, getPinningClassNames } from 'utils/tables/format-functions';

export const DataRow = <T extends {}>({
  row,
  rowIndex,
  onClick,
  isMobileView,
  cellIdHovering,
  setCellIdHovering,
}: {
  row: Row<T>;
  rowIndex: number;
  onClick?: (rowData: T) => void;
  isMobileView: boolean;
  cellIdHovering: string;
  setCellIdHovering: (value: SetStateAction<string>) => void;
}) => (
  <tr
    key={row.id}
    className={classNames({
      'hover:opacity-80 cursor-pointer': typeof onClick === 'function',
    })}
    onClick={() => {
      if (typeof onClick === 'function') onClick(row.original as T);
    }}
    style={{ height: '50px' }}
  >
    {row.getVisibleCells().map(cell => (
      <td
        key={cell.id}
        className={classNames(
          'pl-7 pr-7 text-sm text-brand-gray tracking-wider whitespace-nowrap',
          {
            'bg-brand-gray-light-4': rowIndex % 2 !== 0,
            'bg-white': rowIndex % 2 === 0,
          },
          cell.column.columnDef.meta?.className,
          !isMobileView && getPinningClassNames(cell.column, cell.id === cellIdHovering),
        )}
        style={{
          ...(!isMobileView && getColumnPinningStyles(cell.column)),
          width: cell.column.getSize(),
        }}
        onMouseEnter={() => {
          setCellIdHovering(cell.id);
        }}
        onMouseLeave={() => setCellIdHovering('')}
      >
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
      </td>
    ))}
  </tr>
);
