import { useMemo } from 'react';

import Sensor from 'utils/types/Sensor';

// Sort the sensors ids based on when the setup was completed
export const useSortedSensorIds = (sensors: Sensor[] | undefined): string[] =>
  useMemo(() => {
    if (!Array.isArray(sensors)) {
      return [];
    }

    return sensors
      .sort((a, b) => {
        const dateA = a.setup_completed_at instanceof Date ? a.setup_completed_at.getTime() : 0;
        const dateB = b.setup_completed_at instanceof Date ? b.setup_completed_at.getTime() : 0;

        // If both items have a setup_completed_at date, sort by most recent first
        if (dateA && dateB) {
          return dateB - dateA;
        }

        // If only a has a date and b does not, a should come before b
        if (dateA && !dateB) {
          return -1;
        }

        // If a does not have a date but b does, b should come before a
        if (!dateA && dateB) {
          return 1;
        }

        // If neither item has a setup_completed_at date, their order doesn't change
        return 0;
      })
      .map(sensor => sensor.id);
  }, [sensors]);
