import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import User, { parseUser } from 'utils/types/User';

export const useAlertReadByUsers = (alarmId: string) => {
  const queryKey = ['useAlertReadByUsers', alarmId];

  const queryClient = useQueryClient();

  // Get
  const { data: users, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/alarms/${alarmId}/read_by`),
    select: (usersRaw: any[]) => usersRaw.map(parseUser),
    enabled: !!alarmId,
  });

  // Resolving
  const { mutateAsync: markAlertReadByCurrentUser } = useMutation({
    mutationFn: async (): Promise<User[]> => {
      const usersRaw = await api.post(`/alarms/${alarmId}/read_by`);
      return usersRaw.map(parseUser);
    },
    onSuccess: async users => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: ['useUserAlerts'] });
      queryClient.invalidateQueries({ queryKey: ['useCustomerAlerts'] });
    },
  });

  return {
    users,
    isPending,
    markAlertReadByCurrentUser,
  };
};
