import { RefObject, useEffect, Dispatch, SetStateAction } from 'react';

// inspiration taken from here https://usehooks.com/useOnClickOutside/
export const useOnClickOutside = (
  ref: RefObject<HTMLElement>,
  setState: Dispatch<SetStateAction<boolean>>,
) => {
  useEffect(() => {
    const listener: (e: Event) => void = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Element)) return;

      setState(false);
    };
    document.addEventListener('mousedown', listener, { passive: true });
    document.addEventListener('touchstart', listener, { passive: true });
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, setState]);
};
