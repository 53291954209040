import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';

import { GatewayStateBadge } from 'components';
import { faSquirtyGateway } from 'components/icons';
import GatewayState from 'utils/enums/GatewayState';
import 'styles/components/_ws-map-icon.scss';

const GatewayIconMarker: React.FC<{
  id: string;
  name: string;
  state: GatewayState;
  position: [number, number];
  onClick: () => void;
}> = ({ id, name, state, position, onClick }) => {
  const customMarkerIcon = L.divIcon({
    html: renderToStaticMarkup(
      <span className={state}>
        <FontAwesomeIcon className="ws-map-icon__fa-icon" icon={faSquirtyGateway} size="2x" />
      </span>,
    ),
    bgPos: [0, 0],
    iconSize: [0, 0],
    iconAnchor: [15, 15],
    className: 'ws-map-icon ws-map-icon-gateway',
  });

  return (
    <Marker //@ts-ignore
      className="ws-gateway-map-marker"
      pathOptions={{
        className: state,
      }}
      position={position}
      eventHandlers={{
        click: onClick,
      }}
      zIndexOffset={state === GatewayState.INACTIVE ? -100 : 100}
      icon={customMarkerIcon}
    >
      <Popup>
        <div className="text-sm text-center mt-3">
          {name ? (
            <Link
              className="text-brand-green mb-1 block underline hover:text-blue-500"
              to={`/user/gateways/${id}`}
            >
              {name} <span className="font-serif">{`(${id})`}</span>
            </Link>
          ) : (
            <p className="my-0">{id}</p>
          )}

          <GatewayStateBadge state={state} />
        </div>
      </Popup>
    </Marker>
  );
};

export default GatewayIconMarker;
