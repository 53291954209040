import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  seenOnly?: boolean;
  unseenOnly?: boolean;
  resolvedOnly?: boolean;
};

export const useUserAlerts = (
  userId?: string,
  { enableGet = true, seenOnly, unseenOnly, resolvedOnly }: Options = {},
) => {
  const queryKey = ['useUserAlerts', userId, seenOnly, unseenOnly, resolvedOnly];

  const {
    data: alerts,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/users/${userId}/alerts`, {
        params: {
          seen_only: seenOnly,
          unseen_only: unseenOnly,
          resolved_only: resolvedOnly,
        },
      }),
    select: (alerts: any[]) => alerts.map(parseAlarm),
    enabled: !!userId && enableGet,
  });

  return {
    alerts,
    isPending: isPending || isLoading || isFetching,
  };
};
