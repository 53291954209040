import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { Blueprint, parseBlueprint, SensorPosition } from 'utils/types';

type SensorPositionDetails = {
  blueprint: Blueprint;
  changed_by?: {
    id: string;
    email: string;
    full_name: string;
  };
  created_date: Date;
  position: SensorPosition;
  previous_position: SensorPosition;
};

export const useSensorPositions = (sensorId?: string) => {
  const queryKey = ['sensorPositions', sensorId];

  const { data: sensorPositions, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/blueprints/positions`),
    select: (results: any[]) =>
      results.map(
        ({ blueprint, created_date, ...result }) =>
          ({
            blueprint: parseBlueprint(blueprint),
            created_date: new Date(created_date),
            ...result,
          }) as SensorPositionDetails,
      ),
    enabled: !!sensorId,
  });

  return {
    sensorPositions,
    isPending,
  };
};
