import { useCallback, useEffect, useRef } from 'react';

export const useIsMounted = () => {
  const isMountedComponent = useRef(false);

  useEffect(() => {
    isMountedComponent.current = true;
    return () => {
      isMountedComponent.current = false;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return useCallback(() => isMountedComponent.current, []);
};
