import { Dispatch, SetStateAction, useState } from 'react';

import { Dropdown, DropdownItem } from 'components/dropdowns';

const TAG_TYPES = [
  'Primary Element Category',
  'Primary Element Type',
  'Complementary Element',
  'Ventilation',
  'Insulation',
  'Climate',
  'Orientation',
  'Room Type',
  'Vertical Placement',
  'Construction Phase',
  'Construction Principles',
  'Casing',
  'Screw Type',
];

export const TagTypeDropdown: React.FC<{ setTagType: Dispatch<SetStateAction<string>> }> = ({
  setTagType,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(TAG_TYPES[0]);

  return (
    <Dropdown
      className="w-full inline-flex items-center justify-center text-sm rounded-none"
      buttonText={selected}
      open={isOpen}
      setisOpen={setIsOpen}
      buttonWidth="w-full"
      variant="info"
    >
      {TAG_TYPES.map(tagType => (
        <DropdownItem
          key={tagType}
          controlId={`sensorMeasureType-${tagType}`}
          onClick={() => {
            setSelected(tagType);
            setIsOpen(false);
          }}
        >
          {tagType}
        </DropdownItem>
      ))}
    </Dropdown>
  );
};
