import queryString from 'query-string';
import { useEffect, useState, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export type KnownQueryKeys =
  | 'selectCustomer'
  | 'timeFrom'
  | 'timeTo'
  | 'step'
  | 'show-historical-positions'
  | 'sensorIds';

export type QueryParamOptions = {
  pushHistoryChanges?: boolean;
};

export const useQueryParam = (
  key: KnownQueryKeys,
  options: QueryParamOptions = {},
): [string | undefined, Dispatch<SetStateAction<string | undefined>>] => {
  const location = useLocation();
  const setSearchParams = useSearchParams()[1];

  const queryParsed = queryString.parse(location.search);
  const queryValue = queryParsed[key] && (queryParsed[key] as string);
  const [value, setValue] = useState(typeof queryValue === 'string' ? queryValue : undefined);
  const [previousValue, setPreviousValue] = useState<string>();

  useEffect(() => {
    const queryParsed = queryString.parse(location.search);
    const queryValue = queryParsed[key] && (queryParsed[key] as string);
    const newValue = typeof queryValue === 'string' ? queryValue : undefined;
    if (newValue !== value) {
      setValue(typeof queryValue === 'string' ? queryValue : undefined);
    }
  }, [key, location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const queryParsed = queryString.parse(location.search);
    if (queryParsed[key] !== value) {
      queryParsed[key] = value as string;

      // Do never push initial query parameter since it will get the user
      // stuck in a state where when you navigate back you are pushed forward again.
      if (previousValue !== undefined && options.pushHistoryChanges) {
        setSearchParams(queryString.stringify(queryParsed), { replace: false });
      } else {
        setSearchParams(queryString.stringify(queryParsed), { replace: true });
      }
    }

    if (value !== previousValue) {
      setPreviousValue(value);
    }
  }, [key, value]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};
