import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ResolveAlertForm, ResolveAlertFormValues } from 'components';
import Modal from 'components/modals/Modal';

export const ResolveAlertModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  onSubmit: (
    resolveAlertFormValues: ResolveAlertFormValues,
    { resetForm }: { resetForm: () => void },
  ) => Promise<void>;
}> = ({ show, setShow, onSubmit }) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      title={t('forms.ResolveAlertModal.title')}
      show={show}
      setShow={setShow}
      onClick={e => e.stopPropagation()}
    >
      <ResolveAlertForm onSubmit={onSubmit} />
    </Modal>
  );
};
