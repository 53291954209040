import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const Description = ({ description }: { description?: string }) => {
  const { t } = useTranslation('components');

  return (
    <p
      className={classNames('text-sm', {
        'text-brand-gray-light-3 truncate': description,
      })}
    >
      {description || t('cards.sensor_groups.SensorGroupThumbnailCard.descriptionMissing')}
    </p>
  );
};
