import AlarmRuleSubType from 'utils/enums/AlarmRuleSubType';

const enum AlarmRuleType {
  BuiltIn = 10,
  Custom = 20,
}

export const getAlarmRuleTypeSubtypes = (alarmRuleType: AlarmRuleType) => {
  switch (alarmRuleType) {
    case AlarmRuleType.BuiltIn:
      return [
        AlarmRuleSubType.BuiltInCustom,
        AlarmRuleSubType.MissingValues48Hours,
        AlarmRuleSubType.MissingValues1Week,
        AlarmRuleSubType.MissingValues4Weeks,
        AlarmRuleSubType.ReferenceValues,
      ];
    case AlarmRuleType.Custom:
      return [AlarmRuleSubType.Custom];
    default:
      return [];
  }
};

export default AlarmRuleType;
