import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { useCurrentUser } from 'utils/hooks';
import Customer from 'utils/types/Customer';
import Report from 'utils/types/Report';

export const ReportsTable: React.FC<{
  tableIdentifier: string;
  reports: Report[];
  isPending?: boolean;
  customers?: Customer[];
  showCustomer?: boolean;
}> = ({ tableIdentifier, reports, customers = [], isPending = false, showCustomer = false }) => {
  const { isAdmin } = useCurrentUser();

  const { t } = useTranslation('components');

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<Report>();

    return [
      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('tables.ReportsTable.columns.name.text'),
        enableColumnFilter: false,
        size: 250,
        cell: ({ getValue, row: { original } }) => (
          <TableLink
            name={getValue()}
            url={`/user/reports/${original.id}`}
            datatip={t('tables.ReportsTable.columns.name.dataTip.1')}
            externalDatatip={t('tables.ReportsTable.columns.name.dataTip.2')}
          />
        ),
      }),

      columnHelper.accessor('description', {
        id: 'description',
        header: () => t('tables.ReportsTable.columns.description.text'),
        enableColumnFilter: false,
        size: 250,
      }),

      columnHelper.accessor('timestamp_from', {
        id: 'timestamp_from',
        header: () => t('tables.ReportsTable.columns.timestamp_from.text'),
        enableColumnFilter: false,
        sortingFn: 'datetime',
        size: 100,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('timestamp_to', {
        id: 'timestamp_to',
        header: () => t('tables.ReportsTable.columns.timestamp_to.text'),
        sortingFn: 'datetime',
        enableColumnFilter: true,
        size: 100,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.ReportsTable.columns.created_date.text'),
        sortingFn: 'datetime',
        sortDescFirst: true,
        enableColumnFilter: false,
        size: 100,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('customer_id', {
        id: 'customer_id',
        header: () => t('tables.ReportsTable.columns.customer_id.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !showCustomer || !isAdmin,
        },
        cell: ({ getValue, row: { original } }) => {
          const value = getValue();
          const customer = customers.find(customer => customer.id === value);
          return (
            <TableLink
              name={customer ? customer.name : value}
              url={`/admin/customers/${original.customer_id}`}
              datatip={t('tables.ReportsTable.columns.customer_id.dataTip.1')}
              externalDatatip={t('tables.ReportsTable.columns.customer_id.dataTip.2')}
            />
          );
        },
      }),
    ];
  }, [customers, isAdmin, showCustomer, t]);

  return (
    <Table
      tableIdentifier={`reports-table-${tableIdentifier}`}
      data={reports}
      columns={columns}
      pageSize={10}
      loading={isPending}
      showSearchBar
      sortBy={[
        {
          id: 'created_date',
          desc: true,
        },
      ]}
    />
  );
};
