import { components, InputProps, GroupBase } from 'react-select';

export const CustomSelectInput = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  inputClassName,
  ...props
}: InputProps<Option, IsMulti, Group>) =>
  props.isHidden ? (
    <components.Input inputClassName={inputClassName} {...props} />
  ) : (
    <components.Input inputClassName="focus:ring-0" {...props} />
  );
