import { faMarker } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'styles/components/_ws-edit-button.scss';

export const EditButton: React.FC<{
  onClick: (event: React.MouseEvent<HTMLSpanElement>) => void;
  tooltipText: string;
}> = ({ onClick, tooltipText }) => (
  <span
    className="ws-edit-button ws-edit-button__bottom-right"
    data-tooltip-content={tooltipText}
    data-tooltip-id="route-tooltip"
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    <FontAwesomeIcon icon={faMarker} />
  </span>
);
