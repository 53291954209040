import { t } from 'translations';
import GatewayState from 'utils/enums/GatewayState';

const getGatewayStateText = (gatewayState?: GatewayState): string => {
  switch (gatewayState) {
    case GatewayState.ACTIVE:
      return t('utils:texts.gatewayState.getGatewayStateText.Active');
    case GatewayState.INACTIVE:
      return t('utils:texts.gatewayState.getGatewayStateText.InActive');
    case GatewayState.CREATED:
      return t('utils:texts.gatewayState.getGatewayStateText.Created');
    default:
      return 'n/a';
  }
};

export { getGatewayStateText };
