import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useWindowSize } from 'utils/hooks';

const Carousel: React.FC<{
  className?: string;
  elements: JSX.Element[];
  wrapperClassName?: string;
  showSlideIndicators?: boolean;
}> = ({ className, elements, wrapperClassName = 'h-32', showSlideIndicators = false }) => {
  const { t } = useTranslation('components');

  const [activeIdx, setActiveIdx] = useState(0);

  const [windowWidth] = useWindowSize();

  useEffect(() => setActiveIdx(0), [windowWidth]);

  const nSteps = elements.length;

  return (
    <div className={className}>
      <div className="relative">
        {/* Carousel wrapper */}
        <div className={classNames('relative overflow-hidden rounded-lg', wrapperClassName)}>
          {elements.map((element, index) => (
            <div
              key={index}
              className={classNames(
                'duration-700 ease-in-out absolute inset-0 transition-all transform',
                {
                  'translate-x-0 z-20': activeIdx === index,
                  'translate-x-full z-10': activeIdx < index,
                  '-translate-x-full z-10': activeIdx > index,
                },
              )}
            >
              {element}
            </div>
          ))}
        </div>

        {/* Slider indicators */}
        {showSlideIndicators && (
          <div className="absolute z-30 flex space-x-3 -translate-x-1/2 bottom-1 left-1/2">
            {elements.map((element, index) => (
              <button
                key={index}
                type="button"
                className={classNames('w-3 h-3 rounded-full', {
                  'bg-brand-gray-light-2': index === activeIdx,
                  'bg-brand-gray-light-3 hover:bg-black': index !== activeIdx,
                })}
                onClick={() => setActiveIdx(index)}
              />
            ))}
          </div>
        )}

        {/* Slider controls */}
        <button
          type="button"
          className={classNames(
            'absolute top-1/2 left-1 z-30 flex items-center justify-center cursor-pointer group focus:outline-none -translate-y-1/2',
            {
              hidden: activeIdx === 0,
            },
          )}
          onClick={() => {
            setActiveIdx(index => Math.min(Math.max(0, index - 1), nSteps - 1));
          }}
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-brand-gray-light-3/30 group-hover:bg-brand-gray-light-2/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-brand-gray-light-2 sm:w-6 sm:h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 19l-7-7 7-7"
              />
            </svg>
            <span className="sr-only">{t('Carousel.previous')}</span>
          </span>
        </button>
        <button
          type="button"
          className={classNames(
            'absolute top-1/2 right-1 z-30 flex items-center justify-center cursor-pointer group focus:outline-none -translate-y-1/2',
            {
              hidden: activeIdx === nSteps - 1,
            },
          )}
          onClick={() => {
            setActiveIdx(index => Math.min(Math.max(0, index + 1), nSteps - 1));
          }}
        >
          <span className="inline-flex items-center justify-center w-8 h-8 rounded-full sm:w-10 sm:h-10 bg-brand-gray-light-3/30 group-hover:bg-brand-gray-light-2/50 group-focus:ring-4 group-focus:ring-white group-focus:outline-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-brand-gray-light-2 sm:w-6 sm:h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
            <span className="sr-only">{t('Carousel.next')}</span>
          </span>
        </button>
      </div>
    </div>
  );
};

export default Carousel;
