import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, SensorsSelectInput, GatewaysSelectInput, SubmittingButton } from 'components';
import { faSquirtyGateway, faSquirtySensor } from 'components/icons';
import Modal from 'components/modals/Modal';
import Gateway from 'utils/types/Gateway';
import Sensor from 'utils/types/Sensor';

const SensorsAndGatewaysSelectInputModal: React.FC<{
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  sensors?: Sensor[];
  selectedSensors?: Sensor[];
  gateways?: Gateway[];
  selectedGateways?: Gateway[];
  onSubmit: ({
    gatewayIdsSelected,
    sensorIdsSelected,
  }: {
    sensorIdsSelected: string[];
    gatewayIdsSelected: string[];
  }) => Promise<void>;
  prependElements?: React.ReactNode;
  appendElements?: React.ReactNode;
  isPending: boolean;
}> = ({
  show,
  setShow,
  sensors,
  selectedSensors,
  gateways,
  selectedGateways,
  onSubmit,
  prependElements,
  appendElements,
  isPending,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const [sensorIdsSelected, setSensorIdsSelected] = useState<string[]>([]);
  const [gatewayIdsSelected, setGatewayIdsSelected] = useState<string[]>([]);

  const { t } = useTranslation('components');

  useEffect(() => {
    setSensorIdsSelected((selectedSensors || []).map(sensor => sensor.id));
  }, [selectedSensors]);

  useEffect(() => {
    setGatewayIdsSelected((selectedGateways || []).map(gateway => gateway.id));
  }, [selectedGateways]);

  return (
    <Modal
      title={t('modals.SensorsAndGatewaysSelectInputModal.title')}
      className="overflow-y-visible"
      show={show}
      setShow={(show: boolean) => {
        setShow(show);
        if (!show) {
          setIsDirty(false);
          setSensorIdsSelected((selectedSensors || []).map(sensor => sensor.id));
          setGatewayIdsSelected((selectedGateways || []).map(gateway => gateway.id));
        }
      }}
      hasUnsavedChanges={useCallback(() => isDirty, [isDirty])}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {prependElements && prependElements}

      {!!sensors && (
        <div className="mb-5">
          <h2 className="mb-2">
            <FontAwesomeIcon className="mr-2" icon={faSquirtySensor} size="lg" />
            {t('modals.SensorsSelectInputModal.chooseSensors')}
          </h2>
          <SensorsSelectInput
            initialSelectedSensorIds={sensorIdsSelected}
            menuPlacement="bottom"
            onSelect={(_, sensorIds) => {
              setSensorIdsSelected(sensorIds);
              setIsDirty(true);
            }}
            onDelete={(_, sensorIds) => {
              setSensorIdsSelected(sensorIds);
              setIsDirty(true);
            }}
            sensorsToSelect={sensors}
            showDescriptions
          />
        </div>
      )}

      {!!gateways && (
        <div className="mb-5">
          <h2 className="mb-2">
            <FontAwesomeIcon className="mr-2" icon={faSquirtyGateway} />
            {t('modals.SensorsSelectInputModal.chooseGateways')}
          </h2>
          <GatewaysSelectInput
            initialSelectedGatewayIds={gatewayIdsSelected}
            menuPlacement="bottom"
            onSelect={(_, gatewayIds) => {
              setGatewayIdsSelected(gatewayIds);
              setIsDirty(true);
            }}
            onDelete={(_, gatewayIds) => {
              setGatewayIdsSelected(gatewayIds);
              setIsDirty(true);
            }}
            gatewaysToSelect={gateways}
          />
        </div>
      )}

      {appendElements && appendElements}

      <div className="flex">
        <SubmittingButton
          onClick={async () => {
            setIsDirty(false);
            await onSubmit({
              gatewayIdsSelected,
              sensorIdsSelected,
            });
          }}
          buttonText={t('modals.SensorsAndGatewaysSelectInputModal.submit')}
          submitting={isPending}
          disabled={isPending || !isDirty}
        />

        <Button
          variant="outline-danger"
          className="ml-3"
          onClick={() => setShow(false)}
          disabled={isPending}
        >
          {t('modals.SensorsAndGatewaysSelectInputModal.cancel')}
        </Button>
      </div>
    </Modal>
  );
};

export default SensorsAndGatewaysSelectInputModal;
