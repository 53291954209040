import SensorLink, { Props } from 'components/SensorLink';
import { useTimePeriod } from 'utils/hooks';
import { useSensor } from 'utils/hooks/data';

export const SensorLinkWithName: React.FC<
  React.PropsWithChildren<Omit<Props, 'timeFrom' | 'timeTo'>>
> = ({ sensorId, ...props }) => {
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { sensor } = useSensor(sensorId);

  return (
    <SensorLink sensorId={sensorId} timeFrom={timeFrom} timeTo={timeTo} {...props}>
      {sensor?.name || sensorId}
    </SensorLink>
  );
};
