import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import Permission from 'utils/enums/Permission';
import Sensor from 'utils/types/Sensor';

export const useUtilsPermissionsSensors = (
  sensors?: Sensor[],
  permission: Permission = Permission.View,
) => {
  const sensorIds = (sensors || []).map(sensor => sensor.id);

  const queryKey = ['useUtilsPermissionsSensors', sensorIds];

  const { data: sensorPermissions, isPending } = useQuery({
    queryKey,
    queryFn: async () =>
      api.post(`/utils/permissions/sensors_bulk?permission=${permission}`, sensorIds),
    select: (sensorPermissions: any) => sensorPermissions as { [key: string]: boolean },
  });

  return {
    sensorPermissions,
    isPending,
  };
};
