import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseSensorGroup } from 'utils/types/SensorGroup';

export const useSensorGroupParentGroups = (
  sensorGroupId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['sensorGroupParentGroups', sensorGroupId];

  // Get
  const { data: parentGroups, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/parent_groups`),
    select: (data: any[]) => data.map(parseSensorGroup),
    enabled: enableGet && !!sensorGroupId,
  });

  return {
    parentGroups,
    isPending,
  };
};
