import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import {
  SensorTagCasing,
  SensorTagClimate,
  SensorTagComplementaryElement,
  SensorTagConstructionPhase,
  SensorTagConstructionPrinciples,
  SensorTagInsulation,
  SensorTagInsulationType,
  SensorTagOrientation,
  SensorTagPrimaryElementCategory,
  SensorTagPrimaryElementType,
  SensorTagRoomType,
  SensorTagScrewType,
  SensorTagUsage,
  SensorTagVentilation,
  SensorTagVerticalPlacement,
} from 'utils/enums';
import {
  getSensorTagCasingText,
  getSensorTagCasingTextShort,
  getSensorTagClimateText,
  getSensorTagClimateTextShort,
  getSensorTagComplementaryElementText,
  getSensorTagComplementaryElementTextShort,
  getSensorTagConstructionPhaseText,
  getSensorTagConstructionPhaseTextShort,
  getSensorTagConstructionPrinciplesText,
  getSensorTagConstructionPrinciplesTextShort,
  getSensorTagInsulationText,
  getSensorTagInsulationTextShort,
  getSensorTagInsulationTypeText,
  getSensorTagInsulationTypeTextShort,
  getSensorTagOrientationText,
  getSensorTagOrientationTextShort,
  getSensorTagPrimaryElementCategoryText,
  getSensorTagPrimaryElementCategoryTextShort,
  getSensorTagPrimaryElementTypeText,
  getSensorTagPrimaryElementTypeTextShort,
  getSensorTagRoomTypeText,
  getSensorTagRoomTypeTextShort,
  getSensorTagScrewTypeText,
  getSensorTagScrewTypeTextShort,
  getSensorTagUsageText,
  getSensorTagUsageTextShort,
  getSensorTagVentilationText,
  getSensorTagVentilationTextShort,
  getSensorTagVerticalPlacementText,
  getSensorTagVerticalPlacementTextShort,
} from 'utils/texts/sensor-tags';
import { SensorTag } from 'utils/types/SensorTags';

type BadgeProps = Omit<HTMLAttributes<HTMLSpanElement>, 'onClick'> & {
  onClick?: (sensorTag: SensorTag) => Promise<void>;
  useColors?: boolean;
};

export const SensorTagPrimaryElementCategoryBadge: React.FC<
  {
    sensorTagPrimaryElementCategory: SensorTagPrimaryElementCategory;
  } & BadgeProps
> = ({ sensorTagPrimaryElementCategory, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-blue-light-2': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagPrimaryElementCategoryText(sensorTagPrimaryElementCategory)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagPrimaryElementCategory) : undefined}
    {...props}
  >
    {getSensorTagPrimaryElementCategoryTextShort(sensorTagPrimaryElementCategory)}
  </Badge>
);

export const SensorTagPrimaryElementTypeBadge: React.FC<
  {
    sensorTagPrimaryElementType: SensorTagPrimaryElementType;
  } & BadgeProps
> = ({ sensorTagPrimaryElementType, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-blue-light-1': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagPrimaryElementTypeText(sensorTagPrimaryElementType)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagPrimaryElementType) : undefined}
    {...props}
  >
    {getSensorTagPrimaryElementTypeTextShort(sensorTagPrimaryElementType)}
  </Badge>
);

export const SensorTagComplementaryElementBadge: React.FC<
  {
    sensorTagComplementaryElement: SensorTagComplementaryElement;
  } & BadgeProps
> = ({ sensorTagComplementaryElement, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-blue': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagComplementaryElementText(sensorTagComplementaryElement)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagComplementaryElement) : undefined}
    {...props}
  >
    {getSensorTagComplementaryElementTextShort(sensorTagComplementaryElement)}
  </Badge>
);

export const SensorTagOrientationBadge: React.FC<
  {
    sensorTagOrientation: SensorTagOrientation;
  } & BadgeProps
> = ({ sensorTagOrientation, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-orange-light-1': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagOrientationText(sensorTagOrientation)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagOrientation) : undefined}
    {...props}
  >
    {getSensorTagOrientationTextShort(sensorTagOrientation)}
  </Badge>
);

export const SensorTagRoomTypeBadge: React.FC<
  {
    sensorTagRoomType: SensorTagRoomType;
  } & BadgeProps
> = ({ sensorTagRoomType, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-orange-light-2': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagRoomTypeText(sensorTagRoomType)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagRoomType) : undefined}
    {...props}
  >
    {getSensorTagRoomTypeTextShort(sensorTagRoomType)}
  </Badge>
);

export const SensorTagVerticalPlacementBadge: React.FC<
  {
    sensorTagVerticalPlacement: SensorTagVerticalPlacement;
  } & BadgeProps
> = ({ sensorTagVerticalPlacement, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-orange': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagVerticalPlacementText(sensorTagVerticalPlacement)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagVerticalPlacement) : undefined}
    {...props}
  >
    {getSensorTagVerticalPlacementTextShort(sensorTagVerticalPlacement)}
  </Badge>
);

export const SensorTagCasingBadge: React.FC<
  {
    sensorTagCasing: SensorTagCasing;
  } & BadgeProps
> = ({ sensorTagCasing, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-black': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagCasingText(sensorTagCasing)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagCasing) : undefined}
    {...props}
  >
    {getSensorTagCasingTextShort(sensorTagCasing)}
  </Badge>
);

export const SensorTagClimateBadge: React.FC<
  {
    sensorTagClimate: SensorTagClimate;
  } & BadgeProps
> = ({ sensorTagClimate, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-gray-light-4 text-black': useColors,
        'bg-brand-gray-light-2 text-white': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagClimateText(sensorTagClimate)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagClimate) : undefined}
    {...props}
  >
    {getSensorTagClimateTextShort(sensorTagClimate)}
  </Badge>
);

export const SensorTagConstructionPhaseBadge: React.FC<
  {
    sensorTagConstructionPhase: SensorTagConstructionPhase;
  } & BadgeProps
> = ({ sensorTagConstructionPhase, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-gray-light-3': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagConstructionPhaseText(sensorTagConstructionPhase)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagConstructionPhase) : undefined}
    {...props}
  >
    {getSensorTagConstructionPhaseTextShort(sensorTagConstructionPhase)}
  </Badge>
);

export const SensorTagConstructionPrinciplesBadge: React.FC<
  {
    sensorTagConstructionPrinciples: SensorTagConstructionPrinciples;
  } & BadgeProps
> = ({ sensorTagConstructionPrinciples, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white bg-brand-gray-light-2',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
      },
      className,
    )}
    data-tooltip-content={getSensorTagConstructionPrinciplesText(sensorTagConstructionPrinciples)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagConstructionPrinciples) : undefined}
    {...props}
  >
    {getSensorTagConstructionPrinciplesTextShort(sensorTagConstructionPrinciples)}
  </Badge>
);

export const SensorTagInsulationBadge: React.FC<
  {
    sensorTagInsulation: SensorTagInsulation;
  } & BadgeProps
> = ({ sensorTagInsulation, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-gray-light-1': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagInsulationText(sensorTagInsulation)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagInsulation) : undefined}
    {...props}
  >
    {getSensorTagInsulationTextShort(sensorTagInsulation)}
  </Badge>
);

export const SensorTagInsulationTypeBadge: React.FC<
  {
    sensorTagInsulationType: SensorTagInsulationType;
  } & BadgeProps
> = ({ sensorTagInsulationType, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-gray-light-1': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagInsulationTypeText(sensorTagInsulationType)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagInsulationType) : undefined}
    {...props}
  >
    {getSensorTagInsulationTypeTextShort(sensorTagInsulationType)}
  </Badge>
);

export const SensorTagScrewTypeBadge: React.FC<
  {
    sensorTagScrewType: SensorTagScrewType;
  } & BadgeProps
> = ({ sensorTagScrewType, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-black': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagScrewTypeText(sensorTagScrewType)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagScrewType) : undefined}
    {...props}
  >
    {getSensorTagScrewTypeTextShort(sensorTagScrewType)}
  </Badge>
);

export const SensorTagVentilationBadge: React.FC<
  {
    sensorTagVentilation: SensorTagVentilation;
  } & BadgeProps
> = ({ sensorTagVentilation, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-gray-light-3': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagVentilationText(sensorTagVentilation)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagVentilation) : undefined}
    {...props}
  >
    {getSensorTagVentilationTextShort(sensorTagVentilation)}
  </Badge>
);

export const SensorTagUsageBadge: React.FC<
  {
    sensorTagUsage: SensorTagUsage;
  } & BadgeProps
> = ({ sensorTagUsage, className, onClick, useColors, ...props }) => (
  <Badge
    className={classNames(
      'text-white',
      {
        'cursor-pointer hover:shadow-md': !!onClick,
        'bg-brand-gray': useColors,
        'bg-brand-gray-light-2': !useColors,
      },
      className,
    )}
    data-tooltip-content={getSensorTagUsageText(sensorTagUsage)}
    data-tooltip-id="route-tooltip"
    onClick={onClick ? async () => await onClick(sensorTagUsage) : undefined}
    {...props}
  >
    {getSensorTagUsageTextShort(sensorTagUsage)}
  </Badge>
);
