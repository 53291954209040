import classNames from 'classnames';
import { HTMLAttributes, useContext } from 'react';

import { getScaleStateBlueprint } from 'utils/blueprint/scale-state';
import { getSensorStateHexColor } from 'utils/colors';
import { BlueprintViewStateContext } from 'utils/contexts';

export const Header: React.FC<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  const { blueprintViewState } = useContext(BlueprintViewStateContext);

  const scaleStateMap = getScaleStateBlueprint(blueprintViewState);

  return (
    <div
      className={classNames(
        'flex flex-col lg:flex-row justify-end mb-2 rounded gap-2 lg:gap-5',
        className,
      )}
      {...props}
    >
      <div className="flex flex-1 lg:flex-none">
        {scaleStateMap.map(state => (
          <span
            key={state.label}
            data-tooltip-content={state.getTooltip?.() || ''}
            style={{ backgroundColor: getSensorStateHexColor(state.value) }}
            data-tooltip-id="route-tooltip"
            className="flex items-center text-white flex-1 lg:flex-none py-2 px-2 text-xs sm:text-sm last:rounded-r-md first:rounded-l-md text-center md:pl-5 md:pr-5"
          >
            {state.label}
          </span>
        ))}
      </div>
    </div>
  );
};
