import React from 'react';
import { Link } from 'react-router-dom';

import { BlueprintItemCard } from 'components';
import { BlueprintSettingsDropdown } from 'components/dropdowns';
import { Blueprint } from 'utils/types';

export const BlueprintItemWithSettingsCard: React.FC<{
  blueprintId?: string;
  onSelect?: (blueprint: Blueprint) => Promise<void>;
}> = ({ blueprintId, onSelect }) => (
  <div className="relative">
    <Link to={`/user/blueprints/${blueprintId}`}>
      <BlueprintItemCard blueprintId={blueprintId} onSelect={onSelect} />
    </Link>
    {blueprintId && (
      <BlueprintSettingsDropdown
        className="absolute z-10 inset-y-0 right-0 m-2"
        size="xs"
        blueprintId={blueprintId}
      />
    )}
  </div>
);
