import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseSensorGroup, SensorGroupUpdate } from 'utils/types/SensorGroup';

type Options = HookOptions & {
  signPrimaryImage?: boolean;
};

export const USE_USER_GROUPS_QUERY_KEY = 'useUserGroups';

export const useUserGroups = (
  userId?: string,
  { enableGet = true, signPrimaryImage = false }: Options = {},
) => {
  const queryClient = useQueryClient();
  const queryKey = [USE_USER_GROUPS_QUERY_KEY, userId, signPrimaryImage];

  // Get
  const {
    data: groups,
    isPending,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/users/${userId}/groups`, {
        params: { sign_primary_image: signPrimaryImage },
      }),
    select: (groups: any[]) => groups.map(parseSensorGroup),
    enabled: !!userId && enableGet,
  });

  // Create by Id
  const { mutateAsync: createSensorGroupByUserId } = useMutation({
    mutationFn: async ({
      userId,
      ...values
    }: SensorGroupUpdate & { userId?: string }): Promise<any> =>
      await api.post(`/users/${userId}/sensor_groups`, values),
    onSuccess: (sensorGroupRaw, { userId }) => {
      queryClient.invalidateQueries({
        queryKey: [USE_USER_GROUPS_QUERY_KEY, userId],
      });
    },
  });

  return {
    groups,
    isPending: isPending || isLoading || isFetching,
    createSensorGroupByUserId,
    refetch,
  };
};
