import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import GeographicLocation, {
  GeographicLocationFields,
  parseGeographicLocation,
} from 'utils/types/GeographicLocation';
import HookOptions from 'utils/types/HookOptions';

export const useGatewayGeographicLocation = (
  gatewayId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['gatewaysGeographicLocation', gatewayId];

  const queryClient = useQueryClient();

  const {
    data: geographicLocation,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/gateways/${gatewayId}/geographic_location`),
    select: (geographicLocation: any) => parseGeographicLocation(geographicLocation),
    enabled: enableGet && !!gatewayId,
  });

  const { mutateAsync: updateGatewayGeographicLocation } = useMutation({
    mutationFn: async (
      geographicLocationFields: GeographicLocationFields,
    ): Promise<GeographicLocation> => {
      const geographicLocation = await api.put(
        `/gateways/${gatewayId}/geographic_location`,
        geographicLocationFields,
      );
      return parseGeographicLocation(geographicLocation);
    },
    onSuccess: geographicLocation => {
      // Save new alarm rule to cache
      queryClient.setQueryData(queryKey, geographicLocation);

      [
        ['customerGateways'],
        ['gatewayAccessLinks', gatewayId],
        ['gateways', gatewayId],
        ['useSensorGroupUsers'],
      ].forEach(queryKey => {
        queryClient.invalidateQueries({ queryKey });
      });
    },
  });

  return {
    geographicLocation,
    isPending: isPending || isLoading || isFetching,
    updateGatewayGeographicLocation,
  };
};
