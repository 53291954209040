import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseAlarm } from 'utils/types/Alarm';

export const useGatewayAlarms = (gatewayId?: string) => {
  const queryKey = ['gatewayAlarms', gatewayId];

  // Get
  const { data: alarms, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/gateways/${gatewayId}/alarms`),
    select: (data: any[]) => data.map(parseAlarm),
    enabled: !!gatewayId,
  });

  return {
    alarms,
    isPending,
  };
};
