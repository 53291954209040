import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { Button } from 'components';
import Card from 'components/cards/Card';
import { dateToFormattedDateString, dateToPrettyDate } from 'utils/date';
import { useBlueprint } from 'utils/hooks/data';
import { Blueprint } from 'utils/types';

export const BlueprintItemCard: React.FC<{
  blueprintId?: string;
  onSelect?: (blueprint: Blueprint) => Promise<void>;
  loading?: boolean;
  clickable?: boolean;
}> = ({ blueprintId, loading, onSelect, clickable = true }) => {
  const { t } = useTranslation('components');

  const { blueprint, isPending } = useBlueprint(blueprintId, { useThumbnail: true });

  const onClick = async (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (onSelect && blueprint) {
      await onSelect(blueprint);
    }
  };

  const isLoading = isPending || loading;

  return (
    <Card
      imgClassName="rounded-l-xl grayscale"
      noPaddingBody
      imgUrl={blueprint?.storage_url || blueprint?.storage_url_thumbnail}
      className={classnames('relative overflow-hidden card-hover-effect mb-5', {
        clickable,
      })}
      style={{ height: 200 }}
      imgHeight={200}
      imgObjectFit="cover"
      body={
        <div
          style={
            isLoading
              ? {}
              : {
                  backgroundColor: 'rgba(64, 64, 64, 0.75)',
                }
          }
          className="p-4 w-1/2 flex flex-col gap-5 justify-around absolute z-10 inset-y-0 left-0"
        >
          <div>
            <h3 className="font-semibold text-white mb-0.5">
              {isLoading ? <Skeleton width={100} /> : blueprint?.descriptionTruncated}
            </h3>
            {isLoading ? (
              <Skeleton />
            ) : (
              <p
                className="text-xs text-white"
                data-tooltip-content={
                  blueprint?.updated_date
                    ? `${t('blueprints.BlueprintItemCard.updatedDate')} ${dateToPrettyDate(blueprint?.updated_date)}`
                    : `${t('blueprints.BlueprintItemCard.createdDate')} ${dateToPrettyDate(blueprint?.created_date)}`
                }
                data-tooltip-id="route-tooltip"
              >
                <FontAwesomeIcon size="xs" icon={faCalendarAlt} className="mr-1" />
                {blueprint?.updated_date
                  ? dateToFormattedDateString(blueprint?.updated_date)
                  : dateToFormattedDateString(blueprint?.created_date)}
              </p>
            )}
          </div>
          {onSelect && (
            <Button className="bg-brand-blue" onClick={onClick}>
              {t('blueprints.BlueprintItemCard.onSelectText')}
            </Button>
          )}
        </div>
      }
    />
  );
};
