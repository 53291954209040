import FileBase, { parseFileBase } from 'utils/types/FileBase';

type GatewayFile = FileBase & {
  gateway_id: string;
};

export const parseGatewayFile = (satewayFile: any): GatewayFile =>
  parseFileBase(satewayFile) as GatewayFile;

export default GatewayFile;
