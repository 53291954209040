import React, { Dispatch, SetStateAction } from 'react';

export type ContextProps = {
  step: number;
  setStep: Dispatch<SetStateAction<number | undefined>>;
  next: () => void;
  previous: () => void;
  setupStarted: Date | null;
  setSetupStarted: Dispatch<SetStateAction<Date | null>>;
};

export const SetupContext = React.createContext<ContextProps>({} as ContextProps);
