// Taken from: https://stackoverflow.com/a/49328524/2538589
// Potential alternative: https://www.npmjs.com/package/react-device-detect

/* // Opera 8.0+
export const isOpera =
  (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0; // eslint-disable-line no-undef
 */
// Firefox 1.0+
/* export const isFirefox = typeof InstallTrigger !== 'undefined'; */

// Safari 3.0+ "[object HTMLElementConstructor]"
export const isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]';
  })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification)); // eslint-disable-line no-undef

// Internet Explorer 6-11
/* export const isIE = /*@cc_on!@*/ /* false || !!document.documentMode;  */

// Edge 20+
/* export const isEdge = !isIE && !!window.StyleMedia; */

// Chrome 1 - 71
/* export const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime); */

// Blink engine detection
/* export const isBlink = (isChrome || isOpera) && !!window.CSS; */

export const screenHasFocus = () =>
  document.visibilityState === 'visible' && document.hasFocus && document.hasFocus();

export const isMac = () => navigator.userAgent.indexOf('Mac OS X') !== -1;
