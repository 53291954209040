import { Row } from '@tanstack/react-table';

import { sortOrderActivityState } from 'utils/enums/ActivityState';
import { sortOrderHumidityState } from 'utils/enums/HumidityState';
import { sortOrderSensorState } from 'utils/enums/MoistureState';
import { sortOrderMoldGrowthRiskState } from 'utils/enums/MoldGrowthRiskState';
import { sortOrderSignalStrengthState } from 'utils/enums/SignalStrengthState';
import { sortOrderTemperatureState } from 'utils/enums/TemperatureState';
import GeographicLocation from 'utils/types/GeographicLocation';

export const sortingFnBoolean = <T extends {}>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
  const valueA = rowA.getValue(columnId) as boolean;
  const valueB = rowB.getValue(columnId) as boolean;

  if (valueA === valueB) {
    return 0;
  }
  return valueA ? 1 : -1;
};

export const sortingFnDateTime = <T extends {}>(rowA: Row<T>, rowB: Row<T>, columnId: string) => {
  const a: Date | undefined = rowA.getValue(columnId);
  const b: Date | undefined = rowB.getValue(columnId);
  return (a?.getTime() || 0) - (b?.getTime() || 0);
};

export const sortingFnActivityState = <T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
) => {
  const a: string | undefined = rowA.getValue(columnId);
  const b: string | undefined = rowB.getValue(columnId);

  const orderA = a !== undefined ? sortOrderActivityState[a] : 0;
  const orderB = b !== undefined ? sortOrderActivityState[b] : 0;

  return orderA - orderB;
};

export const sortingFnHumidityState = <T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
) => {
  const a: string | undefined = rowA.getValue(columnId);
  const b: string | undefined = rowB.getValue(columnId);

  const orderA = a !== undefined ? sortOrderHumidityState[a] : 0;
  const orderB = b !== undefined ? sortOrderHumidityState[b] : 0;

  return orderA - orderB;
};

export const sortingFnMoldGrowthRiskState = <T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
) => {
  const a: string | undefined = rowA.getValue(columnId);
  const b: string | undefined = rowB.getValue(columnId);

  const orderA = a !== undefined ? sortOrderMoldGrowthRiskState[a] : 0;
  const orderB = b !== undefined ? sortOrderMoldGrowthRiskState[b] : 0;

  return orderA - orderB;
};

export const sortingFnSensorState = <T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
) => {
  const a: string | undefined = rowA.getValue(columnId);
  const b: string | undefined = rowB.getValue(columnId);

  const orderA = a !== undefined ? sortOrderSensorState[a] : 0;
  const orderB = b !== undefined ? sortOrderSensorState[b] : 0;

  return orderA - orderB;
};

export const sortingFnSignalStrengthState = <T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
) => {
  const a: string | undefined = rowA.getValue(columnId);
  const b: string | undefined = rowB.getValue(columnId);

  const orderA = a !== undefined ? sortOrderSignalStrengthState[a] : 0;
  const orderB = b !== undefined ? sortOrderSignalStrengthState[b] : 0;

  return orderA - orderB;
};

export const sortingFnTemperatureState = <T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
) => {
  const a: string | undefined = rowA.getValue(columnId);
  const b: string | undefined = rowB.getValue(columnId);

  const orderA = a !== undefined ? sortOrderTemperatureState[a] : 0;
  const orderB = b !== undefined ? sortOrderTemperatureState[b] : 0;

  return orderA - orderB;
};

export const sortingFnGeographicLocation = <T extends {}>(
  rowA: Row<T>,
  rowB: Row<T>,
  columnId: string,
  desc?: boolean,
) => {
  const a: GeographicLocation | undefined = rowA.getValue(columnId);
  const b: GeographicLocation | undefined = rowB.getValue(columnId);
  const valueA = a?.description || '';
  const valueB = b?.description || '';
  return valueA > valueB ? -1 : 1;
};
