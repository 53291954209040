const enum AlarmRuleParameter {
  MOISTURE = 'MOISTURE',
  TEMPERATURE = 'TEMPERATURE',
  HUMIDITY = 'HUMIDITY',

  // Weather data
  WEATHER_RAIN_1H = 'WEATHER_RAIN_1H', // Total rain past 1 hour [mm]
  // WEATHER_SNOW_1H = 'WEATHER_SNOW_1H', // Total snow past 1 hour [mm]
  WEATHER_HUMIDITY = 'WEATHER_HUMIDITY', // Humidity [%]
  // WEATHER_PRESSURE = 'WEATHER_PRESSURE', // Atmospheric pressure [hPa]
  WEATHER_TEMPERATURE = 'WEATHER_TEMPERATURE', // Temperature [°C]
  // WeatherWindSpeed = 'WEATHER_WIND_SPEED', // Wind speed [m/s]
}

export default AlarmRuleParameter;
