const enum SensorType {
  WOODSENSE_WOODY = 'WOODSENSE_WOODY',
  WOODSENSE_FLATTY = 'WOODSENSE_FLATTY',
  SENSATIVE_STRIP = 'SENSATIVE_STRIP',
  // Elsys Sensors
  ELSYS = 'ELSYS',
  SMAPPEE = 'SMAPPEE',
}

export enum SensorSubtype {
  // Woody
  WOODSENSE_WOODY_PREMIUM = 'WOODSENSE_WOODY_PREMIUM',
  WOODSENSE_WOODY_QA = 'WOODSENSE_WOODY_QA',

  // Flatty
  WOODSENSE_FLATTY_v1_0 = 'WOODSENSE_FLATTY_v1_0',

  // Sensative
  SENSATIVE_STRIP_DRIP = 'SENSATIVE_STRIP_DRIP',

  // Elsys
  ELSYS_ERS_CO2_LITE = 'ELSYS_ERS_CO2_LITE',
  ELSYS_ERS_LITE = 'ELSYS_ERS_LITE',
  ELSYS_ERS_EYE = 'ELSYS_ERS_EYE',
  ELSYS_ERS_SOUND = 'ELSYS_ERS_SOUND',
  ELSYS_ERS_VOC = 'ELSYS_ERS_VOC',

  // Smappee
  SMAPPEE_SERVICELOCATION = 'SMAPPEE_SERVICELOCATION',
}

export default SensorType;
