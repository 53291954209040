import AlarmRule, { parseAlarmRule } from 'utils/types/AlarmRule';
import CustomerSettings, { parseCustomerSettings } from 'utils/types/CustomerSettings';

type Customer = {
  id: string;
  name: string;
  logo_url?: string;
  demo_application_id?: string;

  // Relations
  alarm_rules?: AlarmRule[];
  settings: CustomerSettings;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseCustomer = (customer: any): Customer => {
  customer.created_date = new Date(customer.created_date);

  if (customer.updated_date) {
    customer.updated_date = new Date(customer.updated_date);
  }

  if (customer.alarm_rules) {
    customer.alarm_rules = customer.alarm_rules.map(parseAlarmRule);
  }

  if (customer.settings) {
    customer.settings = parseCustomerSettings(customer.settings);
  }

  return customer as Customer;
};

// Synthetic customer reserved for admins
export const allCustomer = {
  id: 'ALL_CUSTOMERS',
  name: '- All -',
  settings: {
    show_groups: true,
    show_reports: true,
    show_alarms: true,
    show_gateways: true,
    created_date: new Date(1661265532014),
  },
  created_date: new Date(1639486427620),
} as Customer;

// Cache query keys
export const getQueryKeyCustomerAlarmRules = (customerId: string) => [
  'customerAlarmRules',
  customerId,
];
export const getQueryKeyCustomerAlarmRuleSensors = (
  customerId: string,
  alarmRuleId: string,
  idsOnly: boolean,
) => ['customerAlarmRuleSensors', customerId, alarmRuleId, idsOnly];

export const CUSTOMER_ID_TECTOR_STOCK = 'dc59df888cfd458514b45931';

export default Customer;
