import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import SensorJoinAccept, { parseSensorJoinAccept } from 'utils/types/SensorJoinAccept';

export const useSensorJoinAccepts = (
  sensorId: string,
  {
    fromTimestamp,
    toTimestamp,
    limit,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
    limit?: number;
  },
): {
  sensorJoinAccepts?: SensorJoinAccept[];
  isPending: boolean;
} => {
  const queryKey = ['sensorJoinAccepts', sensorId, fromTimestamp, toTimestamp, limit];

  // Sensor Transmissions - Get
  const { data: sensorJoinAccepts, isPending } = useQuery<SensorJoinAccept[]>({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/join_accepts`, {
        params: { from_timestamp: fromTimestamp, to_timestamp: toTimestamp, limit },
      }),
    select: (sensorJoinAccepts: any[]) => sensorJoinAccepts.map(parseSensorJoinAccept),
    enabled: !!sensorId,
  });

  return {
    sensorJoinAccepts,
    isPending,
  };
};
