import { Field, useField } from 'formik';
import { ChangeEvent } from 'react';

import { AirtableSalesOrder } from 'utils/types/Airtable';

export const SalesOrderSelectInput: React.FC<{
  name: string;
  salesOrders: AirtableSalesOrder[];
  onSelect?: (salesOrder?: AirtableSalesOrder) => void;
  isLoading?: boolean;
}> = ({ name, salesOrders = [], onSelect, isLoading }) => {
  const [field, { value }, { setValue }] = useField(name); // eslint-disable-line @typescript-eslint/no-unused-vars

  const controlId = Math.random().toString(36).substring(7);

  const _onSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const salesOrderId = e.target.value;
    setValue(salesOrderId);
    if (onSelect) {
      const salesOrder = salesOrders.find(x => x.id === salesOrderId)!;
      onSelect(salesOrder);
    }
  };

  return (
    <div className="mb-3">
      <label className="mb-2" htmlFor={controlId}>
        Sales order
      </label>
      <Field
        as="select"
        name={name}
        id={controlId}
        className="rounded"
        value={value || ''}
        onChange={_onSelect}
        disabled={isLoading}
      >
        {isLoading ? (
          <option value={undefined}>Loading...</option>
        ) : (
          <>
            <option value={undefined}>Select sales order</option>
            {salesOrders.map((salesOrder, index) => (
              <option key={index} value={salesOrder.id}>
                {salesOrder.fields['Deal name']} (receive by:{' '}
                {salesOrder.fields['Customer receive sensor by']})
              </option>
            ))}
          </>
        )}
      </Field>
    </div>
  );
};
