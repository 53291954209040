import FileBase, { parseFileBase } from 'utils/types/FileBase';

type SensorGroupFile = FileBase & {
  sensor_group_id: string;
};

export const parseSensorGroupFile = (sensorGroupFile: any): SensorGroupFile =>
  parseFileBase(sensorGroupFile) as SensorGroupFile;

export default SensorGroupFile;
