export class FeatureFlagValidationError extends Error {
  constructor(brokenFeatureFlag: string, undefinedValue: string) {
    const prefix = 'FeatureFlagValidationError: ';
    const message = `${brokenFeatureFlag} is missing defined value for ${undefinedValue}. `;
    const suffix = 'Make sure the flag is properly defined in /src/feature-flags.json';

    super(prefix + message + suffix);
  }
}

export class FeatureFlagNotFoundError extends Error {
  constructor(flagName: string) {
    const prefix = 'FeatureFlagNotFoundError: ';
    const message = `No definition found for feature flag '${flagName}'. `;
    const suffix = 'Make sure the flag is properly defined in /src/feature-flags.json';

    super(prefix + message + suffix);
  }
}
