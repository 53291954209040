import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import SmappeeElectricityConsumptionAggregation from 'utils/enums/SmappeeElectricityConsumptionAggregation';

// Read more about the parameters in the Smappee API docs
// https://smappee.atlassian.net/wiki/spaces/DEVAPI/pages/526581813/Get+Electricity+Consumption
export type SmappeeAlwaysOn = {
  timestamp: Date;
  alwaysOn?: number; // "Standby" power consumption
};

export type SmappeeChannel = {
  id: number;
  name: string;
  consumption: SmappeeChannelConsumption[];
};

export type SmappeeChannelConsumption = {
  timestamp: Date;
  active?: number;
  current?: number;
  reactive?: number;
};

export const useSensorSmappeeDetails = (
  sensorId?: string,
  {
    fromTimestamp,
    toTimestamp,
    accessToken,
    aggregation,
  }: {
    fromTimestamp?: Date;
    toTimestamp?: Date;
    accessToken?: string;
    aggregation: SmappeeElectricityConsumptionAggregation;
  } = {
    aggregation: SmappeeElectricityConsumptionAggregation.Hourly,
  },
) => {
  fromTimestamp = fromTimestamp && floorToLatest5Minutes(fromTimestamp);
  toTimestamp = toTimestamp && floorToLatest5Minutes(toTimestamp);

  const queryKey = ['sensorSmappeeDetails', sensorId, fromTimestamp, toTimestamp, aggregation];

  // Sensor Transmissions - Get
  const { data: smappeeDetails, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/smappee`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
          access_token: accessToken,
          aggregation,
        },
      }),
    select: ({
      channels,
      always_on,
      access_token,
    }: {
      channels: any[];
      always_on: any[];
      access_token: string;
    }) => ({
      alwaysOn: always_on?.map(
        ({ timestamp, alwaysOn }: { timestamp: string; alwaysOn?: number }) => ({
          timestamp: new Date(timestamp),
          alwaysOn,
        }),
      ) as SmappeeAlwaysOn[],
      channels: channels?.map(
        ({ id, name, consumption }: { id: number; name: string; consumption: any[] }) => ({
          id,
          name,
          consumption: consumption?.map(
            ({ timestamp, ...props }: { timestamp: string; [x: string]: any }) => ({
              timestamp: new Date(timestamp),
              ...props,
            }),
          ),
        }),
      ) as SmappeeChannel[],
      accessToken: access_token,
    }),
    enabled: !!sensorId && !!fromTimestamp && !!toTimestamp,
  });

  return {
    smappeeDetails,
    isPending,
  };
};
