import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import Permission from 'utils/enums/Permission';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import HookOptions from 'utils/types/HookOptions';
import { parseSensor } from 'utils/types/Sensor';

export type Options = HookOptions & {
  includeTags?: boolean;
  includeGeographicLocation?: boolean;
  includeStates?: boolean;
  permission?: Permission;
};

export const useCustomerSensors = (
  customerId?: string,
  {
    enableGet = true,
    includeTags = false,
    ignoreAdminCustomerOverwrite = false,
    includeGeographicLocation = false,
    includeStates = false,
    permission = Permission.View,
  }: Options = {},
) => {
  const queryKey = [
    'customerSensors',
    customerId,
    includeTags,
    includeGeographicLocation,
    includeStates,
    permission,
  ];

  const { showAllCustomers } = useSelectedCustomer();

  const url =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? '/admin/sensors'
      : `/customers/${customerId}/sensors`;

  // Get
  const {
    data: sensors,
    isPending,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(url, {
        params: {
          include_thumbnails: false,
          include_sensor_tags: includeTags,
          include_geographic_location: includeGeographicLocation,
          include_states: includeStates,
          permission,
        },
      }),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: !!customerId && enableGet,
  });

  return {
    sensors,
    isPending: isPending || isLoading || isFetching,
    refetch,
  };
};
