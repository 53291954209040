import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useMemo, Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import SensorTagsForm from 'components/forms/SensorTagsForm';
import Modal from 'components/modals/Modal';
import SelectSensorFromTagsModal from 'components/modals/SelectSensorFromTagsModal';
import { useSelectedCustomer, useCurrentUser } from 'utils/hooks';
import { useCustomerSensors } from 'utils/hooks/data';
import SensorTags from 'utils/types/SensorTags';

const SensorTagsModal: React.FC<
  React.PropsWithChildren<{
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    onSubmit: (sensorTags: SensorTags) => Promise<void>;
    sensorTags?: SensorTags;
    sensorId?: string;
    showCopyFromSensorButton?: boolean;
  }>
> = ({
  show,
  setShow,
  onSubmit,
  sensorTags,
  sensorId,
  showCopyFromSensorButton = false,
  children,
}) => {
  const [showModal, setShowModal] = useState(false);

  const { t } = useTranslation();

  const { isAdmin } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { sensors } = useCustomerSensors(customerId, {
    includeTags: true,
    enableGet: showCopyFromSensorButton,
  });

  // Do not include current sensor in sensors to copy from
  const sensorsFiltered = useMemo(
    () => sensors?.filter(sensor => sensor.id !== sensorId),
    [sensors, sensorId],
  );

  return (
    <Modal
      title={t('components:modals.SensorTagsModal.title')}
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      {showCopyFromSensorButton && (
        <button className="float-right underline" onClick={() => setShowModal(true)}>
          <FontAwesomeIcon className="mr-1" icon={faFileImport} />
          <span>{t('components:modals.SensorTagsModal.copyFromSensor')}</span>
        </button>
      )}

      <SensorTagsForm
        sensorTags={sensorTags}
        onSubmit={onSubmit}
        sensorIdForPredictions={sensorId}
        showBadges
        showCasingTag
        showEnvironmentTags
        showPrimaryPlacementTags
        showRoomAndOrientationTags
        showTheSensorTags
        showTitles
        showUsageTag={isAdmin}
      />

      {showCopyFromSensorButton && (
        <SelectSensorFromTagsModal
          show={showModal}
          setShow={setShowModal}
          onSelect={async sensor => {
            onSubmit(sensor.tags);
          }}
          sensors={sensorsFiltered}
        />
      )}

      {children}
    </Modal>
  );
};

export default SensorTagsModal;
