import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseGroupHierarchyTree } from 'utils/types/GroupHierarchyTree';
import HookOptions from 'utils/types/HookOptions';

export const useSensorGroupHierarchyTree = (
  sensorGroupId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['sensorGroupsHierarchyTree', sensorGroupId];

  // Get
  const { data: groupHierarchyTree, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/hierarchy`),
    select: (data: any) => parseGroupHierarchyTree(data),
    enabled: enableGet && !!sensorGroupId,
  });

  return {
    groupHierarchyTree,
    isPending,
  };
};
