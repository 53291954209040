type SensorJoinAccept = {
  id: string;
  hardware_id: string;
  timestamp: Date;
  application_id: string;
};

export const parseSensorJoinAccept = (sensorJoinAccept: any): SensorJoinAccept => {
  sensorJoinAccept.timestamp = new Date(sensorJoinAccept.timestamp);
  return sensorJoinAccept as SensorJoinAccept;
};

export default SensorJoinAccept;
