import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Spinner from 'components/Spinner';

const ProgressIndicator: React.FC<{
  inProgress?: boolean;
  failed?: boolean;
  dataTipProgress?: string;
  dataTipFailed?: string;
}> = ({ inProgress = false, failed = false, dataTipProgress, dataTipFailed }) => {
  if (failed) {
    return (
      <FontAwesomeIcon
        className="text-brand-orange ml-2.5 mt-1.5"
        icon={faTimes}
        data-tooltip-content={dataTipFailed}
        data-tooltip-id="route-tooltip"
      />
    );
  }

  if (inProgress) {
    return (
      <Spinner
        className="ml-2.5"
        data-tooltip-content={dataTipProgress}
        data-tooltip-id="route-tooltip"
      />
    );
  }

  return null;
};

export default ProgressIndicator;
