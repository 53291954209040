import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { ReactComponent as GoogleIcon } from 'images/icons/google-g-logo.svg';

export const SignInWithGoogleButton: React.FC<{ className?: string | null }> = ({ className }) => {
  const { t } = useTranslation('pages');

  return (
    <button
      className={classNames(
        'flex justify-center align-middle items-center py-1.5 w-full rounded border-2 border-brand-gray-light-4 bg-white hover:bg-brand-gray-light-4',
        className,
      )}
    >
      <GoogleIcon className="mx-2" height={20} width={20} />
      {t('Login.useGoogleText')}
    </button>
  );
};
