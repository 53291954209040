export enum SensorTagPrimaryElementType {
  // Terrain elements
  // WallFences = 201,
  // RetainingWalls = 202,
  // UndergroundService = 203,
  // PedestrianBridges = 204,
  // StairsAndRamps = 205,

  // External walls
  ExternalPrecastWalls = 211,
  ExternalInSituWalls = 212,
  ExternalMasonryWalls = 213,
  ExternalFrameStructuredWalls = 214,
  ExternalFrameStructuredBulkheadWalls = 215,
  ExternalCurtainWalls = 216,
  ExternalWallInsulation = 217,
  ExternalLightShafts = 218,

  // Interior Walls
  InteriorPrecastWalls = 221,
  InteriorInSituWalls = 222,
  InteriorBrickWalls = 223,
  InteriorFrameStructuredWalls = 224,
  InteriorFrameStructuredBulkheadWalls = 225,
  InteriorCurtainWalls = 226,

  // Slabs
  PrecastSlabs = 231,
  InSituSlabs = 232,
  FrameStructuredSlabs = 233,
  Screeds = 234,
  OtherSlabs = 239,

  // Stairs
  PrecastStairs = 241,
  InSituStairs = 242,
  CompositeStairs = 243,
  PrecastRamps = 244,
  InSituRamps = 245,
  CompositeRamps = 246,
  FixedLaddersAndSteps = 247,

  // Load-bearing beams and columns
  PrecastBeams = 251,
  InSituBeams = 252,
  SteelBeams = 253,
  OtherBeams = 254,
  PrecastColumns = 255,
  InSituColumns = 256,
  SteelColumns = 257,
  OtherColumns = 259,

  // Balconies and External hallways
  PrecastBalconiesAndExteriorHallways = 261,
  InSituBalconiesAndExteriorHallways = 262,
  CompositeBalconiesAndExteriorHallways = 263,

  // Roofs
  RafterRoofs = 271,
  RoofCassettes = 272,
  WarmRoofs = 273,
  CurtainRoofs = 274,
  MovableRoofs = 275,
  CanopiesAndShelters = 276,
  OtherRoofConstructions = 279,

  // Custom
  NotRelevant = 501,
  Other = 502,
}
