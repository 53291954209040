import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import HookOptions from 'utils/types/HookOptions';
import Sensor, { parseSensor } from 'utils/types/Sensor';

export const useBlueprintSensors = (
  blueprintId?: string,
  { enableGet = true, disableLoading = true }: HookOptions = {},
) => {
  const queryKey = ['blueprintSensors', blueprintId];

  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const queryClient = useQueryClient();

  // Sensors - Get
  const { data: sensors, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/blueprints/${blueprintId}/sensors`),
    select: (sensors: any[]) => sensors.map(parseSensor),
    enabled: enableGet && !!blueprintId,
    meta: {
      disableLoading,
    },
  });

  const onSuccessSensorsUpdate = async (sensors: Sensor[]) => {
    queryClient.setQueryData(queryKey, sensors);
    queryClient.invalidateQueries({
      queryKey: ['blueprintPositions', blueprintId],
    });
    queryClient.invalidateQueries({
      queryKey: ['customerBlueprintPositions', customerId],
    });
    queryClient.invalidateQueries({
      queryKey: ['userBlueprintPositions', userId],
    });
    queryClient.invalidateQueries({
      queryKey: ['sensorGroupBlueprints'],
    });
    queryClient.invalidateQueries({
      queryKey: ['sensorBlueprint'],
    });
    queryClient.invalidateQueries({
      queryKey: ['blueprintSensorValues'],
    });
  };

  // Add sensor to blueprint
  const { mutateAsync: addSensorToBlueprintById } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (sensorId: string) => {
      const sensorsRaw: any[] = await api.post(`/blueprints/${blueprintId}/sensors/${sensorId}`);
      return sensorsRaw.map(parseSensor);
    },
    onSuccess: onSuccessSensorsUpdate,
    meta: {
      disableLoading,
    },
  });

  // Remove sensor from blueprint
  const { mutateAsync: removeSensorFromBlueprintById } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (sensorId: string) => {
      const sensorsRaw: any[] = await api.delete(`/blueprints/${blueprintId}/sensors/${sensorId}`);
      return sensorsRaw.map(parseSensor);
    },
    onSuccess: onSuccessSensorsUpdate,
    meta: {
      disableLoading,
    },
  });

  // Add sensors to blueprint (bulk)
  const { mutateAsync: addSensorsToBlueprintById } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (sensorIds: string[]) => {
      if (sensorIds.length === 0) throw new Error('No sensors to add');

      const sensorsRaw: any[] = await api.post(
        `/blueprints/${blueprintId}/sensors_bulk?sensor_ids=${sensorIds.join(',')}`,
      );
      return sensorsRaw.map(parseSensor);
    },
    onSuccess: onSuccessSensorsUpdate,
    meta: {
      disableLoading,
    },
  });

  // Remove sensors from blueprint (bulk)
  const { mutateAsync: removeSensorsFromBlueprintById } = useMutation({
    mutationKey: queryKey,
    mutationFn: async (sensorIds: string[]) => {
      if (sensorIds.length === 0) throw new Error('No sensors to remove');

      const sensorsRaw: any[] = await api.delete(
        `/blueprints/${blueprintId}/sensors_bulk?sensor_ids=${sensorIds.join(',')}`,
      );
      return sensorsRaw.map(parseSensor);
    },
    onSuccess: onSuccessSensorsUpdate,
    meta: {
      disableLoading,
    },
  });

  return {
    addSensorsToBlueprintById,
    addSensorToBlueprintById,
    isPending,
    removeSensorFromBlueprintById,
    removeSensorsFromBlueprintById,
    sensors,
  };
};
