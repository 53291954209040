import { useEffect, useState, MutableRefObject, useRef } from 'react';

// From: https://dev.to/jmalvarez/check-if-an-element-is-visible-with-react-hooks-27h8
const _useIsVisible = (ref: MutableRefObject<HTMLDivElement | undefined>) => {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));

    observer.observe(ref?.current!);
    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return isIntersecting;
};

export const useIsVisible = (): [MutableRefObject<HTMLDivElement>, boolean] => {
  const ref = useRef<HTMLDivElement>();
  const isIntersecting = _useIsVisible(ref);

  // @ts-ignore
  return [ref, isIntersecting];
};
