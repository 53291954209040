import { useTranslation } from 'react-i18next';

import { UncontrolledSwitch } from 'components';
import { useGateway } from 'utils/hooks/data';

export const AlarmCell: React.FC<{ id: string; checked: boolean }> = ({ id, checked }) => {
  const { updateGateway } = useGateway(id, { enableGet: false });
  const { t } = useTranslation('components');

  const on = t('tables.GatewaysPaginatedTable.on');
  const off = t('tables.GatewaysPaginatedTable.off');

  return (
    <UncontrolledSwitch
      data-tooltip-content={t('tables.GatewaysPaginatedTable.alarmCellSwitchTooltip', {
        toggle: checked ? off : on,
      })}
      data-tooltip-id="route-tooltip"
      className="justify-center"
      onChange={async e => await updateGateway({ send_alarms: e.target.checked })}
      defaultChecked={checked}
    />
  );
};
