const enum Locale {
  DANISH = 'DANISH',
  ENGLISH = 'ENGLISH',
}

export const parseI18LanguageToLocale = (i18Locale: string): Locale => {
  // Locales seen so far:
  //  {None, 'da', 'da-DK', 'en', 'en-GB', 'en-NZ', 'en-US', 'nb-NO', 'sv-SE'}
  switch (i18Locale) {
    case 'da':
      return Locale.DANISH;
    case 'da-DK':
      return Locale.DANISH;
    case 'en':
      return Locale.ENGLISH;
    case 'en-GB':
      return Locale.ENGLISH;
    case 'en-NZ':
      return Locale.ENGLISH;
    case 'en-US':
      return Locale.ENGLISH;
    case 'nb-NO':
      return Locale.DANISH;
    case 'sv-SE':
      return Locale.DANISH;
    default:
      return Locale.DANISH;
  }
};

export const getLocaleFlagCountryCode = (locale: Locale) => {
  switch (locale) {
    case Locale.DANISH:
      return 'DK';
    case Locale.ENGLISH:
      return 'GB';
    default:
      return undefined;
  }
};

export default Locale;
