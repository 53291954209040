import { t } from 'translations';
import { SensorTagOrientation } from 'utils/enums';

export const getSensorTagOrientationText = (sensorTagOrientation: SensorTagOrientation) => {
  switch (sensorTagOrientation) {
    case SensorTagOrientation.North:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.North');
    case SensorTagOrientation.NorthWest:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.NorthWest');
    case SensorTagOrientation.West:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.West');
    case SensorTagOrientation.SouthWest:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.SouthWest');
    case SensorTagOrientation.South:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.South');
    case SensorTagOrientation.SouthEast:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.SouthEast');
    case SensorTagOrientation.East:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.East');
    case SensorTagOrientation.NorthEast:
      return t('utils:texts.sensor-tags.getSensorTagOrientationText.NorthEast');
    default:
      return 'n/a';
  }
};

export const getSensorTagOrientationTextShort = getSensorTagOrientationText;
