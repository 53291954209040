import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { sensorId2NameFunc, userId2NameFunc } from 'utils/formatting';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useCurrentUserUsers, useUserAndCustomerSensors } from 'utils/hooks/data';
import { getAlarmTypeText } from 'utils/sensor/texts';
import Alarm from 'utils/types/Alarm';

export const AlarmNotificationsTable: React.FC<{
  tableIdentifier: string;
  data: Alarm[];
  onClick?: (alert: Alarm) => void;
  columnsToShow?: (keyof Alarm)[];
  hideHeader?: boolean;
  isPending?: boolean;
}> = ({
  tableIdentifier,
  data,
  onClick,
  columnsToShow = ['sensor_id', 'created_date', 'type', 'responsible_user'],
  hideHeader,
  isPending,
  ...props
}) => {
  const { t } = useTranslation('components');

  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { users } = useCurrentUserUsers();
  const { sensors, isPending: isPendingSensors } = useUserAndCustomerSensors(userId, customerId);

  const columns = useMemo(() => {
    const userId2Name = userId2NameFunc(users);
    const sensorId2Name = sensorId2NameFunc(sensors);

    const columnHelper = createColumnHelper<Alarm>();

    return [
      columnHelper.accessor('sensor_id', {
        id: 'sensor_id',
        header: () => t('tables.AlarmNotificationsTable.columns.sensor_id.text'),
        enableColumnFilter: false,
        size: 120,
        meta: {
          hidden: !columnsToShow.includes('sensor_id') && !columnsToShow.includes('gateway_id'),
        },
        cell: ({ getValue, row: { original } }) =>
          original.sensor_id ? (
            <TableLink
              name={sensorId2Name[original.sensor_id] || getValue()}
              url={`/user/sensors/${getValue()}`}
              datatip={t('tables.AlarmNotificationsTable.columns.sensor_id.dataTip.1')}
              externalDatatip={t('tables.AlarmNotificationsTable.columns.sensor_id.dataTip.2')}
            />
          ) : (
            <TableLink
              name={original.gateway_id}
              url={`/user/gateways/${original.gateway_id}`}
              datatip={t('tables.AlarmNotificationsTable.columns.gateway_id.dataTip.1')}
              externalDatatip={t('tables.AlarmNotificationsTable.columns.gateway_id.dataTip.2')}
            />
          ),
      }),
      columnHelper.accessor('id', {
        id: 'id',
        header: () => t('tables.AlarmNotificationsTable.columns.id.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('id'),
        },
      }),
      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.AlarmNotificationsTable.columns.sent_time.text'),
        sortingFn: 'datetime',
        size: 100,
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('created_date'),
        },
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),
      columnHelper.accessor('responsible_user', {
        id: 'responsible_user',
        header: () => t('tables.AlarmNotificationsTable.columns.responsible_user.text'),
        enableColumnFilter: false,
        size: 100,
        meta: {
          hidden: !columnsToShow.includes('responsible_user'),
        },
        cell: ({ getValue }) => {
          const value = getValue();
          if (!value) return <>n/a</>;
          const name = userId2Name[value.id];
          const text = name ?? value.id;
          return (
            <TableLink
              name={text}
              url={`/user/organization/users/${getValue()}`}
              datatip={t('tables.DocumentChangesTable.columns.changed_by.dataTip.1')}
              externalDatatip={t('tables.DocumentChangesTable.columns.changed_by.dataTip.2')}
            />
          );
        },
      }),
      columnHelper.accessor('type', {
        id: 'type',
        header: () => t('tables.AlarmNotificationsTable.columns.type.text'),
        enableColumnFilter: false,
        meta: {
          hidden: !columnsToShow.includes('type'),
        },
        cell: ({ getValue, row: { original } }) => <>{getAlarmTypeText(getValue())}</>,
      }),
    ];
  }, [columnsToShow, sensors, t, users]);

  return (
    <Table
      tableIdentifier={`alarm-notifications-table-${tableIdentifier}`}
      data={data}
      columns={columns}
      pageSize={5}
      onClick={onClick}
      hideHeader={hideHeader}
      noDataPlaceholder={t('tables.AlarmNotificationsTable.noDataIndication')}
      loading={isPending || isPendingSensors}
      {...props}
    />
  );
};
