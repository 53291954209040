export const extractMatchedId = (props: any) => {
  const match = props.match || {};
  const params = match.params || {};
  return params.id;
};

export const extractSecondMatchedId = (props: any) => {
  const match = props.match || {};
  const params = match.params || {};
  return params.secondId;
};
