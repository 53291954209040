import { Button } from 'components';
import { t } from 'translations';
import { MySwal } from 'utils/notifications/swal';

export const confirmSensorDownlinkSubmit = async () => {
  const result = await MySwal.fire({
    title: (
      <p className="text-3xl">
        {t('components:notifications.sensors.confirmSensorDownlinkSubmit.submitTitle')}
      </p>
    ),
    html: (
      <>
        <p>{t('components:notifications.sensors.confirmSensorDownlinkSubmit.submitHtml.1')}</p>
        <p>{t('components:notifications.sensors.confirmSensorDownlinkSubmit.submitHtml.2')}</p>
        <div className="flex gap-x-5 justify-center mt-3">
          <Button onClick={() => MySwal.clickConfirm()} size="lg" variant="danger">
            {t('components:notifications.sensors.confirmSensorDownlinkSubmit.submitConfirmText')}
          </Button>
          <Button onClick={() => MySwal.close()} size="lg" variant="outline-secondary">
            {t('components:notifications.sensors.confirmSensorDownlinkSubmit.submitCancelText')}
          </Button>
        </div>
      </>
    ),
    icon: 'warning',
    showConfirmButton: false,
    showCancelButton: false,
  });

  return result.isConfirmed;
};
