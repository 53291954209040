import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { SalesOrderFormValues } from 'pages/admin/AdminSalesOrders/AdminSalesOrdersNewTab/components';
import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseSalesOrder } from 'utils/types/SalesOrder';

export const useAdminSalesOrders = () => {
  const queryKey = ['adminSalesOrders'];

  const { isAdmin } = useCurrentUser();

  const queryClient = useQueryClient();

  // Get sales orders
  const { data: salesOrders, isFetching } = useQuery({
    queryKey,
    queryFn: () => api.get('/admin/sales_orders'),
    select: (salesOrders: any[]) => salesOrders.map(parseSalesOrder),
    enabled: isAdmin,
  });

  // Create sales order
  const { mutateAsync: completeSalesOrder } = useMutation({
    mutationFn: async (salesOrder: SalesOrderFormValues) => {
      const salesOrders: any[] = await api.post('/admin/sales_orders', salesOrder);
      return salesOrders.map(parseSalesOrder);
    },
    onSuccess: async salesOrders => {
      queryClient.setQueryData(queryKey, salesOrders);
      queryClient.invalidateQueries({
        queryKey: ['adminAirtableSalesOrders'],
      });
    },
  });

  return {
    completeSalesOrder,
    salesOrders,
    isFetching,
  };
};
