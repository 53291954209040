import { faSquarePlus, faSquareMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';

export const ExpandButton: React.FC<
  {
    expanded: boolean;
    setExpanded: Dispatch<SetStateAction<boolean>>;
  } & Omit<FontAwesomeIconProps, 'icon' | 'onClick'>
> = ({ expanded, setExpanded, className, ...props }) => (
  <FontAwesomeIcon
    className={classNames('cursor-pointer', className)}
    icon={expanded ? faSquareMinus : faSquarePlus}
    onClick={() => setExpanded(val => !val)}
    {...props}
  />
);
