import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseSalesOrder } from 'utils/types/SalesOrder';

export const useAdminGroupSalesOrders = (groupId?: string) => {
  const queryKey = ['useAdminGroupSalesOrders'];

  const { isAdmin } = useCurrentUser();

  // Get
  const { data: salesOrders, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/admin/sensor_groups/${groupId}/sales_orders`, {
        params: { include_demo_data: true, include_thumbnails: false },
      }),
    select: (salesOrders: any[]) => salesOrders.map(parseSalesOrder),
    enabled: isAdmin && !!groupId,
  });

  return {
    salesOrders,
    isPending,
  };
};
