import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import HookOptions from 'utils/types/HookOptions';
import { parsePublicTTNGateway } from 'utils/types/PublicTTNGateway';

export const usePublicTTNGateway = (gatewayId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['publicTTNGateways', gatewayId];

  // Get
  const { data: publicTTNGateway } = useQuery({
    queryKey,
    queryFn: () =>
      axios.get(
        `https://mapper.packetbroker.net/api/v2/gateways/netID=000013,tenantID=ttn,id=${gatewayId}`,
      ),
    select: (result: any) => {
      const gateway = parsePublicTTNGateway(result.data);
      return gateway;
    },
    enabled: enableGet && !!gatewayId,
  });

  return {
    publicTTNGateway,
  };
};
