import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SensorGroupDevicesSelectModal from 'components/modals/SensorGroupDevicesSelectModal';
import SensorIconBlack from 'images/tector-icon-black.png';
import SensorIconBlue from 'images/tector-icon-blue.png';
import { unqiueArrayByStringProperty } from 'utils/arrays';
import Permission from 'utils/enums/Permission';
import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import {
  useSensorGroupGateways,
  useSensorGroupSensors,
  useUserAndCustomerGateways,
  useUserAndCustomerGroups,
  useUserAndCustomerSensors,
  useUtilsPermissionsGroup,
} from 'utils/hooks/data';

export const DevicesButton = ({
  sensorGroupId,
  isRootNode,
}: {
  sensorGroupId: string;
  isRootNode: boolean;
}) => {
  const { t } = useTranslation('components');

  const [showAddSensorModal, setShowAddSensorModal] = useState(false);

  const { hasAccess } = useUtilsPermissionsGroup(sensorGroupId, Permission.Edit);

  const { userId } = useCurrentUser();
  const { customerId } = useSelectedCustomer();

  const { sensors } = useUserAndCustomerSensors(userId, customerId, {
    includeTags: true,
    enableGet: true,
  });
  const { gateways } = useUserAndCustomerGateways(userId, customerId);

  const { gateways: sensorGroupGateways } = useSensorGroupGateways(sensorGroupId);
  const { sensors: sensorGroupSensors } = useSensorGroupSensors(sensorGroupId);

  const availableSensors = useMemo(
    () => unqiueArrayByStringProperty([...sensors, ...(sensorGroupSensors || [])], 'id'),
    [sensors, sensorGroupSensors],
  );
  const availableGateways = useMemo(
    () => unqiueArrayByStringProperty([...gateways, ...(sensorGroupGateways || [])], 'id'),
    [gateways, sensorGroupGateways],
  );

  const { groups } = useUserAndCustomerGroups(userId, customerId, {
    enableGet: showAddSensorModal,
    signPrimaryImage: false,
  });

  return (
    <>
      <button
        disabled={!hasAccess}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();

          setShowAddSensorModal(true);
        }}
        data-tooltip-content={
          hasAccess
            ? t('GroupHierarchy.GroupHierarchyNode.DevicesButton.text')
            : t('GroupHierarchy.GroupHierarchyNode.DevicesButton.disabledTooltip')
        }
        data-tooltip-id="route-tooltip"
      >
        <img
          src={hasAccess ? SensorIconBlue : SensorIconBlack}
          alt="Devices"
          className="h-4 w-auto"
        />
      </button>
      <SensorGroupDevicesSelectModal
        sensorGroupId={sensorGroupId}
        show={showAddSensorModal}
        setShow={setShowAddSensorModal}
        availableSensors={availableSensors}
        availableGateways={availableGateways}
        availableGroups={groups}
        isRootNode={isRootNode}
      />
    </>
  );
};
