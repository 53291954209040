import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { SensorSelect } from 'components';
import InfoText from 'components/InfoText';
import Modal from 'components/modals/Modal';
import Sensor from 'utils/types/Sensor';

const SensorSelectModal: React.FC<{
  show: boolean;
  setShow: Dispatch<boolean>;
  onSelect: (sensor?: Sensor) => void;
  initialSensor?: Sensor;
  sensors?: Sensor[];
  infoText?: string;
}> = ({ show, setShow, onSelect, initialSensor, sensors, infoText }) => {
  const { t } = useTranslation('components');

  return (
    <Modal size="sm" title={t('modals.SensorSelectModal')} show={show} setShow={setShow}>
      <SensorSelect
        onSelect={onSelect}
        sensorsToSelect={sensors}
        initialSelectedSensor={initialSensor}
      />

      {infoText && <InfoText className="mt-3" text={infoText} />}
    </Modal>
  );
};

export default SensorSelectModal;
