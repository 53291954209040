import { Dispatch, SetStateAction, useState } from 'react';

const boxpointOptions = ['Outliers', 'Suspected Outliers', 'All', 'None'];

export const boxPointEnumMap = new Map<string, string | boolean>([
  ['None', false],
  ['Outliers', 'outliers'],
  ['Suspected Outliers', 'suspectedoutliers'],
  ['All', 'all'],
]);

export const BoxpointSelect: React.FC<{
  setSelectedBoxpoint: Dispatch<SetStateAction<string>>;
}> = ({ setSelectedBoxpoint }) => {
  const [selected, setSelected] = useState<number>(0);

  function onChange(i: number) {
    setSelected(prev => (i === prev ? -1 : i));
    setSelectedBoxpoint(boxpointOptions[i]);
  }

  return (
    <div className="flex gap-4 my-2">
      {boxpointOptions.map((boxpointType, i) => (
        <label key={i}>
          {boxpointType}
          <input
            className="mx-2"
            type="checkbox"
            checked={i === selected}
            onChange={() => onChange(i)}
          />
        </label>
      ))}
    </div>
  );
};
