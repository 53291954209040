import { t } from 'translations';
import { SensorTagVentilation } from 'utils/enums';

export const getSensorTagVentilationText = (sensorTagVentilation: SensorTagVentilation) => {
  switch (sensorTagVentilation) {
    case SensorTagVentilation.Ventilated:
      return t('utils:texts.sensor-tags.getSensorTagVentilationText.Ventilated');
    case SensorTagVentilation.NotVentilated:
      return t('utils:texts.sensor-tags.getSensorTagVentilationText.NotVentilated');
    default:
      return 'n/a';
  }
};

export const getSensorTagVentilationTextShort = (sensorTagVentilation: SensorTagVentilation) => {
  switch (sensorTagVentilation) {
    case SensorTagVentilation.Ventilated:
      return t('utils:texts.sensor-tags.getSensorTagVentilationTextShort.Ventilated');
    case SensorTagVentilation.NotVentilated:
      return t('utils:texts.sensor-tags.getSensorTagVentilationTextShort.NotVentilated');
    default:
      return 'n/a';
  }
};
