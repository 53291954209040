import classNames from 'classnames';

export const DropdownItem: React.FC<
  React.PropsWithChildren<{
    className?: string;
    id?: number;
    onClick?: () => void;
    controlId: string;
  }>
> = ({ children, className, id = 0, onClick, controlId }) => (
  <div
    className={classNames(
      'text-brand-gray-light-1 hover:bg-brand-gray-light-4 block px-4 py-2 text-sm',
      className,
    )}
    role="menuitem"
    id={`menu-item-${id}-${controlId}`}
    onClick={() => {
      if (typeof onClick === 'function') {
        onClick();
      }
    }}
  >
    {children}
  </div>
);
