import { useLocation, Link } from 'react-router-dom';

import { Button } from 'components';
import { useCurrentUser } from 'utils/hooks';

export const AdminLoginUserButton: React.FC<{
  userId?: string;
}> = ({ userId }) => {
  const { isAdmin } = useCurrentUser();
  const { pathname } = useLocation();
  if (!isAdmin || !userId) return null;
  return (
    <Link to={{ pathname: '/admin/users/login', search: `?id=${userId}` }} state={{ pathname }}>
      <Button variant="primary" size="sm">
        Log ind som bruger
      </Button>
    </Link>
  );
};
