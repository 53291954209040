import classNames from 'classnames';
import React, { HTMLAttributes, ChangeEvent } from 'react';

const Select: React.FC<
  {
    value?: string;
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void;
  } & HTMLAttributes<HTMLSelectElement>
> = ({ children, value, onChange, className, ...props }) => (
  <select
    className={classNames(className, 'bg-clip-padding bg-no-repeat')}
    value={value}
    onChange={e => typeof onChange === 'function' && onChange(e)}
    {...props}
  >
    {children}
  </select>
);

export default Select;
