import docIcon from 'images/icons/file-formats/doc-icon.svg';
import docxIcon from 'images/icons/file-formats/docx-icon.svg';
import epsIcon from 'images/icons/file-formats/eps-icon.svg';
import gifIcon from 'images/icons/file-formats/gif-icon.svg';
import heicIcon from 'images/icons/file-formats/heic-icon.svg';
import jpegIcon from 'images/icons/file-formats/jpeg-icon.svg';
import jpgIcon from 'images/icons/file-formats/jpg-icon.svg';
import pdfIcon from 'images/icons/file-formats/pdf-icon.svg';
import pngIcon from 'images/icons/file-formats/png-icon.svg';
import rawIcon from 'images/icons/file-formats/raw-icon.svg';
import txtIcon from 'images/icons/file-formats/txt-icon.svg';
import xlsIcon from 'images/icons/file-formats/xls-icon.svg';
import xlsxIcon from 'images/icons/file-formats/xlsx-icon.svg';

export const toBase64 = (file: Blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export const getMimeTypeIcon = (fileType: string) => {
  switch (fileType) {
    case 'image/jpeg':
      return jpegIcon;
    case 'image/jpg':
      return jpgIcon;
    case 'application/postscript':
      return epsIcon;
    case 'image/gif':
      return gifIcon;
    case 'image/heic':
      return heicIcon;
    case 'application/pdf':
      return pdfIcon;
    case 'image/png':
      return pngIcon;
    case 'application/msword':
      return docIcon;
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      return docxIcon;
    case 'application/vnd.ms-excel':
      return xlsIcon;
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      return xlsxIcon;
    case 'text/plain':
      return txtIcon;
    default:
      return rawIcon;
  }
};

export const checkImageUrl = (
  imageSrc: string,
  successCallback: () => void,
  errorCallback: () => void
) => {
  let img = new Image();
  img.onload = successCallback;
  img.onerror = errorCallback;
  img.src = imageSrc;
};
