import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Badge, BadgeProps } from 'components';
import {
  faSquirtyGroupTypeDelivery,
  faSquirtyGroupTypeProject,
  faSquirtyGroupTypeUnknown,
} from 'components/icons';
import GroupType, { getGroupTypeText } from 'utils/enums/GroupType';

export const GroupTypeBadge: React.FC<
  { type?: GroupType; showDataTip?: boolean } & Omit<BadgeProps, 'onMouseEnter' | 'onMouseLeave'>
> = ({ type, className = 'text-xs', showDataTip = false, ...props }) => {
  const { t } = useTranslation('components');

  const groupTypeText = getGroupTypeText(type);
  const icon =
    type === GroupType.Delivery
      ? faSquirtyGroupTypeDelivery
      : type === GroupType.Project
        ? faSquirtyGroupTypeProject
        : faSquirtyGroupTypeUnknown;
  const dataTip = t('Badges/GroupTypeBadge.dataTip', {
    type: groupTypeText,
  });

  return (
    <Badge
      {...props}
      className={classNames(
        'text-white',
        {
          'bg-brand-gray-light-1': type === GroupType.Unknown,
          'bg-brand-green': type === GroupType.Delivery,
          'bg-brand-orange': type === GroupType.Project,
        },
        className,
      )}
      data-tooltip-content={showDataTip ? dataTip : undefined}
      data-tooltip-id="route-tooltip"
    >
      <FontAwesomeIcon className="mr-1" icon={icon} size="sm" />
      {groupTypeText}
    </Badge>
  );
};
