import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Warning } from 'components';
import CustomerSelectModal from 'components/modals/CustomerSelectModal';
import SensorFrequencyUpdateModal from 'components/modals/SensorFrequencyUpdateModal';
import SensorTagsModal from 'components/modals/SensorTagsModal';
import { sensorId2NameFunc } from 'utils/formatting';
import {
  useSensorDownlinks,
  useCurrentUserCustomers,
  useSensorsBulkUpdate,
} from 'utils/hooks/data';
import { notificationSuccess, notificationWarningConfirm } from 'utils/notifications';
import Sensor from 'utils/types/Sensor';
import SensorTags from 'utils/types/SensorTags';

export const ActionComponents: React.FC<{
  sensors: Sensor[];
  sensorIdsToUpdate: string[];
  showCustomerSelect: boolean;
  setShowCustomerSelect: Dispatch<SetStateAction<boolean>>;
  showSensorDownlinkModal: boolean;
  setShowSensorDownlinkModal: Dispatch<SetStateAction<boolean>>;
  showSensorTagModal: boolean;
  setShowSensorTagModal: Dispatch<SetStateAction<boolean>>;
}> = ({
  sensors,
  sensorIdsToUpdate,
  showCustomerSelect,
  setShowCustomerSelect,
  showSensorDownlinkModal,
  setShowSensorDownlinkModal,
  showSensorTagModal,
  setShowSensorTagModal,
}) => {
  const { t } = useTranslation('components');

  const { customers } = useCurrentUserCustomers(sensorIdsToUpdate.length > 0);
  const { createDownlinkById } = useSensorDownlinks();
  const { updateSensorsBulk } = useSensorsBulkUpdate();

  const bulkUpdateSensors = async (sensorIds: string[], customerId?: string) => {
    await updateSensorsBulk({ sensorIds, customer_id: customerId });
  };

  const bulkUpdateFrequency = async (sensorIds: string[], frequency: number) => {
    for (const id of sensorIds) {
      await createDownlinkById({ id, frequency });
    }
  };

  const bulkUpdateSensorTags = async (sensorIds: string[], tags: SensorTags) => {
    await updateSensorsBulk({ sensorIds, tags });
  };

  const sensorId2Name = sensorId2NameFunc(sensors);

  const ChoosenSensors = () => (
    <div className="mt-3">
      <p className="mb-2">
        {t('cards.sensor_groups.SensorGroups.text', { count: sensorIdsToUpdate.length })}
      </p>
      {sensorIdsToUpdate.map(id => (
        <li key={id}>{sensorId2Name[id]}</li>
      ))}
    </div>
  );

  return (
    <>
      <CustomerSelectModal
        title={t('tables.SensorsPaginatedTable.ActionComponents.CustomerSelectModal.title')}
        onSelect={async customer => {
          const res = await notificationWarningConfirm();
          if (res) {
            await bulkUpdateSensors(sensorIdsToUpdate, customer?.id);
            notificationSuccess(
              t('tables.SensorsPaginatedTable.ActionComponents.CustomerSelectModal.success'),
            );
            setShowCustomerSelect(false);
          }
        }}
        show={showCustomerSelect}
        showAllOption
        customers={customers}
        setShow={setShowCustomerSelect}
      >
        <ChoosenSensors />
      </CustomerSelectModal>

      <SensorFrequencyUpdateModal
        onSubmit={async frequency => {
          const res = await notificationWarningConfirm();
          if (res) {
            await bulkUpdateFrequency(sensorIdsToUpdate, frequency);
            notificationSuccess(
              t('tables.SensorsPaginatedTable.ActionComponents.SensorFrequencyUpdateModal.success'),
            );
            setShowSensorDownlinkModal(false);
          }
        }}
        show={showSensorDownlinkModal}
        setShow={setShowSensorDownlinkModal}
      >
        <ChoosenSensors />
      </SensorFrequencyUpdateModal>

      <SensorTagsModal
        onSubmit={async tags => {
          const res = await notificationWarningConfirm();
          if (res) {
            await bulkUpdateSensorTags(sensorIdsToUpdate, tags);
            notificationSuccess(
              t('tables.SensorsPaginatedTable.ActionComponents.SensorTagsModal.success'),
            );
            setShowSensorTagModal(false);
          }
        }}
        show={showSensorTagModal}
        setShow={setShowSensorTagModal}
      >
        <Warning className="mt-3">
          {t('tables.SensorsPaginatedTable.ActionComponents.SensorTagsModal.bulkWarning')}
        </Warning>
        <ChoosenSensors />
      </SensorTagsModal>
    </>
  );
};
