import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useRef, useState, HTMLAttributes, useEffect } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';

import { GlobalSearchInput } from 'components';
import { useOnClickOutside } from 'utils/hooks';

export const GlobalSearchButton: React.FC<HTMLAttributes<SVGElement>> = ({
  className,
  ...props
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [show, setShow] = useState(false);
  const { t } = useTranslation('components');

  useOnClickOutside(modalRef, () => setShow(false));

  useHotkeys('esc', () => setShow(false), {
    enableOnFormTags: ['INPUT'],
    enabled: show,
  });

  useEffect(() => {
    if (show && inputRef.current) {
      inputRef.current.focus();
    }
  }, [show, inputRef]);

  return (
    <>
      <FontAwesomeIcon
        className={classNames('cursor-pointer py-3 px-1 text-brand-blue', className)}
        icon={faSearch}
        data-tooltip-content={t('buttons.GlobalSearchButton')}
        data-tooltip-id="route-tooltip"
        data-tooltip-place="left"
        onClick={e => {
          e.preventDefault();
          setShow(true);
        }}
        size="lg"
        {...props}
      />

      <div
        tabIndex={-1}
        aria-hidden="true"
        className={classNames(
          'overflow-y-auto overflow-x-hidden fixed top-0',
          'right-0 left-0 z-50 w-full md:inset-0',
          'h-modal md:h-full bg-black/50',
          {
            hidden: !show,
          },
        )}
      >
        <div ref={modalRef} className="relative w-full max-w-2xl h-full md:h-auto mx-auto mt-20">
          <div className="relative bg-white rounded-lg shadow">
            <GlobalSearchInput ref={inputRef} onSelect={suggestion => setShow(false)} />
          </div>
        </div>
      </div>
    </>
  );
};
