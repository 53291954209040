import { useTranslation } from 'react-i18next';

import UsersInlineList from 'components/UsersInlineList';
import environment from 'utils/environment';
import { Environment, isFeatureEnabled } from 'utils/isFeatureEnabled';
import User from 'utils/types/User';

export const ResolvedBy = ({ user }: { user?: User }) => {
  const { t } = useTranslation('components');

  const isResolveAlertEnabled = isFeatureEnabled('resolveAlert', environment as Environment);

  const showResolvedBy = isResolveAlertEnabled && user && user?.full_name !== 'Woodsense Robot';

  if (!showResolvedBy) return null;

  return (
    <>
      {showResolvedBy && (
        <div className="flex flex-row gap-1 items-center">
          <span className="text-brand-gray-light-1 text-xs">
            {t('user.Alerts.AlertCard.resolvedBy')}
          </span>
          <UsersInlineList
            users={user ? [user] : []}
            size="md"
            data-tooltip-content={`${t('user.Alerts.AlertCard.resolvedBy')} ${user?.full_name}`}
            data-tooltip-id="route-tooltip"
          />
        </div>
      )}
    </>
  );
};
