import { sub } from 'date-fns/sub';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarRangeInput, CalendarRangeInputProps } from 'components';
import SimpleSelect, { SimpleSelectOption } from 'components/forms/SimpleSelect';
import Modal from 'components/modals/Modal';

const CalendarRangeModal: React.FC<
  {
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    setupCompletedDate?: Date;
    entireHistoryDate?: Date;
    latestActiveDate?: Date;
    showPredefinedRanges?: boolean;
  } & CalendarRangeInputProps
> = ({
  show,
  setShow,
  entireHistoryDate,
  latestActiveDate,
  setupCompletedDate,
  onSelect,
  showPredefinedRanges = false,
  ...props
}) => {
  const { t } = useTranslation('components');

  const now = new Date();

  let options: SimpleSelectOption<Date>[] = [
    { name: t('modals.CalendarModal.options.past3Months'), value: sub(now, { months: 3 }) },
    { name: t('modals.CalendarModal.options.past6Months'), value: sub(now, { months: 6 }) },
    { name: t('modals.CalendarModal.options.pastYear'), value: sub(now, { years: 1 }) },
    { name: t('modals.CalendarModal.options.past2Years'), value: sub(now, { years: 2 }) },
  ];

  if (setupCompletedDate) {
    options.push({
      name: t('modals.CalendarModal.options.setupCompleted'),
      value: setupCompletedDate,
    });
  }

  if (latestActiveDate) {
    options.push({
      name: t('modals.CalendarModal.options.latestActiveDate'),
      value: sub(latestActiveDate, { weeks: 1 }),
    });
  }

  if (entireHistoryDate) {
    // Remove options that's from before 'entiry history' date
    options.push({
      name: t('modals.CalendarModal.options.entiryHistory'),
      value: entireHistoryDate,
    });
    options = options.filter(x => x.value >= entireHistoryDate);
  }

  return (
    <Modal size="sm" title={t('modals.CalendarModal.title')} show={show} setShow={setShow}>
      {showPredefinedRanges && (
        <SimpleSelect
          className="mb-1"
          onSelect={value => onSelect({ start: value, end: now })}
          placeholder={t('modals.CalendarModal.selectPredefinedRange')}
          options={options}
        />
      )}
      <CalendarRangeInput onSelect={onSelect} {...props} />
    </Modal>
  );
};

export default CalendarRangeModal;
