import { ComponentPropsWithoutRef } from 'react';

export const FormInput: React.FC<
  {
    label?: string;
    text?: string;
    placeholder?: string;
    readonly?: boolean;
  } & ComponentPropsWithoutRef<'input'>
> = ({ label, text, className, placeholder = '', ...props }) => {
  const controlId = Math.random().toString(36).substring(7);

  return (
    <div className="mb-3">
      {label && <label htmlFor={controlId}>{label}</label>}
      <input
        id={controlId}
        className={className}
        placeholder={placeholder}
        value={text || ''}
        {...props}
      />
    </div>
  );
};
