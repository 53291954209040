import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LockSwitch } from 'components';
import { BlueprintCanvas } from 'components/BlueprintCanvas';
import { BlueprintAttachedSensorsField } from 'components/BlueprintSensorPositioning/components';
import Sensor from 'utils/types/Sensor';

export const BlueprintSensorPositioning: React.FC<{
  blueprintId: string;
  availableSensors?: Sensor[];
  onlyEnableSensorIds?: string[];
}> = ({ blueprintId, onlyEnableSensorIds, availableSensors }) => {
  const [enableSensorsSelect, setEnableSensorsSelect] = useState(false);

  const { t } = useTranslation('components');

  return (
    <div className="mb-3">
      <BlueprintCanvas
        blueprintId={blueprintId}
        onlyEnableSensorIds={onlyEnableSensorIds}
        showHeader
        showSensors
        showPlayableTimeline
        showToolbox
        enableWheelScrolling
        enableDragging
        enableClicking
      />

      <div className="mt-3 mb-1 flex justify-between items-center">
        <p className="mt-3 mb-1">
          {t('blueprints.blueprintSensorPositioning.BlueprintAttachedSensorsField.text.1')}
        </p>
        <LockSwitch
          className="shrink-1"
          isChecked={enableSensorsSelect}
          setIsChecked={setEnableSensorsSelect}
        />
      </div>

      <BlueprintAttachedSensorsField
        blueprintId={blueprintId}
        availableSensors={availableSensors}
        disabled={!enableSensorsSelect}
      />
    </div>
  );
};
