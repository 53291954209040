import { t } from 'translations';
import { SensorTagPrimaryElementType } from 'utils/enums';

export const getSensorTagPrimaryElementTypeText = (
  sensorTagPrimaryElementType: SensorTagPrimaryElementType,
) => {
  switch (sensorTagPrimaryElementType) {
    case SensorTagPrimaryElementType.ExternalPrecastWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalPrecastWalls');
    case SensorTagPrimaryElementType.ExternalInSituWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalInSituWalls');
    case SensorTagPrimaryElementType.ExternalMasonryWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalMasonryWalls');
    case SensorTagPrimaryElementType.ExternalFrameStructuredWalls:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalFrameStructuredWalls',
      );
    case SensorTagPrimaryElementType.ExternalFrameStructuredBulkheadWalls:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalFrameStructuredBulkheadWall',
      );
    case SensorTagPrimaryElementType.ExternalCurtainWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalCurtainWalls');
    case SensorTagPrimaryElementType.ExternalWallInsulation:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalWallInsulation');
    case SensorTagPrimaryElementType.ExternalLightShafts:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.ExternalLightShafts');
    case SensorTagPrimaryElementType.InteriorPrecastWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InteriorPrecastWalls');
    case SensorTagPrimaryElementType.InteriorInSituWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InteriorInSituWalls');
    case SensorTagPrimaryElementType.InteriorBrickWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InteriorBrickWalls');
    case SensorTagPrimaryElementType.InteriorFrameStructuredWalls:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InteriorFrameStructuredWalls',
      );
    case SensorTagPrimaryElementType.InteriorFrameStructuredBulkheadWalls:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InteriorFrameStructuredBulkheadWall',
      );
    case SensorTagPrimaryElementType.InteriorCurtainWalls:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InteriorCurtainWalls');
    case SensorTagPrimaryElementType.PrecastSlabs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.PrecastSlabs');
    case SensorTagPrimaryElementType.InSituSlabs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InSituSlabs');
    case SensorTagPrimaryElementType.FrameStructuredSlabs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.FrameStructuredSlabs');
    case SensorTagPrimaryElementType.Screeds:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.Screeds');
    case SensorTagPrimaryElementType.OtherSlabs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.OtherSlabs');
    case SensorTagPrimaryElementType.PrecastStairs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.PrecastStairs');
    case SensorTagPrimaryElementType.InSituStairs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InSituStairs');
    case SensorTagPrimaryElementType.CompositeStairs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.CompositeStairs');
    case SensorTagPrimaryElementType.PrecastRamps:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.PrecastRamps');
    case SensorTagPrimaryElementType.InSituRamps:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InSituRamps');
    case SensorTagPrimaryElementType.CompositeRamps:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.CompositeRamps');
    case SensorTagPrimaryElementType.FixedLaddersAndSteps:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.FixedLaddersAndSteps');
    case SensorTagPrimaryElementType.PrecastBeams:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.PrecastBeams');
    case SensorTagPrimaryElementType.InSituBeams:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InSituBeams');
    case SensorTagPrimaryElementType.SteelBeams:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.SteelBeams');
    case SensorTagPrimaryElementType.OtherBeams:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.OtherBeams');
    case SensorTagPrimaryElementType.PrecastColumns:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.PrecastColumns');
    case SensorTagPrimaryElementType.InSituColumns:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InSituColumns');
    case SensorTagPrimaryElementType.SteelColumns:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.SteelColumns');
    case SensorTagPrimaryElementType.OtherColumns:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.OtherColumns');
    case SensorTagPrimaryElementType.PrecastBalconiesAndExteriorHallways:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.PrecastBalconiesAndExteriorHallways',
      );
    case SensorTagPrimaryElementType.InSituBalconiesAndExteriorHallways:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.InSituBalconiesAndExteriorHallways',
      );
    case SensorTagPrimaryElementType.CompositeBalconiesAndExteriorHallways:
      return t(
        'utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.CompositeBalconiesAndExteriorHallwa',
      );
    case SensorTagPrimaryElementType.RafterRoofs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.RafterRoofs');
    case SensorTagPrimaryElementType.RoofCassettes:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.RoofCassettes');
    case SensorTagPrimaryElementType.WarmRoofs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.WarmRoofs');
    case SensorTagPrimaryElementType.CurtainRoofs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.CurtainRoofs');
    case SensorTagPrimaryElementType.MovableRoofs:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.MovableRoofs');
    case SensorTagPrimaryElementType.CanopiesAndShelters:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.CanopiesAndShelters');
    case SensorTagPrimaryElementType.OtherRoofConstructions:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.OtherRoofConstructions');
    case SensorTagPrimaryElementType.Other:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.Other');
    case SensorTagPrimaryElementType.NotRelevant:
      return t('utils:texts.sensor-tags.getSensorTagPrimaryElementTypeText.NotRelevant');
    default:
      return 'n/a';
  }
};

export const getSensorTagPrimaryElementTypeTextShort = getSensorTagPrimaryElementTypeText;
