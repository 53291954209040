export const chunkify = <T>(a: T[], chunkSize: number) => {
  const chunks: T[][] = [];
  const n = Math.ceil(a.length / chunkSize);
  for (let i = 0; i < n; i += 1) {
    chunks.push(a.slice(i * chunkSize, (i + 1) * chunkSize));
  }
  return chunks;
};

export const sortedArrayByStringProperty = <T extends { [property: string]: any }>(
  arr: T[] = [],
  property: string
) => {
  const arrSorted = arr.slice();
  arrSorted.sort((a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    return 0;
  });
  return arrSorted;
};

export const unqiueArrayByStringProperty = <T extends { [property: string]: any }>(
  arr: T[] = [],
  property: string
) => {
  const arrUnqiue = arr.slice();
  const seen = new Set();
  return arrUnqiue.filter(item => {
    const itemProperty = item[property];
    return seen.has(itemProperty) ? false : seen.add(itemProperty);
  });
};
