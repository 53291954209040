import { Dispatch, SetStateAction, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import SensorForm, { SensorFormProps } from 'components/forms/SensorForm';
import Modal from 'components/modals/Modal';
import { useIsFormDirty } from 'utils/hooks';

const UpdateSensorModal: React.FC<
  PropsWithChildren<
    SensorFormProps & {
      show: boolean;
      setShow: Dispatch<SetStateAction<boolean>>;
      useLinkAsTitle?: boolean;
    }
  >
> = ({ show, setShow, values, useLinkAsTitle = false, children, ...props }) => {
  const [formInnerRef, isFormDirty] = useIsFormDirty();

  const title = (
    <>
      {values?.id && useLinkAsTitle ? (
        <Link
          className="text-brand-green underline hover:text-blue-500"
          to={`/user/sensors/${values?.id}`}
        >
          {values.name}
        </Link>
      ) : (
        values?.name
      )}
    </>
  );

  return (
    <Modal title={title} show={show} setShow={setShow} hasUnsavedChanges={isFormDirty}>
      <SensorForm values={values} {...props} innerRef={formInnerRef} />
      {children}
    </Modal>
  );
};

export default UpdateSensorModal;
