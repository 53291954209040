import illustration01FullColor from 'images/illustrations/illu-01-fullcolour-resized.png';
import illustration01 from 'images/illustrations/illu-01-subtle-resized.png';
import illustration02FullColor from 'images/illustrations/illu-02-fullcolour-resized.png';
import illustration02 from 'images/illustrations/illu-02-subtle-resized.png';
import illustration06FullColor from 'images/illustrations/illu-06-fullcolour-resized.png';
import illustration06 from 'images/illustrations/illu-06-subtle-resized.png';
import GroupType from 'utils/enums/GroupType';

export const getIllustrations = (groupType: GroupType) => {
  switch (groupType) {
    case GroupType.Project:
      return { default: illustration01, hovering: illustration01FullColor };
    case GroupType.Delivery:
      return { default: illustration06, hovering: illustration06FullColor };
    default:
      return { default: illustration02, hovering: illustration02FullColor };
  }
};

export const getFallbackIllustration = () => illustration01;
