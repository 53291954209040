import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import SignalStrengthState from 'utils/enums/SignalStrengthState';
import { getSignalStrengthStateText } from 'utils/sensor/texts';

export const SignalStrengthStateBadge: React.FC<
  { state?: SignalStrengthState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) =>
  state ? (
    <Badge
      {...props}
      className={classNames('text-xs font-medium text-white', className, {
        'bg-brand-gray-light-2': state === SignalStrengthState.UNKNOWN,
        'bg-signalStrengthStateVeryGood': state === SignalStrengthState.VERY_GOOD,
        'bg-signalStrengthStateGood': state === SignalStrengthState.GOOD,
        'bg-signalStrengthStateFair': state === SignalStrengthState.FAIR,
        'bg-signalStrengthStateModerate': state === SignalStrengthState.MODERATE,
        'bg-signalStrengthStatePoor': state === SignalStrengthState.POOR,
        'bg-signalStrengthStateVeryPoor': state === SignalStrengthState.VERY_POOR,
      })}
    >
      {getSignalStrengthStateText(state)}
    </Badge>
  ) : (
    <Spinner className="ml-2 text-brand-gray-light-1" />
  );
