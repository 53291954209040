import { useCurrentUser, useSelectedCustomer } from 'utils/hooks';
import { useUserAlerts, useCustomerAlerts } from 'utils/hooks/data';

export const useAlerts = () => {
  const { customerId } = useSelectedCustomer();
  const { userId } = useCurrentUser();

  const userAlertsResult = useUserAlerts(userId, { enableGet: !customerId });
  const customerAlertsResult = useCustomerAlerts(customerId, { enableGet: !!customerId });

  const alerts = customerId ? customerAlertsResult.alerts : userAlertsResult.alerts;
  const isPending = customerId ? customerAlertsResult.isPending : userAlertsResult.isPending;

  return {
    alerts,
    isPending,
  };
};
