import AlarmRuleSubType from 'utils/enums/AlarmRuleSubType';
import AlarmRuleType from 'utils/enums/AlarmRuleType';
import Locale from 'utils/enums/Locale';
import AlarmRuleCriterion, { parseAlarmRuleCriterion } from 'utils/types/AlarmRuleCriterion';
import AlarmRuleLocaleDetail, {
  parseAlarmRuleLocaleDetail,
} from 'utils/types/AlarmRuleLocaleDetail';

type AlarmRule = {
  id: string;

  name: string;
  type: AlarmRuleType;
  subtype: AlarmRuleSubType;

  alarm_rule_criteria: AlarmRuleCriterion[];
  alarm_rule_locale_details: AlarmRuleLocaleDetail[];

  // Active time period
  active_period_from?: Date | null;
  active_period_to?: Date | null;
  repeat_yearly?: boolean;

  created_date: Date;
  created_by: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseAlarmRule = (alarmRule: any): AlarmRule => {
  alarmRule.created_date = new Date(alarmRule.created_date);
  if (alarmRule.updated_date) {
    alarmRule.updated_date = new Date(alarmRule.updated_date);
  }

  if (alarmRule.alarm_rule_criteria) {
    alarmRule.alarm_rule_criteria = alarmRule.alarm_rule_criteria.map(parseAlarmRuleCriterion);
  }
  if (alarmRule.alarm_rule_locale_details) {
    alarmRule.alarm_rule_locale_details = alarmRule.alarm_rule_locale_details.map(
      parseAlarmRuleLocaleDetail,
    );
  }

  if (alarmRule.active_period_from) {
    alarmRule.active_period_from = new Date(alarmRule.active_period_from);
  }
  if (alarmRule.active_period_to) {
    alarmRule.active_period_to = new Date(alarmRule.active_period_to);
  }

  return alarmRule as AlarmRule;
};

export const getAlarmRuleLocalizedName = (alarmRule?: AlarmRule, locale?: Locale) => {
  const localeDetails = alarmRule?.alarm_rule_locale_details;
  const localeDetail = localeDetails?.find(localeDetail => localeDetail.locale === locale);
  if (localeDetail && localeDetail.name) {
    return localeDetail.name;
  }
  return alarmRule?.name;
};

export const alarmRuleId2LocalizedNameFunc = (locale: Locale, alarmRules?: AlarmRule[]) =>
  Object.fromEntries(
    (alarmRules || []).map(alarmRule => [
      alarmRule.id,
      getAlarmRuleLocalizedName(alarmRule, locale),
    ]),
  );

// Cache query keys
export const getQueryKeyAlarmRule = (alarmRuleId: string) => ['alarmRules', alarmRuleId];
export const getQueryKeyAdminAlarmRuleCustomers = (alarmRuleId: string) => [
  'adminAlarmRuleCustomers',
  alarmRuleId,
];
export const getQueryKeyAdminAlarmRules = () => ['adminAlarmRules'];

export default AlarmRule;
