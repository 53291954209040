import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  includeGeographicLocation?: boolean;
};

export const useUserChatToken = (userId?: string, { enableGet = true }: Options = {}) => {
  const queryKey = ['userChatToken', userId];

  // Get
  const {
    data: chatToken,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/users/${userId}/chat_token`, {
        _disableErrorNotifications: true,
      }),
    select: (token: any) => token as string,
    enabled: !!userId && enableGet,
  });

  return {
    chatToken,
    isPending: isPending || isLoading || isFetching,
  };
};
