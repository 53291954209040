import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import SensorTags from 'utils/types/SensorTags';

export const useSensorTags = (sensorId?: string) => {
  const queryClient = useQueryClient();

  // Sensor Tags - Update
  const { mutateAsync: updateSensorTags } = useMutation({
    mutationFn: (sensorTags: SensorTags) => api.patch(`/sensors/${sensorId}/tags`, sensorTags),
    onSuccess: async (sensorRaw: any) => {
      queryClient.setQueryData(['sensors', sensorId], sensorRaw);
      for (const queryKey of [
        ['customerSensors'],
        ['userSensors'],
        ['sensorGroupSensors'],
        ['blueprintSensors'],
      ]) {
        await queryClient.invalidateQueries({ queryKey });
      }
    },
  });

  // Update by sensor Id
  const { mutateAsync: updateSensorTagsById } = useMutation({
    mutationFn: ({ id: sensorId, ...sensorTags }: { id: string } & SensorTags) =>
      api.patch(`/sensors/${sensorId}/tags`, sensorTags),
    onSuccess: async (sensorRaw: any) => {
      queryClient.setQueryData(['sensors', sensorId], sensorRaw);
      for (const queryKey of [
        ['customerSensors'],
        ['userSensors'],
        ['sensorGroupSensors'],
        ['blueprintSensors'],
      ]) {
        await queryClient.invalidateQueries({ queryKey });
      }
    },
  });

  return {
    updateSensorTags,
    updateSensorTagsById,
  };
};
