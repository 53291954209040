import { Dispatch, SetStateAction } from 'react';
import { useLocalStorage } from 'usehooks-ts';

export const useLocalStorageString = (
  key: string,
  initialValue: string | null,
): [string | null, Dispatch<SetStateAction<string | null>>] => {
  const [valueStorage, setValueStorage] = useLocalStorage<{ value: string | null }>(key, {
    value: initialValue,
  });
  const value = valueStorage ? valueStorage.value : null;
  const setValue = (newValue: string | null | ((newValue: string | null) => string | null)) => {
    const valueToStore = newValue instanceof Function ? newValue(value) : newValue;
    setValueStorage({ value: valueToStore });
  };
  return [value, setValue];
};
