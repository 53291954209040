import { useCallback, useEffect, useState } from 'react';

export const useIsPressingModKey = () => {
  const [isPressingModKey, setIsPressingModKey] = useState(false);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent, isKeyDown: boolean) => {
      if (
        (event.key === 'Control' || event.key === 'Meta') &&
        isKeyDown !== isPressingModKey &&
        setIsPressingModKey
      ) {
        setIsPressingModKey(isKeyDown);
      }
    },
    [isPressingModKey, setIsPressingModKey],
  );

  useEffect(() => {
    const handleKeyUp = (event: KeyboardEvent) => handleKeyPress(event, false);
    const handleKeyDown = (event: KeyboardEvent) => handleKeyPress(event, true);

    window.addEventListener('keyup', handleKeyUp);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keyup', handleKeyUp);
      window.addEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyPress]);

  return isPressingModKey;
};
