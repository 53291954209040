import AccessLinkType from 'utils/enums/AccessLinkType';
import Role from 'utils/enums/Role';
import Customer from 'utils/types/Customer';
import SensorGroup from 'utils/types/SensorGroup';
import User from 'utils/types/User';

type AccessLink = {
  role: Role;
  type: AccessLinkType;
  customer?: Customer;
  user?: User;
  inherited_from_group?: SensorGroup;

  created_date: Date;
  created_by: string;
};

export type AccessLinkUpdate = {
  role?: Role;
  customer_id?: string;
  user_id?: string;
};

export const parseAccessLink = (accessLink: any): AccessLink => {
  accessLink.created_date = new Date(accessLink.created_date);
  return accessLink as AccessLink;
};

export default AccessLink;
