import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/modals/Modal';
import SensorFrequencyUpdate from 'components/sensors/SensorFrequencyUpdate';

const SensorFrequencyUpdateModal: React.FC<
  React.PropsWithChildren<{
    value?: number;
    show: boolean;
    setShow: Dispatch<boolean>;
    onSubmit: (frequency: number) => Promise<void>;
  }>
> = ({ value, show, setShow, onSubmit, children }) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      title={t('modals.SensorFrequencyUpdateModal.title')}
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      <SensorFrequencyUpdate value={value} onSubmit={onSubmit} />
      {children}
    </Modal>
  );
};

export default SensorFrequencyUpdateModal;
