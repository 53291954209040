const enum AlarmRuleSubType {
  BuiltInCustom = 110,
  MissingValues48Hours = 121,
  MissingValues1Week = 122,
  MissingValues4Weeks = 123,
  ReferenceValues = 130,

  Custom = 200,
}

export default AlarmRuleSubType;
