import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { floorToLatest5Minutes } from 'utils/date';
import { useGatewaySensors } from 'utils/hooks/data/gateways/useGatewaySensors';
import { parseTransmissionsMixed } from 'utils/sensor/transmissions';
import { parseTransmission } from 'utils/types/Transmission';

export const useGatewayTransmissions = (
  gatewayId: string,
  {
    fromTimestamp,
    toTimestamp,
    limit,
    includeMetaColumns = false,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
    limit?: number;
    includeMetaColumns?: boolean;
  },
) => {
  fromTimestamp = floorToLatest5Minutes(fromTimestamp);
  toTimestamp = floorToLatest5Minutes(toTimestamp);

  const queryKey = [
    'gatewayTransmissions',
    gatewayId,
    fromTimestamp,
    toTimestamp,
    limit,
    includeMetaColumns,
  ];

  // Get
  const { data: transmissionsRaw, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/gateways/${gatewayId}/transmissions`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
          limit,
          include_meta_columns: includeMetaColumns,
        },
      }),
    select: (transmissions: any[]) => transmissions.map(parseTransmission),
    enabled: !!gatewayId && !!fromTimestamp && !!toTimestamp,
  });

  // Get sensors
  const { sensors } = useGatewaySensors(gatewayId, {
    fromTimestamp,
    toTimestamp,
  });

  // Parse transmissions
  const transmissions = useMemo(
    () =>
      parseTransmissionsMixed({
        transmissions: transmissionsRaw,
        sensors,
      }),
    [transmissionsRaw, sensors],
  );

  return {
    transmissions,
    isPending,
  };
};
