import { format } from 'date-fns';

import { LineMarkerData } from 'components/plots/helpers/types';
import { brandGrayLight3, brandOrangeLight1 } from 'utils/colors';
import { Event } from 'utils/types';

export const eventsToLineMarkerData = (events: Event[], maxYValue: number): LineMarkerData[][] =>
  events.map(event => [
    {
      name: `[EVENT_START] ${event.name}`,
      text: event.body_text,
      label: { show: false },
      type: 'event',
      xAxis: `${format(event.timestamp, 'yyyy-MM-dd')}T${format(event.timestamp, 'HH:mm')}`,
      yAxis: 0,
      lineStyle: {
        color: event.name.includes('Alert') ? brandOrangeLight1 : brandGrayLight3,
      },
    },
    {
      name: `[EVENT_END] ${event.name}`,
      text: event.body_text,
      label: { show: false },
      type: 'event',
      xAxis: `${format(event.timestamp, 'yyyy-MM-dd')}T${format(event.timestamp, 'HH:mm')}`,
      yAxis: maxYValue,
    },
  ]);
