import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { userId2NameFunc } from 'utils/formatting';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import HookOptions from 'utils/types/HookOptions';
import { parseUser } from 'utils/types/User';

export const useCustomerUsers = (
  customerId?: string,
  { enableGet = true, ignoreAdminCustomerOverwrite = false }: HookOptions = {},
) => {
  const queryKey = ['customerUsers', customerId];

  const { showAllCustomers } = useSelectedCustomer();
  const url =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? '/admin/users'
      : `/customers/${customerId}/users`;

  // Get
  const { data: users, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(url),
    select: (users: any[]) => users.map(user => parseUser(user)),
    enabled: !!customerId && enableGet,
  });

  // Helper objects
  const userId2Name = useMemo(() => userId2NameFunc(users), [users]);

  return {
    users,
    userId2Name,
    isPending,
  };
};
