import { t } from 'translations';

const enum GroupType {
  Unknown = 10,
  Delivery = 20,
  Project = 30,
}

export const getGroupTypeText = (groupType?: GroupType): string => {
  switch (groupType) {
    case GroupType.Unknown:
      return t('utils:enums.GroupType.texts.Unknown');
    case GroupType.Delivery:
      return t('utils:enums.GroupType.texts.Delivery');
    case GroupType.Project:
      return t('utils:enums.GroupType.texts.Project');
    default:
      return 'n/a';
  }
};

export default GroupType;
