import groupBy from 'lodash/groupBy';
import mean from 'lodash/mean';
import meanBy from 'lodash/meanBy';

import { dateToFormattedDateString } from 'utils/date';
import { degrees2radians } from 'utils/trigonometry';
import { WeatherWindDataTuple } from 'utils/types/PlotTypes';

export const groupAndMeanWeatherDataByDate = (weatherDataWind: WeatherWindDataTuple[]) => {
  // Group by date
  const weatherDataWindGrouped = groupBy(
    weatherDataWind.map(x => ({
      sin: Math.sin(degrees2radians(x[2])),
      cos: Math.cos(degrees2radians(x[2])),
      ...x,
    })),
    x => dateToFormattedDateString(x[0])
  );

  // Calculate mean for each group
  // const weatherDataWindGroupedMeaned = Object.entries(weatherDataWindGrouped).map(
  const weatherDataWindGroupedMeaned = Object.values(weatherDataWindGrouped).map(values => {
    // Calculate mean date
    const dateMean = new Date(mean(values.map(x => x[0].getTime())));

    // Calculate mean wind direction
    const sinMean = meanBy(values, x => x.sin);
    const cosMean = meanBy(values, x => x.cos);
    const meanRadians = (Math.atan2(sinMean, cosMean) + 2 * Math.PI) % (2 * Math.PI);

    // Calculate mean wind speed
    const windSpeedMean = mean(values.map(x => x[1]));

    return [dateMean, windSpeedMean, meanRadians];
  });

  return weatherDataWindGroupedMeaned;
};
