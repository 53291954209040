import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { MarkAsReadButton, ResolveButton } from 'components';
import { ResolvedBy } from 'components/cards/AlertCard/components';
import Card from 'components/cards/Card';
import { faSquirtyAlert } from 'components/icons';
import UsersInlineList from 'components/UsersInlineList';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { AlertSeverityLevel } from 'utils/enums';
import environment from 'utils/environment';
import {
  useAlarmGateway,
  useAlarmRule,
  useAlarmSensor,
  useAlertDescription,
  useAlertReadByUsers,
} from 'utils/hooks/data';
import { Environment, isFeatureEnabled } from 'utils/isFeatureEnabled';
import { getAlertName } from 'utils/texts/AlertName';
import Alarm from 'utils/types/Alarm';

export const AlertCard = ({
  alert,
  isPending,
  isMinimized = false,
}: {
  alert: Alarm;
  isPending: boolean;
  isMinimized?: boolean;
}) => {
  const { t } = useTranslation('components');

  const { sensor, isPending: isSensorPending } = useAlarmSensor(alert.id);
  const { gateway, isPending: isGatewayPending } = useAlarmGateway(alert.id);

  const { description } = useAlertDescription(alert.id);

  const { users } = useAlertReadByUsers(alert.id);

  const { alarmRule, isPending: isAlarmRulePending } = useAlarmRule(alert?.alarm_rule_id);

  const isLoading = isPending || isAlarmRulePending || isSensorPending || isGatewayPending;

  const isResolveAlertEnabled = isFeatureEnabled('resolveAlert', environment as Environment);

  return (
    <div className="relative w-full">
      {isLoading ? (
        <Skeleton
          className={classNames('rounded-md w-full', {
            'h-16': isMinimized,
            'h-32': !isMinimized,
          })}
        />
      ) : (
        <Card
          noPaddingBody
          body={
            <Link to={`/user/alerts/${alert.id}`}>
              <div className="relative text-xs p-2">
                {/* Stripe: colored based on incident severity */}
                <div
                  className={classNames('absolute top-0 left-0 w-2 h-full', {
                    'bg-brand-orange':
                      alert?.severity_level === AlertSeverityLevel.Critical &&
                      alert.resolved_time === null,
                    'bg-brand-blue':
                      alert?.severity_level === AlertSeverityLevel.Critical &&
                      alert.resolved_time !== null,
                    'bg-brand-gray-light-2':
                      alert?.severity_level === AlertSeverityLevel.Info || !alert?.severity_level,
                  })}
                />

                {/* Content */}
                <div className="flex flex-row gap-2 ml-2 justify-between ">
                  <div className="flex items-start gap-2">
                    {' '}
                    {/* Alert Icon */}
                    <FontAwesomeIcon
                      size="lg"
                      icon={faSquirtyAlert}
                      className={classNames({
                        'text-brand-orange':
                          alert?.severity_level === AlertSeverityLevel.Critical &&
                          alert.resolved_time === null,
                        'text-brand-blue':
                          alert?.severity_level === AlertSeverityLevel.Critical &&
                          alert.resolved_time !== null,
                        'text-brand-gray-light-2':
                          alert?.severity_level === AlertSeverityLevel.Info ||
                          !alert?.severity_level,
                      })}
                    />
                    {/* Alert details */}
                    <div className="flex flex-col gap-1 leading-none mt-auto">
                      <div className="flex flex-row sm:flex-row items-start sm:items-center text-brand-green-light-1 truncate gap-2">
                        {/* Device name */}
                        <div className="text-xs truncate">
                          {sensor?.name ?? gateway?.name ?? ''}
                        </div>
                        <FontAwesomeIcon size="2xs" icon={faCircle} className="my-auto" />
                        {/* Time */}
                        <div
                          className="text-xs"
                          data-tooltip-content={dateToLocaleString(alert.created_date)}
                          data-tooltip-id="route-tooltip"
                        >
                          {dateToPrettyDate(alert.created_date)}
                        </div>
                      </div>
                      {/* Title */}
                      <div className="text-sm text-brand-gray">
                        {alert?.type
                          ? getAlertName(alert.type, alarmRule ?? undefined)
                          : t('user.Alerts.AlertCard.unknownAlert')}
                      </div>
                      {!isMinimized && (
                        <>
                          {/* Description */}
                          <div className="flex text-xs text-brand-gray-light-1 w-56 sm:w-full">
                            <span className="truncate overflow-hidden whitespace-nowrap">
                              {description}
                            </span>
                          </div>
                          {/* Action buttons */}
                          <div className="flex flex-row gap-2 mt-2">
                            <MarkAsReadButton alertId={alert.id} />
                            {isResolveAlertEnabled && <ResolveButton alertId={alert.id} />}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="flex flex-row items-start gap-2">
                    {(users ?? []).length > 0 && (
                      <div className="flex flex-row gap-1 items-center">
                        <span className="text-brand-gray-light-1 text-xs">
                          {t('user.Alerts.AlertCard.seenBy')}
                        </span>
                        <UsersInlineList
                          users={users}
                          size="md"
                          data-tooltip-content={`${t('user.Alerts.AlertCard.seenBy')} ${users ? users.map(user => user.full_name).join(', ') : ''}`}
                          data-tooltip-id="route-tooltip"
                        />
                      </div>
                    )}

                    <ResolvedBy user={alert?.resolved_by} />
                  </div>
                </div>
              </div>
            </Link>
          }
        />
      )}
    </div>
  );
};
