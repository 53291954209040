enum TreeType {
  UNKNOWN = 'UNKNOWN',
  PINE = 'PINE', // Fyr
  SPRUCE = 'SPRUCE', // Gran
  CUSTOM_PLYWOOD_BUNCH_001 = 'CUSTOM_PLYWOOD_BUNCH_001', // Custom calibration for Bunch
  DOUGLAS_FIR = 'DOUGLAS_FIR', // Custom calibration from online published results
  OAK_DENMARK = 'OAK_DENMARK', // Egetræ
  CHESTNUT = 'CHESTNUT', // Kastanje
  OAK_HEARTWOOD_LJUBLJANA = 'OAK_HEARTWOOD_LJUBLJANA', // Egetræ fra Ljubljana (Hjertetræ)
  POPLAR_THERMALLY_MODIFIED = 'POPLAR_THERMALLY_MODIFIED', // Toplar TMT
  SPRUCE_UK_COATED_LJUBLJANA = 'SPRUCE_UK_COATED_LJUBLJANA', // Gran fra Ljubljana (UK coated)
}

// Chestnut, Oak, Toplar TMT, Spruce coated

export default TreeType;
