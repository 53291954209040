type DeviceLoRaDetails = {
  id: string;

  // Activation information
  app_eui?: string;
  dev_eui?: string;
  app_key?: string;

  // TTN Details
  ttn_server_url?: string;
  ttn_app_id?: string;
  ttn_dev_id?: string;
};

export const parseDeviceLoRaDetails = (deviceLoRaDetails: any): DeviceLoRaDetails =>
  deviceLoRaDetails as DeviceLoRaDetails;

export default DeviceLoRaDetails;
