import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Dispatch, SetStateAction, HTMLAttributes } from 'react';

import { IconSwitch } from 'components';

export const ArrowUpDownSwitch: React.FC<
  {
    label?: string;
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
    onChange?: (checked: boolean) => Promise<void>;
  } & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>
> = ({ ...props }) => (
  <IconSwitch isCheckedIcon={faAngleUp} isNotCheckedIcon={faAngleDown} {...props} />
);
