import classNames from 'classnames';
import { Dispatch, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  SubmittingButton,
  CustomerOrUserSelectInput,
  RoleSelectInput,
  UncontrolledSwitch,
} from 'components';
import { EmailForm } from 'components/forms/EmailForm';
import Modal from 'components/modals/Modal';
import Role from 'utils/enums/Role';
import Customer from 'utils/types/Customer';
import User from 'utils/types/User';

export const AccessLinkAddModal: React.FC<{
  show: boolean;
  setShow: Dispatch<boolean>;
  onSelect: (
    role: Role,
    inviteNew: boolean,
    details: { customer?: Customer; user?: User; email?: string },
  ) => Promise<void>;
  customers?: Customer[];
  users?: User[];
  isPending?: boolean;
  onlyAllowExisting?: boolean;
}> = ({ show, setShow, onSelect, customers, users, isPending, onlyAllowExisting = false }) => {
  const [inviteNew, setInviteNew] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [email, setEmail] = useState<string>();
  const [role, setRole] = useState<Role>(Role.Member);
  const [user, setUser] = useState<User>();
  const [customer, setCustomer] = useState<Customer>();

  const { t } = useTranslation();

  const userCustomerInputRef = useRef<HTMLInputElement | null>(null);
  const roleInputRef = useRef<HTMLInputElement | null>(null);

  const formIsValid = role && (inviteNew ? email : user || customer);
  const isValid = !isPending && formIsValid;
  const showSwitch = !onlyAllowExisting;

  useEffect(() => {
    if (inviteNew) {
      setUser(undefined);
      setCustomer(undefined);
    }
  }, [inviteNew]);

  useEffect(() => {
    if (onlyAllowExisting && inviteNew) {
      setInviteNew(false);
    }
  }, [onlyAllowExisting]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal
      className="sm:min-w-[400px]"
      title={t('components:modals.AccessLinkAddModal.title')}
      show={show}
      setShow={setShow}
      size="sm"
    >
      {showSwitch && (
        <div className="flex justify-around mb-5">
          <p className={classNames({ 'text-brand-gray-light-3': !inviteNew })}>
            {t('components:modals.AccessLinkAddModal.addNew')}
          </p>

          <UncontrolledSwitch
            className="justify-center"
            onChange={async e => setInviteNew(prevValue => !prevValue)}
            defaultChecked={!inviteNew}
          />

          <p className={classNames({ 'text-brand-gray-light-3': inviteNew })}>
            {t('components:modals.AccessLinkAddModal.addExisting')}
          </p>
        </div>
      )}

      {inviteNew ? (
        <div className="mb-5">
          <p className="mb-1 cursor-pointer" onClick={() => userCustomerInputRef?.current?.focus()}>
            {t('components:modals.AccessLinkAddModal.newUser')}
          </p>

          <EmailForm
            onSubmit={async (values, isValid) => {
              setEmail(isValid ? values.email : undefined);
            }}
            autoSubmitOnChange
          />
        </div>
      ) : (
        <>
          <p className="mb-1 cursor-pointer" onClick={() => userCustomerInputRef?.current?.focus()}>
            {t('components:modals.AccessLinkAddModal.userOrOrganization')}
          </p>

          <CustomerOrUserSelectInput
            ref={userCustomerInputRef}
            className="mb-5"
            onSelect={(customerOrUser, type) => {
              if (type === 'customer') {
                setCustomer(customerOrUser as Customer);
                setUser(undefined);
              } else {
                setCustomer(undefined);
                setUser(customerOrUser as User);
              }
            }}
            customersToSelect={customers}
            usersToSelect={users}
            isPending={isPending}
          />
        </>
      )}

      <p className="mb-1 cursor-pointer" onClick={() => roleInputRef?.current?.focus()}>
        {t('common:role')}
      </p>
      <RoleSelectInput
        ref={roleInputRef}
        className="mb-5"
        onSelect={role => {
          setRole(role);
        }}
        initialSelectedOption={role}
        isPending={isPending}
      />

      <SubmittingButton
        variant="primary"
        onClick={async () => {
          if (isValid) {
            setIsSubmitting(true);
            try {
              await onSelect(role, inviteNew, { customer, user, email });
            } catch (error) {}
            setIsSubmitting(false);
          }
        }}
        submitting={isSubmitting}
        disabled={!isValid || isSubmitting}
        buttonText={
          inviteNew
            ? t('components:modals.AccessLinkAddModal.Button.invite')
            : t('components:modals.AccessLinkAddModal.Button.existing')
        }
        size="md"
      />
    </Modal>
  );
};
