import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UsersInlineList from 'components/UsersInlineList';
import { useGatewayUsers } from 'utils/hooks/data';

const GatewayUsersInlineList: React.FC<{
  gatewayId: string;
}> = ({ gatewayId }) => {
  const { t } = useTranslation('components');

  const { users } = useGatewayUsers(gatewayId, { disableErrorNotifications: true });

  return (
    <Link to={`/user/gateways/${gatewayId}/access`}>
      <UsersInlineList
        users={users}
        data-tooltip-content={t('sensors.GatewayUsersInlineList.dataTip')}
        data-tooltip-id="route-tooltip"
      />
    </Link>
  );
};

export default GatewayUsersInlineList;
