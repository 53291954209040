import classnames from 'classnames';
import { ComponentPropsWithRef } from 'react';

import Tooltip from 'components/Tooltip';

export const StaticTextInput: React.FC<
  {
    label?: string;
    tooltip?: string;
    prepend?: string | JSX.Element;
    append?: string | JSX.Element;
  } & ComponentPropsWithRef<'input'>
> = ({
  label,
  tooltip,
  value,
  placeholder,
  readOnly = true,
  required,
  className,
  prepend,
  type = 'text',
  append,
  ...props
}) => {
  const controlId = Math.random().toString(36).substring(7);

  return (
    <div className={classnames(className, 'mb-3')}>
      {label && (
        <label className={classnames({ inline: required })} htmlFor={controlId}>
          {label}
        </label>
      )}
      {required && (
        <span
          className="inline"
          data-tooltip-content="This is a required field"
          data-tooltip-id="route-tooltip"
        >
          *
        </span>
      )}
      {tooltip && <Tooltip className="px-2" tooltip={tooltip} />}

      <div className="flex bg-brand-gray-light-4 rounded-lg">
        {prepend && (
          <span
            className={classnames(
              'rounded-lg text-brand-gray border-2 border-r-0 border-brand-gray-light-3 flex justify-center align-middle items-center text-xs bg-white py-1.5 px-3.5 rounded-r-none',
            )}
          >
            {prepend}
          </span>
        )}
        <input
          id={controlId}
          className={classnames({
            'rounded-l-none': prepend,
            'rounded-r-none': append,
          })}
          readOnly={readOnly}
          disabled={readOnly}
          placeholder={placeholder}
          type={type}
          value={value}
          {...props}
        />
        {append && (
          <span className="rounded-lg text-brand-blue-light-1 border-2 border-l-0 border-brand-gray-light-3 flex justify-center align-middle items-center text-xs bg-white py-1.5 px-3.5 rounded-l-none">
            {append}
          </span>
        )}
      </div>
    </div>
  );
};
