import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import queryClient from 'utils/queries/client';
import { parseAlarmRule, getQueryKeyAlarmRule } from 'utils/types/AlarmRule';
import { getQueryKeyCustomerAlarmRuleSensors } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';
import { getQueryKeySensorAlarmRules, getQueryKeySensorAlarmRuleEnabled } from 'utils/types/Sensor';

export const useSensorAlarmRules = (sensorId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = getQueryKeySensorAlarmRules(sensorId!);

  const { customerId } = useSelectedCustomer();

  // Get
  const {
    data: alarmRules,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/alarm_rules`),
    select: ({ enabled, available }: { enabled: any[]; available: any[] }) => ({
      enabled: enabled.map(parseAlarmRule),
      available: available.map(parseAlarmRule),
    }),
    enabled: enableGet && !!sensorId,
  });

  // Enable alarm rule
  const { mutateAsync: enableAlarmRuleById, isPending: isEnablingAlarmRule } = useMutation({
    mutationFn: (alarmRuleId: string) =>
      api.post(`/sensors/${sensorId}/alarm_rules/${alarmRuleId}`),
    onSuccess: async (data, alarmRuleId) => {
      queryClient.setQueryData(queryKey, data);
      queryClient.setQueryData(getQueryKeySensorAlarmRuleEnabled(sensorId!, alarmRuleId), true);
      queryClient.invalidateQueries({ queryKey: getQueryKeyAlarmRule(alarmRuleId) });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, false),
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, true),
      });
    },
  });

  // Disable alarm rule
  const { mutateAsync: disableAlarmRuleById, isPending: isDisablingAlarmRule } = useMutation({
    mutationFn: (alarmRuleId: string) =>
      api.delete(`/sensors/${sensorId}/alarm_rules/${alarmRuleId}`),
    onSuccess: async (data, alarmRuleId) => {
      queryClient.setQueryData(queryKey, data);
      queryClient.setQueryData(getQueryKeySensorAlarmRuleEnabled(sensorId!, alarmRuleId), false);
      queryClient.invalidateQueries({ queryKey: getQueryKeyAlarmRule(alarmRuleId) });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, false),
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, true),
      });
    },
  });

  return {
    enableAlarmRuleById,
    disableAlarmRuleById,
    isPending: isPending || isLoading || isFetching,
    enabledAlarmRules: alarmRules?.enabled,
    availableAlarmRules: alarmRules?.available,
    isMutating: isEnablingAlarmRule || isDisablingAlarmRule,
  };
};
