import { useEffect, useMemo, useState } from 'react';

import { PlotlyData } from 'components/plots/insights_plots/PlotlyUtils';
import {
  DebouncedNumericInput,
  SensorSelectDropdown,
  TagsSelect,
  BoxpointSelect,
  boxPointEnumMap,
} from 'components/plots/insights_plots/sensors/components';
import { SensorsTabPlot } from 'components/plots/insights_plots/sensors/SensorsTabPlot';
import Spinner from 'components/Spinner';
import api from 'utils/api';

export type SensorsDataTabOptions = 'Sensors' | 'Groups' | 'Customers';

const DEFAULT_SENSOR_DISTANCE_KM = 1000;
const DEFAULT_INTERVAL_PERCENTAGE = 90;

export const SensorsTab: React.FC<{
  currentTab: SensorsDataTabOptions;
  startDate: Date;
  endDate: Date;
  measureType: string;
  tagType: string;
}> = ({ currentTab, startDate, endDate, measureType, tagType }) => {
  const [loadingPlot, setLoadingPlot] = useState<boolean>(false);
  const [tagsInInterval, setTagsInInterval] = useState<Set<string>>(new Set());
  const [sensors, setSensors] = useState<Map<string, string>>(
    new Map<string, string>([['All Sensors (agg)', '']]),
  );
  const [sensorGroups, setSensorGroups] = useState<Map<string, string>>(
    new Map<string, string>([['All Groups (agg)', '']]),
  );
  const [customers, setCustomers] = useState<Map<string, string>>(
    new Map<string, string>([['All Customers (agg)', '']]),
  );
  //Plot data
  const [sensorsBoxPlotData, setSensorsBoxplotData] = useState<PlotlyData | null>(null);
  const [transmissionsIntervalPlotData, setTransmissionsIntervalPlotData] =
    useState<PlotlyData | null>(null);
  // Request data
  const [selectedBoxpointType, setSelectedBoxpointType] = useState<string>('Outliers');
  const [maxDistanceToSensor, setMaxSensorDistance] = useState<number>(DEFAULT_SENSOR_DISTANCE_KM);
  const [selectedTags, setSelectedTags] = useState<Set<string>>(new Set());
  const [selectedSensors, setSelectedSensors] = useState<string[]>([]);
  const [selectedSensorGroups, setSelectedSensorGroups] = useState<string[]>([]);
  const [selectedCustomers, setSelectedCustomers] = useState<string[]>([]);
  const [previousTransmissionData, setPreviousTransmissionData] = useState<string>('');
  const [percentageInterval, setPercentageInterval] = useState<number>(90);

  useEffect(() => {
    // We'll need to get new transmissions data if we change the time intervals
    setPreviousTransmissionData('');
  }, [startDate, endDate]);

  useMemo(() => {
    setLoadingPlot(true);
    api
      .post('/admin/insights/plots/sensors_tab_plots', {
        tab_name: currentTab.toLowerCase(),
        max_distance_to_sensor: maxDistanceToSensor,
        start_date: startDate.toISOString().split('T')[0],
        end_date: endDate.toISOString().split('T')[0],
        tag_type: tagType.toLowerCase().replaceAll(' ', '_'),
        measure: measureType.toLowerCase(),
        boxpoints: boxPointEnumMap.get(selectedBoxpointType),
        selected_sensor_ids: currentTab === 'Sensors' ? selectedSensors.join() : '',
        selected_group_ids: currentTab === 'Groups' ? selectedSensorGroups.join() : '',
        selected_customer_ids: currentTab === 'Customers' ? selectedCustomers.join() : '',
        tag_values: [...selectedTags].join(),
        previous_transmission_data: previousTransmissionData,
        percentage_interval: percentageInterval,
      })
      .then(response => {
        if (response['sensorIds'] !== undefined)
          setSensors(new Map<string, string>(Object.entries(response['sensorIds'])));
        if (response['groupIds'] !== undefined)
          setSensorGroups(new Map<string, string>(Object.entries(response['groupIds'])));
        if (response['customerIds'] !== undefined)
          setCustomers(new Map<string, string>(Object.entries(response['customerIds'])));

        const tagValues = response['tagValues']?.split(',');
        const transmissionData = response['previousTransmissionData'];
        setSensorsBoxplotData({
          data: response['sensorsBoxplot']['data'],
          layout: response['sensorsBoxplot']['layout'],
        });
        setTransmissionsIntervalPlotData({
          data: response['transmissionsPlot']['data'],
          layout: response['transmissionsPlot']['layout'],
        });
        setTagsInInterval(new Set([...tagValues, ...tagsInInterval]));
        if (transmissionData !== undefined) setPreviousTransmissionData(transmissionData);
        setLoadingPlot(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab,
    maxDistanceToSensor,
    startDate,
    endDate,
    tagType,
    measureType,
    percentageInterval,
    selectedBoxpointType,
    selectedSensors,
    selectedSensorGroups,
    selectedCustomers,
    selectedTags,
  ]);

  return (
    <div className="flex flex-col divide-y divide-slate-400">
      <div className="flex flex-col mb-2 pt-2">
        <div>Select {currentTab}</div>
        <SensorSelectDropdown
          currentTab={currentTab}
          items={
            (currentTab === 'Sensors' && sensors) ||
            (currentTab === 'Groups' && sensorGroups) ||
            (currentTab === 'Customers' && customers) ||
            new Map<string, string>([['No items found', '']])
          }
          setSelectedSensors={setSelectedSensors}
          setSelectedGroups={setSelectedSensorGroups}
          setSelectedCustomers={setSelectedCustomers}
        />
      </div>
      <div className="flex items-center gap-2 mb-2 pt-2">
        <div>Select boxpoint type: </div>
        <BoxpointSelect setSelectedBoxpoint={setSelectedBoxpointType}></BoxpointSelect>
      </div>
      <div className="flex mb-2 pt-2">
        <div className="flex items-center pr-2">Maximum distance to first chosen sensor(s):</div>
        <DebouncedNumericInput
          minValue={1}
          maxValue={1000}
          defaultValue={DEFAULT_SENSOR_DISTANCE_KM}
          suffix={'km'}
          debouncedSetValueCallback={setMaxSensorDistance}
        />
      </div>
      <div className="mb-2 pt-2">
        Select device tags:
        <TagsSelect tagOptions={tagsInInterval} setSelectedTags={setSelectedTags} />
      </div>
      <div id="sensors-tab-boxplot" className="relative mb-2 pt-2">
        <SensorsTabPlot sensorsPlotData={sensorsBoxPlotData} />
        {loadingPlot && <Spinner className="absolute top-10 right-10 border-brand-blue" />}
      </div>
      <div className="flex mb-2 pt-2">
        <div className="flex items-center pr-2">Percentile interval range (1-100%):</div>
        <DebouncedNumericInput
          minValue={1}
          maxValue={100}
          defaultValue={DEFAULT_INTERVAL_PERCENTAGE}
          suffix={'%'}
          debouncedSetValueCallback={setPercentageInterval}
        />
      </div>
      <div id="sensors-tab-transmissions-interval-plot" className="relative mb-2 pt-2">
        <SensorsTabPlot sensorsPlotData={transmissionsIntervalPlotData} />
        {loadingPlot && <Spinner className="absolute top-10 right-10 border-brand-blue" />}
      </div>
    </div>
  );
};
