import {
  SensorTagCasing,
  SensorTagClimate,
  SensorTagComplementaryElement,
  SensorTagConstructionPhase,
  SensorTagConstructionPrinciples,
  SensorTagInsulation,
  SensorTagInsulationType,
  SensorTagOrientation,
  SensorTagPrimaryElementCategory,
  SensorTagPrimaryElementType,
  SensorTagRoomType,
  SensorTagScrewType,
  SensorTagUsage,
  SensorTagVentilation,
  SensorTagVerticalPlacement,
} from 'utils/enums';

type SensorTags = {
  sensor_id: string;

  // Location tags
  primary_element_category?: SensorTagPrimaryElementCategory;
  primary_element_category_description?: string;
  primary_element_type?: SensorTagPrimaryElementType;
  primary_element_type_description?: string;
  complementary_element?: SensorTagComplementaryElement;
  complementary_element_description?: string;

  // Custom location tags
  orientation?: SensorTagOrientation;
  room_type?: SensorTagRoomType;
  room_type_description?: string;
  vertical_placement?: SensorTagVerticalPlacement;

  // Global tags
  casing?: SensorTagCasing;
  climate?: SensorTagClimate;
  construction_phase?: SensorTagConstructionPhase;
  construction_principles?: SensorTagConstructionPrinciples;
  insulation?: SensorTagInsulation;
  insulation_type?: SensorTagInsulationType;
  screw_type?: SensorTagScrewType;
  ventilation?: SensorTagVentilation;
  usage?: SensorTagUsage;

  // Other
  created_date: Date;
  updated_by?: string;
  updated_date?: Date;
};

export const parseSensorTags = (sensorTags: any): SensorTags => {
  if (sensorTags.created_date) {
    sensorTags.created_date = new Date(sensorTags.created_date);
  }
  if (sensorTags.updated_date) sensorTags.updated_date = new Date(sensorTags.updated_date);

  return sensorTags as SensorTags;
};

export type SensorTagName =
  | 'primary_element_category'
  | 'primary_element_type'
  | 'complementary_element'
  | 'orientation'
  | 'room_type'
  | 'vertical_placement'
  | 'casing'
  | 'climate'
  | 'construction_phase'
  | 'construction_principles'
  | 'insulation'
  | 'insulation_type'
  | 'screw_type'
  | 'ventilation'
  | 'usage';

export type SensorTagDescriptionName =
  | 'primary_element_category_description'
  | 'primary_element_type_description'
  | 'complementary_element_description'
  | 'room_type_description';

export type SensorTag =
  | SensorTagCasing
  | SensorTagClimate
  | SensorTagComplementaryElement
  | SensorTagConstructionPhase
  | SensorTagConstructionPrinciples
  | SensorTagInsulation
  | SensorTagInsulationType
  | SensorTagOrientation
  | SensorTagPrimaryElementCategory
  | SensorTagPrimaryElementType
  | SensorTagRoomType
  | SensorTagScrewType
  | SensorTagVentilation
  | SensorTagVerticalPlacement
  | SensorTagUsage;

export const anySensorTagsDefined = (sensorTags?: SensorTags) =>
  !!sensorTags?.primary_element_category ||
  !!sensorTags?.primary_element_type ||
  !!sensorTags?.complementary_element ||
  !!sensorTags?.orientation ||
  !!sensorTags?.room_type ||
  !!sensorTags?.vertical_placement ||
  !!sensorTags?.casing ||
  !!sensorTags?.climate ||
  !!sensorTags?.construction_phase ||
  !!sensorTags?.construction_principles ||
  !!sensorTags?.insulation ||
  !!sensorTags?.insulation_type ||
  !!sensorTags?.screw_type ||
  !!sensorTags?.ventilation ||
  !!sensorTags?.usage;

export default SensorTags;
