import ReportGenerationStep from 'utils/enums/ReportGenerationStep';

type ReportRevision = {
  id: string;
  description?: string;
  report_id: string;
  generation_step: ReportGenerationStep;
  generation_step_timestamp: Date;
  failed_reason: string;
  timestamp_from: Date;
  timestamp_to: Date;
  include_weather?: boolean;
  include_notes?: boolean;
  include_images?: boolean;
  include_blueprints?: boolean;
  use_custom_location?: boolean;
  sensor_id_weather_location?: string;
  address?: string;
  storage_path?: string;
  storage_url?: string;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseReportRevision = (reportRevision: any): ReportRevision => {
  reportRevision.created_date = new Date(reportRevision.created_date);
  reportRevision.timestamp_from = new Date(reportRevision.timestamp_from);
  reportRevision.timestamp_to = new Date(reportRevision.timestamp_to);

  if (reportRevision.updated_date) {
    reportRevision.updated_date = new Date(reportRevision.updated_date);
  }

  if (reportRevision.generation_step_timestamp) {
    reportRevision.generation_step_timestamp = new Date(reportRevision.generation_step_timestamp);
  }
  return reportRevision;
};

export type ReportRevisionFormValues = Pick<
  ReportRevision,
  | 'description'
  | 'timestamp_from'
  | 'timestamp_to'
  | 'include_notes'
  | 'include_images'
  | 'include_weather'
  | 'include_blueprints'
  | 'use_custom_location'
  | 'sensor_id_weather_location'
  | 'address'
  | 'created_by'
> & {
  name?: string;
  sensor_ids: string[];
  customer_id: string;
  locale: string;
};

export default ReportRevision;
