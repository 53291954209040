import isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiValue } from 'react-select';

import { MultiSelect } from 'components/inputs/components';
import { OptionType, GatewaysSelectInputProps } from 'components/inputs/GatewaysSelectInput/types';
import { sortedArrayByStringProperty } from 'utils/arrays';
import { useCurrentUserCustomers } from 'utils/hooks/data';

const GatewaysSelectInputInner = (
  {
    disabled,
    initialSelectedGatewayIds,
    gatewaysToSelect,
    label,
    ...props
  }: GatewaysSelectInputProps,
  ref: React.Ref<any>,
) => {
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<OptionType>>();

  const { customers } = useCurrentUserCustomers();

  const { t } = useTranslation('components');

  // Create mapping from customer ID -> customer name
  const customerId2name: { [key: string]: string } = useMemo(() => {
    // Sort customers by name
    const customersSorted = sortedArrayByStringProperty(customers, 'name');

    const _customerId2name: { [key: string]: string } = Object.fromEntries(
      customersSorted.map(customer => [customer.id, customer.name || customer.id]),
    );
    _customerId2name['null'] = t('inputs.GatewaysSelectInput.ownedByOther');
    return _customerId2name;
  }, [customers, t]);

  // Define options array
  const options = useMemo(() => {
    const customerId2gateways: { [key: string]: OptionType[] } = {};
    Object.keys(customerId2name).forEach(customerId => (customerId2gateways[customerId] = []));
    gatewaysToSelect?.forEach(gateway => {
      // If user doesn't have access to the gateway's customer -> Put it in the "n/a" category
      const customerId = customerId2gateways.hasOwnProperty(gateway.customer_id!)
        ? gateway.customer_id!
        : 'null';
      customerId2gateways[customerId].push({
        value: gateway.id,
        label: gateway.id,
      });
    });

    // Construct options grouped by customer
    return Object.entries(customerId2name).map(([customerId, customerName]) => ({
      label: customerName,
      options: sortedArrayByStringProperty(customerId2gateways[customerId], 'label'),
    }));
  }, [customerId2name, gatewaysToSelect]);

  // If `initialSelectedGatewayIds` is provided -> use them to select initial options
  useEffect(() => {
    if (initialSelectedGatewayIds) {
      const optionsFlat = options.map(option => option.options).flat();
      const optionsSelectedFlat = optionsFlat.filter(option =>
        initialSelectedGatewayIds.includes(option.value),
      );

      const selectedOptionValues = selectedOptions?.map(x => x.value);
      const valuesSelectedFlat = optionsSelectedFlat?.map(x => x.value);
      if (!isEqual(selectedOptionValues, valuesSelectedFlat)) {
        setSelectedOptions(optionsSelectedFlat);
      }
    }
  }, [initialSelectedGatewayIds, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      {label && <label htmlFor="gateways-select-input">{label}</label>}
      <MultiSelect
        ref={ref}
        options={options}
        value={selectedOptions ?? []}
        onChange={setSelectedOptions}
        disabled={disabled}
        inputId="gateways-select-input"
        placeholderLoading={t('inputs.GatewaysSelectInput.placeholder.loading')}
        placeholderLoaded={t('inputs.GatewaysSelectInput.placeholder.loaded')}
        allItemsLabel={t('inputs.helpers.MultiSelect.allGateways')}
        {...props}
      />
    </div>
  );
};

export const GatewaysSelectInput = React.forwardRef<any, GatewaysSelectInputProps>(
  GatewaysSelectInputInner,
);
