export type BlueprintPosition = {
  id: string;
  blueprint_id: string;
  sensor_id: string;
  position_x?: number;
  position_y?: number;
  created_date: Date;
  created_by: string;
  updated_date?: Date;
  updated_by?: string;

  // Custom properties not saved in database
  isPositionDefined?: boolean;
};

export const parseBlueprintPosition = (blueprintPosition: any) => {
  blueprintPosition.created_date = new Date(blueprintPosition.created_date);
  if (blueprintPosition.updated_date) {
    blueprintPosition.updated_date = new Date(blueprintPosition.updated_date);
  }

  blueprintPosition.isPositionDefined =
    typeof blueprintPosition?.position_x === 'number' &&
    typeof blueprintPosition?.position_y === 'number';

  return blueprintPosition as BlueprintPosition;
};
