import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import Sensor from 'utils/types/Sensor';

export const RemoveFromGroupCell: React.FC<{
  sensor: Sensor;
  onDeleteClick: (...props: any) => void;
}> = ({ sensor, onDeleteClick }) => {
  const { t } = useTranslation('components');

  return (
    <Button
      variant="outline-danger"
      size="sm"
      onClick={e => {
        onDeleteClick(sensor, e);
        e.stopPropagation();
      }}
    >
      {t('tables.SensorsPaginatedTable.removeFromGroupButtonText')}
    </Button>
  );
};
