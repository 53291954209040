import { EChartsOption } from 'echarts/types/dist/shared';
import { Opts, EChartsReactProps } from 'echarts-for-react/lib/types';
import React from 'react';

import { lg } from 'utils/breakpoints';
import { ReactEChartsCore, echarts } from 'utils/echarts';
import { useWindowSize } from 'utils/hooks';
import { grid, gridMobile } from 'utils/plots/plot-config';

const BasePlot: React.FC<
  {
    option: EChartsOption;
  } & Omit<EChartsReactProps, 'option'>
> = ({ option, onEvents, ...props }) => {
  const [width] = useWindowSize();
  // Default grid
  if (!option.grid) {
    option.grid = width > lg ? grid : gridMobile;
  }

  // Default colors
  if (!option.color) {
    option.color = [
      '#c23531',
      '#2f4554',
      '#61a0a8',
      '#d48265',
      '#91c7ae',
      '#749f83',
      '#ca8622',
      '#bda29a',
      '#6e7074',
      '#546570',
      '#c4ccd3',
    ];
  }

  return (
    <ReactEChartsCore
      echarts={echarts}
      option={option}
      opts={
        {
          renderer: 'canvas',
        } as Opts
      }
      onEvents={onEvents}
      {...props}
    />
  );
};

export default BasePlot;
