import AlarmType from 'utils/enums/AlarmType';

type AlarmNotification = {
  id: string;
  alarm_id: string;
  description?: string;
  type: AlarmType;
  receiver_email?: string;
  receiver_sms?: string;
  sent_time?: Date;
  user_id?: string;
  sensor_id?: string;
  gateway_id?: string;
  seen_on_web?: boolean;
  seen_time?: Date;

  created_date: Date;
  created_by?: string;
};

export const parseAlarmNotification = (alarmNotification: any): AlarmNotification => {
  alarmNotification.created_date = new Date(alarmNotification.created_date);

  if (alarmNotification.sent_time) {
    alarmNotification.sent_time = new Date(alarmNotification.sent_time);
  }
  if (alarmNotification.seen_time) {
    alarmNotification.seen_time = new Date(alarmNotification.seen_time);
  }
  if (alarmNotification.resolved_time) {
    alarmNotification.resolved_time = new Date(alarmNotification.resolved_time);
  }

  return alarmNotification;
};

export default AlarmNotification;
