interface Option {
  rounded?: number;
}

const calculateMax = <T>(val?: T[], property?: keyof T, { rounded = 10 }: Option = {}) => {
  if (!val || !property) return 0;

  const max = Math.max(
    ...val.map(val => val[property] as any).filter(val => typeof val === 'number')
  );

  return isFinite(max) ? Math.ceil(max / rounded) * rounded : undefined;
};

const calculateMin = <T>(val?: T[], property?: keyof T, rounded = 10) => {
  if (!val || !property) return 0;

  const min = Math.min(
    ...val.map(val => val[property] as any).filter(val => typeof val === 'number')
  );

  return isFinite(min) ? Math.floor(min / rounded) * rounded : undefined;
};

export { calculateMin, calculateMax };
