import { differenceInDays } from 'date-fns/differenceInDays';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import SimpleReactCalendar from 'simple-react-calendar';

import 'styles/components/_calendar.scss';

export interface CalendarRangeInputProps {
  onSelect: ({ start, end }: { start: Date; end: Date }) => void;
  initialStartDate?: Date;
  initialEndDate?: Date;
  maxMonthsBack?: number;
}

export const CalendarRangeInput: React.FC<CalendarRangeInputProps> = ({
  onSelect,
  initialStartDate,
  initialEndDate,
  maxMonthsBack = 48,
}) => {
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);

  const { t } = useTranslation('components');

  // Set min date
  const now = new Date();
  const minDate = new Date(now.getTime());
  minDate.setMonth(minDate.getMonth() - maxMonthsBack);
  const rangeLimit = differenceInDays(now, minDate) + 1;

  const _onSelect = ({ start, end }: { start: Date; end: Date }) => {
    end.setHours(end.getHours() + 24);
    end.setMilliseconds(end.getMilliseconds() - 1);
    setStartDate(start);
    setEndDate(end);
    onSelect({ start, end });
  };

  const CALENDAR_DAYS_OF_WEEK = [
    t('inputs.CalendarRange.Mon'), // 'Man',
    t('inputs.CalendarRange.Tue'), // 'Tir',
    t('inputs.CalendarRange.Wed'), // 'Ons',
    t('inputs.CalendarRange.Thu'), // 'Tor',
    t('inputs.CalendarRange.Fri'), // 'Fre',
    t('inputs.CalendarRange.Sat'), // 'Lør',
    t('inputs.CalendarRange.Sun'), // 'Søn',
  ];

  return (
    <SimpleReactCalendar
      daysOfWeek={CALENDAR_DAYS_OF_WEEK}
      activeMonth={startDate ?? now}
      selected={{
        start: startDate,
        end: endDate,
      }}
      minDate={minDate}
      maxDate={now}
      rangeLimit={rangeLimit}
      mode="range"
      onSelect={_onSelect}
    />
  );
};
