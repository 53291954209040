import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes, SyntheticEvent } from 'react';

export type AlertProps = {
  variant?: 'white' | 'danger' | 'info';
  onClose?: (e: SyntheticEvent) => void;
} & HTMLAttributes<HTMLDivElement>;

export const Alert: React.FC<AlertProps> = ({
  children,
  variant = 'white',
  className,
  onClose,
  ...props
}) => (
  <div
    className={classNames(className, 'p-4 drop-shadow rounded relative', {
      'bg-brand-orange-light-2 text-brand-gray': variant === 'danger',
      'bg-white text-brand-gray': variant === 'white',
      'bg-brand-green text-white': variant === 'info',
    })}
    {...props}
  >
    {onClose && (
      <FontAwesomeIcon
        className={classNames('absolute right-3 top-2.5 cursor-pointer text-lg', {
          'text-brand-orange': variant === 'danger',
          'text-brand-gray': variant === 'white',
          'text-brand-gray-light-4': variant === 'info',
        })}
        icon={faXmark}
        onClick={onClose}
      />
    )}

    {children}
  </div>
);
