import {
  SensorTagCasing,
  SensorTagClimate,
  SensorTagComplementaryElement,
  SensorTagConstructionPhase,
  SensorTagConstructionPrinciples,
  SensorTagInsulation,
  SensorTagInsulationType,
  SensorTagOrientation,
  SensorTagPrimaryElementCategory,
  SensorTagPrimaryElementType,
  SensorTagRoomType,
  SensorTagScrewType,
  SensorTagUsage,
  SensorTagVentilation,
  SensorTagVerticalPlacement,
} from 'utils/enums';

export type SensorTagProbabilities<T extends string | number | symbol> = Record<T, number>;

type SensorTagPredictions = {
  primary_element_category: SensorTagProbabilities<SensorTagPrimaryElementCategory>;
  primary_element_type: SensorTagProbabilities<SensorTagPrimaryElementType>;
  complementary_element: SensorTagProbabilities<SensorTagComplementaryElement>;
  // Custom location tags
  orientation: SensorTagProbabilities<SensorTagOrientation>;
  room_type: SensorTagProbabilities<SensorTagRoomType>;
  vertical_placement: SensorTagProbabilities<SensorTagVerticalPlacement>;
  // Global tags
  casing: SensorTagProbabilities<SensorTagCasing>;
  climate: SensorTagProbabilities<SensorTagClimate>;
  construction_phase: SensorTagProbabilities<SensorTagConstructionPhase>;
  construction_principles: SensorTagProbabilities<SensorTagConstructionPrinciples>;
  insulation: SensorTagProbabilities<SensorTagInsulation>;
  insulation_type: SensorTagProbabilities<SensorTagInsulationType>;
  screw_type: SensorTagProbabilities<SensorTagScrewType>;
  ventilation: SensorTagProbabilities<SensorTagVentilation>;
  usage: SensorTagProbabilities<SensorTagUsage>;
};

export const sensorTagPredictionsEmpty = {
  primary_element_category: {},
  primary_element_type: {},
  complementary_element: {},
  // Custom location tags
  orientation: {},
  room_type: {},
  vertical_placement: {},
  // Global tags
  casing: {},
  climate: {},
  construction_phase: {},
  construction_principles: {},
  insulation: {},
  insulation_type: {},
  screw_type: {},
  ventilation: {},
  usage: {},
} as SensorTagPredictions;

export default SensorTagPredictions;
