import { forwardRef, MutableRefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericSelectInput, SelectProps } from 'components';
import Role from 'utils/enums/Role';
import { getRoleText } from 'utils/texts/Role';

interface Props extends Omit<SelectProps<Role>, 'name'> {
  id?: string;
  includeLabel?: boolean;
}

export const RoleSelectInput = forwardRef<HTMLInputElement, Props>(
  ({ id, includeLabel = false, ...props }, _ref) => {
    const ref = _ref as MutableRefObject<HTMLInputElement | null>;
    const name = 'ws-role-select-input';

    const { t } = useTranslation('components');

    return (
      <>
        {includeLabel && (
          <label htmlFor={name} onClick={() => ref?.current && ref.current.focus()}>
            {t('inputs.RoleSelect.label')}
          </label>
        )}

        <GenericSelectInput
          id={id}
          ref={ref}
          name={name}
          optionsToSelect={useMemo(() => [Role.Guest, Role.Member, Role.Owner], [])}
          placeholder={t('inputs.RoleSelect.placeholder')}
          valueToTextFn={useCallback((value: Role) => getRoleText(value), [])}
          {...props}
        />
      </>
    );
  },
);
