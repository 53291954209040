import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

// Source: https://www.svgrepo.com/svg/62904/line-graph-down-arrow
const faSquirtyChartDown: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    70, // width
    70, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M70.227,57.273c0-0.63-0.509-1.132-1.124-1.132c-0.63,0-1.132,0.509-1.132,1.132l0.005,9.486L48.535,44.666l-8.342,12.68l-9.689-37.104l-7.126,22.4L2.567,10.89c-0.036-0.058-0.098-0.098-0.143-0.145V1.128C2.425,0.502,1.917,0,1.292,0S0.165,0.506,0.165,1.128v68.946h48.604c0.625,0,1.125-0.506,1.125-1.131c0-0.621-0.504-1.127-1.125-1.127H2.425V14.8l21.67,33.062l6.199-19.488l8.996,34.466l9.507-14.455l17.382,19.759h-9.083c-0.625,0-1.127,0.509-1.127,1.129s0.502,1.127,1.127,1.127h13.139L70.227,57.273z',
  ],
};

export default faSquirtyChartDown;
