import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useFormikContext } from 'formik';

import { Alert } from 'components';

interface ErrorMessageDisplayProps<T> {
  showFieldsOnly?: (keyof T)[];
}

const ErrorMessageDisplay = <T extends {}>({ showFieldsOnly }: ErrorMessageDisplayProps<T>) => {
  const { errors } = useFormikContext<T>();

  const messages = Object.entries(errors)
    .filter(([key, message]: [key: string, message: any]) =>
      showFieldsOnly ? showFieldsOnly.includes(key as keyof T) : true,
    )
    .map(([key, message]: [key: string, message: any]) =>
      !!message.description ? message.description : !!message.filename ? message.filename : message,
    )
    .flat();

  return messages.length > 0 ? (
    <Alert variant="danger" className="my-3.5">
      {messages?.map((message, i) => (
        <p
          className={classNames('text-white', {
            'mb-1': i + 1 < messages.length,
          })}
          key={i}
        >
          <>
            <FontAwesomeIcon className="mr-3" size="lg" icon={faExclamationCircle} />
            {message}
          </>
        </p>
      ))}
    </Alert>
  ) : (
    <></>
  );
};

export default ErrorMessageDisplay;
