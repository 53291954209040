import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubmittingButton, Button } from 'components';
import FileForm from 'components/forms/FileForm';
import Modal from 'components/modals/Modal';
import FileBase from 'utils/types/FileBase';

const UpdateFileModal: React.FC<{
  onSubmit: (values: FileBase) => Promise<void>;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  values?: FileBase;
  buttonText: string;
  submittingText: string;
  onDelete?: () => Promise<void>;
  showMarkAsPrimary?: boolean;
  closeOnClickOutside?: boolean;
}> = ({
  onSubmit,
  show,
  setShow,
  values,
  buttonText,
  submittingText,
  onDelete,
  showMarkAsPrimary = false,
  closeOnClickOutside = true,
}) => {
  const { t } = useTranslation('components');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (!show && isSubmitting) {
      setIsSubmitting(false);
    }
  }, [show, isSubmitting]);

  return (
    <Modal
      title={buttonText}
      show={show}
      setShow={setShow}
      closeOnClickOutside={closeOnClickOutside}
      footer={
        <div className="flex justify-start gap-x-2 mt-2">
          <SubmittingButton
            submittingText={submittingText}
            buttonText={buttonText}
            submitting={isSubmitting}
            form="fileForm"
          />

          {onDelete && (
            <Button
              variant="outline-danger"
              disabled={isSubmitting}
              onClick={async () => {
                setIsSubmitting(true);
                await onDelete();
                setIsSubmitting(false);
              }}
            >
              {t('forms.FileForm.deleteButtonText')}
            </Button>
          )}
        </div>
      }
    >
      <FileForm
        onSubmit={async values => {
          setIsSubmitting(true);
          onSubmit(values);
          setIsSubmitting(false);
        }}
        values={values}
        buttonText={buttonText}
        submittingText={submittingText}
        onDelete={onDelete}
        showMarkAsPrimary={showMarkAsPrimary}
        hideButtons
      />
    </Modal>
  );
};

export default UpdateFileModal;
