import { faBoltLightning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import capitalize from 'lodash/capitalize';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Warning } from 'components';
import SensorSetupGuideLink from 'components/links/SensorSetupGuideLink';
import { useSensor } from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';

export const SensorNotSetupAlert: React.FC<{
  className?: string;
  sensorId?: string;
  showMarkAsSetup?: boolean;
  includeLinkToSensor?: boolean;
}> = ({ className, sensorId, showMarkAsSetup = false, includeLinkToSensor = false }) => {
  const { t } = useTranslation();

  const { sensor, updateSensor } = useSensor(sensorId);

  if (sensor && !!sensor.setup_completed_at) return null;

  return (
    <Warning className={className}>
      <span className="font-extrabold">{t('utils:notice')}</span>:{' '}
      {sensor && includeLinkToSensor ? (
        <>
          {capitalize(t('utils:sensorWord'))}{' '}
          <Link className="underline hover:text-blue-500" to={`/user/sensors/${sensorId}`}>
            {sensor.name}
          </Link>
        </>
      ) : (
        <>
          {t('utils:your')}{' '}
          {includeLinkToSensor ? (
            <Link className="underline hover:text-blue-500" to={`/user/sensors/${sensorId}`}>
              {t('utils:sensorWord')}
            </Link>
          ) : (
            t('utils:sensorWord')
          )}
        </>
      )}{' '}
      {t('components:Alerts.SensorNotSetupAlert.notSetupSensor')}.{' '}
      <SensorSetupGuideLink className="inline" textClassName="text-white" />
      {showMarkAsSetup && (
        <>
          {' '}
          {t('utils:or')}{' '}
          <span
            className="underline hover:text-blue-500 cursor-pointer"
            onClick={async () => {
              await updateSensor({ setup_completed_at: new Date() });
              notificationSuccess(t('components:Alerts.SensorNotSetupAlert.onMarkAsSetupClick'));
            }}
          >
            {t('components:Alerts.SensorNotSetupAlert.markAsSetup')}
            <span>
              <FontAwesomeIcon className="ml-1 underline" icon={faBoltLightning} />
            </span>
          </span>
          .
        </>
      )}
    </Warning>
  );
};
