import { Dispatch, SetStateAction, PropsWithChildren } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import GatewayForm from 'components/forms/gateways/GatewayForm';
import Modal from 'components/modals/Modal';
import { useIsFormDirty } from 'utils/hooks';
import Gateway from 'utils/types/Gateway';

const UpdateGatewayModal: React.FC<
  PropsWithChildren<{
    onSubmit: (values: Partial<Gateway>) => Promise<void>;
    show: boolean;
    setShow: Dispatch<SetStateAction<boolean>>;
    values?: Gateway;
    showCustomer?: boolean;
    useLinkAsTitle?: boolean;
  }>
> = ({ show, setShow, values, useLinkAsTitle = false, children, ...props }) => {
  const [formInnerRef, isFormDirty] = useIsFormDirty();

  const title = (
    <>
      {values?.id && useLinkAsTitle ? (
        <Link
          className="text-brand-green underline hover:text-blue-500"
          to={`/user/gateways/${values?.id}`}
        >
          {values.name}
        </Link>
      ) : (
        values?.name
      )}
    </>
  );

  return (
    <Modal title={title} show={show} setShow={setShow} hasUnsavedChanges={isFormDirty}>
      {values ? (
        <GatewayForm values={values} {...props} innerRef={formInnerRef} />
      ) : (
        <Skeleton width={250} />
      )}
      {children}
    </Modal>
  );
};

export default UpdateGatewayModal;
