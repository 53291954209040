import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { Link } from 'react-router-dom';

import { Badge, ControlledSwitch } from 'components';
import AlarmRuleType from 'utils/enums/AlarmRuleType';
import { parseI18LanguageToLocale } from 'utils/enums/Locale';
import { useAlarmRule, useSensorAlarmRules, useSensorAlarmRule } from 'utils/hooks/data';
import { getAlarmRuleLocalizedName } from 'utils/types/AlarmRule';

const SensorAlarmRule: React.FC<{ alarmRuleId: string; sensorId: string }> = ({
  alarmRuleId,
  sensorId,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const { t, i18n } = useTranslation('components');
  const currentLocale = parseI18LanguageToLocale(i18n.language);

  const { alarmRule, isPending: isPendingAlarmRule } = useAlarmRule(alarmRuleId, {
    disableErrorNotifications: true,
  });
  const { enableAlarmRuleById, disableAlarmRuleById, isMutating } = useSensorAlarmRules(sensorId, {
    enableGet: false,
  });
  const { enabled, isPending: isPendingSensorAlarmRule } = useSensorAlarmRule(
    sensorId,
    alarmRuleId,
  );

  const isPending = isPendingAlarmRule || isPendingSensorAlarmRule;

  useEffect(() => {
    setIsChecked(!!enabled);
  }, [enabled]);

  if (isPending || isMutating) {
    return <Skeleton count={1} />;
  }

  // TODO: Remove when making reference values alarm rule public available
  if (alarmRuleId === '620a4567d10ab6488de28bbb') {
    return null;
  }

  if (!isPending && !alarmRule) {
    // User don't have access
    return null;
  }

  return (
    <div className="flex justify-between mb-2">
      <ControlledSwitch
        className="mb-0"
        label={
          <>
            {getAlarmRuleLocalizedName(alarmRule, currentLocale)}
            {alarmRule?.type === AlarmRuleType.BuiltIn && (
              <Badge className="bg-brand-green-light-3 text-white ml-2">
                {t('sensors.SensorAlarmRule.woodsenseRule')}
              </Badge>
            )}
          </>
        }
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        onChange={async e => {
          try {
            if (e.target.checked) {
              await enableAlarmRuleById(alarmRuleId);
            } else {
              await disableAlarmRuleById(alarmRuleId);
            }
          } catch (err) {
            setIsChecked(!e.target.checked);
          }
        }}
      />

      <Link
        className="text-brand-green underline hover:text-blue-500"
        to={`/user/alarms/rules/${alarmRuleId}?fromSensorId=${sensorId}`}
      >
        {t('sensors.SensorAlarmRule.goToLink')}
      </Link>
    </div>
  );
};

export default SensorAlarmRule;
