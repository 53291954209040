import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export type ButtonType = 'submit' | 'reset' | 'button' | undefined;

export type Variant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'info'
  | 'outline-primary'
  | 'outline-secondary'
  | 'outline-danger'
  | 'outline-info';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
  type?: ButtonType;
  disabled?: boolean;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  variant?: Variant;
}

export const Button: React.FC<React.PropsWithChildren<ButtonProps>> = ({
  children,
  className,
  type = 'button',
  size = 'md',
  variant = 'primary',
  disabled = false,
  ...props
}) => (
  <button
    type={type}
    disabled={disabled}
    className={classNames(
      'rounded-[7px] focus:ring-2 transition-colors ease-in-out duration-100 will-change-transform',
      'disabled:opacity-50',
      className,
      {
        'px-1.5 py-[2px] text-[10px] lg:text-xs border': size === 'xs',
        'px-2.5 py-1.5 text-[10px] lg:text-xs border': size === 'sm',
        'px-3 py-1.5 text-xs lg:text-sm border-2': size === 'md',
        'px-4 py-2 text-sm lg:text-base border-[3px]': size === 'lg',
        // primary
        'bg-brand-blue border-brand-blue text-white hover:bg-brand-blue-light-1 hover:border-brand-blue-light-1':
          variant === 'primary',
        'border-brand-blue text-brand-blue bg-white hover:bg-brand-blue hover:text-white':
          variant === 'outline-primary',
        // secondary
        'bg-brand-gray-light-4 border-brand-gray-light-4': variant === 'secondary',
        'border-brand-gray-light-3 text-brand-gray-light-2 hover:bg-brand-gray-light-3 hover:text-white':
          variant === 'outline-secondary',
        // danger
        'border-brand-orange bg-brand-orange text-white hover:bg-brand-orange hover:border-brand-orange':
          variant === 'danger',
        'border-brand-orange text-brand-orange bg-white hover:bg-brand-orange hover:text-white':
          variant === 'outline-danger',
        // info
        'bg-brand-green border-brand-green hover:bg-brand-green-light-1 hover:border-brand-green-light-1 text-white':
          variant === 'info',
        'border-brand-green text-brand-green bg-white hover:bg-brand-green hover:text-white':
          variant === 'outline-info',
      },
    )}
    {...props}
  >
    {children}
  </button>
);
