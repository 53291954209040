import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';

import api from 'utils/api';
import queryClient from 'utils/queries/client';
import { useQueryDelete } from 'utils/queries/delete';
import { parseDemoApplication } from 'utils/types/DemoApplication';

export const useDemoApplication = (demoApplicationId?: string, disableProgressBar = false) => {
  const [waitingForDemoApplicationToSetup, setWaitingForDemoApplicationToSetup] = useState(false);
  const queryKey = ['demoApplications', demoApplicationId];

  // Get
  const { data: demoApplication, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/demo_applications/${demoApplicationId}`, {
        _disableProgressBar: disableProgressBar,
      }),
    select: (demoApplication: any) => parseDemoApplication(demoApplication),
    enabled: !!demoApplicationId,
    meta: {
      disableLoading: true,
    },
  });

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (demoApplication) {
      if (!demoApplication.setup_finished_at) {
        if (!waitingForDemoApplicationToSetup) {
          setWaitingForDemoApplicationToSetup(true);
        }
        timeout = setTimeout(() => {
          queryClient.invalidateQueries({ queryKey: ['demoApplications'] });
        }, 2000);
      } else if (waitingForDemoApplicationToSetup) {
        window.location.reload();
      }
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [demoApplication, waitingForDemoApplicationToSetup]); // eslint-disable-line react-hooks/exhaustive-deps

  // Delete
  const { mutateAsync: deleteDemoApplication } = useQueryDelete({
    api,
    queryKey,
    endpoint: `/demo_applications/${demoApplicationId}`,
    invalidateQueryKeys: [queryKey.slice(0, 1)],
  });

  return {
    demoApplication,
    deleteDemoApplication,
    isPending,
  };
};
