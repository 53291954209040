import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import {
  SubmittingButton,
  CustomerSelectInput,
  FormCheckboxInput,
  FormTextInput,
  GlobalAddressInput,
} from 'components';
import Form, { FormProps } from 'components/forms/Form';
import GatewayMap from 'components/maps/GatewayMap';
import Customer from 'utils/types/Customer';
import Gateway from 'utils/types/Gateway';

const GatewayForm: React.FC<
  {
    onSubmit: (values: Gateway) => Promise<void>;
    values?: Partial<Gateway>;
    showCustomer?: boolean;
    readOnly?: boolean;
  } & Pick<FormProps<Gateway>, 'innerRef'>
> = ({ onSubmit, values, showCustomer = false, readOnly = false, innerRef }) => {
  readOnly = readOnly || !onSubmit;
  const { t } = useTranslation('components');

  const schemaObj = {
    id: yup.string(),
    name: yup.string().required(),
    customer_id: yup.string(),
    geographic_location: yup.object({
      address: yup.string().nullable(),
      latitude: yup
        .number()
        .typeError(t('forms.GatewayForm.errors.numberLatitude'))
        .min(-90, t('forms.GatewayForm.errors.minLatitude'))
        .max(90, t('forms.GatewayForm.errors.maxLatitude'))
        .nullable(),
      longitude: yup
        .number()
        .typeError(t('forms.GatewayForm.errors.numberLongitude'))
        .min(-180, t('forms.GatewayForm.errors.minLongitude'))
        .max(180, t('forms.GatewayForm.errors.maxLongitude'))
        .nullable(),
      description: yup.string().nullable(),
    }),
    send_alarms: yup.boolean().default(true),
  };

  return (
    <Form<Gateway>
      validateOnMount
      enableReinitialize
      schemaObj={schemaObj}
      onSubmit={async values => await onSubmit(values)}
      values={values as Gateway}
      innerRef={innerRef}
      body={({ handleSubmit, setFieldValue, values: valuesFormik, isSubmitting }) => {
        const onCustomerSelect = async (customer: Customer) => {
          setFieldValue('customer_id', customer.id);
        };

        const onAddressSelected = ({ lon, lat }: { lon: number; lat: number }) => {
          setFieldValue('geographic_location.longitude', lon);
          setFieldValue('geographic_location.latitude', lat);
        };

        return (
          <form noValidate onSubmit={handleSubmit}>
            <Field
              type="hidden"
              name="geographic_location.longitude"
              value={valuesFormik.geographic_location?.latitude}
            />
            <Field
              type="hidden"
              name="geographic_location.longitude"
              value={valuesFormik.geographic_location?.longitude}
            />

            {values?.id && (
              <FormTextInput label={t('forms.gateways.GatewayForm.id.label')} name="id" disabled />
            )}

            <FormTextInput label={t('forms.gateways.GatewayForm.name.label')} name="name" />
            <FormTextInput
              label={t('forms.gateways.GatewayForm.location_description.label')}
              name="geographic_location.description"
              disabled={readOnly}
            />

            {showCustomer && (
              <div className="grid lg:grid-cols-2 gap-x-8">
                <div>
                  <CustomerSelectInput
                    label={t('forms.gateways.GatewayForm.customer.label')}
                    onSelect={onCustomerSelect}
                    initialSelectedCustomerId={values?.customer_id}
                  />
                </div>

                <FormTextInput
                  label={t('forms.gateways.GatewayForm.customer.id')}
                  name="customer_id"
                  disabled
                />
              </div>
            )}

            <div className="mb-3">
              <label htmlFor="address-input">{t('forms.gateways.GatewayForm.address.label')}</label>
              <GlobalAddressInput onAddressSelected={onAddressSelected} disabled={readOnly} />
            </div>

            <FormCheckboxInput
              label={t('forms.gateways.GatewayForm.send_alarms.label')}
              name="send_alarms"
              disabled={readOnly}
            />

            {!readOnly && (
              <SubmittingButton
                className="mb-3"
                submittingText={t('forms.gateways.GatewayForm.submittingText')}
                buttonText={t('forms.gateways.GatewayForm.buttonText')}
                submitting={isSubmitting}
              />
            )}
            <GatewayMap gateway={valuesFormik} />
          </form>
        );
      }}
    />
  );
};

export default GatewayForm;
