import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import BlueprintForm, { BlueprintFormValues } from 'components/forms/BlueprintForm';
import Modal from 'components/modals/Modal';
import { notificationSuccess } from 'utils/notifications';
import { Blueprint } from 'utils/types';

export const EditBlueprintModal: React.FC<{
  blueprint: Blueprint;
  updateBlueprint: (data: Partial<Omit<Blueprint, 'id'>>) => Promise<Blueprint>;
  show: boolean;
  setShow: Dispatch<boolean>;
}> = ({ show, setShow, blueprint, updateBlueprint }) => {
  const { t } = useTranslation('components');

  return (
    <Modal
      title={t('modals.EditBlueprintModal.title')}
      show={show}
      setShow={setShow}
      closeOnClickOutside={false}
    >
      <div className="mb-5">
        <BlueprintForm
          buttonText={t('modals.EditBlueprintModal.updateText')}
          existingBlueprintValues={{
            file: { filename: blueprint?.filename! },
            description: blueprint?.description,
            tags: blueprint?.tags,
          }}
          onSubmit={async (data: BlueprintFormValues) => {
            await updateBlueprint(data);
            notificationSuccess(t('modals.EditBlueprintModal.blueprintUpdated'));
          }}
        />
      </div>
    </Modal>
  );
};
