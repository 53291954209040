import { t } from 'translations';
import { RiskScoreReasonKey } from 'utils/enums';

export const getRiskScoreReasonKeyText = (riskScoreReasonKey: RiskScoreReasonKey) => {
  switch (riskScoreReasonKey) {
    case RiskScoreReasonKey.bursting_sensor:
      return t('utils:texts.getRiskScoreReasonKeyText.bursting_sensor');
    case RiskScoreReasonKey.inactive_sensor:
      return t('utils:texts.getRiskScoreReasonKeyText.inactive_sensor');
    case RiskScoreReasonKey.inactive_sensor_during_rain:
      return t('utils:texts.getRiskScoreReasonKeyText.inactive_sensor_during_rain');
    case RiskScoreReasonKey.nearby_point_increase:
      return t('utils:texts.getRiskScoreReasonKeyText.nearby_point_increase');
    case RiskScoreReasonKey.point_increase_handled:
      return t('utils:texts.getRiskScoreReasonKeyText.point_increase_handled');
    case RiskScoreReasonKey.point_increase_unhandled:
      return t('utils:texts.getRiskScoreReasonKeyText.point_increase_unhandled');
    case RiskScoreReasonKey.trend_increase:
      return t('utils:texts.getRiskScoreReasonKeyText.trend_increase');
    default:
      return 'n/a';
  }
};
