import React, { Dispatch, SetStateAction } from 'react';

import { BlueprintViewStateType } from 'utils/types';

export type BlueprintViewStateContextProps = {
  blueprintViewState: BlueprintViewStateType;
  setBlueprintViewState: Dispatch<SetStateAction<BlueprintViewStateType>>;
};

export const BlueprintViewStateContext = React.createContext<BlueprintViewStateContextProps>({
  blueprintViewState: BlueprintViewStateType.Moisture,
  setBlueprintViewState: () => {},
});
