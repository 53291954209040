import { format, getDate, getMonth as dateFnsGetMonth } from 'date-fns';

import { t } from 'translations';
import { md } from 'utils/breakpoints';
import { echarts } from 'utils/echarts';
import { dateFormat } from 'utils/plots/dateFormat';

export const xAxisFormatter = (
  value: string,
  index: number,
  daysAmount: number,
  timeAxisTicks: number,
): string => {
  const checkedDate = new Date(value).setHours(0, 0, 0, 0);
  const currentDate = new Date().setHours(0, 0, 0, 0);

  const date = new Date(value);

  // If the date is the first of the month, return the month name
  // If its new years day, append the year as well
  if (getDate(date) === 1 && daysAmount >= 4) {
    if (dateFnsGetMonth(date) === 0) {
      return `${format(date, 'LLL yyyy')}`;
    }
    return format(date, 'LLL');
  }

  if (daysAmount >= 4) {
    if (checkedDate === currentDate && index === timeAxisTicks) {
      //set last tick to current time
      const currentValue = date.getHours() === 0 ? date : new Date();
      return echarts.time.format(currentValue, `${dateFormat} {HH}:{mm}`, false);
    }
    return echarts.time.format(date, dateFormat, false);
  } else {
    return echarts.time.format(date, `${dateFormat} {HH}:{mm}`, false);
  }
};

export const getTimeAxisTicks = (width: number) => (width < md ? 4 : 6);

export const getMonth = (x: number) => {
  if (x < 0) return;
  if (x > 12) return;
  return months[x];
};

const months = [
  t('utils:months.January'),
  t('utils:months.February'),
  t('utils:months.March'),
  t('utils:months.April'),
  t('utils:months.May'),
  t('utils:months.June'),
  t('utils:months.July'),
  t('utils:months.August'),
  t('utils:months.September'),
  t('utils:months.October'),
  t('utils:months.November'),
  t('utils:months.December'),
];
