import Konva from 'konva';
import React, {
  useEffect,
  useRef,
  useState,
  Dispatch,
  DragEvent as ReactDragEvent,
  MutableRefObject,
  SetStateAction,
} from 'react';
import 'konva/lib/shapes/Text';
import 'konva/lib/shapes/Label';
import 'konva/lib/shapes/Image';

import { CanvasToolboxes, MainStage, MainStageProps } from 'components/CanvasStage/components';
import { ImageSize, StageState } from 'components/CanvasStage/types';
import { useWindowSize } from 'utils/hooks';
import { truncateNumber } from 'utils/numbers';

export const MIN_SCALE = 0.01;
export const MAX_SCALE = 4.0;
export const SCALE_STEPS = 1.05;
export const PERFECT_DRAW_ENABLED = false;

export const CanvasStage: React.FC<
  {
    stageHeight?: number;
    imageSize: ImageSize;
    setImageSize: Dispatch<SetStateAction<ImageSize>>;
    backgroundImage: HTMLImageElement | undefined;
    onClick?: ({ evt, target }: Konva.KonvaEventObject<MouseEvent>) => void;
    onDrop?: (
      {
        xFraction,
        yFraction,
      }: {
        xFraction: number;
        yFraction: number;
      },
      e: ReactDragEvent<HTMLDivElement>,
    ) => Promise<void>;
    enableToolbox?: boolean;
    topToolboxButtons?: JSX.Element;
    bottomToolboxButtons?: JSX.Element;
    onDragEnd?: ({ target }: Konva.KonvaEventObject<DragEvent>) => void;
    onDragStart?: ({ target }: Konva.KonvaEventObject<DragEvent>) => void;
  } & Pick<
    MainStageProps,
    'enableWheelScrolling' | 'enableDragging' | 'imageConfigs' | 'labelConfigs'
  >
> = ({
  stageHeight = 500,
  imageSize,
  setImageSize,
  backgroundImage,
  onDrop,
  onClick,
  enableToolbox = false,
  topToolboxButtons,
  bottomToolboxButtons,
  onDragEnd,
  onDragStart,
  ...props
}) => {
  const stageRef = useRef<Konva.Stage>() as MutableRefObject<Konva.Stage> | undefined;
  const divRef = useRef<HTMLDivElement>(null);

  const [stageState, setStageState] = useState<StageState>({
    scale: MIN_SCALE,
    x: 0,
    y: 0,
  });
  const [grayscale, setGrayscale] = useState(false);

  const [windowWidth] = useWindowSize();

  // Set width of canvas to match parent div
  useEffect(() => {
    const div = divRef?.current;
    const stage = stageRef?.current;
    if (!div || !stage) return;
    const rect = div.getBoundingClientRect();
    stage.width(rect.width - 2); // The 2 is from the 1px border in both sides
  }, [divRef, stageRef, windowWidth]);

  // Get size of image
  useEffect(() => {
    const width = backgroundImage?.width || 0;
    const height = backgroundImage?.height || 0;

    setImageSize({
      width,
      height,
    });
  }, [backgroundImage, setImageSize]);

  useEffect(() => {
    const stage = stageRef?.current;
    if (!stage || imageSize.height === 0 || imageSize.width === 0) return;

    // Set initial position to be centered and maxed zoomed out
    const stageWidth = stage.width();
    const stageHeight = stage.height();

    // Calculate scale that matches the best fit for one of the sides
    const scaleWidth = stageWidth / imageSize.width;
    const scaleHeight = stageHeight / imageSize.height;
    const scaleMin = Math.min(scaleWidth, scaleHeight);
    const scale = truncateNumber(scaleMin, MIN_SCALE, MAX_SCALE);

    setStageState({
      scale,
      x: -(imageSize.width * scale - stageWidth) / 2.0,
      y: -(imageSize.height * scale - stageHeight) / 2.0,
    });
  }, [stageRef, imageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleOnDrop = async (e: ReactDragEvent<HTMLDivElement>) => {
    e.preventDefault();

    if (!stageRef || !stageRef.current) return;

    stageRef.current.setPointersPositions(e);
    const pointerPosition = stageRef.current.getPointerPosition();
    if (!pointerPosition) return;

    pointerPosition.x -= stageState.x;
    pointerPosition.y -= stageState.y;

    pointerPosition.x /= stageState.scale;
    pointerPosition.y /= stageState.scale;

    // Do not allow position outside boundary of image
    pointerPosition.x = truncateNumber(pointerPosition.x, 0, imageSize.width);
    pointerPosition.y = truncateNumber(pointerPosition.y, 0, imageSize.height);

    // Compute fractions for backend
    const xFraction = pointerPosition.x / imageSize.width;
    const yFraction = pointerPosition.y / imageSize.height;

    if (onDrop) {
      onDrop(
        {
          xFraction,
          yFraction,
        },
        e,
      );
    }
  };

  return (
    <div
      ref={divRef}
      className="relative overflow-hidden transition ease-in-out delay-300"
      onDrop={handleOnDrop}
      onDragOver={e => e.preventDefault()}
    >
      <div className="flex flex-col-reverse lg:flex-row rounded-sm max-w-full">
        {enableToolbox && (
          <CanvasToolboxes
            stageRef={stageRef}
            setStageState={setStageState}
            grayscale={grayscale}
            setGrayscale={setGrayscale}
            imageSize={imageSize}
            topButtons={topToolboxButtons}
            bottomButtons={bottomToolboxButtons}
          />
        )}

        <MainStage
          stageRef={stageRef}
          backgroundImage={backgroundImage}
          stageHeight={stageHeight}
          stageState={stageState}
          imageSize={imageSize}
          grayscale={grayscale}
          setStageState={setStageState}
          onClick={onClick}
          onDragEnd={onDragEnd}
          onDragStart={onDragStart}
          {...props}
        />
      </div>
    </div>
  );
};
