import ActivityState from 'utils/enums/ActivityState';
import HumidityState from 'utils/enums/HumidityState';
import MoistureState from 'utils/enums/MoistureState';
import MoldGrowthRiskState from 'utils/enums/MoldGrowthRiskState';
import SignalStrengthState from 'utils/enums/SignalStrengthState';
import TemperatureState from 'utils/enums/TemperatureState';

type SensorStates = {
  // id: string; Not important
  activity_state: ActivityState;
  humidity_state: HumidityState;
  mold_growth_risk_state: MoldGrowthRiskState;
  moisture_state: MoistureState;
  signal_strength_state: SignalStrengthState;
  temperature_state: TemperatureState;
  created_date: Date;
  updated_date?: Date;
  created_by: string;
  updated_by?: string;
};

export const parseSensorStates = (sensorStates?: any): SensorStates => {
  if (!sensorStates) {
    return {
      activity_state: ActivityState.INACTIVE,
      humidity_state: HumidityState.UNKNOWN,
      mold_growth_risk_state: MoldGrowthRiskState.UNKNOWN,
      moisture_state: MoistureState.UNKNOWN,
      signal_strength_state: SignalStrengthState.UNKNOWN,
      temperature_state: TemperatureState.UNKNOWN,
      created_date: new Date(),
      created_by: '<Unknown>',
    } as SensorStates;
  }

  sensorStates.created_date = new Date(sensorStates.created_date);
  if (sensorStates.updated_date) sensorStates.updated_date = new Date(sensorStates.updated_date);

  return sensorStates as SensorStates;
};

export default SensorStates;
