import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faSquirtyRoomType: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    30, // width
    24, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M12.75 13.5H9.75C9.55109 13.5 9.36032 13.421 9.21967 13.2803C9.07902 13.1397 9 12.9489 9 12.75V9.75C9 9.55109 9.07902 9.36032 9.21967 9.21967C9.36032 9.07902 9.55109 9 9.75 9H12.75C12.9489 9 13.1397 9.07902 13.2803 9.21967C13.421 9.36032 13.5 9.55109 13.5 9.75V11.49C14.0208 10.8862 14.7656 10.5 15.6 10.5H22.0191L22.3308 10.147C22.4323 10.0205 22.4915 9.86518 22.5 9.70312C22.4894 9.51562 22.4101 9.33859 22.2773 9.20578L19.5 6.75V2.25C19.5 2.05109 19.421 1.86032 19.2803 1.71967C19.1397 1.57902 18.9489 1.5 18.75 1.5H17.25C17.0511 1.5 16.8603 1.57902 16.7197 1.71967C16.579 1.86032 16.5 2.05109 16.5 2.25V4.09219L12.3516 0.418125C12.0938 0.1875 11.5992 0 11.2523 0C10.9055 0 10.4119 0.1875 10.1522 0.418125L0.224063 9.20719C0.0904841 9.33901 0.0106327 9.51575 0 9.70312C0.00809703 9.86363 0.066293 10.0176 0.166406 10.1433L1.04719 11.1422C1.18014 11.2763 1.35826 11.3562 1.54688 11.3663C1.70613 11.3583 1.85904 11.3013 1.98469 11.2031L3 10.3069V18C3 18.3978 3.15804 18.7794 3.43934 19.0607C3.72064 19.342 4.10218 19.5 4.5 19.5H12.75V13.5ZM29.4998 21H27.75V13.5C27.75 12.6717 27.1458 12 26.4 12H15.6C14.8542 12 14.25 12.6717 14.25 13.5V21H12.5002C12.3675 21 12.2403 21.0527 12.1465 21.1465C12.0527 21.2403 12 21.3675 12 21.5002V21.9998C12.0018 22.5292 12.2127 23.0364 12.5867 23.411C12.9607 23.7856 13.4675 23.9973 13.9969 24H28.0031C28.5325 23.9973 29.0393 23.7856 29.4133 23.411C29.7873 23.0364 29.9982 22.5292 30 21.9998V21.5002C30 21.3675 29.9473 21.2403 29.8535 21.1465C29.7597 21.0527 29.6325 21 29.4998 21ZM25.5 21H16.5V14.25H25.5V21Z',
  ],
};

export default faSquirtyRoomType;
