import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import NoReportsIcon from 'images/reports-icon.png';

export const NoReports: React.FC<{ setShowModal: Dispatch<SetStateAction<boolean>> }> = ({
  setShowModal,
}) => {
  const { t } = useTranslation('components');
  return (
    <div className="p-5 bg-brand-gray-light-4 text-center rounded-xl">
      <h1 className="text-3xl font-semibold text-brand-gray-light-1 text-center mb-5">
        {t('cards.ReportsCard.noReportsText')}
      </h1>
      <p>{t('cards.ReportsCard.noReportsText1')}</p>
      <p>{t('cards.ReportsCard.noReportsText2')}</p>

      <img
        className="mx-auto max-h-36 w-auto my-10"
        src={NoReportsIcon}
        alt={t('cards.ReportsCard.noReports')}
      />

      <Button className="bg-brand-blue mx-auto" onClick={() => setShowModal(true)}>
        {t('cards.ReportsCard.addReportText')}
      </Button>
    </div>
  );
};
