import { useTranslation } from 'react-i18next';

import { Badge } from 'components';
import { OptionGroupType } from 'components/inputs/components/MultiSelect/types';

type FormatGroupLabelProps = {
  data: OptionGroupType;
  allOptionsCount: number;
};

export const FormatGroupLabel: React.FC<FormatGroupLabelProps> = ({ data, allOptionsCount }) => {
  const { t } = useTranslation('components');

  return (
    <div className="flex items-center justify-between pb-2">
      <span>{data.label}</span>
      <Badge className="relative -top-1 text-white bg-brand-gray-light-1" size="sm">
        {data.label === t('inputs.helpers.MultiSelect.selectAll')
          ? `${allOptionsCount}`
          : `${data.options.length}`}
      </Badge>
    </div>
  );
};
