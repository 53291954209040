import isEqual from 'lodash/isEqual';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, MultiValue, MenuPlacement, createFilter } from 'react-select';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { CustomSelectInput } from 'components/inputs/components';
import {
  CustomMultiValue,
  FormatGroupLabel,
  MultiValueLabel,
  Option,
} from 'components/inputs/components/MultiSelect/components';
import { OptionGroupType, OptionType } from 'components/inputs/components/MultiSelect/types';

interface MultiSelectProps {
  ref?: React.Ref<any>;
  value: MultiValue<OptionType>;
  options: OptionGroupType[];
  onBlur?: (ids: string[]) => Promise<void>;
  onChange: (newValue: MultiValue<OptionType>) => void;
  onSelect?: (id: string, ids: string[]) => void;
  onDelete?: (id: string, ids: string[]) => void;
  initialSelectedIds?: string[];
  disabled?: boolean;
  menuPlacement?: MenuPlacement;
  isPending?: boolean;
  inputId: string;
  placeholderLoading: string;
  placeholderLoaded: string;
  allItemsLabel: string;
  allPossibleItemsLabel?: string;
}

const SELECT_ALL_VALUE = '<SELECT_ALL>';

export const MultiSelect: React.FC<MultiSelectProps> = ({
  ref,
  value,
  options,
  onBlur,
  onChange,
  onDelete,
  onSelect,
  disabled,
  menuPlacement,
  isPending,
  inputId,
  placeholderLoading,
  placeholderLoaded,
  allItemsLabel,
  allPossibleItemsLabel,
}) => {
  const { t } = useTranslation('components');

  const [inputValue, setInputValue] = useState('');

  const valueValues = value.map(x => x.value);

  const optionsNotDisabledFlat = options
    .map(({ options, ...option }) => ({
      options: options.filter(x => !x.isDisabled),
      ...option,
    }))
    .flatMap(group => group.options);
  const optionsDisabledFlat = options
    .map(({ options, ...option }) => ({
      options: options.filter(x => x.isDisabled),
      ...option,
    }))
    .flatMap(group => group.options);

  const selectAllOption: OptionType = {
    value: SELECT_ALL_VALUE,
    label:
      allPossibleItemsLabel && optionsDisabledFlat.length > 0
        ? allPossibleItemsLabel
        : allItemsLabel,
  };

  const optionsDisabledButSelectedFlat = optionsDisabledFlat.filter(option =>
    valueValues.includes(option.value),
  );
  // console.log('optionsDisabledButSelectedFlat', optionsDisabledButSelectedFlat);
  const optionsCount = options.map(option => option.options.length).reduce((a, b) => a + b, 0);
  const isSelectAllSelected = (): boolean =>
    sameOptionsSelected(
      [...value, selectAllOption],
      [...optionsNotDisabledFlat, ...optionsDisabledButSelectedFlat, selectAllOption],
    );

  const isOptionSelected = (option: OptionType): boolean =>
    valueValues.some(value => value === option.value);

  const getOptions = (): OptionGroupType[] =>
    optionsCount === 0
      ? []
      : [
          {
            label: t('inputs.helpers.MultiSelect.selectAll'),
            options: [selectAllOption],
          },
          ...options,
        ];

  const getValue = (): MultiValue<OptionType> =>
    optionsCount === 0 ? [] : isSelectAllSelected() ? [selectAllOption] : value;

  const onAction = (
    _selectedOptions: MultiValue<OptionType>,
    { action, option, removedValue }: ActionMeta<OptionType>,
  ) => {
    if (!_selectedOptions) _selectedOptions = [];
    // Make sure the select all option is not passed outside this component
    _selectedOptions = _selectedOptions.filter(option => option.value !== SELECT_ALL_VALUE);

    onChange(_selectedOptions);

    const selectedIds = _selectedOptions.map(option => option.value);

    if (action === 'select-option' && onSelect) {
      onSelect(option?.value!, selectedIds);
    } else if (action === 'remove-value' && onDelete) {
      onDelete(removedValue?.value!, selectedIds);
    } else if (action === 'deselect-option' && onDelete) {
      onDelete(option?.value!, selectedIds);
    }
  };

  const handleChange = (
    newValue: MultiValue<OptionType>,
    actionMeta: ActionMeta<OptionType>,
  ): void => {
    const { action, option, removedValue } = actionMeta;

    if (action === 'select-option' && option?.value === selectAllOption.value) {
      onAction(
        [...optionsNotDisabledFlat, ...optionsDisabledButSelectedFlat, selectAllOption],
        actionMeta,
      );
    } else if (
      (action === 'deselect-option' && option?.value === selectAllOption.value) ||
      (action === 'remove-value' && removedValue?.value === selectAllOption.value)
    ) {
      onAction(optionsDisabledButSelectedFlat, actionMeta);
    } else if (actionMeta.action === 'deselect-option' && isSelectAllSelected()) {
      onAction(
        options.flatMap(group => group.options).filter(({ value }) => value !== option?.value),
        actionMeta,
      );
    } else {
      onAction(
        newValue.map((item: OptionType) => item as OptionType),
        actionMeta,
      );
    }
  };

  const handleBlur = async () => {
    if (onBlur) {
      const values = getValue();
      const selectedIds =
        values && values.length > 0
          ? values[0].value === SELECT_ALL_VALUE
            ? [...optionsNotDisabledFlat, ...optionsDisabledButSelectedFlat].map(
                option => option.value,
              )
            : values.map(option => option.value)
          : [];
      await onBlur(selectedIds);
    }
  };

  return (
    <>
      <Select<OptionType, true, OptionGroupType>
        ref={ref}
        isOptionSelected={isOptionSelected}
        menuPortalTarget={document.body}
        menuPosition="fixed"
        inputValue={inputValue}
        onInputChange={(value, action) => {
          if (action.action === 'input-change') {
            setInputValue(value);
          }
        }}
        inputId={inputId}
        isLoading={isPending}
        styles={{
          input: provided => ({
            ...provided,
            boxShadow: 'none',
            zIndex: 1000,
          }),
          menuPortal: provided => ({ ...provided, zIndex: 1000 }),
          multiValue: provided => ({
            ...provided,
            maxWidth: '100%',
          }),
          multiValueLabel: (provided, option) => ({
            ...provided,
            // Add extra padding in right side for disabled options without the "x" button
            paddingRight: option.data.isDisabled ? 6 : 3,
          }),
          multiValueRemove: (provided, option) => ({
            ...provided,
            // Hide remove button for disabled options
            display: option.data.isDisabled ? 'none' : 'flex',
          }),
        }}
        value={getValue()}
        formatGroupLabel={data => <FormatGroupLabel data={data} allOptionsCount={optionsCount} />}
        onChange={handleChange}
        onBlur={handleBlur}
        options={getOptions()}
        placeholder={isPending ? placeholderLoading : placeholderLoaded}
        components={{
          Input: CustomSelectInput,
          Option,
          MultiValueLabel,
          MultiValue: CustomMultiValue,
        }}
        closeMenuOnSelect={false}
        maxMenuHeight={400}
        minMenuHeight={250}
        menuPlacement={menuPlacement}
        isMulti
        isDisabled={disabled}
        isClearable={false}
        backspaceRemovesValue={false}
        hideSelectedOptions={false}
        isSearchable
        menuShouldScrollIntoView
        openMenuOnClick
        filterOption={createFilter({
          ignoreCase: true,
          trim: true,
          ignoreAccents: false,
          stringify: option =>
            option.data.description
              ? `${option.label} ${option.data.description}`
              : `${option.label}}`,
        })}
        isOptionDisabled={option => !!option.isDisabled}
      />

      <ReactTooltip
        id="multi-select-tooltip"
        style={{ zIndex: 10000 }}
        aria-haspopup="true"
        place="top"
        data-tooltip-position-strategy="fixed"
      />
    </>
  );
};

const sameOptionsSelected = (
  options1: MultiValue<OptionType>,
  options2: MultiValue<OptionType>,
): boolean =>
  isEqual(
    new Set(options1.map(option => option.value)),
    new Set(options2.map(option => option.value)),
  );
