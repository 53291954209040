import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import { useMemo, useRef } from 'react';

import api from 'utils/api';
import AccessLink, { AccessLinkUpdate, parseAccessLink } from 'utils/types/AccessLink';
import HookOptions from 'utils/types/HookOptions';

export const useUserCustomerAccessLinks = (userId?: string, { enableGet }: HookOptions = {}) => {
  const queryKey = ['userCustomerAccessLinks', userId];

  const queryClient = useQueryClient();

  const previousAccessLinksRef = useRef<AccessLink[] | undefined>();

  const { data: fetchedAccessLinks, isPending } = useQuery<AccessLink[]>({
    queryKey,
    queryFn: () => api.get(`/users/${userId}/access_links`),
    select: (accessLinksRaw: any[]) => accessLinksRaw.map(parseAccessLink),
    enabled: enableGet && !!userId,
    refetchInterval: 5000,
  });

  const accessLinks = useMemo(() => {
    if (!isEqual(previousAccessLinksRef.current, fetchedAccessLinks)) {
      previousAccessLinksRef.current = fetchedAccessLinks;
      return fetchedAccessLinks || [];
    }
    return previousAccessLinksRef.current;
  }, [fetchedAccessLinks]);

  const { mutateAsync: updateAccessLink } = useMutation({
    mutationFn: async ({ role, customer_id }: AccessLinkUpdate): Promise<AccessLink[]> =>
      api.post(`/customers/${customer_id}/access_links`, {
        role,
        user_id: userId,
      }),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey,
      });
      await queryClient.invalidateQueries({
        queryKey: ['permissions'],
      });
    },
  });

  return {
    updateAccessLink,
    accessLinks,
    isPending,
  };
};
