export enum SensorTagRoomType {
  TechnicalFacilitiesRoom = 10,
  WetRoom = 20,
  Kitchen = 30,
  Basement = 40,
  Bedroom = 50,
  Office = 60,

  // Custom
  NotRelevant = 100,
  Other = 110,
}
