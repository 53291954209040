import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useState, HTMLAttributes } from 'react';

const EditOnHoverText: React.FC<
  { text?: string } & Omit<HTMLAttributes<HTMLSpanElement>, 'onMouseEnter' | 'onMouseLeave'>
> = ({ text = '', className, ...props }) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <span
      className={classNames('cursor-pointer', className)}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      {...props}
    >
      {isHovering ? text.slice(0, -3) : text}
      {isHovering && (
        <span>
          ...
          <FontAwesomeIcon icon={faPen} />
        </span>
      )}
    </span>
  );
};

export default EditOnHoverText;
