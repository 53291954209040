import classNames from 'classnames';
import { components, GroupBase, ControlProps } from 'react-select';

export const CustomSelectControl = <
  Option extends unknown,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  className,
  isFocused,
  ...props
}: ControlProps<Option, IsMulti, Group>) => (
  <components.Control
    className={classNames(
      className,
      'disabled:cursor-not-allowed disabled:bg-brand-gray-light-4 placeholder:font-light placeholder:text-brand-gray-light-3 rounded bg-white border-2 text-xs lg:text-base w-full text-brand-gray',
      {
        'border-blue': isFocused,
        'border-brand-gray-light-3': !isFocused,
      },
    )}
    isFocused={isFocused}
    {...props}
  />
);
