import { forwardRef, useMemo, MutableRefObject } from 'react';

import { CustomerSelect } from 'components';
import { useCurrentUserCustomers } from 'utils/hooks/data';
import Customer from 'utils/types/Customer';

interface CustomerSelectInputProps {
  onSelect: (customer: Customer, { clear }: { clear: () => void }) => void;
  onClear?: () => void;
  label?: string | JSX.Element;
  initialSelectedCustomerId?: string;
  excludeCustomerIds?: string[];
  className?: string;
  _ref?: React.Ref<HTMLInputElement> | MutableRefObject<HTMLElement | undefined>;
}

export const CustomerSelectInput = forwardRef<any, CustomerSelectInputProps>(
  ({ onSelect, onClear, label, initialSelectedCustomerId, excludeCustomerIds, className }, ref) => {
    const { customers, isPending } = useCurrentUserCustomers();

    return (
      <>
        {label && <label htmlFor="ws-customer-select-input">{label}</label>}
        <CustomerSelect
          name="ws-customer-select-input"
          isPending={isPending}
          className={className}
          onSelect={onSelect}
          onClear={onClear}
          customersToSelect={useMemo(
            () => customers?.filter(customer => !excludeCustomerIds?.includes(customer.id)),
            [customers, excludeCustomerIds],
          )}
          initialSelectedCustomer={useMemo(
            () => customers?.find(customer => customer.id === initialSelectedCustomerId),
            [customers, initialSelectedCustomerId],
          )}
          ref={ref}
        />
      </>
    );
  },
);
