import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const AddButton = ({
  disabled = false,
  onAddSubgroup,
}: {
  disabled: boolean;
  onAddSubgroup: () => Promise<void>;
}) => {
  const { t } = useTranslation('components');

  return (
    <button
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();

        if (!disabled) {
          onAddSubgroup();
        }
      }}
      className={classNames('cursor-pointer', {
        'text-brand-gray-light-3 cursor-not-allowed': disabled,
        'text-brand-gray': !disabled,
      })}
      data-tooltip-content={
        disabled
          ? t('GroupHierarchy.GroupHierarchyNode.AddButton.limitReached')
          : t('GroupHierarchy.GroupHierarchyNode.AddButton.text')
      }
      data-tooltip-id="route-tooltip"
    >
      <FontAwesomeIcon icon={faPlus} />
    </button>
  );
};
