import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { t } from 'translations';
import debounce from 'utils/debounce';

const DefaultToast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000,
});

export const notificationSuccess = debounce(
  (message: string) =>
    DefaultToast.fire({
      icon: 'success',
      title: message,
    }),
  250
);

export const MySwal = withReactContent(Swal);

export const notificationError = debounce(
  (message: string) =>
    DefaultToast.fire({
      icon: 'error',
      title: message || t('utils:notifications.notificationError.title'),
    }),
  250
);

export const notificationWarningConfirm = ({
  text,
  title,
  cancelButtonText,
  confirmButtonText,
}: {
  text?: string;
  title?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
} = {}): Promise<boolean> =>
  new Promise((resolve, reject) => {
    Swal.fire({
      title: title || t('utils:notifications.notificationWarningConfirm.title'),
      text: text || t('utils:notifications.notificationWarningConfirm.text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#E43030',
      cancelButtonColor: '#BCBCBC',
      cancelButtonText:
        cancelButtonText || t('utils:notifications.notificationWarningConfirm.cancelButtonText'),
      confirmButtonText:
        confirmButtonText || t('utils:notifications.notificationWarningConfirm.confirmButtonText'),
    }).then(result => {
      resolve(!!result.value);
    });
  });
