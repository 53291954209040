import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useSensorGroup } from 'utils/hooks/data/sensor-groups/useSensorGroup';
import HookOptions from 'utils/types/HookOptions';

export const useGroupDiagnostics = (sensorGroupId?: string, { enableGet }: HookOptions = {}) => {
  const queryKey = ['useSensorGroupUsers', sensorGroupId];

  // Get diagnostics
  const { data, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/diagnostics`),
    select: (data: any) => ({
      sensorIdsFarFromOtherSensors: data.sensor_ids_far_from_other_sensors as string[],
      sensorsDistanceToGroupKm: data.sensors_distance_to_group_km as {
        [key: string]: number | null;
      },
      gatewayIdsTooFarFromSimLocation: data.gateway_ids_too_far_from_sim_location as string[],
      gatewayIdsOfflineInTtn: data.gateway_ids_offline_in_ttn as string[],
      gatewayIdsSimOffline: data.gateway_ids_sim_offline as string[],
      gatewayIdsWithoutHeartbeatsPast24h: data.gateway_ids_without_heartbeats_past_24h as string[],
    }),
    enabled: enableGet && !!sensorGroupId,
  });

  // We need the sensor group to check if the location is set
  const { sensorGroup } = useSensorGroup(sensorGroupId, { enableGet });

  const everythingIsOkay =
    (data &&
      !data.sensorIdsFarFromOtherSensors &&
      !data.gatewayIdsTooFarFromSimLocation &&
      !data.gatewayIdsOfflineInTtn &&
      !data.gatewayIdsSimOffline &&
      !data.gatewayIdsWithoutHeartbeatsPast24h) ||
    (data &&
      data.sensorIdsFarFromOtherSensors &&
      data.sensorIdsFarFromOtherSensors.length === 0 &&
      data.gatewayIdsTooFarFromSimLocation &&
      data.gatewayIdsTooFarFromSimLocation.length === 0 &&
      data.gatewayIdsOfflineInTtn &&
      data.gatewayIdsOfflineInTtn.length === 0 &&
      data.gatewayIdsSimOffline &&
      data.gatewayIdsSimOffline.length === 0 &&
      data.gatewayIdsWithoutHeartbeatsPast24h &&
      data.gatewayIdsWithoutHeartbeatsPast24h.length === 0 &&
      !!sensorGroup?.geographic_location?.latitude &&
      !!sensorGroup?.geographic_location?.longitude);

  return {
    isPending,
    everythingIsOkay,
    ...data,
  };
};
