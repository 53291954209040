import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { CustomerSelect } from 'components';
import Modal from 'components/modals/Modal';
import Customer from 'utils/types/Customer';

const CustomerSelectModal: React.FC<
  React.PropsWithChildren<{
    show: boolean;
    setShow: Dispatch<boolean>;
    onSelect: (customer?: Customer) => void;
    initialCustomer?: Customer;
    customers?: Customer[];
    showAllOption?: boolean;
    isPending?: boolean;
    title?: string;
    className?: string;
  }>
> = ({
  show,
  setShow,
  onSelect,
  initialCustomer,
  customers,
  showAllOption,
  isPending,
  title,
  className,
  children,
}) => {
  const { t } = useTranslation('components');

  return (
    <Modal title={title || t('modals.CustomerSelectModal')} show={show} setShow={setShow} size="sm">
      <CustomerSelect
        className={className}
        onSelect={onSelect}
        customersToSelect={customers}
        initialSelectedCustomer={initialCustomer}
        showAllOption={showAllOption}
        isPending={isPending}
      />
      {children}
    </Modal>
  );
};

export default CustomerSelectModal;
