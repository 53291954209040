import { MouseEventHandler } from 'react';

import {
  SliderInput,
  SliderLabel,
} from 'components/inputs/SliderSwitch/components/SliderOption/componets';

export const SliderOption = ({
  id,
  label,
  isSelected,
  onClick,
}: {
  id: string;
  label: string;
  isSelected: boolean;
  onClick: MouseEventHandler<HTMLInputElement>;
}) => (
  <>
    <SliderInput onClick={onClick} id={id} />
    <SliderLabel id={id} label={label} isSelected={isSelected} />
  </>
);
