import { faSignal } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const HeartbeatStatus: React.FC<
  {
    online?: boolean;
    countsPast24h?: number;
    classNameText?: string;
  } & Omit<FontAwesomeIconProps, 'icon'>
> = ({ online, countsPast24h, className, classNameText, ...props }) => {
  const { t } = useTranslation('components');

  // Avoid generating a "x_plural" translation key
  const count = countsPast24h?.toString();

  return (
    <div className="flex">
      <FontAwesomeIcon
        className={classNames('mr-2', className, {
          'text-brand-orange': !online,
          'text-brand-green-light-1': online,
        })}
        icon={faSignal}
        data-tooltip-content={
          online ? t('signals.HeartbeatStatus.dataTip.1') : t('signals.HeartbeatStatus.dataTip.2')
        }
        data-tooltip-id="route-tooltip"
        {...props}
      />
      {!!count && (
        <p
          className={classNameText}
          data-tooltip-content={t('signals.HeartbeatStatus.dataTip.3', { x: count })}
          data-tooltip-id="route-tooltip"
        >
          {countsPast24h}/24
        </p>
      )}
    </div>
  );
};

export default HeartbeatStatus;
