import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export type BadgeProps = { size?: 'sm' | 'md' | 'xs' } & HTMLAttributes<HTMLSpanElement>;

export const Badge: React.FC<BadgeProps> = ({ children, className, size = 'sm', ...props }) => (
  <span
    className={classNames(className, 'rounded-full inline-block leading-none', {
      'py-2 px-3 text-xs': size === 'md',
      'py-1 px-2 text-[10px] lg:text-xs': size === 'sm',
      'py-1 px-[6px] text-[9px] text-xs': size === 'xs',
    })}
    {...props}
  >
    {children}
  </span>
);
