import React from 'react';

import DemoApplication from 'utils/types/DemoApplication';

export type ContextProps = {
  demoApplication?: DemoApplication;
  demoApplicationHasBeenLoaded: boolean;
};

export const CurrentDemoApplicationSessionContext = React.createContext<ContextProps>(
  {} as ContextProps,
);
export const CurrentDemoApplicationSessionProvider = CurrentDemoApplicationSessionContext.Provider;
