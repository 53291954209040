import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useRouteError } from 'react-router-dom';

import { Alert, Button } from 'components';

const ErrorFallback: React.FC<{
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}> = ({ error, resetErrorBoundary }) => {
  const { t } = useTranslation('components');

  //taken from https://mitchgavan.com/code-splitting-react-safely/
  const setWithExpiry = (key: string, value: string, ttl: number) => {
    const item = {
      value,
      expiry: new Date().getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  };

  const getWithExpiry = (key: string) => {
    const itemString = window.localStorage.getItem(key);
    if (!itemString) return null;

    const item = JSON.parse(itemString);
    const isExpired = new Date().getTime() > item.expiry;

    if (isExpired) {
      localStorage.removeItem(key);
      return null;
    }

    return item.value;
  };

  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry('chunk_failed')) {
        setWithExpiry('chunk_failed', 'true', 10000);
        window.location.reload();
      }
    }
  }, [error]);

  return (
    <Alert className="p-14 text-center" variant="danger">
      <h1 className="text-white mb-3">
        {t('ErrorFallback.somethingWentWrong')}: {error.name}
      </h1>
      <pre className="mb-3 text-white">{error.message}</pre>

      <div className="mb-6">
        <Button variant="primary" onClick={resetErrorBoundary}>
          {t('ErrorFallback.tryAgain')}
        </Button>{' '}
        <Link to="/">
          <Button variant="outline-info">{t('ErrorFallback.goBack')}</Button>
        </Link>
      </div>

      <p className="text-xs text-white">
        {t('ErrorFallback.problemsText')}{' '}
        <a className="underline text-blue-500" href="mailto:support@tector.com">
          support@tector.com
        </a>
      </p>
    </Alert>
  );
};

export const ErrorElement = () => {
  const error = useRouteError() as Error;
  return (
    <ErrorFallback
      error={error}
      resetErrorBoundary={() => {
        window.location.reload();
      }}
    />
  );
};

export default ErrorFallback;
