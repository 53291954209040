import React, { useMemo, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Dropdown, DropdownItem } from 'components';
import ProgressIndicator from 'components/ProgressIndicator';
import ReportGenerationStep from 'utils/enums/ReportGenerationStep';
import { useOnClickOutside } from 'utils/hooks';
import ReportRevision from 'utils/types/ReportRevision';

export const ReportRevisionDropdown: React.FC<{
  onSelect: (reportRevision: ReportRevision) => void;
  reportRevisionIdSelected?: string;
  onAddNew: () => void;
  reportRevisions?: ReportRevision[];
}> = ({ onSelect, reportRevisionIdSelected, onAddNew, reportRevisions = [] }) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);

  const { t } = useTranslation('components');
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  useOnClickOutside(ref, setIsOpen);

  const selectedRevisionsIdx = reportRevisionIdSelected
    ? reportRevisions.findIndex(x => x.id === reportRevisionIdSelected)
    : null;

  const revisionLabel = (idx: number) => (
    <>
      {t('dropdowns.ReportRevisionDropdown.DropdownItem.name')} #{idx + 1}
    </>
  );

  return (
    <Dropdown
      ref={ref}
      buttonText={
        typeof selectedRevisionsIdx === 'number' && selectedRevisionsIdx >= 0
          ? revisionLabel(selectedRevisionsIdx)
          : t('dropdowns.ReportRevisionDropdown.Toggle')
      }
      open={isOpen}
      setisOpen={setIsOpen}
    >
      {reportRevisions.map((reportRevision, i) => (
        <DropdownItem
          className="cursor-pointer"
          onClick={() => {
            onSelect(reportRevision);
            setIsOpen(false);
          }}
          id={i}
          key={i}
          controlId={controlId}
        >
          {revisionLabel(i)}
          {reportRevision.generation_step !== ReportGenerationStep.FINISHED && (
            <ProgressIndicator
              inProgress={!reportRevision.failed_reason}
              failed={!!reportRevision.failed_reason}
              dataTipProgress={t('dropdowns.ReportRevisionDropdown.spinner.dataTip')}
              dataTipFailed={t('dropdowns.ReportRevisionDropdown.failedReason.dataTip')}
            />
          )}
        </DropdownItem>
      ))}
      {onAddNew && (
        <DropdownItem className="cursor-pointer" onClick={onAddNew} id={-1} controlId={controlId}>
          {t('dropdowns.ReportRevisionDropdown.DropdownItem.addNew')}
        </DropdownItem>
      )}
    </Dropdown>
  );
};
