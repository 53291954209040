import 'styles/components/_add-button.scss';

export const AddButton: React.FC<{
  onClick: (event?: React.MouseEvent<HTMLSpanElement>) => void;
  tooltipText?: string;
}> = ({ onClick, tooltipText }) => (
  <span
    className="ws-add-button cursor-pointer bg-white hover:bg-brand-gray-light-4 transition-colors duration-500 bottom-24 fixed right-4 text-brand-blue z-10 text-center block"
    data-tooltip-content={tooltipText}
    data-tooltip-id="route-tooltip"
    onClick={e => {
      e.preventDefault();
      e.stopPropagation();
      onClick(e);
    }}
  >
    +
  </span>
);
