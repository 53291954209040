import classNames from 'classnames';
import { useState } from 'react';

import {
  SensorsTab,
  SensorsDataTabOptions,
} from 'components/plots/insights_plots/sensors/SensorsTab';

export const SensorsDataTabs: React.FC<{
  startDate: Date;
  endDate: Date;
  measureType: string;
  tagType: string;
}> = ({ startDate, endDate, measureType, tagType }) => {
  const [currentTab, setCurrentTab] = useState<SensorsDataTabOptions>('Sensors');

  return (
    <>
      <div className="grid grid-cols-3 w-ful h-11 divide-x border-x border-t rounded-t">
        <SensorDataTabButton title="Sensors" currentTab={currentTab} onClick={setCurrentTab} />
        <SensorDataTabButton title="Groups" currentTab={currentTab} onClick={setCurrentTab} />
        <SensorDataTabButton title="Customers" currentTab={currentTab} onClick={setCurrentTab} />
      </div>
      <div className="m-4">
        <SensorsTab
          currentTab={currentTab}
          startDate={startDate}
          endDate={endDate}
          measureType={measureType}
          tagType={tagType}
        />
      </div>
    </>
  );
};

interface SensorDataTabProps {
  title: string;
  currentTab: string;
  onClick: any;
}

const SensorDataTabButton: React.FC<SensorDataTabProps> = ({ title, currentTab, onClick }) => (
  <button
    className={classNames('flex w-full justify-center items-center', {
      'border-b bg-slate-100 hover:bg-slate-50': currentTab !== title,
    })}
    onClick={() => onClick(title)}
  >
    {title}
  </button>
);
