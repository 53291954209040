import { useMutation } from '@tanstack/react-query';

import { DeliveryNotesReportFormValues } from 'components/forms/DeliveryNotesReportForm';
import api from 'utils/api';

export const useAdminDeliveryNotesReports = () => {
  const { mutateAsync: createDeliveryNotesReport } = useMutation({
    mutationFn: async (deliveryNotesReportFormValues: DeliveryNotesReportFormValues) =>
      api.post('/admin/delivery_notes_reports', deliveryNotesReportFormValues),
  });

  return {
    createDeliveryNotesReport,
  };
};
