import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseSensorGroup } from 'utils/types/SensorGroup';

export const useSensorSensorGroups = (sensorId?: string) => {
  const queryKey = ['sensorSensorGroups', sensorId];

  // Sensor Notes - Get
  const { data: sensorGroups, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensors/${sensorId}/sensor_groups`),
    select: (data: any[]) => data.map(parseSensorGroup),
    enabled: !!sensorId,
  });

  return {
    sensorGroups,
    isPending,
  };
};
