import { FeatureFlagValidationError } from 'utils/isFeatureEnabled/exceptions';
import { IFeatureFlagJson, LoadFeatureFlagsFunction } from 'utils/isFeatureEnabled/types';

export const loadFeatureFlagsJson = () => require('feature-flags.json') as IFeatureFlagJson;

export const getFeatureFlags = (
  loadFeatureFlags: LoadFeatureFlagsFunction = loadFeatureFlagsJson,
) => {
  const flagsJson = loadFeatureFlags();
  const expectedNumberOfKeys = 3;

  for (const [key, flag] of Object.entries(flagsJson)) {
    //Make sure we have don't have etra keys on the flag
    if (Object.keys(flag).length > expectedNumberOfKeys) {
      throw new FeatureFlagValidationError(key, 'flags');
    }
    // Make sure the required keys exist
    if (flag.locally === undefined || flag.locally === null)
      throw new FeatureFlagValidationError(key, 'locally');
    if (flag.development === undefined || flag.development === null)
      throw new FeatureFlagValidationError(key, 'development');
    if (flag.production === undefined || flag.production === null)
      throw new FeatureFlagValidationError(key, 'production');
    // Make sure we have booleans
    if (typeof flag.locally !== 'boolean') throw new FeatureFlagValidationError(key, 'locally');
    if (typeof flag.development !== 'boolean')
      throw new FeatureFlagValidationError(key, 'development');
    if (typeof flag.production !== 'boolean')
      throw new FeatureFlagValidationError(key, 'production');
  }

  return flagsJson;
};
