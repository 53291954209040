import {
  SensorTagCasing,
  SensorTagClimate,
  SensorTagComplementaryElement,
  SensorTagConstructionPhase,
  SensorTagConstructionPrinciples,
  SensorTagInsulation,
  SensorTagInsulationType,
  SensorTagOrientation,
  SensorTagPrimaryElementCategory,
  SensorTagPrimaryElementType,
  SensorTagRoomType,
  SensorTagScrewType,
  SensorTagUsage,
  SensorTagVentilation,
  SensorTagVerticalPlacement,
} from 'utils/enums';

type PossibleSensorTags = {
  primary_element_category: SensorTagPrimaryElementCategory[];
  primary_element_type: SensorTagPrimaryElementType[];
  complementary_element: SensorTagComplementaryElement[];

  // Custom location tags
  orientation: SensorTagOrientation[];
  room_type: SensorTagRoomType[];
  vertical_placement: SensorTagVerticalPlacement[];

  // Global tags
  casing: SensorTagCasing[];
  climate: SensorTagClimate[];
  construction_phase: SensorTagConstructionPhase[];
  construction_principles: SensorTagConstructionPrinciples[];
  insulation: SensorTagInsulation[];
  insulation_type: SensorTagInsulationType[];
  screw_type: SensorTagScrewType[];
  ventilation: SensorTagVentilation[];
  usage: SensorTagUsage[];
};

export const possibleSensorTagsEmpty = {
  primary_element_category: [],
  primary_element_type: [],
  complementary_element: [],

  // Custom location tags
  orientation: [],
  room_type: [],
  vertical_placement: [],

  // Global tags
  casing: [],
  climate: [],
  construction_phase: [],
  construction_principles: [],
  insulation: [],
  insulation_type: [],
  screw_type: [],
  ventilation: [],
  usage: [],
} as PossibleSensorTags;

export default PossibleSensorTags;
