import { useMemo } from 'react';

import { useTimePeriod } from 'utils/hooks/index';

export const useOnMarkTimePeriod = () => {
  const {
    setHighlightPeriod,
    highlightPeriod: [highlightFrom, highlightTo],
  } = useTimePeriod();

  const onMarkTimePeriod = (timeFrom: Date, timeTo: Date) => {
    setHighlightPeriod([timeFrom, timeTo]);
  };

  const onResetMarkedTimePeriod = () => setHighlightPeriod([undefined, undefined]);

  const isPeriodMarked = useMemo(
    () => !!(highlightFrom && highlightTo),
    [highlightFrom, highlightTo],
  );

  return {
    onMarkTimePeriod,
    onResetMarkedTimePeriod: isPeriodMarked ? onResetMarkedTimePeriod : undefined,
    isPeriodMarked,
  };
};
