export const brushOptions = {
  toolbox: ['lineX'],
  type: 'lineX',
  throttleType: 'debounce',
  throttleDelay: 500,
};

const createOnBrushSelectedFn = (
  onMarkTimePeriod?: (timeFrom: Date, timeTo: Date) => void
): Function | undefined => {
  if (!onMarkTimePeriod) return undefined;

  return ({ batch }: { batch: any[] }, echartsInstance: any) => {
    if (batch.length === 0) return;
    const selectedBatch = batch[0];
    const area = selectedBatch.areas[0];
    if (area) {
      const width = echartsInstance?.getWidth?.();
      const options = echartsInstance.getOption();
      const xAxis = options.xAxis[0];
      const xMin = xAxis.min.getTime();
      const xMax = xAxis.max.getTime();
      const xDelta = xMax - xMin;

      const grid = options.grid[0];
      const range = area.range;
      const [_rangeFromIdx, _rangeToIdx] = range;

      const innerWidth = width - grid.left - grid.right;

      const fractionFrom = (_rangeFromIdx - grid.left) / innerWidth;
      const fractionTo = (_rangeToIdx - grid.left) / innerWidth;

      const timeFrom = new Date(xMin + xDelta * fractionFrom);
      const timeTo = new Date(xMin + xDelta * fractionTo);

      return onMarkTimePeriod(timeFrom, timeTo);
    }
  };
};

export default createOnBrushSelectedFn;
