import { Layer } from 'leaflet/index';
import { useLocation } from 'react-router-dom';
import 'leaflet/dist/leaflet.css';

import GatewayIconMarker from 'components/maps/markers/GatewayIconMarker';
import PointsMap from 'components/maps/PointsMap';
import GatewayState from 'utils/enums/GatewayState';
import Gateway from 'utils/types/Gateway';
import Marker from 'utils/types/Marker';
import Position from 'utils/types/Position';

const GatewaysMap: React.FC<{
  gateways?: Gateway[];
  initialPosition?: Position;
  initialZoom?: number;
  onClick?: () => void;
}> = ({
  gateways = [],
  initialPosition = [55.679097, 12.552729],
  initialZoom = 10,
  onClick = () => {},
}) => {
  const location = useLocation();

  // Map function
  const mapPointFn = (gateway: Gateway): Marker => ({
    id: gateway.id,
    name: gateway.name,
    position: [gateway.geographic_location?.latitude, gateway.geographic_location?.longitude],
    state: gateway.state,
  });

  // Sort function (to show active gateways on top)
  const sortPointFn = (a: Gateway, b: Gateway): number =>
    a.state === GatewayState.INACTIVE ? -1 : 1;

  // Update layer function
  // (for making sure classes are set correct on path elements on update)
  const onUpdateLayerFn = (
    layer: Layer & { getElement: () => HTMLElement; options: { className: string } },
  ) => {
    if (layer.getElement) {
      const element = layer.getElement();

      if (element?.classList && element.classList.contains('ws-gateway-map-marker')) {
        // Clear all current state classes
        Object.values(GatewayState).forEach(value => {
          element.classList.remove(value);
        });
        if (layer.options.className) {
          // Add current state class
          element.classList.add(layer.options.className);
        }
      }
    }
  };

  return (
    <PointsMap<Gateway>
      points={gateways}
      MarkerComponent={GatewayIconMarker}
      mapPointFn={mapPointFn} //@ts-ignore
      sortPointFn={sortPointFn} //@ts-ignore
      filterPointFn={gateway =>
        gateway.geographic_location?.latitude && gateway.geographic_location?.longitude
      } //@ts-ignore
      onUpdateLayerFn={onUpdateLayerFn}
      initialPosition={initialPosition}
      initialZoom={initialZoom}
      onClick={onClick}
      isFullScreen={location.pathname === '/user/gateways/map'}
    />
  );
};

export default GatewaysMap;
