import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseUser } from 'utils/types/User';

export const useGatewayUsers = (
  gatewayId: string,
  { disableErrorNotifications = false }: HookOptions = {},
) => {
  const queryKey = ['gatewayUsers', gatewayId];

  // Gateway Users - Get
  const { data: users, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/gateways/${gatewayId}/users`, {
        _disableErrorNotifications: disableErrorNotifications,
      }),
    select: (data: any[]) => data.map(parseUser),
    enabled: !!gatewayId,
  });

  return {
    users,
    isPending,
  };
};
