import { useFiles } from 'utils/hooks/data/useFiles';
import SensorFile, { parseSensorFile } from 'utils/types/SensorFile';

export const useSensorFiles = (sensorId?: string) =>
  useFiles<SensorFile>({
    queryKey: ['sensorFiles', sensorId],
    endpoint: `/sensors/${sensorId}/files`,
    enabled: !!sensorId,
    parseFn: parseSensorFile,
    invalidateQueryKeys: [
      ['sensorFiles', sensorId],
      ['sensorPrimaryFile', sensorId, false],
      ['sensorPrimaryFile', sensorId, true],
    ],
  });
