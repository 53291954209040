import { t } from 'translations';
import AlarmRuleType from 'utils/enums/AlarmRuleType';

export const getAlarmRuleTypeText = (alarmRuleType: AlarmRuleType) => {
  switch (alarmRuleType) {
    case AlarmRuleType.Custom:
      return t('utils:texts.AlarmRuleType.getAlarmRuleTypeText.Custom');
    case AlarmRuleType.BuiltIn:
      return t('utils:texts.AlarmRuleType.getAlarmRuleTypeText.BuiltIn');
    default:
      return 'n/a';
  }
};
