import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { HTMLAttributes, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SettingsDropdown } from 'components';
import { ConfigureGroupModal } from 'components/modals';
import GroupType from 'utils/enums/GroupType';
import { useSensorGroup } from 'utils/hooks/data';
import { notificationSuccess, notificationWarningConfirm } from 'utils/notifications';
import SensorGroup from 'utils/types/SensorGroup';

export const SensorGroupSettingsDropdown: React.FC<
  {
    sensorGroup: SensorGroup;
    size?: SizeProp;
    onDeleteRedirectPath?: String | undefined;
  } & Pick<HTMLAttributes<HTMLDivElement>, 'className'>
> = ({ sensorGroup, size = 'sm', onDeleteRedirectPath, className }) => {
  const [showEditModal, setShowEditModal] = useState(false);

  const { t } = useTranslation('components');
  const navigate = useNavigate();
  const { updateSensorGroup, deleteSensorGroup } = useSensorGroup(sensorGroup.id, {
    enableGet: false,
  });

  const onUpdateGroupModalSubmit = async (newValues: Partial<SensorGroup>) => {
    await updateSensorGroup(newValues);
    notificationSuccess(
      t('dropdowns.SensorGroupSettingsDropdown.onUpdateGroupModalSubmit.successText'),
    );
    setShowEditModal(false);
  };

  const onDeleteGroup = async () => {
    const confirmed = await notificationWarningConfirm();
    if (!confirmed) return;
    await deleteSensorGroup(() => {
      notificationSuccess(t('dropdowns.SensorGroupSettingsDropdown.onDeleteGroup.successText'));
    });
    if (onDeleteRedirectPath !== undefined) {
      navigate(`/${onDeleteRedirectPath}`);
    }
  };

  const handleEdit = () => {
    setShowEditModal(true);
  };

  return (
    <>
      <SettingsDropdown
        size={size}
        className={className}
        onEdit={handleEdit}
        onDelete={
          sensorGroup?.type === GroupType.Project || sensorGroup?.type === GroupType.Unknown
            ? onDeleteGroup
            : undefined
        }
      />
      {sensorGroup && (
        <ConfigureGroupModal
          show={showEditModal}
          setShow={setShowEditModal}
          onSubmit={onUpdateGroupModalSubmit}
          group={sensorGroup}
        />
      )}
    </>
  );
};
