import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Alert, AlertProps } from 'components/alerts';

export type AlertWithIconProps = AlertProps & { icon: IconDefinition };

export const AlertWithIcon: React.FC<AlertWithIconProps> = ({ icon, children, ...props }) => (
  <Alert {...props}>
    <div className="flex">
      <div>
        <FontAwesomeIcon className="mr-1" icon={icon} />
      </div>
      <div className="flex grow">
        <div>{children}</div>
      </div>
    </div>
  </Alert>
);
