import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';

export const SearchBar = ({
  filtering,
  setFiltering,
  resetPageIndex,
  placeholder,
}: {
  filtering: string;
  setFiltering: Dispatch<SetStateAction<string>>;
  resetPageIndex: (defaultState?: boolean) => void;
  placeholder: string;
}) => (
  <div className="flex relative" data-testid="search-bar">
    <input
      name="search_field"
      type="text"
      value={filtering}
      onChange={e => {
        setFiltering(e.target.value);
        resetPageIndex();
      }}
      placeholder={placeholder}
      className="px-3 py-1.5 text-xs lg:text-sm border-2 rounded-[7px]"
    />
    {filtering && (
      <span
        className="absolute top-1/2 right-2 transform -translate-y-1/2 cursor-pointer"
        onClick={() => {
          setFiltering('');
          resetPageIndex();
        }}
      >
        <FontAwesomeIcon icon={faXmark} className="text-sm" />
      </span>
    )}
  </div>
);
