import { QueryClient } from '@tanstack/react-query';

// Documentation:
// https://react-query.tanstack.com/reference/QueryClient
const queryClient = new QueryClient({
  // queryCache: {},
  // mutationCache: {},
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: 900000, // [ms] = 15 minutes
      placeholderData: false,
    },
    // cacheTime: 300000, // [ms] = 300 seconds
  },
});

// Notes regarding staleTime, refetch and cache
// ============================================
//
// With staleTime = 15min and refetchOn* = true we make sure
// that every hook that is mounted 15 minutes after first
// successful fetch will refetch the data in the background.

export default queryClient;
