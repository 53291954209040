import Plot from 'react-plotly.js';

import { LoadingPlot, PlotlyData } from 'components/plots/insights_plots';

export type PlotlyBoxplotData = PlotlyData & {
  tagValues: string[];
  sensorIds: Map<string, string>;
};

export const SensorsTabPlot: React.FC<{
  sensorsPlotData: PlotlyData | null;
}> = ({ sensorsPlotData }) => (
  <>
    {
      !sensorsPlotData && (
        <LoadingPlot hideSpinner={true} />
      ) /*We're using the spinner controlled by the parent component so we can hide this one */
    }
    {sensorsPlotData && (
      <Plot className="w-full" data={sensorsPlotData.data} layout={sensorsPlotData.layout} />
    )}
  </>
);
