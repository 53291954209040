import { useTranslation } from 'react-i18next';
import { components, MultiValueProps } from 'react-select';

import { OptionType } from 'components/inputs/components/MultiSelect/types';

export const CustomMultiValue = (props: MultiValueProps<OptionType>) => {
  const { t } = useTranslation('components');
  const { children, selectProps, index } = props;

  const maxToShow = 3; // Number of options to show in the select input
  const selectedValues = Array.isArray(selectProps.value) ? selectProps.value : [];

  return (
    <>
      {index < maxToShow && <components.MultiValue {...props}>{children}</components.MultiValue>}
      {index === maxToShow && selectedValues.length > maxToShow && (
        <div className="flex px-2 items-center">
          +{selectedValues.length - maxToShow} {t('inputs.helpers.CustomMultiValue.more')}
        </div>
      )}
    </>
  );
};
