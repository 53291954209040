import { ColumnDef } from '@tanstack/react-table';

import { simpleHash } from 'utils/hash';

export const localStoragePrefix = 'ws-table';

export const createColumnBasedTableIdentifier = <T extends {}>(columns: ColumnDef<T>[]) =>
  simpleHash(columns.flatMap(column => column.id || '').join());

export const getLocalStorageIdentifiers = () =>
  [...Array(localStorage.length).keys()]
    .map(i => localStorage.key(i)!)
    .filter(key => !!key)
    .filter(key => key.startsWith(localStoragePrefix));
