import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import { allCustomer } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';
import { parseReport } from 'utils/types/Report';
import { ReportRevisionFormValues } from 'utils/types/ReportRevision';

export const useCustomerReports = (customerId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['customerReports', customerId];

  const queryClient = useQueryClient();

  const { user } = useCurrentUser();
  const { showAllCustomers } = useSelectedCustomer();
  const url = showAllCustomers ? '/admin/reports' : `/customers/${customerId}/reports`;

  // Get
  const {
    data: reports,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(url),
    select: (reports: any[]) => reports.map(parseReport),
    enabled: !!customerId,
  });

  // Create
  const { mutateAsync: createReportByCustomer } = useMutation({
    mutationFn: async ({ customer_id, ...values }: ReportRevisionFormValues) => {
      if (!customer_id) throw new Error('Customer ID is required');

      // Prevent admins from submitting with customer_id == ALL_CUSTOMERS
      if (customer_id === allCustomer.id) {
        customer_id = user?.customer_id!;
      }

      const report = await api.post(`/customers/${customer_id}/reports`, values);
      return parseReport(report);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['customerReports', customerId] });
    },
  });

  return {
    createReportByCustomer,
    reports,
    isPending: isPending || isLoading || isFetching,
  };
};
