import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { SubmittingButton, FormTextInput } from 'components';
import { EmailFormValues } from 'components/forms/EmailForm/types';
import Form from 'components/forms/Form';

export const EmailForm: React.FC<{
  onSubmit: (values: EmailFormValues, isValid: boolean) => void;
  values?: any;
  buttonText?: string;
  autoSubmitOnChange?: boolean;
}> = ({ onSubmit, buttonText, values = {}, autoSubmitOnChange }) => {
  const { t } = useTranslation('components');

  const schemaObj = {
    email: yup
      .string()
      .email(t('forms.EmailForm.schema.email.email'))
      .required(t('forms.EmailForm.schema.email.required')),
  };

  return (
    <Form<EmailFormValues>
      schemaObj={schemaObj}
      onSubmit={values => onSubmit(values, true)}
      values={values}
      validateOnChange
      body={({ handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit} className="flex flex-col gap-3">
          <FormTextInput
            className="mb-0"
            type="email"
            name="email"
            autoFocus
            placeholder={t('forms.EmailForm.email.placeholder')}
            inputClassName="border-brand-gray-light-4 placeholder:text-brand-gray-light-2 h-12 text-lg"
          />

          {buttonText && (
            <div className="text-center">
              <SubmittingButton
                className="w-full h-12"
                submittingText={buttonText}
                buttonText={buttonText}
                submitting={isSubmitting}
                size="lg"
              />
            </div>
          )}

          {autoSubmitOnChange && <AutoSubmitOnChange onSubmit={onSubmit} />}
        </form>
      )}
    />
  );
};

const AutoSubmitOnChange: React.FC<{
  onSubmit: (values: EmailFormValues, isValid: boolean) => void;
}> = ({ onSubmit }) => {
  const { values, isValid, validate } = useFormikContext<EmailFormValues>();

  useEffect(() => {
    onSubmit({ email: values.email }, isValid);
  }, [values, onSubmit, isValid, validate]);
  return null;
};
