import classnames from 'classnames';
import { ComponentPropsWithRef } from 'react';

import ActivityState from 'utils/enums/ActivityState';
import GatewayState from 'utils/enums/GatewayState';
import MoistureState from 'utils/enums/MoistureState';
import 'styles/components/_ws-pulsating-circle.scss';

export type Props = {
  typeClass?: MoistureState | GatewayState | ActivityState;
  viewOnly?: boolean;
} & ComponentPropsWithRef<'span'>;

const PulsatingCircle: React.FC<Props> = ({
  typeClass = ActivityState.INACTIVE,
  className,
  viewOnly,
  ...props
}) => (
  <span
    className={classnames(
      { 'ws-pulsating-circle': !viewOnly, 'ws-static-circle': viewOnly },
      typeClass,
      className,
    )}
    {...props}
  />
);

export default PulsatingCircle;
