import { faLock, faLockOpen, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useMemo, Dispatch, SetStateAction, HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

export const IconSwitch: React.FC<
  {
    label?: string;
    isChecked: boolean;
    setIsChecked: Dispatch<SetStateAction<boolean>>;
    onChange?: (checked: boolean) => Promise<void>;
    isCheckedIcon: IconDefinition;
    isNotCheckedIcon: IconDefinition;
    isCheckedClassNames?: string;
    isNotCheckedClassNames?: string;
  } & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'>
> = ({
  label,
  isChecked,
  setIsChecked,
  onChange,
  className = 'mb-2',
  isCheckedIcon = faLockOpen,
  isNotCheckedIcon = faLock,
  isCheckedClassNames,
  isNotCheckedClassNames,
  ...props
}) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);
  const { t } = useTranslation('components');

  const lock = t('inputs.iconSwitch.lock');
  const unlock = t('inputs.iconSwitch.unlock');

  return (
    <>
      <input
        className="hidden"
        type="checkbox"
        id={controlId}
        name={controlId}
        checked={isChecked}
        onChange={async event => {
          setIsChecked(event.target.checked);
          if (onChange) await onChange(event.target.checked);
        }}
        {...props}
      />
      <label
        className={classNames('cursor-pointer', className)}
        htmlFor={controlId}
        data-testid="lock-switch-id"
      >
        <FontAwesomeIcon
          className={classNames('text-brand-orange w-5 h-5', {
            [isCheckedClassNames ? isCheckedClassNames : '']: isChecked,
            [isNotCheckedClassNames ? isNotCheckedClassNames : '']: !isChecked,
          })}
          data-tooltip-content={isChecked ? lock : unlock}
          data-tooltip-id="route-tooltip"
          icon={isChecked ? isCheckedIcon : isNotCheckedIcon}
        />
      </label>
    </>
  );
};
