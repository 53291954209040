import { t } from 'translations';
import { SensorTagInsulationType } from 'utils/enums';

export const getSensorTagInsulationTypeText = (
  sensorTagInsulationType: SensorTagInsulationType,
) => {
  switch (sensorTagInsulationType) {
    case SensorTagInsulationType.RockWool:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeText.RockWool');
    case SensorTagInsulationType.GlassWool:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeText.GlassWool');
    case SensorTagInsulationType.Polyisocyanurate:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeText.Polyisocyanurate');
    case SensorTagInsulationType.ExpandedPolystyrene:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeText.ExpandedPolystyrene');
    case SensorTagInsulationType.ExtrudedPolystyrene:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeText.ExtrudedPolystyrene');
    case SensorTagInsulationType.CelluloseFibre:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeText.CelluloseFibre');
    case SensorTagInsulationType.CelluloseFibreWarmcel:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeText.CelluloseFibreWarmcel');
    default:
      return 'n/a';
  }
};

export const getSensorTagInsulationTypeTextShort = (
  sensorTagInsulationType: SensorTagInsulationType,
) => {
  switch (sensorTagInsulationType) {
    case SensorTagInsulationType.RockWool:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeTextShort.RockWool');
    case SensorTagInsulationType.GlassWool:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeTextShort.GlassWool');
    case SensorTagInsulationType.Polyisocyanurate:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeTextShort.Polyisocyanurate');
    case SensorTagInsulationType.ExpandedPolystyrene:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeTextShort.ExpandedPolystyrene');
    case SensorTagInsulationType.ExtrudedPolystyrene:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeTextShort.ExtrudedPolystyrene');
    case SensorTagInsulationType.CelluloseFibre:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeTextShort.CelluloseFibre');
    case SensorTagInsulationType.CelluloseFibreWarmcel:
      return t('utils:texts.sensor-tags.getSensorTagInsulationTypeTextShort.CelluloseFibreWarmcel');
    default:
      return 'n/a';
  }
};
