import React, { Dispatch, SetStateAction } from 'react';

import { EmailForm, EmailFormValues } from 'components/forms/EmailForm';
import Modal from 'components/modals/Modal';

const EmailModal: React.FC<{
  onSubmit: (values: EmailFormValues) => Promise<void>;
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  intialEmail?: string;
  title: string;
  buttonText: string;
  prependElement?: JSX.Element | string;
}> = ({ onSubmit, show, setShow, intialEmail, title, buttonText, prependElement }) => (
  <Modal title={title} show={show} setShow={setShow}>
    {prependElement}
    <EmailForm onSubmit={onSubmit} buttonText={buttonText} values={{ email: intialEmail }} />
  </Modal>
);

export default EmailModal;
