import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';

export const NoGateways: React.FC<{ setShowModal: Dispatch<SetStateAction<boolean>> }> = ({
  setShowModal,
}) => {
  const { t } = useTranslation('components');
  return (
    <div className="p-5 bg-brand-gray-light-4 text-center rounded-xl">
      <h1 className="text-3xl font-semibold text-brand-gray-light-1 text-center mb-5">
        {t('emptyStates.noGatewaysText')}
      </h1>
      <p className="mb-4">{t('emptyStates.noGatewaysText1')}</p>

      <Button className="bg-brand-blue mx-auto" onClick={() => setShowModal(true)}>
        {t('emptyStates.addGatewayText')}
      </Button>
    </div>
  );
};
