import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import { parseDeviceLoRaDetails } from 'utils/types/DeviceLoRaDetails';
import HookOptions from 'utils/types/HookOptions';

export const useAdminSensorLoRaDetails = (
  sensorId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['adminSensorLoRaDetails', sensorId];

  const queryClient = useQueryClient();

  const { isAdmin } = useCurrentUser();

  const { data: deviceLoRaDetails, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/admin/sensors/${sensorId}/lora_details`),
    select: (data: any) => parseDeviceLoRaDetails(data),
    enabled: isAdmin && enableGet && !!sensorId,
  });

  const { mutateAsync: registerDeviceInTTNById } = useMutation({
    mutationFn: async (sensorId: string) =>
      api.post(`/admin/sensors/${sensorId}/lora_details/register`),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: ['adminSensors'] });
    },
  });

  const { mutateAsync: unregisterDeviceFromTTNById } = useMutation({
    mutationFn: async (sensorId: string) =>
      api.delete(`/admin/sensors/${sensorId}/lora_details/unregister`),
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: ['adminSensors'] });
    },
  });

  return {
    deviceLoRaDetails,
    registerDeviceInTTNById,
    unregisterDeviceFromTTNById,
    isPending,
  };
};
