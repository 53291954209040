import FileBase, { parseFileBase } from 'utils/types/FileBase';

type SensorFile = FileBase & {
  sensor_id: string;
};

export const parseSensorFile = (sensorFile: any): SensorFile =>
  parseFileBase(sensorFile) as SensorFile;

export default SensorFile;
