const enum AlarmRuleComparisonOperator {
  // Arithmetic operators
  GreaterThan = 10, // >
  LessThan = 20, // <

  // Custom operators
  SubsequentIncrease = 110, // A certain increase in value
  SubsequentDecrease = 120, // A certain decrease in value
}

export default AlarmRuleComparisonOperator;
