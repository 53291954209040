import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Table, TableLink, TableProps } from 'components';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { getDownlinkStatusText } from 'utils/enums/DownlinkStatus';
import { userId2NameFunc } from 'utils/formatting';
import { useCurrentUserUsers } from 'utils/hooks/data';
import SensorDownlink from 'utils/types/SensorDownlink';

type DownlinkTableDataType = SensorDownlink & { name?: string };

export const DownlinksTable: React.FC<
  {
    sensorId: string;
    data: SensorDownlink[];
    loading: boolean;
    showUser: boolean;
  } & Omit<TableProps<SensorDownlink>, 'columns' | 'data'>
> = ({ tableIdentifier, sensorId, data, loading, showUser = false, ...props }) => {
  const { users } = useCurrentUserUsers();

  const userId2Name = userId2NameFunc(users);

  const { t } = useTranslation('components');

  const sensorDownLinkData = useMemo(
    () =>
      data.map(x => {
        const name = userId2Name[x.created_by];
        if (!name) return x;

        return { ...x, name };
      }),
    [data], //eslint-disable-line
  ) as DownlinkTableDataType[];

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<DownlinkTableDataType>();

    return [
      columnHelper.accessor('created_date', {
        id: 'created_date',
        header: () => t('tables.DownlinksTable.columns.created_date.text'),
        enableColumnFilter: false,
        sortingFn: 'datetime',
        size: 150,
        cell: ({ getValue }) => (
          <span
            data-tooltip-content={dateToLocaleString(getValue())}
            data-tooltip-id="route-tooltip"
          >
            {dateToPrettyDate(getValue())}
          </span>
        ),
      }),

      columnHelper.accessor('frequency', {
        id: 'frequency',
        header: () => t('tables.DownlinksTable.columns.frequency.text'),
        size: 100,
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          className: 'text-right',
        },
      }),

      columnHelper.accessor('status', {
        id: 'status',
        header: () => t('tables.DownlinksTable.columns.status.text'),
        enableColumnFilter: false,
        size: 150,
        cell: ({ getValue }) => {
          const value = getValue();
          return value ? getDownlinkStatusText(value) : null;
        },
      }),

      columnHelper.accessor('name', {
        id: 'name',
        header: () => t('tables.DownlinksTable.columns.created_by.text'),
        size: 200,
        enableColumnFilter: false,
        meta: {
          hidden: !showUser,
        },
        cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <TableLink
            name={original.name || original.created_by}
            url={`/user/organization/users/${original.created_by}`}
            datatip={t('tables.DownlinksTable.columns.created_by.dataTip.1')}
            externalDatatip={t('tables.DownlinksTable.columns.created_by.dataTip.2')}
          />
        ),
      }),
    ];
  }, [showUser, t]);

  return (
    <Table
      tableIdentifier={`downlinks-table-${{ tableIdentifier }}`}
      data={sensorDownLinkData}
      loading={loading}
      columns={columns}
      {...props}
    />
  );
};
