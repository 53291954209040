import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import UsersInlineList from 'components/UsersInlineList';
import { useSensorUsers } from 'utils/hooks/data';

const SensorUsersInlineList: React.FC<{
  sensorId: string;
}> = ({ sensorId }) => {
  const { t } = useTranslation('components');

  const { users } = useSensorUsers(sensorId);

  return (
    <Link to={`/user/sensors/${sensorId}/access`}>
      <UsersInlineList
        users={users}
        data-tooltip-content={t('sensors.SensorsUsersInlineList.dataTip')}
        data-tooltip-id="route-tooltip"
      />
    </Link>
  );
};

export default SensorUsersInlineList;
