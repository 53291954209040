import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import GatewayState from 'utils/enums/GatewayState';
import { getGatewayStateText } from 'utils/texts/gateway-state';

export const GatewayStateBadge: React.FC<
  { state?: GatewayState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className = 'text-xs', ...props }) => (
  <Badge
    {...props}
    className={classNames(
      'text-white',
      {
        'bg-brand-green': state === GatewayState.ACTIVE,
        'bg-brand-gray-light-2': state === GatewayState.INACTIVE,
      },
      className,
    )}
  >
    {getGatewayStateText(state)}
  </Badge>
);
