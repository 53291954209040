import { Cell } from '@tanstack/react-table';
import classNames from 'classnames';

import { getColumnPinningStyles, getPinningClassNames } from 'utils/tables/format-functions';

export const PlaceholderRow = <T extends {}>({
  colSpan,
  cellTemplates,
  rowIndex,
  isMobileView,
}: {
  colSpan: number;
  cellTemplates: Cell<T, any>[];
  rowIndex: number;
  isMobileView: boolean;
}) => (
  <tr
    aria-colspan={colSpan}
    key={`placeholderRow-${rowIndex}`}
    className="even:bg-brand-gray-light-4"
    style={{ height: '50px' }}
  >
    {cellTemplates.map((cell, cellIndex) => (
      <td
        key={`placeholderCell-${rowIndex}-${cellIndex}`}
        className={classNames(
          'first:pl-7 last:pr-7 overflow-hidden text-sm text-brand-gray tracking-wider whitespace-nowrap',
          cell.column.columnDef.meta?.className,
          !isMobileView && getPinningClassNames(cell.column, false),
        )}
        style={{
          ...(!isMobileView && getColumnPinningStyles(cell.column)),
          width: cell.column.getSize(),
        }}
      />
    ))}
  </tr>
);
