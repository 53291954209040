import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { BlueprintFormValues } from 'components/forms/BlueprintForm';
import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import { Blueprint, parseBlueprint } from 'utils/types';
import { allCustomer } from 'utils/types/Customer';
import EnrichedFile from 'utils/types/EnrichedFile';
import HookOptions from 'utils/types/HookOptions';

type BlueprintCreate = Omit<BlueprintFormValues, 'file'> &
  EnrichedFile & {
    customer_id?: string;
  };

export const useCustomerBlueprints = (
  customerId?: string,
  { enableGet = true, ignoreAdminCustomerOverwrite = false }: HookOptions = {},
) => {
  const queryKey = ['customerBlueprints', customerId];

  const queryClient = useQueryClient();

  const { user } = useCurrentUser();
  const { showAllCustomers } = useSelectedCustomer();
  const url =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? '/admin/blueprints'
      : `/customers/${customerId}/blueprints`;

  // Get
  const {
    data: blueprints,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(url),
    select: (blueprints: any[]) => blueprints.map(parseBlueprint),
    enabled: enableGet && !!customerId,
  });

  // Create
  const { mutateAsync: createBlueprintByCustomer } = useMutation({
    mutationFn: async (values: BlueprintCreate): Promise<Blueprint> => {
      // Prevent admins from submitting with customer_id == ALL_CUSTOMERS
      if (values.customer_id === allCustomer.id) {
        values.customer_id = user?.customer_id;
      }
      const blueprint = await api.post(`/customers/${customerId}/blueprints`, values);
      return parseBlueprint(blueprint);
    },
    onSuccess: (blueprint: Blueprint): void => {
      queryClient.setQueryData(queryKey, (old?: Blueprint[]) =>
        Array.isArray(old) ? [...old, blueprint] : [blueprint],
      );
    },
  });

  return {
    blueprints,
    isPending: isPending || isLoading || isFetching,
    createBlueprintByCustomer,
  };
};
