import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseSensorGroup } from 'utils/types/SensorGroup';

export const useGroupProjectGroup = (groupId?: string, { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['useGroupProjectGroup', groupId];

  // Get
  const { data: projectGroup, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${groupId}/project_group`),
    select: (group: any | null) => (group ? parseSensorGroup(group) : undefined),
    enabled: enableGet && !!groupId,
  });

  return {
    projectGroup,
    isPending,
  };
};
