import { t } from 'translations';

export const PI = Math.PI;

export const degrees2radians = (degrees: number) => degrees * (PI / 180.0);
export const radians2degrees = (radians: number) => radians * (180.0 / PI);

// Binary search
export const degrees2worldCornerReverseRounded = (degrees: number, round: number) =>
  degrees2worldCornerReverse(Math.round(degrees / round) * round);

export const degrees2worldCornerReverse = (degrees: number) => {
  degrees = Math.round((degrees + 360) % 360);
  if (degrees < 180) {
    if (degrees === 0) return t('utils:trigonometry.degrees2worldCornerReverse.N'); // Is equal to 0
    // Is between 0 and 180
    if (degrees < 90) {
      // Is between 0 and 90
      if (degrees < 45) return t('utils:trigonometry.degrees2worldCornerReverse.NNE'); // Is between 0 and 45
      if (degrees === 45) return t('utils:trigonometry.degrees2worldCornerReverse.NE'); // Is equal to 45
      return t('utils:trigonometry.degrees2worldCornerReverse.ENE'); // Is between 45 and 90
    }
    if (degrees === 90) return t('utils:trigonometry.degrees2worldCornerReverse.E'); // Is equal to 90
    // Is between 90 and 180
    if (degrees < 135) return t('utils:trigonometry.degrees2worldCornerReverse.ESE'); // Is between 90 and 135
    if (degrees === 135) return t('utils:trigonometry.degrees2worldCornerReverse.SE'); // Is equal to 135
    return t('utils:trigonometry.degrees2worldCornerReverse.SSE'); // Is between 135 and 180
  }
  if (degrees === 180) return t('utils:trigonometry.degrees2worldCornerReverse.S'); // Is equal to 180
  // Is between 180 and 360
  if (degrees < 270) {
    if (degrees < 225) return t('utils:trigonometry.degrees2worldCornerReverse.SSW'); // Is between 180 and 225
    if (degrees === 225) return t('utils:trigonometry.degrees2worldCornerReverse.SW'); // Is equal to 225
    return t('utils:trigonometry.degrees2worldCornerReverse.WSW'); // Is between 225 and 270
  }
  if (degrees === 270) return t('utils:trigonometry.degrees2worldCornerReverse.W'); // Is equal to 270
  // Is between 270 and 360
  if (degrees < 315) return t('utils:trigonometry.degrees2worldCornerReverse.WNW'); // Is between 270 and 315
  if (degrees === 315) return t('utils:trigonometry.degrees2worldCornerReverse.NW'); // Is equal to 315
  return t('utils:trigonometry.degrees2worldCornerReverse.NNW'); // Is between 315 and 360
};
