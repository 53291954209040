import orderBy from 'lodash/orderBy';

import Locale from 'utils/enums/Locale';
import AlarmRule, { getAlarmRuleLocalizedName } from 'utils/types/AlarmRule';

export const sortAlarmRules = (alarmRules?: AlarmRule[], currentLocale?: Locale) =>
  orderBy(
    alarmRules || [],
    ['type', a => getAlarmRuleLocalizedName(a, currentLocale)?.toLowerCase()],
    ['desc', 'asc'],
  );
