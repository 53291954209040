import { HTMLAttributes } from 'react';

export type SimpleSelectOption<T> = {
  name: string;
  value: T;
};

const SimpleSelect = <T extends {}>({
  onSelect,
  placeholder,
  options,
  ...props
}: {
  onSelect: (value: T) => void | Promise<void>;
  placeholder: string;
  options: SimpleSelectOption<T>[];
} & Omit<HTMLAttributes<HTMLSelectElement>, 'onSelect'>) => (
  <select
    onChange={e => {
      const index = e.target.selectedIndex - 1;
      if (index === -1) return;
      const option = options[index];
      onSelect(option.value);
    }}
    {...props}
  >
    <option value="">{placeholder}</option>
    {Object.values(options).map((option, i) => (
      <option key={i} value={String(i)}>
        {option.name}
      </option>
    ))}
  </select>
);

export default SimpleSelect;
