import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseSensor } from 'utils/types/Sensor';

export const useSensorsBulk = (sensorIds: string[], { enableGet = true }: HookOptions = {}) => {
  const queryKey = ['useSensorsBulk', sensorIds];

  const {
    data: sensors,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.post('/sensors_bulk', {
        sensor_ids: sensorIds,
      }),
    select: (sensorsRaw: any[]) => sensorsRaw.map(parseSensor),
    enabled: enableGet && sensorIds && sensorIds.length > 0,
  });

  return {
    sensors,
    isPending: isPending || isLoading || isFetching,
  };
};
