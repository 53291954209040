import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import queryClient from 'utils/queries/client';

interface UseQueryDeleteOptions<T> {
  queryKey: any;
  api: AxiosInstance;
  endpoint: string;
  invalidateQueryKeys?: any[];
  onSuccess?: (data: any) => Promise<T>;
}

export const useQueryDelete = <T>({
  queryKey,
  api,
  endpoint,
  invalidateQueryKeys = [],
  onSuccess = undefined,
}: UseQueryDeleteOptions<T>) =>
  useMutation({
    mutationFn: async (callback?: (res: any) => void) => {
      const res = await api.delete(endpoint);

      // This is done e.g. to allow for resetting query key right after API call
      if (callback) {
        callback(res);
      }
      return res;
    },
    onSuccess: async result => {
      invalidateQueryKeys.map(invalidateQueryKey =>
        queryClient.invalidateQueries({ queryKey: invalidateQueryKey, exact: false }),
      );

      if (onSuccess) {
        onSuccess(result);
      }
    },
  });
