import { orderBy } from 'lodash';
import { useMemo } from 'react';

import { unqiueArrayByStringProperty } from 'utils/arrays';
import { useCustomerBlueprints, useUserBlueprints } from 'utils/hooks/data';

export const useUserAndCustomerBlueprints = (userId?: string, customerId?: string) => {
  const {
    blueprints: blueprintsUser,
    isPending: isPendingUser,
    createBlueprintByUser,
  } = useUserBlueprints(userId);
  const {
    blueprints: blueprintsCustomer,
    isPending: isPendingCustomer,
    createBlueprintByCustomer,
  } = useCustomerBlueprints(customerId);

  const blueprintsMem = useMemo(
    () =>
      orderBy(
        unqiueArrayByStringProperty(
          [...(blueprintsUser || []), ...(blueprintsCustomer || [])],
          'id',
        ),
        'created_date',
        'desc',
      ),
    [blueprintsUser, blueprintsCustomer],
  );

  return {
    blueprints: blueprintsMem,
    isPending: isPendingCustomer || isPendingUser,
    createBlueprintByUser,
    createBlueprintByCustomer,
  };
};
