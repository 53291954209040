import GatewaySensorsDistance from 'utils/enums/GatewaySensorsDistance';
import GatewayState from 'utils/enums/GatewayState';
import GatewayFile, { parseGatewayFile } from 'utils/types/GatewayFile';
import GeographicLocation, { parseGeographicLocation } from 'utils/types/GeographicLocation';

export type SIMSource = 'Onomondo' | '1NCE';
export type NetworkServerSource = 'TTS' | 'TTN';

type Gateway = {
  id: string;
  name: string;
  state: GatewayState;
  customer_id: string;
  demo_application_id?: string;
  send_alarms: boolean;
  setup_completed_at?: Date;
  gateway_file_id?: string;
  gateway_file_url?: string;
  sensors_distance?: GatewaySensorsDistance;
  latest_transmission_received_at?: Date;
  version?:
    | 'v1.0'
    | 'v1.1'
    | 'v1.2'
    | 'v1.3'
    | 'v1.4'
    | 'v1.5'
    | 'v1.6'
    | 'v1.7'
    | 'v1.8'
    | 'v1.9'
    | 'v2.0'
    | 'v2.1'
    | 'v2.2'
    | 'v2.3';

  // Meta data
  meta_cpu_usage?: number; // 0-100%
  meta_memory_usage?: number; // 0-100%
  meta_sd_card_usage?: number; // 0-100%
  meta_rootfs_usage?: number; // 0-100%
  meta_modem_signal_rssi?: number; //

  // Other details
  heartbeats_past_24h?: number;

  // SIM details
  sim_id?: string;
  sim_source?: SIMSource;
  sim_ip_address?: string;
  sim_online?: boolean;

  // Network server
  network_server_source?: NetworkServerSource;
  network_server_online?: boolean;

  // Relations
  geographic_location?: GeographicLocation;
  gateway_files?: GatewayFile[];

  created_date: Date;
  created_by: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseGateway = (gateway: any): Gateway => {
  gateway.created_date = new Date(gateway.created_date);

  if (gateway.updated_date) {
    gateway.updated_date = new Date(gateway.updated_date);
  }

  if (gateway.setup_completed_at) {
    gateway.setup_completed_at = new Date(gateway.setup_completed_at);
  }

  if (gateway.latest_transmission_received_at) {
    gateway.latest_transmission_received_at = new Date(gateway.latest_transmission_received_at);
  }

  if (gateway.geographic_location) {
    gateway.geographic_location = parseGeographicLocation(gateway.geographic_location);
  }

  if (gateway.gateway_files) {
    gateway.gateway_files = gateway.gateway_files.map(parseGatewayFile);
  }

  return gateway;
};

export const networkServerSource2value = (server: NetworkServerSource | undefined) => {
  switch (server) {
    case 'TTS':
      return 2;
    case 'TTN':
      return 1;
    default:
      return 0;
  }
};

export type GatewaySMSCommand = 'reboot' | 'restartNetwork' | 'restartNetworkAll' | 'resetSIM';

export default Gateway;
