import React, { Dispatch } from 'react';

import Customer from 'utils/types/Customer';

export type ContextProps = {
  customer?: Customer;
  customerId?: string;
  customerIsFetching: boolean;
  setCustomerId: Dispatch<string | undefined>;
  showAllCustomers: boolean;
};

export const SelectedCustomerSessionContext = React.createContext<ContextProps>({} as ContextProps);
export const SelectedCustomerSessionProvider = SelectedCustomerSessionContext.Provider;
