import AlarmRuleComparisonOperator from 'utils/enums/AlarmRuleComparisonOperator';
import AlarmRuleCriterionType from 'utils/enums/AlarmRuleCriterionType';
import AlarmRuleParameter from 'utils/enums/AlarmRuleParameter';

type AlarmRuleCriterion = {
  id: string;

  // alarm_rule: AlarmRule;
  parameter: AlarmRuleParameter;
  comparison_operator: AlarmRuleComparisonOperator;
  value: number;

  type: AlarmRuleCriterionType;
  delayed_days?: number;

  created_date: Date;
  created_by: string;
};

export const parseAlarmRuleCriterion = (alarmRuleCriterion: any): AlarmRuleCriterion => {
  alarmRuleCriterion.created_date = new Date(alarmRuleCriterion.created_date);

  return alarmRuleCriterion as AlarmRuleCriterion;
};

export default AlarmRuleCriterion;
