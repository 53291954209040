import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseGateway } from 'utils/types/Gateway';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  enableGetIds?: boolean;
  fromTimestamp: Date;
  toTimestamp: Date;
};

export const useSensorGateways = (
  sensorId: string,
  { fromTimestamp, toTimestamp, enableGet = true, enableGetIds = false }: Options,
) => {
  const queryKey = ['sensorGateways', sensorId, fromTimestamp, toTimestamp];
  const queryKeyIds = ['sensorGatewayIds', sensorId, fromTimestamp, toTimestamp];

  // Sensor Gateways - Get
  const { data: gateways, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/gateways`, {
        params: {
          resolve_gateways: true,
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (gateways: any[]) => gateways.map(gateway => parseGateway(gateway)),
    enabled: !!sensorId && enableGet,
  });

  // Sensor Gateway IDs only - Get
  // NOTE: The endpoint is much "cheaper" when passing `resolve_gateways=false`
  // This makes sense when only interested in the gateway Ids and not the entire objects.
  const { data: gatewayIds, isPending: isPendingIds } = useQuery({
    queryKey: queryKeyIds,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/gateways`, {
        params: {
          resolve_gateways: false,
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (gatewayIds: any[]) => gatewayIds as string[],
    enabled: !!sensorId && enableGetIds,
  });

  return {
    gateways,
    gatewayIds,
    isPending,
    isPendingIds,
  };
};
