import { lazy } from 'react';
import { createBrowserRouter } from 'react-router-dom';

import { ErrorElement } from 'components/ErrorFallback';
import adminRoutes from 'utils/ws-routes-admin';
import publicRoutes from 'utils/ws-routes-public';
import userRoutes from 'utils/ws-routes-user';

const AppLayoutAdmin = lazy(
  () => import(/* webpackChunkName: "AppLayoutAdmin" */ 'layouts/AppLayoutAdmin'),
);
const AppLayoutBase = lazy(
  () => import(/* webpackChunkName: "AppLayoutBase" */ 'layouts/AppLayoutBase'),
);
const AppLayoutUser = lazy(
  () => import(/* webpackChunkName: "AppLayoutUser" */ 'layouts/AppLayoutUser'),
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayoutBase />,
    errorElement: <ErrorElement />,
    children: [
      ...publicRoutes,
      {
        path: '/user',
        element: <AppLayoutUser />,
        children: userRoutes,
        errorElement: <ErrorElement />,
      },
      {
        path: '/admin',
        element: <AppLayoutAdmin />,
        children: adminRoutes,
      },
    ],
  },
]);

export default router;
