import { useMutation } from '@tanstack/react-query';
import { AxiosInstance } from 'axios';

import queryClient from 'utils/queries/client';

interface UseQueryCreateOptions<T> {
  queryKey: any;
  api: AxiosInstance;
  endpoint: string;
  invalidateQueryKeys?: any[];
  invalidateExact?: boolean;
  onMutate?: (variables: any) => Promise<any> | void;
  parseFn?: (data: any) => T;
}

export const useQueryCreate = <T>({
  queryKey,
  api,
  endpoint,
  invalidateQueryKeys = [],
  invalidateExact = true,
  onMutate,
  parseFn,
}: UseQueryCreateOptions<T>) =>
  useMutation({
    onMutate,
    mutationFn: (values: Partial<T>) => api.post(endpoint, values),
    onSuccess: values => {
      if (parseFn) {
        values = parseFn(values);
      }

      if (queryKey) {
        queryClient.setQueryData(queryKey, (old: T) => {
          // NOTE: Here it is assumed that the value of the provided query key
          // is an array hence we will either extend the array (if it already exists)
          // or we will initialize the array with one single item.
          if (Array.isArray(old)) {
            return [...old, values];
          }
          return [values];
        });
      }

      invalidateQueryKeys.forEach(invalidateQueryKey => {
        queryClient.invalidateQueries({ queryKey: invalidateQueryKey, exact: invalidateExact });
      });
    },
    // onSettled: () => {},
  });
