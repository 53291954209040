import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import HookOptions from 'utils/types/HookOptions';
import { SensorGroupUpdate, parseSensorGroup } from 'utils/types/SensorGroup';

type Options = HookOptions & {
  signPrimaryImage?: boolean;
};

export const USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY = 'customerSensorGroups';

export const useCustomerSensorGroups = (
  customerId?: string,
  {
    enableGet = true,
    signPrimaryImage = false,
    ignoreAdminCustomerOverwrite = false,
  }: Options = {},
) => {
  const queryKey = [USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY, customerId, signPrimaryImage];

  const queryClient = useQueryClient();
  const { showAllCustomers } = useSelectedCustomer();
  const url =
    showAllCustomers && !ignoreAdminCustomerOverwrite
      ? '/admin/sensor_groups'
      : `/customers/${customerId}/sensor_groups`;

  // Get
  const {
    data: sensorGroups,
    isPending,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(url, {
        params: { sign_primary_image: signPrimaryImage },
      }),
    select: (sensorGroups: any[]) => sensorGroups.map((group: any) => parseSensorGroup(group)),
    enabled: !!customerId && enableGet,
  });

  // Create by Id
  const { mutateAsync: createSensorGroupByCustomerId } = useMutation({
    mutationFn: async ({
      customerId,
      ...values
    }: SensorGroupUpdate & { customerId?: string }): Promise<any> => {
      if (showAllCustomers) {
        // This is kept here as a redundant backup, since we shouldn't call this mutation
        // with ALL_CUSTOMERS from the front end anyway.
        throw Error('Cannot create group on behalf of ALL CUSTOMERS');
      }
      return await api.post(`/customers/${customerId}/sensor_groups`, values);
    },
    onSuccess: (sensorGroupRaw, { customerId }) => {
      queryClient.invalidateQueries({
        queryKey: [USE_CUSTOMER_SENSOR_GROUPS_QUERY_KEY, customerId],
      });
    },
  });

  return {
    sensorGroups,
    isPending: isPending || isLoading || isFetching,
    createSensorGroupByCustomerId,
    refetch,
  };
};
