import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

// Source: https://www.svgrepo.com/svg/62904/line-graph-down-arrow
const faSquirtyChartForecast: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    1024, // width
    1024, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M833.792 426.666667a21.333333 21.333333 0 0 0-21.333333 21.333333v298.666667a21.333333 21.333333 0 0 0 42.666666 0v-298.666667a21.333333 21.333333 0 0 0-21.333333-21.333333M641.194667 746.666667v-170.666667a21.333333 21.333333 0 0 0-42.666667 0v170.666667a21.333333 21.333333 0 0 0 42.666667 0M426.965333 744.874667v-295.082667a21.333333 21.333333 0 0 0-42.666666 0v295.082667a21.333333 21.333333 0 0 0 42.666666 0M192 768.042667a21.333333 21.333333 0 0 0 21.333333-21.333334v-126.250666a21.333333 21.333333 0 0 0-42.666666 0v126.250666a21.333333 21.333333 0 0 0 21.333333 21.333334M832 810.666667h-640a21.333333 21.333333 0 0 0 0 42.666666h640a21.333333 21.333333 0 0 0 0-42.666666 M851.669333 186.410667a21.248 21.248 0 0 0-5.845333-8.874667 22.144 22.144 0 0 0-7.466667-4.096 20.48 20.48 0 0 0-9.898666-2.773333h-122.666667a21.333333 21.333333 0 1 0 0 42.666666h81.536l-171.52 201.557334-204.288-163.072a21.290667 21.290667 0 0 0-28.970667 2.133333L178.090667 476.245333A21.418667 21.418667 0 0 0 193.792 512a21.333333 21.333333 0 0 0 15.701333-6.869333l191.018667-207.573334 204.842667 163.584a21.333333 21.333333 0 0 0 29.568-2.816l177.536-208.64V320a21.290667 21.290667 0 1 0 42.666666 0V197.333333a20.565333 20.565333 0 0 0-3.413333-10.922666',
  ],
};

export default faSquirtyChartForecast;
