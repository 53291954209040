import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import SensorFile, { parseSensorFile } from 'utils/types/SensorFile';

export const useSensorPrimaryFile = (
  sensorId?: string,
  useThumbnail: boolean = false,
  enableGet: boolean = true,
) => {
  const queryKey = ['sensorPrimaryFile', sensorId, useThumbnail];

  // Sensor File
  const { data: file, isPending } = useQuery<SensorFile | null>({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/files/primary/`, {
        params: { use_thumbnail: useThumbnail },
        _disableErrorNotifications: true,
        _disableRedirects: true,
      }),
    select: (file: any) => {
      if (!file) return null;

      return parseSensorFile(file);
    },
    enabled: enableGet && !!sensorId,
    meta: {
      disableLoading: true,
    },
  });

  return {
    file,
    isPending,
  };
};
