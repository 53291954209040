import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseReport } from 'utils/types/Report';

export const useSensorGroupReports = (sensorGroupId?: string) => {
  const queryKey = ['sensorGroupReports', sensorGroupId];

  // Get
  const { data: reports, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/reports`),
    select: (reports: any[]) => reports.map(report => parseReport(report)),
    enabled: !!sensorGroupId,
  });

  return {
    reports,
    isPending,
  };
};
