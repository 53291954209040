import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import Permission from 'utils/enums/Permission';
import HookOptions from 'utils/types/HookOptions';

export const useUtilsPermissionsGroup = (
  groupId?: string,
  permission: Permission = Permission.View,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['permissions', 'group', groupId, permission];

  // Get
  const { data: hasAccess, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/utils/permissions/groups/${groupId}`, { params: { permission } }),
    select: (data: any) => data as boolean,
    enabled: enableGet && !!groupId,
  });

  return {
    hasAccess,
    isPending,
  };
};
