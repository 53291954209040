import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

import { LoadingPlot, PlotlyData } from 'components/plots/insights_plots';
import api from 'utils/api';

export const GatewaysMapPlot: React.FC = () => {
  const [gatewaysMapPlotData, setData] = useState<PlotlyData | null>(null);

  useEffect(() => {
    api.get('/admin/insights/plots/gateways_map').then(response => {
      setData(response);
    });
  }, []);

  return (
    <>
      {!gatewaysMapPlotData && <LoadingPlot />}
      {gatewaysMapPlotData && (
        <Plot
          className="w-full"
          data={gatewaysMapPlotData.data}
          layout={gatewaysMapPlotData.layout}
        />
      )}
    </>
  );
};

export const GatewaysHeartbeatsPlot: React.FC = () => {
  const [gatewaysHeartbeatsPlotData, setData] = useState<PlotlyData | null>(null);

  useEffect(() => {
    api.get('/admin/insights/plots/gateways_heartbeats').then(response => {
      setData(response);
    });
  }, []);

  return (
    <>
      {!gatewaysHeartbeatsPlotData && <LoadingPlot />}
      {gatewaysHeartbeatsPlotData && (
        <Plot
          className={'w-full'}
          data={gatewaysHeartbeatsPlotData.data}
          layout={gatewaysHeartbeatsPlotData.layout}
        />
      )}
    </>
  );
};
