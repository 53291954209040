import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';

export const useGroupUnresolvedAlertsCount = (sensorGroupId?: string) => {
  const queryKey = ['useGroupUnresolvedAlertsCount', sensorGroupId];

  // Get
  const { data: groupUnresolvedAlertsCount, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/alarms/unresolved_count`),
    select: (count: any) => count as number,
    enabled: !!sensorGroupId,
  });

  return {
    groupUnresolvedAlertsCount,
    isPending,
  };
};
