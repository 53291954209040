import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import { sortedArrayByStringProperty } from 'utils/arrays';
import Sensor from 'utils/types/Sensor';

interface SensorOption {
  readonly value: string;
  readonly label: string;
}

interface SensorSelectProps {
  onSelect: (sensor: Sensor) => void;
  initialSelectedSensor?: Sensor;
  sensorsToSelect?: Sensor[];
  isPending?: boolean;
  id?: string;
  placeholder?: string;
}

export const SensorSelect = forwardRef<any, SensorSelectProps>(
  ({ initialSelectedSensor, onSelect, sensorsToSelect, isPending, id, placeholder }, ref) => {
    const { t } = useTranslation('components');

    const onChange = (selectedOption: SingleValue<SensorOption>) => {
      const selectedSensor = sensorsToSelect?.find(sensor => sensor.id === selectedOption?.value);
      if (selectedSensor) return onSelect(selectedSensor);
    };

    const options = sortedArrayByStringProperty(sensorsToSelect, 'name').map(
      sensor =>
        ({
          label: sensor.name || sensor.hardware_id,
          value: sensor.id,
        }) as SensorOption,
    );

    const defaultValue = initialSelectedSensor
      ? {
          label: initialSelectedSensor.name || initialSelectedSensor.hardware_id,
          value: initialSelectedSensor.id,
        }
      : undefined;

    return (
      <Select<SensorOption, false>
        ref={ref}
        classNamePrefix="select"
        isLoading={isPending}
        isClearable
        styles={{
          input: provided => ({
            ...provided,
            opacity: 0,
          }),
        }}
        isSearchable
        defaultValue={defaultValue}
        formatOptionLabel={option => <>{option.label}</>}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        menuShouldScrollIntoView
        menuPosition="fixed"
        openMenuOnClick
        openMenuOnFocus
        inputId={id}
        noOptionsMessage={() => t('inputs.SensorSelect.noOptionsMessage')}
        placeholder={placeholder || t('inputs.SensorSelect.placeholder')}
      />
    );
  },
);
