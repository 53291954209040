import { RawNodeDatum } from 'react-d3-tree';
import { Link } from 'react-router-dom';

import { GroupHierarchyNodeCard } from 'components/GroupHierarchy/components/GroupHierarchyNode/components';
import Permission from 'utils/enums/Permission';
import { useUtilsPermissionsGroup } from 'utils/hooks/data';

type GroupHierarchyNodeProps = {
  node: RawNodeDatum;
  groupId: string;
  toggleNode: () => void;
  isRootNode: boolean;
};

export const GroupHierarchyNode: React.FC<GroupHierarchyNodeProps> = ({ node, ...props }) => {
  const currentGroupId = node.attributes?.id.toString();

  const { hasAccess } = useUtilsPermissionsGroup(currentGroupId, Permission.View);

  return (
    <foreignObject width="300" height="300" x="-150" y="-150">
      {hasAccess ? (
        <Link to={currentGroupId ? `/user/groups/${currentGroupId}` : '#'}>
          <GroupHierarchyNodeCard node={node} {...props} />
        </Link>
      ) : (
        <GroupHierarchyNodeCard node={node} {...props} />
      )}
    </foreignObject>
  );
};
