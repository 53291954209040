import { differenceInDays } from 'date-fns/differenceInDays';
import { CallbackDataParams } from 'echarts/types/dist/shared';

import { brandGrayLight4, brandGreen, brandOrange } from 'utils/colors';
import { echarts } from 'utils/echarts';
import AnomalyValidationType from 'utils/enums/AnomalyValidationType';
import { getTimeAxisTicks } from 'utils/plots/axis-formatters';
import { dateFormat } from 'utils/plots/dateFormat';
import { DataAnomalyTuple } from 'utils/types/PlotTypes';

export const lineStyle = {
  width: 3,
  shadowColor: 'rgba(0, 0, 0, 0.2)',
  shadowBlur: 2,
  shadowOffsetY: 0,
};

export const grid = {
  left: 70,
  top: 30,
  right: 70,
  bottom: 60,
};

export const gridMobile = {
  left: 50,
  top: 40,
  right: 50,
  bottom: 70,
};

export const itemStyleAnomalies = {
  borderColor: brandOrange,
  borderWidth: 2.0,
  color: (params: CallbackDataParams) => {
    const data = params.data as DataAnomalyTuple;
    const anomaly = data[3];
    if (anomaly.validation === AnomalyValidationType.AnomalyCorrectlyMarked) {
      return brandGreen;
    }
    if (anomaly.validation === AnomalyValidationType.AnomalyIncorrectlyMarked) {
      return brandOrange;
    }
    return brandGrayLight4;
  },
  opacity: 1.0,
  shadowColor: 'rgba(0, 0, 0, 0.2)',
  shadowBlur: 2,
  shadowOffsetY: 0,
};

export const axisLabel = ({
  width,
  fontSize,
  timeFrom,
  timeTo,
}: {
  width: number;
  fontSize: number;
  timeFrom: Date;
  timeTo: Date;
}) => ({
  fontSize,
  fontFamily: 'Montserrat, sans-serif',
  formatter: (value: string, index: number) => {
    const checkedDate = new Date(value).setHours(0, 0, 0, 0);
    const currentDate = new Date().setHours(0, 0, 0, 0);

    const daysAmount = differenceInDays(timeFrom, timeTo);

    const timeAxisTicks = getTimeAxisTicks(width);

    const date = new Date(value);
    if (daysAmount >= 4) {
      if (checkedDate === currentDate && index === timeAxisTicks) {
        //set last tick to current time
        const currentValue = date.getHours() === 0 ? date : new Date();
        return echarts.time.format(currentValue, `${dateFormat} {HH}:{mm}`, false);
      }
      return echarts.time.format(date, dateFormat, false);
    } else {
      return echarts.time.format(date, `${dateFormat} {HH}:{mm}`, false);
    }
  },
});
