import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Danger } from 'components';
import { useGateway } from 'utils/hooks/data';

export const GatewayOfflineAlert: React.FC<{
  className?: string;
  gatewayId: string;
  includeLinkToGateway?: boolean;
}> = ({ className, gatewayId, includeLinkToGateway = false }) => {
  const { t } = useTranslation();

  const { gateway } = useGateway(gatewayId);

  return (
    <Danger className={className}>
      <span className="font-extrabold">{t('utils:notice')}</span>:{' '}
      {gateway && includeLinkToGateway ? (
        <Link className="underline hover:text-blue-500" to={`/user/gateways/${gatewayId}`}>
          {gateway.name}
        </Link>
      ) : (
        <>
          {t('utils:your')}{' '}
          {includeLinkToGateway ? (
            <Link className="underline hover:text-blue-500" to={`/user/gateways/${gatewayId}`}>
              {t('utils:gateway')}
            </Link>
          ) : (
            t('utils:gateway')
          )}
        </>
      )}{' '}
      {t('components:overview.StatusAlerts.offlineGateway')}.
    </Danger>
  );
};
