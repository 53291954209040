import capitalize from 'lodash/capitalize';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import ExternalLink from 'components/ExternalLink';

const GatewaySetupGuideLink: React.FC<
  HTMLAttributes<HTMLDivElement> & {
    textClassName?: string;
  }
> = ({ className = 'mt-5', textClassName = 'text-sm', ...props }) => {
  const { t } = useTranslation('components');

  return (
    <div className={className} {...props}>
      <ExternalLink
        className={textClassName}
        text={capitalize(t('links.GatewaySetupGuideLink.text'))}
        url={t('links.GatewaySetupGuideLink.url')}
      />
    </div>
  );
};

export default GatewaySetupGuideLink;
