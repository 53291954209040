import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import AlarmRule, { parseAlarmRule } from 'utils/types/AlarmRule';
import { getQueryKeyCustomerAlarmRuleSensors } from 'utils/types/Customer';
import { getQueryKeySensorAlarmRules } from 'utils/types/Sensor';

type EndpointReturnType = { enabled: any[]; available: any[] };

export const useAlarmRuleSensors = (alarmRuleId?: string) => {
  const { customerId } = useSelectedCustomer();

  const queryClient = useQueryClient();

  // Enable alarm rule by sensor id
  const { mutateAsync: enableAlarmRuleBySensorId } = useMutation({
    mutationFn: async (sensorId: string): Promise<AlarmRule[]> => {
      const { enabled }: EndpointReturnType = await api.post(
        `/sensors/${sensorId}/alarm_rules/${alarmRuleId}`,
      );
      return enabled.map(parseAlarmRule);
    },
    onSuccess: async (alarmRules, sensorId) => {
      queryClient.setQueryData(getQueryKeySensorAlarmRules(sensorId), alarmRules);
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, false),
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, true),
      });
    },
  });

  // Disable alarm rule
  const { mutateAsync: disableAlarmRuleBySensorId } = useMutation({
    mutationFn: async (sensorId: string): Promise<AlarmRule[]> => {
      const { enabled }: EndpointReturnType = await api.delete(
        `/sensors/${sensorId}/alarm_rules/${alarmRuleId}`,
      );
      return enabled.map(parseAlarmRule);
    },
    onSuccess: async (alarmRules, sensorId) => {
      queryClient.setQueryData(getQueryKeySensorAlarmRules(sensorId), alarmRules);
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, false),
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, true),
      });
    },
  });

  return {
    disableAlarmRuleBySensorId,
    enableAlarmRuleBySensorId,
  };
};
