import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import Avatar from 'react-avatar';
import { useTranslation } from 'react-i18next';

import { brandOrange } from 'utils/colors';
import User from 'utils/types/User';

type KwargsToShow = {
  title: string;
  name?: string;
  value?: string;
  color?: string;
};

const UsersInlineList: React.FC<
  {
    users?: User[];
    size?: 'md';
    maxUsers?: number;
  } & HTMLAttributes<HTMLDivElement>
> = ({ users = [], size = 'md', maxUsers = 6, className, style, ...props }) => {
  const { t } = useTranslation('components');

  const userKwargs: KwargsToShow[] = users.slice(0, maxUsers).map(user => ({
    name: user.full_name,
    title: `${user.full_name}`,
  }));
  const kwargsToShow =
    users.length > maxUsers
      ? [
          ...userKwargs.slice(0, maxUsers - 1),
          {
            value: `${maxUsers - 1}+`,
            title: t('UsersInlineList.title', { nUsers: users.length }),
            color: brandOrange,
          },
        ]
      : userKwargs;

  const sizePx = size === 'md' ? 20 : 20;
  const offset = Math.round(sizePx / 2) + 2;
  const width = sizePx + (kwargsToShow.length - 1) * offset;

  return (
    <div
      className={classNames('relative cursor-pointer', className)}
      style={{ width, height: sizePx, ...style }}
      {...props}
    >
      {kwargsToShow.map((kwargs, index) => (
        <Avatar
          className="absolute"
          style={{ left: `${index * offset}px` }}
          key={index}
          size={`${sizePx}px`}
          round={true}
          maxInitials={2}
          {...kwargs}
        />
      ))}
    </div>
  );
};

export default UsersInlineList;
