import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, MouseEvent, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Name = ({
  name,
  setName,
  onUpdateGroupName,
}: {
  name: string;
  setName: Dispatch<SetStateAction<string>>;
  onUpdateGroupName: (newName: string) => Promise<void>;
}) => {
  const { t } = useTranslation('components');

  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {isEditing ? (
        <input
          type="text"
          value={name}
          onChange={e => {
            setName(e.target.value);
          }}
          onBlur={() => {
            setIsEditing(false);
            onUpdateGroupName(name);
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              setIsEditing(false);
              onUpdateGroupName(name);
            }
          }}
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className="text-base text-brand-gray mb-1 border-b border-brand-gray-light-4"
          autoFocus
        />
      ) : (
        <h5 className="flex items-center text-base text-brand-gray mb-1 border-b border-brand-gray-light-4 justify-between">
          <span className="truncate">{name}</span>

          <FontAwesomeIcon
            icon={faPen}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();

              setIsEditing(true);
            }}
            className="ml-2 p-2 cursor-pointer flex-shrink-0 outline-none"
            data-tooltip-content={t('GroupHierarchy.GroupHierarchyNode.renameGroupText')}
            data-tooltip-id="route-tooltip"
          />
        </h5>
      )}
    </>
  );
};
