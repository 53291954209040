import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { forwardRef, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { SingleValue } from 'react-select';

import { faSquirtyUser, faSquirtyUsers } from 'components/icons';
import { CustomSelectControl, CustomSelectInput } from 'components/inputs/components';
import { sortedArrayByStringProperty } from 'utils/arrays';
import Customer from 'utils/types/Customer';
import User from 'utils/types/User';

interface CustomerOrUserOption {
  readonly value: Customer | User;
  readonly label: string;
  readonly type: 'customer' | 'user';
}

interface CustomerOrUserSelectInputProps {
  onSelect: (
    customer: Customer | User,
    type: 'customer' | 'user',
    { clear }: { clear: () => void },
  ) => void;
  customersToSelect?: Customer[];
  usersToSelect?: User[];
  className?: string;
  name?: string;
  isPending?: boolean;
}

export const CustomerOrUserSelectInput = forwardRef<any, CustomerOrUserSelectInputProps>(
  ({ onSelect, customersToSelect, usersToSelect, className, name, isPending }, ref) => {
    const { t } = useTranslation('components');

    const [selectedOption, setSelectedOption] = useState<CustomerOrUserOption | null>(null);

    const clear = () => setSelectedOption(null);

    const onChange = (selectedOptionValue: SingleValue<CustomerOrUserOption>) => {
      const selectedOption = options.find(option => option.value === selectedOptionValue?.value);
      setSelectedOption(selectedOption || null);

      if (selectedOption) {
        return onSelect(selectedOption.value, selectedOption.type, { clear });
      }
    };
    // ...sortedArrayByStringProperty(
    const options = useMemo(
      () =>
        sortedArrayByStringProperty(
          [
            ...(customersToSelect || []).map(
              customer =>
                ({
                  label: customer.name,
                  value: customer,
                  type: 'customer',
                }) as CustomerOrUserOption,
            ),
            ...(usersToSelect || []).map(
              user =>
                ({
                  label: user.full_name,
                  value: user,
                  type: 'user',
                }) as CustomerOrUserOption,
            ),
          ],
          'label',
        ),
      [customersToSelect, usersToSelect],
    );

    return (
      <Select<CustomerOrUserOption, false>
        ref={ref}
        className={className}
        classNamePrefix="select"
        placeholder={t('inputs.CustomerOrUserSelect.placeholder')}
        name="ws-customer-select-input"
        inputId={name}
        isLoading={isPending}
        isSearchable
        value={selectedOption}
        formatOptionLabel={option => (
          <>
            <FontAwesomeIcon
              className={classNames({
                'text-brand-green mr-[6px]': option.type === 'customer',
                'text-brand-green-light-2 ml-[3px] mr-[10px]': option.type === 'user',
              })}
              icon={option.type === 'customer' ? faSquirtyUsers : faSquirtyUser}
            />
            {option.label}
          </>
        )}
        components={{
          Input: CustomSelectInput,
          Control: CustomSelectControl,
        }}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        menuShouldScrollIntoView
        menuPosition="fixed"
        openMenuOnClick
        openMenuOnFocus
      />
    );
  },
);
