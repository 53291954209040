import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Warning } from 'components';
import GatewaySetupGuideLink from 'components/links/GatewaySetupGuideLink';
import { useGateway } from 'utils/hooks/data';
import { notificationSuccess } from 'utils/notifications';

export const GatewayNotSetupAlert: React.FC<{
  className?: string;
  gatewayId: string;
  showMarkAsSetup?: boolean;
  includeLinkToGateway?: boolean;
}> = ({ className, gatewayId, showMarkAsSetup = false, includeLinkToGateway = false }) => {
  const { t } = useTranslation();

  const { gateway, updateGateway } = useGateway(gatewayId);

  if (gateway && !!gateway.setup_completed_at) return null;

  return (
    <Warning className={className}>
      <span className="font-extrabold">{t('utils:notice')}</span>:{' '}
      {gateway && includeLinkToGateway ? (
        <Link className="underline hover:text-blue-500" to={`/user/gateways/${gatewayId}`}>
          {gateway.name}
        </Link>
      ) : (
        <>
          {t('utils:your')}{' '}
          {includeLinkToGateway ? (
            <Link className="underline hover:text-blue-500" to={`/user/gateways/${gatewayId}`}>
              {t('utils:gateway')}
            </Link>
          ) : (
            t('utils:gateway')
          )}
        </>
      )}{' '}
      {t('components:Alerts.GatewayNotSetupAlert.notSetupGateway')}.{' '}
      <GatewaySetupGuideLink className="inline" textClassName="text-white" />
      {showMarkAsSetup && (
        <>
          {' '}
          {t('utils:or')}{' '}
          <span
            className="underline hover:text-blue-500 cursor-pointer"
            onClick={async () => {
              await updateGateway({ setup_completed_at: new Date() });
              notificationSuccess(t('components:Alerts.GatewayNotSetupAlert.onMarkAsSetupClick'));
            }}
          >
            {t('components:Alerts.GatewayNotSetupAlert.markAsSetup')}
          </span>
          .
        </>
      )}
    </Warning>
  );
};
