import { forwardRef, MutableRefObject, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { GenericSelectInput, SelectProps } from 'components';
import SensorGroup from 'utils/types/SensorGroup';

interface Props extends Omit<SelectProps<SensorGroup>, 'name'> {
  includeLabel?: boolean;
  groupsToSelect?: SensorGroup[];
  labelText?: string;
}

const GroupSelectInput = forwardRef<HTMLInputElement, Props>(
  ({ includeLabel = false, groupsToSelect = [], labelText, ...props }, _ref) => {
    const ref = _ref as MutableRefObject<HTMLInputElement | null>;
    const name = 'ws-group-select-input';

    const { t } = useTranslation('components');

    return (
      <>
        {includeLabel && (
          <label htmlFor={name} onClick={() => ref?.current && ref.current.focus()}>
            {labelText || t('inputs.GroupSelect.label')}
          </label>
        )}

        <GenericSelectInput
          ref={ref}
          name={name}
          optionsToSelect={useMemo(() => groupsToSelect, [groupsToSelect])}
          placeholder={t('inputs.GroupSelect.placeholder')}
          valueToTextFn={useCallback((value: SensorGroup) => value.name, [])}
          valueToIdFn={useCallback((value: SensorGroup) => value.id, [])}
          noOptionsText={t('inputs.GroupSelect.noOptionsText')}
          loadingText={t('inputs.GroupSelect.loadingText')}
          {...props}
        />
      </>
    );
  },
);

export default GroupSelectInput;
