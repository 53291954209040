import { t } from 'translations';
import { SensorTagCasing } from 'utils/enums';

export const getSensorTagCasingText = (sensorTagCasing: SensorTagCasing) => {
  switch (sensorTagCasing) {
    case SensorTagCasing.ClosedCasing:
      return t('utils:texts.sensor-tags.getSensorTagCasingText.ClosedCasing');
    case SensorTagCasing.OpenCasing:
      return t('utils:texts.sensor-tags.getSensorTagCasingText.OpenCasing');
    default:
      return 'n/a';
  }
};

export const getSensorTagCasingTextShort = (sensorTagCasing: SensorTagCasing) => {
  switch (sensorTagCasing) {
    case SensorTagCasing.ClosedCasing:
      return t('utils:texts.sensor-tags.getSensorTagCasingTextShort.ClosedCasing');
    case SensorTagCasing.OpenCasing:
      return t('utils:texts.sensor-tags.getSensorTagCasingTextShort.OpenCasing');
    default:
      return 'n/a';
  }
};
