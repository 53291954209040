import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useQueryDelete } from 'utils/queries/delete';
import { useQueryUpdate } from 'utils/queries/update';
import Report, { parseReport } from 'utils/types/Report';

export const useReport = (reportId?: string) => {
  const queryKey = ['reports', reportId];

  // Get
  const {
    data: report,
    isPending,
    isFetching,
    isLoading,
  } = useQuery<Report>({
    queryKey,
    queryFn: () => api.get(`/reports/${reportId}`),
    enabled: !!reportId,
    select: (data: any) => parseReport(data),
  });

  // Update
  const { mutateAsync: updateReport } = useQueryUpdate({
    api,
    queryKey,
    endpoint: `/reports/${reportId}`,
    invalidateQueryKeys: [queryKey.slice(0, 1)],
  });

  // Delete
  const { mutateAsync: deleteReport } = useQueryDelete({
    api,
    queryKey,
    endpoint: `/reports/${reportId}`,
    invalidateQueryKeys: [queryKey.slice(0, 1)],
  });

  return {
    report,
    updateReport,
    deleteReport,
    isPending: isPending || isFetching || isLoading,
  };
};
