import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { CalendarInput } from 'components';
import Modal from 'components/modals/Modal';

const CalendarModal: React.FC<{
  show: boolean;
  setShow: Dispatch<boolean>;
  onSelect: (date: Date) => void;
  initialDate?: Date;
  minDate?: Date;
  maxDate?: Date;
}> = ({ show, setShow, onSelect, initialDate, minDate, maxDate }) => {
  const { t } = useTranslation('components');

  return (
    <Modal title={t('modals.CalendarModal.title')} show={show} setShow={setShow}>
      <CalendarInput
        onSelect={date => {
          setShow(false);
          onSelect(date as Date);
        }}
        initialDate={initialDate}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Modal>
  );
};

export default CalendarModal;
