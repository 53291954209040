import classNames from 'classnames';

import { BlueprintViewStateType } from 'utils/types';

export const Slider = ({ id, selected }: { id: string; selected: BlueprintViewStateType }) => (
  <div
    id={id}
    className={classNames(
      'absolute top-0 left-0 w-1/3 bg-brand-green text-white h-full transition-transform duration-300 rounded-full transform',
      {
        'translate-x-0': selected === BlueprintViewStateType.Moisture,
        'translate-x-full': selected === BlueprintViewStateType.SignalStrength,
        'translate-x-double': selected === BlueprintViewStateType.RiskScore,
      },
    )}
    style={{}}
  />
);
