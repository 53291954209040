import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { showChat } from 'utils/hubspot-chat';

export const SupportTicketButton: React.FC<
  {
    tooltipText: string;
  } & HTMLAttributes<HTMLDivElement>
> = ({ tooltipText, className, ...props }) => {
  const { t } = useTranslation('components');

  return (
    <div className={classNames(className, 'text-xs text-center lg:text-left pb-14')} {...props}>
      <span className="mb-3">
        <FontAwesomeIcon
          className="mr-2"
          data-tooltip-content={tooltipText}
          data-tooltip-id="route-tooltip"
          icon={faQuestionCircle}
        />
        {t('buttons.SupportTicketButton.text.1')}
      </span>

      <button
        className="underline ml-2 text-brand-green hover:blue-500"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          showChat();
        }}
      >
        {t('buttons.SupportTicketButton.text.2')}
      </button>
    </div>
  );
};
