import { faAngleDoubleDown, faAngleDoubleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export const ExpandButton = ({ toggleNode }: { toggleNode: () => void }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <button
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();

        toggleNode();
        setIsExpanded(!isExpanded);
      }}
      className="cursor-pointer"
    >
      <FontAwesomeIcon icon={isExpanded ? faAngleDoubleUp : faAngleDoubleDown} />
    </button>
  );
};
