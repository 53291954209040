import Locale from 'utils/enums/Locale';

type AlarmRuleLocaleDetail = {
  id: string;

  locale: Locale;
  name?: string;

  created_date: Date;
  created_by: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseAlarmRuleLocaleDetail = (alarmRuleLocaleDetail: any): AlarmRuleLocaleDetail => {
  alarmRuleLocaleDetail.created_date = new Date(alarmRuleLocaleDetail.created_date);

  if (alarmRuleLocaleDetail.updated_date) {
    alarmRuleLocaleDetail.updated_date = new Date(alarmRuleLocaleDetail.updated_date);
  }

  return alarmRuleLocaleDetail as AlarmRuleLocaleDetail;
};

export default AlarmRuleLocaleDetail;
