import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import { useLayout } from 'utils/hooks';

export const MinimizeSidebarButton: React.FC<{ className?: string }> = ({ className }) => {
  const { toggleSidebar, isSidebarMinimized } = useLayout();
  const { t } = useTranslation('components');

  return (
    <Button variant="secondary" className={className} onClick={() => toggleSidebar()} size="sm">
      {isSidebarMinimized ? (
        <FontAwesomeIcon icon={faArrowRight} size="1x" />
      ) : (
        <>
          <FontAwesomeIcon className="mr-2" icon={faArrowLeft} size="1x" />
          {t('layouts.Sidebar.minimize')}
        </>
      )}
    </Button>
  );
};
