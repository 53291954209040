import { useIsMutating } from '@tanstack/react-query';
import queryString from 'query-string';
import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { SubmittingButton, LoadingButton } from 'components';
import SensorTagsForm from 'components/forms/SensorTagsForm';
import Modal from 'components/modals/Modal';
import SensorType from 'utils/enums/SensorType';
import { useTimePeriod } from 'utils/hooks';
import { useSensor, useSensorPrimaryFile, useSensorTags } from 'utils/hooks/data';
import { BlueprintPosition } from 'utils/types';

const BlueprintSensorModal: React.FC<{
  blueprintPosition?: BlueprintPosition;
  show: boolean;
  showActions?: boolean;
  setShow: Dispatch<boolean>;
  onDetach: (blueprintPosition: BlueprintPosition) => void;
  onResetPosition: (blueprintPosition: BlueprintPosition) => void;
}> = ({ blueprintPosition, show, showActions, setShow, onDetach, onResetPosition }) => {
  const { t } = useTranslation('components');
  const isMutating = !!useIsMutating();

  const sensorId = blueprintPosition?.sensor_id;

  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { sensor } = useSensor(sensorId);
  const { file: fileOriginal } = useSensorPrimaryFile(sensorId, false);
  const { updateSensorTags } = useSensorTags(sensorId);

  return (
    <Modal
      title={
        <Link
          className="text-brand-green underline hover:text-blue-500"
          to={{
            pathname: `/user/sensors/${sensor?.id}`,
            search: queryString.stringify({
              timeFrom: timeFrom?.toISOString(),
              timeTo: timeTo?.toISOString(),
            }),
          }}
        >
          {sensor?.name || sensor?.hardware_id}
        </Link>
      }
      footer={
        <>
          {showActions && blueprintPosition && (
            <div className="flex justify-between">
              <SubmittingButton
                buttonText={t('forms.SensorTagsForm.buttonText')}
                submitting={isMutating}
                form="sensorTagsForm"
              />
              <div className="flex justify-end gap-2">
                <LoadingButton
                  size="sm"
                  variant="outline-info"
                  onClick={() => onResetPosition(blueprintPosition)}
                  isPending={isMutating}
                >
                  {t('modals.BlueprintSensorModal.onResetPosition')}
                </LoadingButton>
                <LoadingButton
                  size="sm"
                  variant="outline-danger"
                  onClick={() => onDetach(blueprintPosition)}
                  isPending={isMutating}
                >
                  {t('modals.BlueprintSensorModal.detach')}
                </LoadingButton>
              </div>
            </div>
          )}
        </>
      }
      show={show}
      setShow={setShow}
    >
      <div className="text-center">
        <a href={fileOriginal?.storage_url} target="_blank" rel="noopener noreferrer">
          <img
            src={fileOriginal?.storage_url}
            className="mx-auto max-w-full max-h-72 rounded-sm"
            alt={
              fileOriginal?.storage_url
                ? fileOriginal?.description
                : t('modals.BlueprintSensorModal.noImage')
            }
          />
        </a>
        {sensor?.geographic_location?.description && (
          <p className="mt-2">
            <>
              {t('modals.BlueprintSensorModal.location')}:{' '}
              <i>{sensor?.geographic_location?.description}</i>
            </>
          </p>
        )}
      </div>

      <SensorTagsForm
        onSubmit={async values => await updateSensorTags(values)}
        sensorTags={sensor?.tags}
        showTitles
        showTheSensorTags={
          sensor?.type === SensorType.WOODSENSE_WOODY ||
          sensor?.type === SensorType.WOODSENSE_FLATTY
        }
        showPrimaryPlacementTags
        showEnvironmentTags
        showRoomAndOrientationTags
        hideSubmitButton
        sensorIdForPredictions={sensorId}
      />
    </Modal>
  );
};

export default BlueprintSensorModal;
