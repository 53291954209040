import {
  CanvasStage,
  MAX_SCALE,
  MIN_SCALE,
  PERFECT_DRAW_ENABLED,
} from 'components/CanvasStage/CanvasStage';
import { ImageSize, LabelProps, StageState } from 'components/CanvasStage/types';

export type { LabelProps, StageState, ImageSize };
export { MIN_SCALE, MAX_SCALE, PERFECT_DRAW_ENABLED };
export default CanvasStage;
