import { useMutation } from '@tanstack/react-query';

import api from 'utils/api';
import queryClient from 'utils/queries/client';
import { useQueryCreate } from 'utils/queries/create';
import User, { parseUser } from 'utils/types/User';

export const useUsers = (enableGet: boolean = true) => {
  const queryKey = ['users'];

  // Update by ID
  const { mutateAsync: updateUserById } = useMutation({
    mutationFn: async ({ id: userId, ...props }: { id: string } & any) => {
      const user = api.put(`/users/${userId}`, props);
      return user;
    },
    onSuccess: (userRaw: User) => {
      const user = parseUser(userRaw);
      queryClient.setQueryData(['users', user.id], user);
      queryClient.invalidateQueries({ queryKey: ['users'], exact: true });
    },
  });

  // Create
  const { mutateAsync: createUser } = useQueryCreate({
    api,
    queryKey,
    endpoint: '/users',
    parseFn: parseUser,
  });

  // Delete
  const { mutateAsync: deleteUserById } = useMutation({
    mutationFn: (userId: string) => api.delete(`/admin/users/${userId}`),
    onSuccess: (result, userId, context) => {
      queryClient.invalidateQueries({ queryKey, exact: true });
      queryClient.removeQueries({ queryKey: ['users', userId] });
    },
  });

  return {
    updateUserById,
    createUser,
    deleteUserById,
  };
};
