import WeatherObservationQuality from 'utils/enums/WeatherObservationQuality';

type SensorWeatherObservation = {
  timestamp: Date; // Time of data calculation UTC
  sensor_id: string; // Sensor ID
  created_at: Date; // Time the row was created
  quality: WeatherObservationQuality; // Quality level
  latitude: number;
  longitude: number;
  rain_1h?: number; // Total rain past 1 hour [mm]
  snow_1h?: number; // Total snow past 1 hour [mm]
  humidity?: number; // Humidity [%]
  pressure?: number; // Atmospheric pressure [hPa]
  temperature?: number; // Temperature [°C]
  wind_speed?: number; // Wind speed [m/s]
  wind_dir?: number; // Wind direction [°]
};

export const parseSensorWeatherObservation = (
  sensorWeatherObservation: any
): SensorWeatherObservation => {
  sensorWeatherObservation.timestamp = new Date(sensorWeatherObservation.timestamp);
  sensorWeatherObservation.created_at = new Date(sensorWeatherObservation.created_at);
  return sensorWeatherObservation as SensorWeatherObservation;
};

export default SensorWeatherObservation;
