import classnames from 'classnames';
import { useField } from 'formik';
import React, { HTMLAttributes, useMemo } from 'react';

export const FormTextareaInput: React.FC<
  {
    label?: string | JSX.Element;
    placeholder?: string;
    text?: string;
    type?: 'text';
    highlightChanges?: boolean;
    rows?: number;
    name: string;
    disabled?: boolean;
    maxLength?: number;
    required?: boolean;
  } & HTMLAttributes<HTMLElement>
> = ({
  label,
  placeholder = '',
  text,
  type = 'text',
  highlightChanges = false,
  rows = 3,
  name,
  className = 'mb-3',
  maxLength,
  required = false,
  ...props
}) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);
  const [field, meta] = useField(name);

  // helpers: {setValue, setTouched, setError}
  // props:   {name, disabled}
  // field:   {name, value, onChange, onBlur}

  // Define if the value is valid
  // const isInvalid = (!props.disabled && meta.error && meta.touched);
  const isInvalid = meta.error && meta.touched;

  // Make sure a empty string is used if no value is defined
  field.value = field.value || '';

  return (
    <div className={className}>
      {label && (
        <label className="mb-1.5" htmlFor={controlId}>
          {label}
          {required && (
            <span
              className="inline"
              data-tooltip-content="This is a required field"
              data-tooltip-id="route-tooltip"
            >
              *
            </span>
          )}
        </label>
      )}
      <textarea
        rows={rows}
        id={controlId}
        className={classnames('', {
          'ws-form-text-input__on-change': meta.initialValue !== meta.value && highlightChanges,
        })}
        placeholder={placeholder}
        maxLength={maxLength}
        {...field}
        {...props}
      />
      {text && <span>{text}</span>}
      {isInvalid && <p className="text-brand-orange">{meta.error}</p>}
    </div>
  );
};
