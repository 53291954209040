import { FieldHookConfig, useField } from 'formik';
import React, { useMemo, ComponentPropsWithoutRef } from 'react';

import Checkbox, { CheckboxProps } from 'components/Checkbox';

export const FormCheckboxInput: React.FC<
  Pick<CheckboxProps, 'label' | 'name' | 'labelClassName'> & ComponentPropsWithoutRef<'input'>
> = ({ label, labelClassName, ...props }) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(props as FieldHookConfig<any>); // eslint-disable-line no-unused-vars

  // helpers: {setValue, setTouched, setError}
  // props:   {name, disabled}
  // field:   {name, value, onChange, onBlur}

  // Define if the value is valid
  const isInvalid = !props.disabled && meta.error && meta.touched;

  // Set default `value`
  field.value = field.value || false;

  return (
    <>
      <Checkbox
        labelClassName={labelClassName}
        label={label}
        id={controlId}
        checked={field.value}
        {...field}
        {...props}
      />
      {isInvalid && <p className="text-brand-orange mb-1.5">{meta.error}</p>}
    </>
  );
};
