import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faSquirtyBricks2: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    512, // width
    512, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M486.4,272.563h-56.021c0.034-0.529,0.307-0.973,0.307-1.51v-81.314c0-14.14-11.46-25.6-25.6-25.6h-56.021 c0.034-0.529,0.307-0.973,0.307-1.51V81.314c0-14.14-11.46-25.6-25.6-25.6H188.237c-14.14,0-25.6,11.46-25.6,25.6v81.314 c0,0.538,0.273,0.981,0.307,1.51h-56.021c-14.14,0-25.6,11.46-25.6,25.6v81.314c0,0.538,0.273,0.981,0.307,1.51H25.6 c-14.14,0-25.6,11.46-25.6,25.6v81.314c0,14.14,11.46,25.6,25.6,25.6h460.8c14.14,0,25.6-11.46,25.6-25.6v-81.314 C512,284.023,500.54,272.563,486.4,272.563z M405.077,189.739v81.314H269.551v-81.314H405.077z M188.237,81.314h135.535v81.314 H188.237V81.314z M106.923,189.739h135.526v81.314H106.923V189.739z M161.126,379.486H25.6v-81.314h135.526V379.486z M323.772,379.486h-0.009H188.237v-81.314h135.535V379.486z M486.4,379.486H350.874v-81.314H486.4V379.486z M499.2,430.686H12.8c-7.074,0-12.8,5.726-12.8,12.8s5.726,12.8,12.8,12.8h486.4c7.074,0,12.8-5.726,12.8-12.8 S506.274,430.686,499.2,430.686z',
  ],
};

export default faSquirtyBricks2;
