enum SignalStrengthState {
  VERY_GOOD = 'VERY_GOOD', // Spreading Factor 7
  GOOD = 'GOOD', // Spreading Factor 8
  FAIR = 'FAIR', // Spreading Factor 9
  MODERATE = 'MODERATE', // Spreading Factor 10
  POOR = 'POOR', // Spreading Factor 11
  VERY_POOR = 'VERY_POOR', // Spreading Factor 12

  INACTIVE = 'INACTIVE',
  UNKNOWN = 'UNKNOWN',
}

export const sortOrderSignalStrengthState: { [key: string]: number } = [
  SignalStrengthState.UNKNOWN,
  SignalStrengthState.VERY_POOR,
  SignalStrengthState.POOR,
  SignalStrengthState.MODERATE,
  SignalStrengthState.FAIR,
  SignalStrengthState.GOOD,
  SignalStrengthState.VERY_GOOD,
]
  .map((state, index) => [state, index])
  .reduce((o: any, [state, index]) => ({ ...o, [state]: index }), {});

export default SignalStrengthState;
