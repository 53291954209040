import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import queryClient from 'utils/queries/client';
import { useQueryCreate } from 'utils/queries/create';
import { parseDemoApplication } from 'utils/types/DemoApplication';

export const useDemoApplications = () => {
  const queryKey = ['demoApplications'];

  // Get
  const { data: demoApplications, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get('/admin/demo_applications'),
    select: (data: any[]) => data.map(parseDemoApplication),
  });

  // Create
  const { mutateAsync: createDemoApplication } = useQueryCreate({
    api,
    queryKey,
    endpoint: '/demo_applications',
    parseFn: parseDemoApplication,
  });

  // Delete
  const { mutateAsync: deleteDemoApplicationById } = useMutation({
    mutationFn: (demoApplicationId?: string) =>
      api.delete(`/admin/demo_applications/${demoApplicationId}`),
    onSuccess: (result, demoApplicationId, context) => {
      queryClient.invalidateQueries({ queryKey, exact: true });
      queryClient.removeQueries({ queryKey: ['demoApplications', demoApplicationId] });
    },
  });

  return {
    demoApplications,
    createDemoApplication,
    deleteDemoApplicationById,
    isPending,
  };
};
