import { faSliders } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { UncontrolledSwitch } from 'components';
import { useSensor } from 'utils/hooks/data';

export const AlarmCell: React.FC<{
  id: string;
  checked: boolean;
  onEditClick: () => void;
  showEditButton: boolean;
}> = ({ id, checked, onEditClick, showEditButton }) => {
  const { updateSensor } = useSensor(id, { enableGet: false });
  const { t } = useTranslation('components');

  const on = t('tables.SensorsPaginatedTable.on');
  const off = t('tables.SensorsPaginatedTable.off');

  return (
    <div className="flex justify-center space-x-2">
      <UncontrolledSwitch
        data-tooltip-content={t('tables.SensorsPaginatedTable.alarmCellSwitchTooltip', {
          toggle: checked ? off : on,
        })}
        data-tooltip-id="route-tooltip"
        className="justify-center"
        onChange={async e => await updateSensor({ send_alarms: e.target.checked })}
        defaultChecked={checked}
      />
      {showEditButton && (
        <FontAwesomeIcon
          onClick={onEditClick}
          data-tooltip-content={t('tables.SensorsPaginatedTable.alarmCellSettingsTooltip')}
          data-tooltip-id="route-tooltip"
          className="text-brand-gray-light-2 cursor-pointer text-lg"
          icon={faSliders}
        />
      )}
    </div>
  );
};
