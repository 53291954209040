import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import Sensor from 'utils/types/Sensor';

type Options = HookOptions & {
  blueprintId?: string;
  onlyValidPositions?: boolean;
};

export const useUtilsBulkSensorPositionCheck = (
  sensors?: Sensor[],
  { enableGet = true, blueprintId, onlyValidPositions }: Options = {},
) => {
  const sensorIds = (sensors || []).map(sensor => sensor.id);

  const queryKey = ['useUtilsBulkSensorPositionCheck', sensorIds, blueprintId, onlyValidPositions];

  const { data: sensorHasBlueprintPosition, isPending } = useQuery({
    queryKey,
    queryFn: async () =>
      api.post('/utils/bulk_sensor_position_check', {
        sensor_ids: sensorIds,
        blueprint_id: blueprintId,
        only_valid_positions: onlyValidPositions,
      }),
    select: (sensorHasBlueprintPosition: any) =>
      sensorHasBlueprintPosition as { [key: string]: boolean },
    enabled: enableGet,
  });

  return {
    sensorHasBlueprintPosition,
    isPending,
  };
};
