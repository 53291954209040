const enum AlarmType {
  Unknown = 0,

  MissingValues48Hours = 51,
  MissingValues1Week = 52,
  MissingValues4Weeks = 53,
  SensativeFloodAlarm = 101,

  GatewayMissingHeartbeats24Hours = 202,
  GatewayMissingHeartbeats1Week = 203,
  GatewayMissingHeartbeats4Weeks = 204,

  CustomAlarmRule = 300,

  ReferenceValues = 400,

  // Internal alarms (anything above 1000)
  InternalNewDevice = 1010,
  InternalMoistureIncreased = 1011,
}

export default AlarmType;
