import DownlinkStatus from 'utils/enums/DownlinkStatus';

type SensorDownlink = {
  id: string;

  sensor_id: string;
  payload: string;
  status?: DownlinkStatus;
  frequency: number;

  created_date: Date;
  created_by: string;
};

export const parseSensorDownlink = (sensorDownlink: any): SensorDownlink => {
  sensorDownlink.created_date = new Date(sensorDownlink.created_date);
  return sensorDownlink;
};

export default SensorDownlink;
