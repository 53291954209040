// @ts-ignore
import Nominatim from 'nominatim-geocoder';
import React, { useCallback, useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';

import debounce from 'utils/debounce';
import isRunningLocally from 'utils/locally';
import 'styles/components/_autosuggest.scss';
import 'styles/components/_address-input.scss';

const geocoder = new Nominatim({
  delay: 250, // delay between requests
  secure: !isRunningLocally, // enables ssl
  // host:'nominatim.openstreetmap.org',
  // customUrl: 'http://your-own-nominatim/', // if you want to host your own nominatim
  format: 'json',
  limit: 5,
});

export type AddressType = {
  lat: number;
  lon: number;
  display_name: string;
};

export const GlobalAddressInput: React.FC<{
  onAddressSelected?: ({ lat, lon, display_name }: AddressType) => void;
  placeholder?: string;
  initialValue?: string;
  disabled?: boolean;
}> = ({
  onAddressSelected = ({ lat, lon, display_name }: AddressType) => {},
  placeholder = 'Adresse',
  initialValue = '',
  disabled = false,
  ...props
}) => {
  const [suggestedInput, setSuggestedInput] = useState(initialValue || '');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestedInput(initialValue || '');
  }, [initialValue]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLookup = useCallback(
    debounce((query: any) => {
      geocoder.search({ q: query }).then((response: any) => {
        setSuggestions(response);
      });
    }, 250),
    [],
  );

  const getSuggestionValue = (suggestion: any) => suggestion.display_name;

  const onSuggestionSelected = (
    event: React.FormEvent<HTMLInputElement>,
    { suggestion }: { suggestion: any },
  ) => {
    event.preventDefault();
    onAddressSelected(suggestion);
  };

  const inputProps = {
    placeholder,
    value: suggestedInput,
    onChange: (
      event: React.FormEvent<HTMLElement>,
      {
        newValue,
        method,
      }: { newValue: string; method: 'down' | 'up' | 'escape' | 'enter' | 'click' | 'type' },
    ) => {
      event.preventDefault();
      setSuggestedInput(newValue);
    },
    id: 'address-input',
    disabled,
  };

  const renderSuggestion = (suggestion: any) => <span>{suggestion.display_name}</span>;

  const onSuggestionsClearRequested = () => {
    // setSuggestedInput('');
  };

  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    setSuggestions([]);
    debouncedLookup(value);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      theme={theme}
      {...props}
    />
  );
};

const theme = {
  container: 'address-autosuggest__container',
  containerOpen: 'address-autosuggest__container--open',
  input: 'address-autosuggest__input form-control',
  inputOpen: 'address-autosuggest__input--open',
  inputFocused: 'address-autosuggest__input--focused',
  suggestionsContainer: 'address-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'address-autosuggest__suggestions-container--open',
  suggestionsList: 'address-autosuggest__suggestions-list',
  suggestion: 'address-autosuggest__suggestion',
  suggestionFirst: 'address-autosuggest__suggestion--first',
  suggestionHighlighted: 'address-autosuggest__suggestion--highlighted',
  sectionContainer: 'address-autosuggest__section-container',
  sectionContainerFirst: 'address-autosuggest__section-container--first',
  sectionTitle: 'address-autosuggest__section-title',
};
