import classNames from 'classnames';
import { Field, useField } from 'formik';
import { useTranslation } from 'react-i18next';

import GatewaySensorsDistance from 'utils/enums/GatewaySensorsDistance';
import getGatewaySensorsDistanceText from 'utils/texts/GatewaySensorsDistance';

export const GatewaySensorsDistanceSelectInput: React.FC<{
  name: string;
  className?: string;
}> = ({ name, className }) => {
  const { t } = useTranslation('components');

  const { value } = useField(name)[1];

  const controlId = Math.random().toString(36).substring(7);
  return (
    <>
      <label className="mb-2" htmlFor={controlId}>
        {t('inputs.GatewaySensorsDistanceSelect.label')}
      </label>
      <Field
        as="select"
        name={name}
        id={controlId}
        className={classNames('rounded', className)}
        value={value || ''}
      >
        <option value={undefined}>{t('inputs.GatewaySensorsDistanceSelect.placeholder')}</option>
        {[
          GatewaySensorsDistance.FROM_0_TO_10M,
          GatewaySensorsDistance.FROM_10_TO_20M,
          GatewaySensorsDistance.FROM_20_TO_50M,
          GatewaySensorsDistance.FROM_50_TO_100M,
          GatewaySensorsDistance.MORE_THAN_100M,
        ].map((gatewaySensorsDistance, index) => (
          <option key={index} value={gatewaySensorsDistance}>
            {getGatewaySensorsDistanceText(gatewaySensorsDistance)}
          </option>
        ))}
      </Field>
    </>
  );
};
