import { useState } from 'react';

import Thumbnail, { ThumbnailProps } from 'components/thumbnails/Thumbnail';
import { useGatewayPrimaryFile } from 'utils/hooks/data';

const GatewayThumbnail: React.FC<
  {
    gatewayId: string;
    gatewayFileId?: string;
  } & ThumbnailProps
> = ({ gatewayId, gatewayFileId, ...props }) => {
  const [loadOriginal, setLoadingOriginal] = useState(false);

  const { file } = useGatewayPrimaryFile(gatewayId, true, !!gatewayFileId);
  const { file: fileOriginal } = useGatewayPrimaryFile(
    gatewayId,
    false,
    !!gatewayFileId && loadOriginal
  );

  return (
    <Thumbnail
      tooltipId={`tooltip-thumbnail-${gatewayId}`}
      loadOriginal={loadOriginal}
      setLoadingOriginal={setLoadingOriginal}
      file={file}
      fileOriginal={fileOriginal}
      {...props}
    />
  );
};

export default GatewayThumbnail;
