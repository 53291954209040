import { SyntheticEvent, useEffect, useState } from 'react';

import { Alert } from 'components';
import { getStorageBoolean, setStorageBoolean } from 'utils/local-storage';

const DismissableAlert: React.FC<{ storageKey: string; html: JSX.Element }> = ({
  storageKey,
  html,
}) => {
  const localStorageKey = `ws-alert-show-${storageKey}`;
  const [show, setShow] = useState(getStorageBoolean(localStorageKey, true));

  useEffect(() => {
    setStorageBoolean(localStorageKey, show);
  }, [show]); // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = (e: SyntheticEvent) => {
    e.preventDefault();
    setShow(false);
  };

  return show ? (
    <Alert className="bg-brand-gray-light-4 p-4 shadow text-white mb-5" onClose={onClose}>
      {html}
    </Alert>
  ) : null;
};

export default DismissableAlert;
