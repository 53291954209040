import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import { parseSensorGroup } from 'utils/types/SensorGroup';

export const useGroupDeliveryGroups = (
  groupId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = ['useGroupDeliveryGroups', groupId];

  // Get
  const { data: deliveryGroups, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${groupId}/delivery_groups`),
    select: (sensorGroups: any[]) => sensorGroups.map(parseSensorGroup),
    enabled: enableGet && !!groupId,
  });

  return {
    deliveryGroups,
    isPending,
  };
};
