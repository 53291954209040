type CustomerSettings = {
  id: string;

  show_groups: boolean;
  show_reports: boolean;
  show_alarms: boolean;
  show_gateways: boolean;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseCustomerSettings = (customerSettings: any): CustomerSettings => {
  customerSettings.created_date = new Date(customerSettings.created_date);

  if (customerSettings.updated_date) {
    customerSettings.updated_date = new Date(customerSettings.updated_date);
  }

  return customerSettings as CustomerSettings;
};

export default CustomerSettings;
