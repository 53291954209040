import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faSquirtyWall: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    25, // width
    24, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M11.413 0H1.63039C1.33024 0 1.08691 0.233591 1.08691 0.521739V4.69565C1.08691 4.9838 1.33024 5.21739 1.63039 5.21739H11.413C11.7132 5.21739 11.9565 4.9838 11.9565 4.69565V0.521739C11.9565 0.233591 11.7132 0 11.413 0Z M23.3695 0H13.5869C13.2868 0 13.0435 0.233591 13.0435 0.521739V4.69565C13.0435 4.9838 13.2868 5.21739 13.5869 5.21739H23.3695C23.6697 5.21739 23.913 4.9838 23.913 4.69565V0.521739C23.913 0.233591 23.6697 0 23.3695 0Z M17.9347 6.26086H8.15212C7.85197 6.26086 7.60864 6.49445 7.60864 6.7826V10.9565C7.60864 11.2447 7.85197 11.4783 8.15212 11.4783H17.9347C18.2349 11.4783 18.4782 11.2447 18.4782 10.9565V6.7826C18.4782 6.49445 18.2349 6.26086 17.9347 6.26086Z M5.97822 6.26086H1.63039C1.33024 6.26086 1.08691 6.49445 1.08691 6.7826V10.9565C1.08691 11.2447 1.33024 11.4783 1.63039 11.4783H5.97822C6.27837 11.4783 6.5217 11.2447 6.5217 10.9565V6.7826C6.5217 6.49445 6.27837 6.26086 5.97822 6.26086Z M23.3695 6.26086H20.1087C19.8085 6.26086 19.5652 6.49445 19.5652 6.7826V10.9565C19.5652 11.2447 19.8085 11.4783 20.1087 11.4783H23.3695C23.6697 11.4783 23.913 11.2447 23.913 10.9565V6.7826C23.913 6.49445 23.6697 6.26086 23.3695 6.26086Z M13.587 17.7391H23.3696C23.6698 17.7391 23.9131 17.5055 23.9131 17.2174V13.0435C23.9131 12.7553 23.6698 12.5217 23.3696 12.5217H13.587C13.2868 12.5217 13.0435 12.7553 13.0435 13.0435V17.2174C13.0435 17.5055 13.2868 17.7391 13.587 17.7391Z M1.63046 17.7391H11.4131C11.7132 17.7391 11.9565 17.5055 11.9565 17.2174V13.0435C11.9565 12.7553 11.7132 12.5217 11.4131 12.5217H1.63046C1.3303 12.5217 1.08698 12.7553 1.08698 13.0435V17.2174C1.08698 17.5055 1.3303 17.7391 1.63046 17.7391Z M1.5 24L23.5 24C23.7761 24 24 23.7761 24 23.5V19.5C24 19.2239 23.7761 19 23.5 19L1.5 19C1.22386 19 1 19.2239 1 19.5V23.5C1 23.7761 1.22386 24 1.5 24Z',
  ],
};

export default faSquirtyWall;
