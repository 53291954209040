import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import TreeType from 'utils/enums/TreeType';
import Sensor from 'utils/types/Sensor';
import SensorTags from 'utils/types/SensorTags';

export const useSensorsBulkUpdate = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateSensorsBulk } = useMutation({
    mutationFn: async ({
      sensorIds,
      ...props
    }: {
      sensorIds: string[];
      customer_id?: string;
      tree_type?: TreeType;
      send_alarms?: boolean;
      tags?: SensorTags;
    }): Promise<Sensor[]> =>
      api.put('/sensors_bulk', {
        sensor_ids: sensorIds,
        ...props,
      }),
    onSuccess: async (sensors, props) => {
      for (const sensor of sensors) {
        queryClient.invalidateQueries({ queryKey: ['sensor', sensor.id] });
      }
      for (const queryKey of [['sensorGroupSensors'], ['blueprintSensors'], ['customerSensors']]) {
        queryClient.invalidateQueries({ queryKey });
      }
    },
  });

  return {
    updateSensorsBulk,
  };
};
