import { t } from 'translations';
import AlarmRuleComparisonOperator from 'utils/enums/AlarmRuleComparisonOperator';

export const getAlarmRuleComparisonOperatorText = (
  alarmRuleComparisonOperator: AlarmRuleComparisonOperator
) => {
  switch (alarmRuleComparisonOperator) {
    case AlarmRuleComparisonOperator.GreaterThan:
      return t(
        'utils:texts.AlarmRuleComparisonOperator.getAlarmRuleComparisonOperatorText.GreaterThan'
      );
    case AlarmRuleComparisonOperator.LessThan:
      return t(
        'utils:texts.AlarmRuleComparisonOperator.getAlarmRuleComparisonOperatorText.LessThan'
      );
    case AlarmRuleComparisonOperator.SubsequentDecrease:
      return t(
        'utils:texts.AlarmRuleComparisonOperator.getAlarmRuleComparisonOperatorText.SubsequentDecrease'
      );
    case AlarmRuleComparisonOperator.SubsequentIncrease:
      return t(
        'utils:texts.AlarmRuleComparisonOperator.getAlarmRuleComparisonOperatorText.SubsequentIncrease'
      );
    default:
      return 'n/a';
  }
};
