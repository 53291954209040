import classnames from 'classnames';
import Leaflet, { LatLngExpression, Layer, Map } from 'leaflet';
import { type CSSProperties, SyntheticEvent, useEffect, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import PointMarker from 'components/maps/markers/PointMarker';
import MoistureState from 'utils/enums/MoistureState';
import Marker from 'utils/types/Marker';
import Position from 'utils/types/Position';
import 'styles/components/_maps.scss';

// Leaflet tile providers
// https://leaflet-extras.github.io/leaflet-providers/preview/
//
// Leaflet Marker options
// https://leafletjs.com/reference-1.7.1.html#circlemarker

export interface PointsMapProps<T> {
  points?: T[];
  MarkerComponent?: React.ElementType;
  filterPointFn?: (point: T, index?: number, array?: T[]) => number | undefined;
  mapPointFn?: (point: T, index?: number) => Marker;
  sortPointFn?: <T extends { state?: any }>(a: T, b: T) => number;
  onUpdateLayerFn?: (layer: Layer) => void;
  initialPosition?: Position;
  initialZoom?: number;
  onClick?: (point: Marker) => void;
  isFullScreen?: boolean;
  dragging?: boolean;
  zoomControl?: boolean;
  style?: CSSProperties;
  scrollWheelZoom?: boolean;
}

const PointsMap = <T extends {}>({
  points = [] as T[],
  MarkerComponent = PointMarker,
  filterPointFn = _defaultFilterPoint,
  mapPointFn = _defaultMapPointFn,
  sortPointFn = _defaultSortPointFn,
  onUpdateLayerFn = _defaultOnUpdateLayerFn,
  initialPosition = [55.679097, 12.552729],
  initialZoom = 10,
  onClick = () => {},
  isFullScreen = false,
  dragging = true,
  zoomControl = true,
  style,
  scrollWheelZoom = false,
}: PointsMapProps<T>) => {
  const [mapElement, setMapElement] = useState<Map>();
  const [markers, setMarkers] = useState<Marker[]>();

  useEffect(() => {
    if (!mapElement) return;

    const markers = points.filter(filterPointFn).map(mapPointFn).sort(sortPointFn);

    // Remove points with the same coordinates
    // NOTE: This is a temporary fix to avoid performance issues
    const usedPositions: string[] = [];
    const uniqueMarkers = markers.reverse().filter(marker => {
      const positionString = marker.position.map(coordinate => coordinate?.toString()).join('-');
      if (usedPositions.includes(positionString)) return false;

      usedPositions.push(positionString);
      return true;
    });

    setMarkers(uniqueMarkers);
  }, [mapElement, points]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!mapElement) return;

    const positions = markers?.map(marker => marker.position);

    if (positions && positions.length > 1) {
      const bounds = Leaflet.latLngBounds(positions.map(x => [...x, 0.0]) as LatLngExpression[]);

      // Set bounds to match markers in map
      if (bounds.getNorthEast() && bounds.getSouthWest()) {
        mapElement.fitBounds(bounds);
      }
    } else if (positions && positions.length === 1) {
      // Set center to match markers in map
      const position = positions[0];
      const lat = position[0]!;
      const lng = position[1]!;
      const bounds = new Leaflet.LatLngBounds(
        [lat - 2, lng - 1] as LatLngExpression, // southWest
        [lat + 2, lng + 1] as LatLngExpression, // northEast
      );
      mapElement.fitBounds(bounds);
    }

    mapElement.eachLayer((layer: Layer) => {
      onUpdateLayerFn(layer);
    });
  }, [markers]); // eslint-disable-line react-hooks/exhaustive-deps

  const onMarkerClick = (e: SyntheticEvent, point: Marker) => onClick(point);

  return (
    <MapContainer
      className={classnames('map-div rounded-sm z-0', {
        'map-full-view': isFullScreen,
      })}
      center={[...initialPosition, 0.0] as LatLngExpression}
      zoom={initialZoom}
      attributionControl={false} //@ts-ignore
      whenReady={({ target }: { target: Map }) => setMapElement(target)}
      tap={false} // Fix for popup bug: https://github.com/PaulLeCam/react-leaflet/issues/822
      scrollWheelZoom={scrollWheelZoom}
      doubleClickZoom={true}
      closePopupOnClick={true}
      dragging={dragging}
      trackResize={false}
      touchZoom={true}
      style={style}
      zoomControl={zoomControl}
    >
      {markers?.map((marker, idx) => (
        <MarkerComponent
          key={idx}
          onClick={(e: SyntheticEvent) => onMarkerClick(e, marker)}
          {...marker}
        />
      ))}

      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
    </MapContainer>
  );
};

const _defaultFilterPoint = <T extends { latitude?: number; longitude?: number }>(point: T) =>
  point.latitude && point.longitude;

const _defaultMapPointFn = <T extends { latitude?: number; longitude?: number }>(
  point: T,
): Marker => ({
  position: [parseFloat(String(point.latitude)), parseFloat(String(point.longitude))],
  ...point,
  state: MoistureState.UNKNOWN,
});

const _defaultSortPointFn = <T extends {}>(a: T, b: T) => 0;

const _defaultOnUpdateLayerFn = (layer: Layer) => {};

export default PointsMap;
