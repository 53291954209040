import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks';
import queryClient from 'utils/queries/client';
import { Blueprint, parseBlueprint, BlueprintPosition, parseBlueprintPosition } from 'utils/types';
import { parseSensor } from 'utils/types/Sensor';

type EndpointReturnProps = {
  blueprint?: Blueprint;
  blueprintPosition?: BlueprintPosition;
};

export const useSensorBlueprint = (sensorId?: string, disableLoading = true) => {
  const queryKey = ['sensorBlueprint', sensorId];

  const { customerId } = useSelectedCustomer();

  // Blueprint - Get
  const { data: endpointReturnProps, isPending } = useQuery<EndpointReturnProps, Error>({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/blueprint`, {
        _disableErrorNotifications: true,
        _disableSentryErrorCapture: true,
      }),
    select: ({ blueprint, position }: any) => ({
      blueprint: parseBlueprint(blueprint),
      blueprintPosition: parseBlueprintPosition(position),
    }),
    placeholderData: { blueprint: undefined, blueprintPosition: undefined },
    enabled: !!sensorId,
    meta: {
      disableLoading,
    },
  });
  const { blueprint, blueprintPosition } = endpointReturnProps || {};

  // Attach
  const { mutateAsync: attachSensorToBlueprint } = useMutation({
    mutationFn: async (blueprintId: string) => {
      const sensorsRaw: any[] = await api.post(`/blueprints/${blueprintId}/sensors/${sensorId}`);
      return sensorsRaw.map(parseSensor);
    },
    onSuccess: async (sensors, blueprintId) => {
      // Update query caches
      queryClient.setQueryData(['blueprintSensors', blueprintId], sensors);

      queryClient.invalidateQueries({ queryKey });
      queryClient.invalidateQueries({ queryKey: ['blueprintPositions', blueprintId] });
      queryClient.invalidateQueries({
        queryKey: ['customerBlueprintPositions', customerId],
      });
      queryClient.invalidateQueries({ queryKey: ['sensorGroupBlueprints'] });
      queryClient.invalidateQueries({ queryKey: ['blueprintSensors', blueprintId] });
      queryClient.invalidateQueries({ queryKey: ['useUtilsBulkSensorPositionCheck'] });
    },
  });

  return {
    attachSensorToBlueprint,
    blueprint,
    blueprintPosition,
    isPending,
  };
};
