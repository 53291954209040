import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import MoldGrowthRiskState from 'utils/enums/MoldGrowthRiskState';
import { getMoldGrowthRiskStateText } from 'utils/sensor/texts';

export const MoldGrowthRiskStateBadge: React.FC<
  { state?: MoldGrowthRiskState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) =>
  state ? (
    <Badge
      {...props}
      className={classNames('text-xs font-medium text-white', className, {
        'bg-moldGrowthRiskHighRisk': state === MoldGrowthRiskState.HIGH_RISK,
        'bg-moldGrowthRiskLowRisk': state === MoldGrowthRiskState.LOW_RISK,
        'bg-moldGrowthRiskModerateRisk': state === MoldGrowthRiskState.MODERATE_RISK,
        'bg-moldGrowthRiskNoRisk': state === MoldGrowthRiskState.NO_RISK,
        'bg-moldGrowthRiskUnknown': state === MoldGrowthRiskState.UNKNOWN,
        'bg-moldGrowthRiskVeryHighRisk': state === MoldGrowthRiskState.VERY_HIGH_RISK,
      })}
    >
      {getMoldGrowthRiskStateText(state)}
    </Badge>
  ) : (
    <Spinner className="ml-2 text-brand-gray-light-1" />
  );
