import LazyLoad from 'react-lazyload';

import ImageCard from 'components/cards/ImageCard';
import FileBase from 'utils/types/FileBase';

export const FileGallery: React.FC<{ files?: FileBase[]; onClick: (file: FileBase) => void }> = ({
  files,
  onClick,
}) => (
  <div className="grid grid-cols-2 lg:grid-cols-4 gap-3">
    {files?.map((file, i) => (
      <div key={i} className="flex-col">
        <LazyLoad offset={100} once>
          <ImageCard key={i} file={file} onClick={onClick} />
        </LazyLoad>
      </div>
    ))}
  </div>
);
