import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { faSquirtyBlueprint } from 'components/icons';
import { useSensorBlueprint } from 'utils/hooks/data';

export const BlueprintCell: React.FC<{
  sensorId: string;
}> = ({ sensorId }) => {
  const { blueprint } = useSensorBlueprint(sensorId);

  const id = `sensor-blueprint-${sensorId}`;

  return (
    <div>
      <ReactTooltip
        id={id}
        aria-haspopup="true"
        place="left"
        data-tooltip-position-strategy="fixed"
        style={{ zIndex: 100 }}
      >
        <img src={blueprint?.storage_url} alt="full size" className="max-w-xs h-auto" />
      </ReactTooltip>
      <Link data-tooltip-id={id} to={`/user/sensors/${sensorId}/positioning`}>
        <p className="text-brand-green hover:text-blue-500 inline hover:underline">
          {blueprint?.descriptionTruncated && (
            <FontAwesomeIcon className="mr-2" icon={faSquirtyBlueprint} />
          )}

          {blueprint?.descriptionTruncated}
        </p>
      </Link>
    </div>
  );
};
