import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faSquirtyHouse1: IconDefinition = {
  prefix: 'fac' as IconPrefix,
  iconName: 'splat' as IconName,
  icon: [
    268, // width
    268, // height
    [], // ligatures
    '', // unicode (if relevant)
    // svg path data
    'M251.377,168.219h-33.962v-40.564h28.81c6.702,0,10.029-8.154,5.26-12.847L136.97,2.154c-2.92-2.871-7.601-2.872-10.52,0 L11.937,114.808c-4.777,4.699-1.432,12.847,5.26,12.847h28.809v133.412c0,4.142,3.358,7.5,7.5,7.5h50.216 c4.142,0,7.5-3.358,7.5-7.5v-55.115h40.977v55.115c0,4.142,3.357,7.5,7.5,7.5c10.507,0,80.544,0,91.678,0 c4.143,0,7.5-3.358,7.5-7.5c0-10.188,0-75.321,0-85.348C258.877,171.577,255.519,168.219,251.377,168.219z M177.877,175.718 c0,2.043,0,75.743,0,77.848h-10.678v-55.115c0-4.141-3.358-7.499-7.5-7.499h-55.977c-4.142,0-7.5,3.358-7.5,7.5v55.115H61.006 V120.154c0-4.142-3.358-7.5-7.5-7.5H35.515l96.196-94.632l96.195,94.632h-17.991c-4.143,0-7.5,3.358-7.5,7.5v48.064h-17.038 C181.235,168.218,177.877,171.576,177.877,175.718z M243.877,253.566h-51v-13.449h51V253.566z M243.877,225.117h-51v-13.449h51 V225.117z M243.877,196.668h-51v-13.449c11.075,0,27.683,0,51,0V196.668z',
  ],
};

export default faSquirtyHouse1;
