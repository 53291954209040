import React, { HTMLAttributes } from 'react';

import {
  SensorTagOrientationBadge,
  SensorTagRoomTypeBadge,
  SensorTagVerticalPlacementBadge,
} from 'components/badges/SensorTagBadges';
import { SensorTagOrientation, SensorTagRoomType, SensorTagVerticalPlacement } from 'utils/enums';
import { SensorTag } from 'utils/types/SensorTags';

const SensorTagsRoomAndOrientation: React.FC<
  {
    sensorTagRoomType?: SensorTagRoomType;
    sensorTagOrientation?: SensorTagOrientation;
    sensorTagVerticalPlacement?: SensorTagVerticalPlacement;
    onClick?: (sensorTag: SensorTag) => Promise<void>;
    useColors?: boolean;
  } & Omit<HTMLAttributes<HTMLSpanElement>, 'onClick'>
> = ({
  sensorTagRoomType,
  sensorTagOrientation,
  sensorTagVerticalPlacement,
  className,
  onClick,
  ...props
}) => (
  <>
    {sensorTagVerticalPlacement && (
      <SensorTagVerticalPlacementBadge
        className={className}
        sensorTagVerticalPlacement={sensorTagVerticalPlacement}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagOrientation && (
      <SensorTagOrientationBadge
        className={className}
        sensorTagOrientation={sensorTagOrientation}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagRoomType && (
      <SensorTagRoomTypeBadge
        className={className}
        sensorTagRoomType={sensorTagRoomType}
        onClick={onClick}
        {...props}
      />
    )}
  </>
);

export default SensorTagsRoomAndOrientation;
