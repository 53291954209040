import { forwardRef, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { ActionMeta, SingleValue } from 'react-select';

import { CustomSelectControl, CustomSelectInput } from 'components/inputs/components';
import { sortedArrayByStringProperty } from 'utils/arrays';
import Customer, { allCustomer } from 'utils/types/Customer';

interface CustomerOption {
  readonly value: string;
  readonly label: string;
}

interface CustomerSelectProps {
  onSelect: (customer: Customer, { clear }: { clear: () => void }) => void;
  onClear?: () => void;
  initialSelectedCustomer?: Customer;
  customersToSelect?: Customer[];
  showAllOption?: boolean;
  className?: string;
  name?: string;
  isPending?: boolean;
  isDisabled?: boolean;
}

export const CustomerSelect = forwardRef<any, CustomerSelectProps>(
  (
    {
      initialSelectedCustomer,
      onSelect,
      onClear,
      customersToSelect,
      className,
      name,
      showAllOption = false,
      isPending,
      isDisabled,
    },
    ref,
  ) => {
    const { t } = useTranslation('components');

    const [selectedCustomerOption, setSelectedCustomerOption] = useState<CustomerOption | null>(
      null,
    );

    const clear = () => setSelectedCustomerOption(null);

    const onChange = (
      selectedOptionValue: SingleValue<CustomerOption>,
      { action, option, removedValue }: ActionMeta<CustomerOption>,
    ) => {
      if (selectedOptionValue?.value === 'ALL_CUSTOMERS') {
        return onSelect({ id: selectedOptionValue?.value } as Customer, { clear });
      }
      const selectedOption = options.find(option => option.value === selectedOptionValue?.value);
      setSelectedCustomerOption(selectedOption || null);

      const selectedCustomer = customersToSelect?.find(
        customer => customer.id === selectedOptionValue?.value,
      );

      if (selectedCustomer) {
        return onSelect(selectedCustomer, { clear });
      }
      if (onClear) {
        return onClear();
      }
    };

    const options = useMemo(
      () =>
        customersToSelect
          ? sortedArrayByStringProperty(
              showAllOption ? [...customersToSelect, allCustomer] : customersToSelect,
              'name',
            ).map(
              customer =>
                ({
                  label: customer.name,
                  value: customer.id,
                }) as CustomerOption,
            )
          : [],
      [customersToSelect, showAllOption],
    );

    useEffect(() => {
      if (initialSelectedCustomer) {
        const selectedOption = options.find(option => option.value === initialSelectedCustomer.id);
        setSelectedCustomerOption(selectedOption || null);
      } else {
        setSelectedCustomerOption(null);
      }
    }, [options, initialSelectedCustomer]);

    return (
      <Select<CustomerOption, false>
        isDisabled={isDisabled}
        ref={ref}
        className={className}
        classNamePrefix="select"
        placeholder={t('inputs.CustomerSelect.placeholder')}
        name="ws-customer-select-input"
        inputId={name}
        isLoading={isPending}
        loadingMessage={() => t('inputs.CustomerSelect.loadingMessage')}
        isClearable
        isSearchable
        value={selectedCustomerOption}
        formatOptionLabel={option => <>{option.label}</>}
        components={{
          Input: CustomSelectInput,
          Control: CustomSelectControl,
        }}
        onChange={onChange}
        options={options}
        menuPlacement="auto"
        menuShouldScrollIntoView
        menuPosition="fixed"
        openMenuOnClick
        openMenuOnFocus
      />
    );
  },
);
