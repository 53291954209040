import { hasAcceptedNecessaryCookies } from 'utils/cookies';
import isRunningLocally from 'utils/locally';
import User from 'utils/types/User';

// @ts-ignore
window.hsConversationsSettings = {
  loadImmediately: false,
};

export const removeChat = () => {
  let tries = 0;
  const interval = setInterval(() => {
    tries++;
    const hubspot: HTMLElement | null = document.querySelector(
      '#hubspot-messages-iframe-container',
    );
    if (hubspot) {
      hubspot.style.setProperty('display', 'none', 'important');
      clearInterval(interval);
    }
    if (tries === 200) clearInterval(interval);
  }, 100);
};

export const hideChat = () => {
  let tries = 0;
  const interval = setInterval(() => {
    tries++;
    // @ts-ignore
    if (window.HubSpotConversations) {
      // @ts-ignore
      window.HubSpotConversations.widget.close();
      clearInterval(interval);
    }
    if (tries === 200) clearInterval(interval);
  }, 100);
};

export const showChat = () => {
  let tries = 0;
  const interval = setInterval(() => {
    tries++;
    const hubspot: HTMLElement | null = document.querySelector(
      '#hubspot-messages-iframe-container',
    );

    // @ts-ignore
    if (hubspot && window.HubSpotConversations) {
      hubspot.style.setProperty('display', 'block', 'important');
      // @ts-ignore
      window.HubSpotConversations.widget.open();
      clearInterval(interval);
    }
    if (tries === 200) clearInterval(interval);
  }, 100);
};

let hubSpotChatInitialized = false;

export const initChat = (user: User | null, isMobile: boolean, token: string) => {
  if (isRunningLocally) return; // Do not run on local environment
  if (hasAcceptedNecessaryCookies()) return;
  if (hubSpotChatInitialized) return;

  // Set user identification
  // @ts-ignore
  window.hsConversationsSettings.identificationEmail = user.email;
  // @ts-ignore
  window.hsConversationsSettings.identificationToken = token;

  let tries = 0;
  const interval = setInterval(() => {
    tries++;
    // @ts-ignore
    if (window.HubSpotConversations) {
      // @ts-ignore
      window.HubSpotConversations.widget.load({ widgetOpen: false });
      hubSpotChatInitialized = true;

      if (isMobile) {
        hideChat();
      }

      clearInterval(interval);
    }
    if (tries === 200) clearInterval(interval);
  }, 100);
};
