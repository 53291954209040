import classNames from 'classnames';
import { useMemo, ComponentPropsWithoutRef, useState } from 'react';

export const UncontrolledSwitch: React.FC<
  {
    label?: string | JSX.Element;
  } & Omit<ComponentPropsWithoutRef<'input'>, 'id' | 'checked'>
> = ({ label, onClick, onChange, defaultChecked, className, ...props }) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);
  const [on, setOn] = useState(!!defaultChecked);

  return (
    <label
      htmlFor={controlId}
      className={classNames('flex items-center cursor-pointer', className)}
    >
      <div className="relative">
        <input
          defaultChecked={defaultChecked}
          onChange={e => {
            setOn(e.target.checked);
            if (typeof onChange === 'function') {
              onChange(e);
            }
          }}
          type="checkbox"
          id={controlId}
          className="sr-only"
          {...props}
        />

        <div
          className={classNames('w-9 h-4 rounded-full shadow-inner checked:bg-brand-green', {
            'bg-brand-green': on,
            'bg-brand-gray-light-3': !on,
          })}
        />

        <div
          className={classNames(
            'bg-white absolute w-4 h-4 rounded-full shadow left-0 top-0 transition duration-150 ease-linear',
            { 'translate-x-6': on },
          )}
        />
      </div>

      <div className="ml-3 text-brand-gray-light-1 font-medium">{label}</div>
    </label>
  );
};
