import { getLocalStorageIdentifiers } from 'utils/tables/identifier';
import User from 'utils/types/User';

export const STORAGE_CUSTOMER_SELECTED = 'ws-customer-selected';
export const STORAGE_SHOW_MY_DEVICES_ONLY_SELECTED = 'ws-show-my-devices-only-selected';
export const STORAGE_SIDEBAR_MINIMIZED = 'ws-sidebar-minimized';
export const STORAGE_USER = 'ws-user';
export const STORAGE_PLOTS_DATA_SHOW_ANOMALIES = 'ws-plots-data-show-anomalies';

export const getStorageUser = (): User | undefined => {
  try {
    return (JSON.parse(localStorage.getItem(STORAGE_USER) as string) || undefined) as User;
  } catch {
    return undefined;
  }
};

export const getStorageBoolean = (key: string, defaultValue?: boolean): boolean => {
  const defaultValueDefined = typeof defaultValue !== 'undefined';

  try {
    let value = JSON.parse(localStorage.getItem(key) as string);
    if (value === null && defaultValueDefined) return !!defaultValue;

    // NOTE: This is a tmp fix for avoiding the previous bug with
    // values increasing in size by prepending and appending '\"' characters
    // from the output of a JSON.stringify() operation.
    if (typeof value === 'boolean') return value;
    value = value.replace(/\W/g, '');
    return value === 'true';
  } catch (err) {
    if (defaultValueDefined) return !!defaultValue;

    return false;
  }
};

export const setStorageBoolean = (key: string, value: boolean) => {
  if (typeof value === 'string') {
    // NOTE: This is a tmp fix for avoiding the previous bug with
    // values increasing in size by prepending and appending '\"' characters
    // from the output of a JSON.stringify() operation.
    value = Boolean((value as string).replace(/\W/g, ''));
  }

  localStorage.setItem(key, JSON.stringify(value));
};

export const clearLocalStorageTableIdentifiers = () =>
  getLocalStorageIdentifiers().forEach(key => localStorage.removeItem(key));
