import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import TemperatureState from 'utils/enums/TemperatureState';
import { getTemperatureStateText } from 'utils/sensor/texts';

export const TemperatureStateBadge: React.FC<
  { state?: TemperatureState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) =>
  state ? (
    <Badge
      {...props}
      className={classNames('text-xs font-medium text-white', className, {
        'bg-temperatureState30-20': state === TemperatureState.TEMPERATURE_30_20,
        'bg-temperatureState20-10': state === TemperatureState.TEMPERATURE_20_10,
        'bg-temperatureState10-0': state === TemperatureState.TEMPERATURE_10_0,
        'bg-temperatureState0-10': state === TemperatureState.TEMPERATURE_0_10,
        'bg-temperatureState10-20': state === TemperatureState.TEMPERATURE_10_20,
        'bg-temperatureState20-30': state === TemperatureState.TEMPERATURE_20_30,
        'bg-temperatureState30-40': state === TemperatureState.TEMPERATURE_30_40,
        'bg-temperatureState40-50': state === TemperatureState.TEMPERATURE_40_50,
        'bg-temperatureState50-60': state === TemperatureState.TEMPERATURE_50_60,
        'bg-brand-gray-light-2': state === TemperatureState.UNKNOWN,
      })}
    >
      {getTemperatureStateText(state)}
    </Badge>
  ) : (
    <Spinner className="ml-2 text-brand-gray-light-1" />
  );
