import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

import CalendarModal from 'components/modals/CalendarModal';
import { dateToFormattedDateString } from 'utils/date';

export const DatePickerInput: React.FC<{
  initialDate?: Date;
  label?: string;
  onSelect: (date: Date) => void;
}> = ({ initialDate, label, onSelect }) => {
  const [show, setShow] = useState(false);
  const [date, setDate] = useState<Date | undefined>(initialDate);
  return (
    <div className="mb-3">
      <CalendarModal
        show={show}
        setShow={setShow}
        onSelect={(date: Date) => {
          setDate(date);
          onSelect(date);
        }}
        initialDate={date}
      />
      <div className="cursor-pointer" onClick={() => setShow(!show)}>
        {label && <label>{label}</label>}
        <div className="form-input disabled:cursor-not-allowed disabled:bg-brand-gray-light-4 placeholder:font-light placeholder:text-brand-gray-light-3 rounded bg-white border-2 border-brand-gray-light-3 text-xs lg:text-base py-1.5 px-2.5 w-full text-brand-gray flex justify-between items-center">
          <div>{date ? dateToFormattedDateString(date) : 'dd/mm/yyyy'}</div>
          <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
        </div>
      </div>
    </div>
  );
};
