import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';

type SensorGroupSummary = {
  name: string;
  description: string;
  number_of_sensors: number;
  number_of_sensor_files: number;
  number_of_gateways: number;
  number_of_gateway_files: number;
  number_of_sensors_attached_blueprint: number;
  created_date: Date;
};

export const useSensorGroupSummary = (sensorGroupId?: string) => {
  const queryKey = ['sensorGroupSummary', sensorGroupId];

  // Get
  const { data: summary, isPending } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/summary`),
    select: (summary: any) => summary as SensorGroupSummary,
    enabled: !!sensorGroupId,
  });

  return {
    summary,
    isPending,
  };
};
