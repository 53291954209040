import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';

export const useAdminSalesOrderMailText = ({
  sensorsCount,
  gatewaysCount,
  postnordTrackingNumber,
  upsTrackingNumber,
}: {
  sensorsCount: number;
  gatewaysCount: number;
  postnordTrackingNumber?: string;
  upsTrackingNumber?: string;
}) => {
  const queryKey = [
    'adminSalesOrderMailText',
    sensorsCount,
    gatewaysCount,
    postnordTrackingNumber,
    upsTrackingNumber,
  ];

  const { isAdmin } = useCurrentUser();

  // Get sales orders
  const { data: salesOrderMailText } = useQuery({
    queryKey,
    queryFn: () =>
      api.get('/admin/sales_orders_mail_html', {
        params: {
          sensors_count: sensorsCount,
          gateways_count: gatewaysCount,
          postnord_tracking_number: postnordTrackingNumber,
          ups_tracking_number: upsTrackingNumber,
        },
        _disableErrorNotifications: true,
      }),
    select: (mailDetails: any) =>
      mailDetails as {
        greeting: string;
        content_html: string;
        content_html_including_greeting: string;
        subject: string;
      },
    enabled: isAdmin,
  });

  return {
    salesOrderMailText,
  };
};
