import { Dispatch, SetStateAction, useState } from 'react';
import Select, { ActionMeta, MultiValue } from 'react-select';

import { SensorsDataTabOptions } from 'components/plots/insights_plots/sensors/SensorsTab';

export const SensorSelectDropdown: React.FC<{
  currentTab: SensorsDataTabOptions;
  items: Map<string, string>;
  setSelectedSensors: Dispatch<SetStateAction<string[]>>;
  setSelectedGroups: Dispatch<SetStateAction<string[]>>;
  setSelectedCustomers: Dispatch<SetStateAction<string[]>>;
}> = ({ currentTab, items, setSelectedSensors, setSelectedGroups, setSelectedCustomers }) => {
  const [itemsSelectedInComponent, setItemsSelectedInComponent] = useState<string[]>([]);

  let options: object[] = [];
  Array.from(items).forEach(item => options.push({ value: item[1], label: item[0] }));

  const setSelectedItems = () => {
    if (currentTab === 'Sensors') {
      setSelectedSensors(itemsSelectedInComponent);
    } else if (currentTab === 'Groups') {
      setSelectedGroups(itemsSelectedInComponent);
    } else if (currentTab === 'Customers') {
      setSelectedCustomers(itemsSelectedInComponent);
    }
  };

  const onChange = (newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => {
    setItemsSelectedInComponent(newValue.map(sensorObject => sensorObject.value));
  };

  return (
    <Select
      isMulti
      name="Sensors"
      options={options}
      className="basic-multi-select"
      classNamePrefix="select"
      closeMenuOnSelect={false}
      onChange={onChange}
      onMenuClose={() => setSelectedItems()}
    />
  );
};
