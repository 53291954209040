import { t } from 'translations';
import { SensorTagName } from 'utils/types/SensorTags';

const getSensorTagText = (sensorTagName: SensorTagName) => {
  switch (sensorTagName) {
    case 'primary_element_category':
      return t('utils:texts.SensorTags.getSensorTagText.primary_element_category');
    case 'primary_element_type':
      return t('utils:texts.SensorTags.getSensorTagText.primary_element_type');
    case 'complementary_element':
      return t('utils:texts.SensorTags.getSensorTagText.complementary_element');
    case 'orientation':
      return t('utils:texts.SensorTags.getSensorTagText.orientation');
    case 'room_type':
      return t('utils:texts.SensorTags.getSensorTagText.room_type');
    case 'vertical_placement':
      return t('utils:texts.SensorTags.getSensorTagText.vertical_placement');
    case 'casing':
      return t('utils:texts.SensorTags.getSensorTagText.casing');
    case 'climate':
      return t('utils:texts.SensorTags.getSensorTagText.climate');
    case 'construction_phase':
      return t('utils:texts.SensorTags.getSensorTagText.construction_phase');
    case 'construction_principles':
      return t('utils:texts.SensorTags.getSensorTagText.construction_principles');
    case 'insulation':
      return t('utils:texts.SensorTags.getSensorTagText.insulation');
    case 'insulation_type':
      return t('utils:texts.SensorTags.getSensorTagText.insulation_type');
    case 'screw_type':
      return t('utils:texts.SensorTags.getSensorTagText.screw_type');
    case 'ventilation':
      return t('utils:texts.SensorTags.getSensorTagText.ventilation');
    case 'usage':
      return t('utils:texts.SensorTags.getSensorTagText.usage');
    default:
      return 'n/a';
  }
};

export default getSensorTagText;
