enum ReportGenerationStep {
  CREATED = 'CREATED',
  INITIATED = 'INITIATED',
  TRANSMISSIONS_LOADED = 'TRANSMISSIONS_LOADED',
  WEATHER_DATA_LOADED = 'WEATHER_DATA_LOADED',

  FRONT_PAGE_FINISHED = 'FRONT_PAGE_FINISHED',
  BIG_PLOT_PAGES_FINISHED = 'BIG_PLOT_PAGES_FINISHED',
  DETAILS_PAGE_FINISHED = 'DETAILS_PAGE_FINISHED',
  IMAGE_PAGES_FINISHED = 'IMAGE_PAGES_FINISHED',
  NOTE_PAGES_FINISHED = 'NOTE_PAGES_FINISHED',
  ALARMS_PAGE_FINISHED = 'ALARMS_PAGE_FINISHED',
  WEATHER_DETAIL_PAGE_FINISHED = 'WEATHER_DETAIL_PAGE_FINISHED',
  BLUEPRINT_PAGES_FINISHED = 'BLUEPRINT_PAGES_FINISHED',

  FINISHED = 'FINISHED',
}

export const getGenerationStepIdx = (reportGenerationStep?: ReportGenerationStep) => {
  switch (reportGenerationStep) {
    case ReportGenerationStep.CREATED:
      return 0;
    case ReportGenerationStep.INITIATED:
      return 1;
    case ReportGenerationStep.TRANSMISSIONS_LOADED:
      return 2;
    case ReportGenerationStep.WEATHER_DATA_LOADED:
      return 3;
    case ReportGenerationStep.FRONT_PAGE_FINISHED:
      return 4;
    case ReportGenerationStep.BIG_PLOT_PAGES_FINISHED:
      return 5;
    case ReportGenerationStep.DETAILS_PAGE_FINISHED:
      return 6;
    case ReportGenerationStep.IMAGE_PAGES_FINISHED:
      return 7;
    case ReportGenerationStep.NOTE_PAGES_FINISHED:
      return 8;
    case ReportGenerationStep.ALARMS_PAGE_FINISHED:
      return 9;
    case ReportGenerationStep.WEATHER_DETAIL_PAGE_FINISHED:
      return 10;
    case ReportGenerationStep.BLUEPRINT_PAGES_FINISHED:
      return 11;
    case ReportGenerationStep.FINISHED:
      return 12;
    default:
      return 0;
  }
};

export const getGenerationProgress = (reportGenerationStep?: ReportGenerationStep) => {
  if (!reportGenerationStep) return 0;
  const n = Object.keys(ReportGenerationStep).length;
  const stepIdx = getGenerationStepIdx(reportGenerationStep);
  return (stepIdx + 1) / n;
};

export default ReportGenerationStep;
