type Note = {
  created_date: Date;
  created_by: string;
  internal?: boolean;
  id: string;
  report_id?: string;
  resolved_at: number;
  sensor_id: string;
  sensor_group_id: string;
  gateway_id: string;
  support_ticket: boolean;
  text: string;
};

export const parseNote = (note: any): Note => {
  note.created_date = new Date(note.created_date);
  return note;
};

export default Note;
