import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { ceilToEndOfDay, floorToMidnight } from 'utils/date';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { parseTransmissionsMixed } from 'utils/sensor/transmissions';
import Customer, { parseCustomer } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';
import Sensor, { parseSensor } from 'utils/types/Sensor';
import Transmission, { parseTransmission } from 'utils/types/Transmission';
import TransmissionAnomaly, { parseTransmissionAnomaly } from 'utils/types/TransmissionAnomaly';

type CustomerDetails = {
  customer: Customer;
  sensors: Sensor[];
  transmissions: Transmission[];
};

export const useAdminInsightsCustomer = (
  customerId: string,
  {
    fromTimestamp,
    toTimestamp,
  }: {
    fromTimestamp: Date;
    toTimestamp: Date;
  },
  { enableGet = true }: HookOptions = {},
) => {
  fromTimestamp = floorToMidnight(fromTimestamp);
  toTimestamp = ceilToEndOfDay(toTimestamp);

  const queryKey = ['adminInsightsCustomers', customerId, fromTimestamp, toTimestamp];

  const { isAdmin } = useCurrentUser();
  const { data: customerDetails, isPending } = useQuery<CustomerDetails, Error>({
    queryKey,
    queryFn: () =>
      api.get(`/admin/insights/customers/${customerId}`, {
        params: {
          from_timestamp: fromTimestamp,
          to_timestamp: toTimestamp,
        },
      }),
    select: (customerDetails: any) => {
      const sensors: Sensor[] = customerDetails.sensors.map(parseSensor);
      const transmissionsRaw: Transmission[] = customerDetails.transmissions.map(parseTransmission);
      const transmissionAnomalies: TransmissionAnomaly[] =
        customerDetails.anomalies.map(parseTransmissionAnomaly);

      const transmissionId2TransmissionAnomaly: { [key: string]: TransmissionAnomaly } =
        transmissionAnomalies?.reduce(
          (o: any, transmissionAnomaly: TransmissionAnomaly) => ({
            ...o,
            [transmissionAnomaly.transmission_id]: transmissionAnomaly,
          }),
          {},
        );

      // Save any transmission anomaly on the transmission object
      transmissionsRaw.forEach((transmission: Transmission) => {
        transmission.anomaly = transmissionId2TransmissionAnomaly[transmission.id];
      });

      const transmissions: Transmission[] = parseTransmissionsMixed({
        transmissions: transmissionsRaw,
        sensors,
      });

      return {
        customer: parseCustomer(customerDetails.customer),
        sensors,
        transmissions,
      } as CustomerDetails;
    },
    placeholderData: {} as CustomerDetails,
    enabled: isAdmin && enableGet && !!customerId,
  });

  return { customerDetails, isPending };
};
