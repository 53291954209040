import classnames from 'classnames';
import { useField } from 'formik';
import { SelectHTMLAttributes, useMemo } from 'react';

type SelectElementProps = SelectHTMLAttributes<HTMLSelectElement>;

export type Props<T> = {
  name: string;
  getEnumTypeText: (enumType: T) => string;
  options: any[];
  prepend?: JSX.Element | string;
  prependTooltip?: string;
  append?: JSX.Element | string;
  label?: string;
  text?: string;
  tooltip?: string;
  placeholder?: string;
} & SelectElementProps;

export const EnumSelectInput = <T extends {}>({
  name,
  getEnumTypeText,
  options,
  prepend,
  prependTooltip,
  append,
  onChange,
  label,
  text,
  tooltip,
  placeholder,
  className,
  disabled = false,
}: Props<T>) => {
  const [field, meta] = useField(name);
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);

  // Define if the value is valid
  // const isInvalid = (!props.disabled && meta.error && meta.touched);
  const isInvalid = !!meta.error;

  // Make sure a empty string is used if no value is defined
  field.value = field.value || '';

  return (
    <div className={className} data-tooltip-content={tooltip} data-tooltip-id="route-tooltip">
      <label className="mb-1 text-brand-gray" htmlFor={controlId}>
        {label}
      </label>
      <div className="flex flex-row items-stretch w-full relative">
        {prepend && (
          <span
            className={classnames(
              'rounded-lg flex border-2 border-brand-gray-light-3 border-r-0 justify-center align-middle items-center text-xs bg-white py-1.5 px-3.5 rounded-r-none',
              {
                'text-brand-blue-light-1': !!field.value,
                'text-brand-gray-light-3': !field.value,
              },
            )}
            data-tooltip-content={prependTooltip}
            data-tooltip-id="route-tooltip"
          >
            {prepend}
          </span>
        )}

        <select
          id={controlId}
          name={field.name}
          className={classnames({ 'rounded-l-none': prepend }, { 'rounded-r-none': append })}
          onChange={onChange ? onChange : field.onChange}
          onBlur={field.onBlur}
          value={field.value}
          disabled={disabled}
        >
          <option value="">{placeholder}</option>
          {options?.map((option, index) => (
            <option key={index} value={String(option)}>
              {getEnumTypeText(option as T)}
            </option>
          ))}
        </select>

        {append && (
          <span className="rounded-lg border-2 border-brand-gray-light-3 border-l-0  flex justify-center align-middle items-center text-xs bg-brand-gray-light-4 py-1.5 px-3.5 rounded-l-none">
            {append}
          </span>
        )}
        {text && <span className="w-full">{text}</span>}
        {!!isInvalid && <p className="text-brand-orange">{meta.error}</p>}
      </div>
    </div>
  );
};
