type DemoApplication = {
  id: string;
  name: string;
  setup_finished_at?: Date;
  created_for?: string;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
};

export const parseDemoApplication = (demoApplication: any): DemoApplication => {
  demoApplication.created_date = new Date(demoApplication.created_date);

  if (demoApplication.updated_date) {
    demoApplication.updated_date = new Date(demoApplication.updated_date);
  }

  if (demoApplication.setup_finished_at)
    demoApplication.setup_finished_at = new Date(demoApplication.setup_finished_at);

  return demoApplication as DemoApplication;
};

export default DemoApplication;
