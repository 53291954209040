import { Dispatch, SetStateAction } from 'react';
import { createPortal } from 'react-dom';

import SensorGroup from 'utils/types/SensorGroup';

export const AttachDropdown = ({
  options,
  top,
  left,
  onAttachSubgroup,
  setIsHovering,
  setDropdownExistingGroupsOpen,
}: {
  top: number;
  left: number;
  options: SensorGroup[];
  setDropdownExistingGroupsOpen: Dispatch<SetStateAction<boolean>>;
  onAttachSubgroup: (selectedGroup: SensorGroup) => Promise<void>;
  setIsHovering: Dispatch<SetStateAction<boolean>>;
}) =>
  createPortal(
    <div
      className="absolute bg-white border border-brand-blue-light-1 rounded shadow-lg z-50"
      style={{
        top,
        left,
      }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <ul
        className="max-h-[150px] overflow-y-auto list-none p-0 m-0
                          [&::-webkit-scrollbar]:w-2 
                          [&::-webkit-scrollbar-track]:bg-brand-gray-light-4 
                          [&::-webkit-scrollbar-thumb]:bg-brand-gray-light-3
                          [&::-webkit-scrollbar-track]:rounded-full
                          [&::-webkit-scrollbar-thumb]:rounded-full"
        onWheel={e => {
          e.stopPropagation();
        }}
      >
        {options.map((option, index) => (
          <li
            key={index}
            className="cursor-pointer px-2 py-2 hover:bg-gray-100 rounded"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();

              onAttachSubgroup(option);
              setIsHovering(false);
              setDropdownExistingGroupsOpen(false);
            }}
          >
            <span className="text-xs">{option.name}</span>
          </li>
        ))}
      </ul>
    </div>,
    document.body,
  );
