import React from 'react';

import GatewaysMap from 'components/maps/GatewaysMap';
import Gateway from 'utils/types/Gateway';

const GatewayMap: React.FC<{
  gateway: Gateway;
  initialZoom?: number;
}> = ({ gateway, initialZoom = 10 }) => {
  if (!gateway.geographic_location?.latitude || !gateway.geographic_location?.longitude) {
    return null;
  }

  return (
    <GatewaysMap
      gateways={[gateway]}
      initialPosition={[
        gateway.geographic_location.latitude,
        gateway.geographic_location.longitude,
      ]}
      initialZoom={initialZoom}
    />
  );
};

export default GatewayMap;
