import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from 'components';
import Spinner from 'components/Spinner';
import MoistureState from 'utils/enums/MoistureState';
import { getMoistureStateText } from 'utils/sensor/texts';

export const MoistureStateBadge: React.FC<
  { state?: MoistureState } & HTMLAttributes<HTMLSpanElement>
> = ({ state, className, ...props }) => {
  const { t } = useTranslation('components');

  if (!state) return <Spinner className="ml-2 text-brand-gray-light-1" />;

  return (
    <Badge
      className={classNames('text-xs font-medium text-white', className, {
        'bg-moistureState0-10 text-brand-gray': state === MoistureState.MOISTURE_0_10,
        'bg-moistureState10-15 text-brand-gray': state === MoistureState.MOISTURE_10_15,
        'bg-moistureState15-20': state === MoistureState.MOISTURE_15_20,
        'bg-moistureState20-25':
          state === MoistureState.MOISTURE_20_25 || state === MoistureState.ACTIVE,
        'bg-moistureState25-30': state === MoistureState.MOISTURE_25_30,
        'bg-moistureState30-35': state === MoistureState.MOISTURE_30_35,
        'bg-moistureState35-40': state === MoistureState.MOISTURE_35_40,
        'bg-moistureState40-100': state === MoistureState.MOISTURE_40_100,
        'bg-moistureStateInactive': state === MoistureState.INACTIVE,
        'bg-moistureStateInvalid': state === MoistureState.INVALID,
        'bg-brand-green': state === MoistureState.CREATED,
        'bg-brand-orange': state === MoistureState.UNKNOWN,
      })}
      data-tooltip-content={
        state === MoistureState.INVALID ? t('badges.MoistureStateBadge.invalidTooltip') : undefined
      }
      data-tooltip-id="route-tooltip"
      {...props}
    >
      {getMoistureStateText(state)}
    </Badge>
  );
};
