import React, { HTMLAttributes } from 'react';

import {
  SensorTagClimateBadge,
  SensorTagConstructionPhaseBadge,
  SensorTagInsulationBadge,
  SensorTagInsulationTypeBadge,
  SensorTagVentilationBadge,
} from 'components/badges/SensorTagBadges';
import {
  SensorTagClimate,
  SensorTagConstructionPhase,
  SensorTagInsulation,
  SensorTagInsulationType,
  SensorTagVentilation,
} from 'utils/enums';
import { SensorTag } from 'utils/types/SensorTags';

const SensorTagsEnvironment: React.FC<
  {
    sensorTagClimate?: SensorTagClimate;
    sensorTagConstructionPhase?: SensorTagConstructionPhase;
    sensorTagInsulation?: SensorTagInsulation;
    sensorTagInsulationType?: SensorTagInsulationType;
    sensorTagVentilation?: SensorTagVentilation;
    onClick?: (sensorTag: SensorTag) => Promise<void>;
    useColors?: boolean;
  } & Omit<HTMLAttributes<HTMLSpanElement>, 'onClick'>
> = ({
  sensorTagClimate,
  sensorTagConstructionPhase,
  sensorTagInsulation,
  sensorTagInsulationType,
  sensorTagVentilation,
  className,
  onClick,
  ...props
}) => (
  <>
    {sensorTagConstructionPhase && (
      <SensorTagConstructionPhaseBadge
        className={className}
        sensorTagConstructionPhase={sensorTagConstructionPhase}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagInsulation && (
      <SensorTagInsulationBadge
        className={className}
        sensorTagInsulation={sensorTagInsulation}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagInsulationType && (
      <SensorTagInsulationTypeBadge
        className={className}
        sensorTagInsulationType={sensorTagInsulationType}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagVentilation && (
      <SensorTagVentilationBadge
        className={className}
        sensorTagVentilation={sensorTagVentilation}
        onClick={onClick}
        {...props}
      />
    )}
    {sensorTagClimate && (
      <SensorTagClimateBadge
        className={className}
        sensorTagClimate={sensorTagClimate}
        onClick={onClick}
        {...props}
      />
    )}
  </>
);

export default SensorTagsEnvironment;
