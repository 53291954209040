import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import api from 'utils/api';
import HookOptions from 'utils/types/HookOptions';
import Transmission, { parseTransmission } from 'utils/types/Transmission';

export const useBlueprintSensorValues = (
  blueprintId: string,
  { timeFrom, timeTo }: { timeFrom?: Date; timeTo?: Date },
  { enableGet }: HookOptions = {},
) => {
  const queryKey = ['blueprintSensorValues', blueprintId, timeFrom?.getTime(), timeTo?.getTime()];

  // Sensors - Get
  const { data: sensorId2Transmission, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/blueprints/${blueprintId}/sensor_values`, {
        params: { from_timestamp: timeFrom, to_timestamp: timeTo },
      }),
    select: (sensor_id2transmissions: any) =>
      Object.entries(sensor_id2transmissions).reduce(
        (
          o: { [key: string]: Transmission[] | undefined },
          [sensorId, transmissions]: [string, any],
        ) => ({
          ...o,
          [sensorId]: transmissions.map(parseTransmission),
        }),
        {},
      ),
    enabled: enableGet && !!blueprintId,
  });

  // Video generation
  const generateAndSendVideo = useCallback(
    async (email: string) => {
      const res = await api.post(`/blueprints/${blueprintId}/videos`, {
        from_timestamp: timeFrom,
        to_timestamp: timeTo,
        email,
      });
      return res.success as boolean;
    },
    [timeFrom, timeTo, blueprintId],
  );

  return {
    generateAndSendVideo,
    sensorId2Transmission,
    isPending,
  };
};
