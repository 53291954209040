import React, { Dispatch } from 'react';

import { getDefaultDateRange } from 'utils/date';
import TimePeriod from 'utils/types/TimePeriod';

export type ContextProps = {
  timePeriod: Required<TimePeriod>;
  highlightPeriod: TimePeriod;
  setTimePeriod: Dispatch<Required<TimePeriod>>;
  setHighlightPeriod: Dispatch<TimePeriod>;
  resetPeriods: () => void;
};

const { start, end } = getDefaultDateRange();

const TimePeriodContext = React.createContext<ContextProps>({
  timePeriod: [start, end],
  highlightPeriod: [undefined, undefined],
  setTimePeriod: () => {},
  setHighlightPeriod: () => {},
  resetPeriods: () => {},
});

export default TimePeriodContext;
