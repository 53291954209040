import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Alert } from 'components';

export const ApiErrorMessage: React.FC<{
  className?: string;
  error?: string;
}> = ({ className = 'mt-3', error }) =>
  error ? (
    <Alert className={className} variant="danger">
      <p className="text-white">
        <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} />
        {error}
      </p>
    </Alert>
  ) : (
    <></>
  );
