import { t } from 'translations';
import { SensorTagConstructionPrinciples } from 'utils/enums';

export const getSensorTagConstructionPrinciplesText = (
  sensorTagConstructionPrinciples: SensorTagConstructionPrinciples,
) => {
  switch (sensorTagConstructionPrinciples) {
    case SensorTagConstructionPrinciples.AssemblyConstruction:
      return t(
        'utils:texts.sensor-tags.getSensorTagConstructionPrinciplesText.AssemblyConstruction',
      );
    case SensorTagConstructionPrinciples.ColumnOrBeamConstruction:
      return t(
        'utils:texts.sensor-tags.getSensorTagConstructionPrinciplesText.ColumnOrBeamConstruction',
      );
    case SensorTagConstructionPrinciples.PreFabricatedModularConstruction:
      return t(
        'utils:texts.sensor-tags.getSensorTagConstructionPrinciplesText.PreFabricatedModularConstruction',
      );
    default:
      return 'n/a';
  }
};

export const getSensorTagConstructionPrinciplesTextShort = (
  sensorTagConstructionPrinciples: SensorTagConstructionPrinciples,
) => {
  switch (sensorTagConstructionPrinciples) {
    case SensorTagConstructionPrinciples.AssemblyConstruction:
      return t(
        'utils:texts.sensor-tags.getSensorTagConstructionPrinciplesTextShort.AssemblyConstruction',
      );
    case SensorTagConstructionPrinciples.ColumnOrBeamConstruction:
      return t(
        'utils:texts.sensor-tags.getSensorTagConstructionPrinciplesTextShort.ColumnOrBeamConstruction',
      );
    case SensorTagConstructionPrinciples.PreFabricatedModularConstruction:
      return t(
        'utils:texts.sensor-tags.getSensorTagConstructionPrinciplesTextShort.PreFabricatedModularConstruction',
      );
    default:
      return 'n/a';
  }
};
