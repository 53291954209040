import { roundToNearestHour } from 'utils/date';

type GatewayHeartbeat = {
  id: string;
  gateway_id: string;
  client_ip?: string;
  timestamp: Date;
  version?: string;

  // Metadata
  meta_cpu_usage?: number; // 54.32
  meta_total_memory_kb?: number; // Example: 126292
  meta_available_memory_kb?: number; // Example: 81212
  meta_sd_card_size_kb?: number; // Example: 62351456
  meta_sd_card_used_kb?: number; // Example: 992
  meta_rootfs_size_kb?: number; // Example: 10176
  meta_rootfs_used_kb?: number; // Example: 784
  meta_modem_access_techonology?: ModemAccessTechnology; // Example: "FDD-LTE"
  meta_modem_signal_rssi?: number; // Example 31

  // Computed
  meta_memory_usage?: number; // In percent
  meta_sd_card_usage?: number; // In percent
  meta_rootfs_usage?: number; // In percent
};

type ModemAccessTechnology =
  | 'NONE'
  | 'CDMA1X'
  | 'CDMA1X-AND-HDR'
  | 'CDMA1X-AND-EHRPD'
  | 'HDR'
  | 'HDR-EHRPD'
  | 'GSM'
  | 'GPRS'
  | 'EDGE'
  | 'WCDMA'
  | 'HSDPA'
  | 'HSUPA'
  | 'HSPA+'
  | 'TDSCDMA'
  | 'TDD-LTE'
  | 'FDD-LTE';

export const parseGatewayHeartbeat = (gatewayHeartbeat: any): GatewayHeartbeat => {
  gatewayHeartbeat.timestamp = new Date(gatewayHeartbeat.timestamp);
  gatewayHeartbeat.timestamp = roundToNearestHour(gatewayHeartbeat.timestamp);

  // Calculate metrics
  gatewayHeartbeat.meta_memory_usage =
    ((gatewayHeartbeat.meta_total_memory_kb - gatewayHeartbeat.meta_available_memory_kb) /
      gatewayHeartbeat.meta_total_memory_kb) *
    100;
  gatewayHeartbeat.meta_sd_card_usage =
    (gatewayHeartbeat.meta_sd_card_used_kb / gatewayHeartbeat.meta_sd_card_size_kb) * 100;
  gatewayHeartbeat.meta_rootfs_usage =
    (gatewayHeartbeat.meta_rootfs_used_kb / gatewayHeartbeat.meta_rootfs_size_kb) * 100;

  // Convert rssi using table
  gatewayHeartbeat.meta_modem_signal_rssi = parseGatewayHeartbeatModemSignalRssi(
    gatewayHeartbeat.meta_modem_signal_rssi
  );

  return gatewayHeartbeat as GatewayHeartbeat;
};

const parseGatewayHeartbeatModemSignalRssi = (rssi?: number) => {
  // Convert rssi using table
  // Model Signal RSSI values:
  // 0:            -113dBm or less
  // 1:            -111dBm
  // 2...30:       -109dBm... -53dBm
  // 31:           -51dBm or greater
  // 99:           Not known or not detectable
  // 100:          -116dBm or less
  // 101:          -115dBm
  // 102...190:    -114dBm...-26dBm
  // 191:          -25dBm or greater
  // 199:          Not known or not detectable
  // 100~199:      Extended to be used in TD-SCDMA indicating received signal code power (RSCP)
  if (!rssi) {
    return undefined;
  }

  if (rssi <= 0) {
    return -113;
  }
  if (rssi === 1) {
    return -111;
  }
  if (rssi >= 2 && rssi <= 30) {
    // Interpolate between -109dBm and -53dBm from 2 and 30
    const rssiFraction = (rssi - 2) / 28;
    return Math.round(rssiFraction * 56) - 109;
  }
  if (rssi === 31) {
    return -51;
  }
  if (rssi === 99) {
    return undefined;
  }
  if (rssi === 100) {
    return -116;
  }
  if (rssi === 101) {
    return -115;
  }
  if (rssi >= 102 && rssi <= 190) {
    // Interpolate between -114dBm and -26dBm from 102 and 190
    const rssiFraction = (rssi - 102) / 88;
    return Math.round(rssiFraction * 88) - 114;
  }
  if (rssi === 191) {
    return -25;
  }
  if (rssi === 199) {
    return undefined;
  }
  return undefined;
};

export default GatewayHeartbeat;
