interface FileBase extends File {
  id: string;
  description: string;
  tags: string;
  filename: string;
  mime_type: string;
  storage_path: string;
  storage_path_thumbnail: string;
  storage_url: string;
  storage_url_thumbnail: string;
  bucket: string;
  marked_as_primary?: boolean;

  created_date: Date;
  created_by?: string;
  updated_date?: Date;
  updated_by?: string;
}

export const parseFileBase = (fileBase: any): FileBase => {
  fileBase.created_date = new Date(fileBase.created_date);

  if (fileBase.updated_date) {
    fileBase.updated_date = new Date(fileBase.updated_date);
  }
  return fileBase as FileBase;
};

export default FileBase;
