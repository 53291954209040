import GatewayLink, { Props } from 'components/GatewayLink';
import { useTimePeriod } from 'utils/hooks';
import { useGateway } from 'utils/hooks/data';

export const GatewayLinkWithName: React.FC<
  React.PropsWithChildren<Omit<Props, 'timeFrom' | 'timeTo'>>
> = ({ gatewayId, ...props }) => {
  const {
    timePeriod: [timeFrom, timeTo],
  } = useTimePeriod();

  const { gateway } = useGateway(gatewayId);

  return (
    <GatewayLink gatewayId={gatewayId} timeFrom={timeFrom} timeTo={timeTo} {...props}>
      {gateway?.name || gatewayId}
    </GatewayLink>
  );
};
