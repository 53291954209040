import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ComponentPropsWithoutRef, useMemo, MutableRefObject } from 'react';

import debounce from 'utils/debounce';

export const SearchField: React.FC<
  {
    onClear?: () => void;
    onSearch: (text: string) => void;
    placeholder: string;
    _ref?: MutableRefObject<HTMLInputElement | null>;
    label?: string;
  } & ComponentPropsWithoutRef<'input'>
> = ({ onClear, onSearch, placeholder, label, _ref, ...props }) => {
  const controlId = useMemo(() => Math.random().toString(36).substring(7), []);

  const onChange = debounce((value: string) => onSearch(value), 250);

  return (
    <>
      {label && <label htmlFor={controlId}>{label}</label>}
      <div className="mb-3 relative flex flex-wrap items-stretch w-full">
        <span className="rounded absolute inset-y-0 left-0 pointer-events-none flex-none flex justify-center align-middle items-center text-xs px-3.5 rounded-r-none">
          <FontAwesomeIcon className="text-brand-gray-light-1 w-4 h-4" icon={faSearch} />
        </span>
        <input
          ref={_ref}
          name="search_field"
          className="py-3 pl-10 flex-1"
          type="text"
          id={controlId}
          placeholder={placeholder}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
          aria-label={placeholder}
          {...props}
        />
      </div>
    </>
  );
};
