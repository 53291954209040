import { Column } from '@tanstack/react-table';
import classNames from 'classnames';

import { dateToLocaleString, dateToPrettyDate } from 'utils/date';

export const formatFunctionDate = (date?: Date) => {
  if (!date) return null;
  return (
    <span data-tooltip-content={dateToPrettyDate(date)} data-tooltip-id="route-tooltip">
      {dateToLocaleString(date)}
    </span>
  );
};

export const percentageMetricCellFn = (value?: number) => {
  if (value === undefined || value === null) {
    return <span className="text-brand-gray-light-2">n/a</span>;
  }
  if (90 <= value) return <span className="text-brand-orange">{value.toFixed(0)}%</span>;
  if (80 <= value) return <span className="text-brand-green">{value.toFixed(0)}%</span>;
  return <span className="text-brand-green-light-1">{value.toFixed(0)}%</span>;
};

export const getPinningClassNames = (column: Column<any>, isHoveringCell?: boolean) =>
  classNames({
    'shadow-[inset_-4px_0_4px_-4px_gray]':
      column.getIsPinned() === 'left' && column.getIsLastColumn('left'),
    'shadow-[inset_4px_0_4px_-4px_gray]':
      column.getIsPinned() === 'right' && column.getIsLastColumn('right'),
    'opacity-95': column.getIsPinned(),
    'opacity-100': !column.getIsPinned(),
    sticky: column.getIsPinned(),
    relative: !column.getIsPinned(),
    'z-20': isHoveringCell && column.getIsPinned(),
    'z-10': !isHoveringCell && column.getIsPinned(),
  });

export const getColumnPinningStyles = (column: Column<any>) => ({
  left: column.getIsPinned() === 'left' ? column.getStart('left') : undefined,
  right: column.getIsPinned() === 'right' ? column.getAfter('right') : undefined,
});
