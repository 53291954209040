import uniq from 'lodash/uniq';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SensorsSelectInput, Warning } from 'components';
import LoadingCard from 'components/cards/LoadingCard';
import { unqiueArrayByStringProperty } from 'utils/arrays';
import Permission from 'utils/enums/Permission';
import {
  useBlueprintSensors,
  useUtilsBulkSensorPositionCheck,
  useUtilsPermissionsSensors,
} from 'utils/hooks/data';
import { notificationWarningConfirm } from 'utils/notifications';
import Sensor from 'utils/types/Sensor';

export const BlueprintAttachedSensorsField: React.FC<{
  blueprintId: string;
  availableSensors?: Sensor[];
  disabled?: boolean;
}> = ({ blueprintId, availableSensors, disabled }) => {
  const [isUpdatingSensors, setIsUpdatingSensors] = useState(false);

  const { t } = useTranslation('components');

  const {
    sensors: blueprintSensors,
    addSensorsToBlueprintById,
    removeSensorsFromBlueprintById,
    isPending: isPendingSensors,
  } = useBlueprintSensors(blueprintId);

  // Combine available sensors and blueprint sensors
  const sensorsToSelect =
    isPendingSensors || !availableSensors
      ? undefined
      : unqiueArrayByStringProperty(
          [...(blueprintSensors || []), ...(availableSensors || [])],
          'id',
        );

  const {
    sensorHasBlueprintPosition: sensorIsAttachedAnyBlueprint,
    isPending: isPendingIsAttachedToAnyBlueprint,
  } = useUtilsBulkSensorPositionCheck(sensorsToSelect);
  const {
    sensorHasBlueprintPosition: sensorIsPlacedOnCurrentBlueprint,
    isPending: isPendingIsPlacedOnCurrentBlueprint,
  } = useUtilsBulkSensorPositionCheck(sensorsToSelect, {
    onlyValidPositions: true,
    blueprintId,
  });
  const {
    sensorHasBlueprintPosition: sensorIsAttachedCurrentBlueprint,
    isPending: isPendingIsAttachedCurrentBlueprint,
  } = useUtilsBulkSensorPositionCheck(sensorsToSelect, {
    blueprintId,
  });
  const { sensorPermissions, isPending: isPendingSensorPermissions } = useUtilsPermissionsSensors(
    sensorsToSelect,
    Permission.Edit,
  );

  const sensorsToSelectIds = sensorsToSelect?.map(sensor => sensor.id);

  // Determine sensors without sufficient permission
  const sensorIdsWithoutSufficientPermission = sensorsToSelectIds?.filter(
    sensorId => sensorPermissions && !sensorPermissions[sensorId],
  );

  // Determine sensors placed on the current blueprint (with position)
  const sensorIdsPlacedOnCurrentBlueprint = sensorsToSelectIds?.filter(
    sensorId => sensorIsPlacedOnCurrentBlueprint && sensorIsPlacedOnCurrentBlueprint[sensorId],
  );

  // Determine sensors attached to another blueprint (with or without position)
  const sensorIdsAttachedAnotherBlueprint = sensorsToSelectIds?.filter(
    sensorId =>
      sensorIsPlacedOnCurrentBlueprint &&
      sensorIsAttachedAnyBlueprint &&
      sensorIsAttachedCurrentBlueprint &&
      !sensorIsPlacedOnCurrentBlueprint[sensorId] &&
      !sensorIsAttachedCurrentBlueprint[sensorId] &&
      sensorIsAttachedAnyBlueprint[sensorId],
  );

  // Sensors will be disabled in dropdown if either:
  const disabledSensorIds = uniq([
    ...(sensorIdsWithoutSufficientPermission || []),
    ...(sensorIdsPlacedOnCurrentBlueprint || []),
    ...(sensorIdsAttachedAnotherBlueprint || []),
  ]);

  const sensorIdToTooltip = Object.fromEntries([
    ...(sensorIdsWithoutSufficientPermission || []).map(sensorId => [
      sensorId,
      t('blueprints.BlueprintAttachedSensorsField.notSufficientPermission'),
    ]),
    ...(sensorIdsPlacedOnCurrentBlueprint || []).map(sensorId => [
      sensorId,
      t('blueprints.BlueprintAttachedSensorsField.placedOnCurrentBlueprint'),
    ]),
    ...(sensorIdsAttachedAnotherBlueprint || []).map(sensorId => [
      sensorId,
      t('blueprints.BlueprintAttachedSensorsField.attachedAnotherBlueprint'),
    ]),
  ]);
  const isPending =
    isPendingSensors ||
    isPendingIsAttachedToAnyBlueprint ||
    isPendingIsPlacedOnCurrentBlueprint ||
    isPendingIsAttachedCurrentBlueprint ||
    isPendingSensorPermissions;

  if (isPending) return <LoadingCard count={2} />;

  const onBlur = async (sensorIds: string[]) => {
    setIsUpdatingSensors(true);

    // Determine delete and newly added IDs
    // Added and deleted sensors IDs can only be any of the available sensors
    const blueprintSensorIds = blueprintSensors?.map(sensor => sensor.id);
    const newSensorIds = sensorIds
      .filter(sensorId => !blueprintSensorIds?.includes(sensorId))
      .filter(sensorId => sensorsToSelectIds?.includes(sensorId));
    const deletedSensorIds = (blueprintSensorIds || [])
      .filter(sensorId => !sensorIds.includes(sensorId))
      .filter(sensorId => sensorsToSelectIds?.includes(sensorId));

    // Sanity check - check if any of the deleted sensors are placed on current blueprint
    const deletedSensorsPlacedOnCurrentBlueprint = deletedSensorIds.filter(
      sensorId => sensorIsPlacedOnCurrentBlueprint?.[sensorId],
    );
    if (deletedSensorsPlacedOnCurrentBlueprint.length > 0) {
      throw new Error('Cannot delete sensors placed on current blueprint');
    }

    // Check if any of the deleted sensors are attached current blueprint
    const deletedSensorsAttachedCurrentBlueprint = deletedSensorIds.filter(
      sensorId => sensorIsAttachedCurrentBlueprint?.[sensorId],
    );

    const confirmed =
      deletedSensorsAttachedCurrentBlueprint.length > 0
        ? await notificationWarningConfirm({
            text: t('blueprints.BlueprintAttachedSensorsField.confirmOnBlur.text'),
            title: t('blueprints.BlueprintAttachedSensorsField.confirmOnBlur.title'),
            cancelButtonText: t('blueprints.BlueprintAttachedSensorsField.confirmOnBlur.cancel'),
            confirmButtonText: t('blueprints.BlueprintAttachedSensorsField.confirmOnBlur.confirm'),
          })
        : true;

    if (confirmed) {
      // Add each new added sensor
      try {
        if (newSensorIds.length > 0) {
          await addSensorsToBlueprintById(newSensorIds);
        }
        // Delete each removed sensor
        if (deletedSensorIds.length > 0) {
          await removeSensorsFromBlueprintById(deletedSensorIds);
        }
      } catch (error) {}
    }

    setIsUpdatingSensors(false);
  };

  const initialSelectedSensorIds = blueprintSensors?.map(sensor => sensor.id);

  return (
    <>
      <SensorsSelectInput
        onBlur={onBlur}
        sensorsToSelect={sensorsToSelect}
        initialSelectedSensorIds={initialSelectedSensorIds}
        disabledSensorIds={disabledSensorIds}
        disabled={disabled}
        isPending={isUpdatingSensors}
        sensorIdToTooltip={sensorIdToTooltip}
        showDescriptions
      />

      {sensorsToSelect?.length === 0 && (
        <Warning className="my-3">
          {t('blueprints.BlueprintAttachedSensorsField.noSensors')}
        </Warning>
      )}
    </>
  );
};
