import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

import api from 'utils/api';
import { parseGateway } from 'utils/types/Gateway';
import HookOptions from 'utils/types/HookOptions';

export const useSensorGroupGateways = (
  sensorGroupId?: string,
  { enableGet = true, disableErrorNotifications = false }: HookOptions = {},
) => {
  const queryKey = ['sensorGroupGateways', sensorGroupId];

  const queryClient = useQueryClient();

  // Get
  const {
    data: gateways,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensor_groups/${sensorGroupId}/gateways`, {
        _disableErrorNotifications: disableErrorNotifications,
      }),
    select: (gateways: any[]) => gateways.map(parseGateway),
    enabled: !!sensorGroupId && enableGet,
  });

  // Callbacks
  const onSuccessAddGateways = async (
    gatewaysRaw: any[],
    { sensorGroupId }: { sensorGroupId: string },
  ) => {
    queryClient.setQueryData(['sensorGroupGateways', sensorGroupId], gatewaysRaw);
  };

  const onSuccessRemoveGateways = async (gatewaysRaw: any[]) => {
    queryClient.setQueryData(['sensorGroupGateways', sensorGroupId], gatewaysRaw);
  };

  // Add gateway to any group
  const { mutateAsync: addGatewayToGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      gatewayId,
    }: {
      sensorGroupId: string;
      gatewayId: string;
    }): Promise<any[]> => api.post(`/sensor_groups/${sensorGroupId}/gateways/${gatewayId}`),
    onSuccess: onSuccessAddGateways,
  });

  // Add gateways to any group (bulk)
  const { mutateAsync: addGatewaysToGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      gatewayIds,
    }: {
      sensorGroupId: string;
      gatewayIds: string[];
    }): Promise<any[]> => {
      if (gatewayIds.length === 0) throw new Error('No gateways to add');
      return api.post(
        `/sensor_groups/${sensorGroupId}/gateways_bulk?gateway_ids=${gatewayIds.join(',')}`,
      );
    },
    onSuccess: onSuccessAddGateways,
  });

  // Add gateways from other group to any group
  const { mutateAsync: addGatewaysFromOtherGroupToGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      otherGroupId,
    }: {
      sensorGroupId: string;
      otherGroupId: string;
    }): Promise<any[]> =>
      api.post(`/sensor_groups/${sensorGroupId}/gateways/add_from_other_group/${otherGroupId}`),
    onSuccess: onSuccessAddGateways,
  });

  // Add gateway to this group
  const addGatewayToGroup = async (gatewayId: string) =>
    await addGatewayToGroupById({ sensorGroupId: sensorGroupId!, gatewayId });

  // Add gateways to current group (bulk)
  const addGatewaysToGroup = async (gatewayIds: string[]) =>
    await addGatewaysToGroupById({ sensorGroupId: sensorGroupId!, gatewayIds });

  // Add gateways from other group to current group
  const addGatewaysFromOtherGroupToGroup = async (otherGroupId: string) =>
    await addGatewaysFromOtherGroupToGroupById({ sensorGroupId: sensorGroupId!, otherGroupId });

  // Remove sensor from current group
  const { mutateAsync: removeGatewayFromGroup } = useMutation({
    mutationFn: (gatewayId: string): Promise<any[]> =>
      api.delete(`/sensor_groups/${sensorGroupId}/gateways/${gatewayId}`),
    onSuccess: onSuccessRemoveGateways,
  });

  // Remove gateways from any group (bulk)
  const { mutateAsync: removeGatewaysFromGroupById } = useMutation({
    mutationFn: ({
      sensorGroupId,
      gatewayIds,
    }: {
      sensorGroupId: string;
      gatewayIds: string[];
    }): Promise<any[]> => {
      if (gatewayIds.length === 0) throw new Error('No gateways to remove');
      return api.delete(
        `/sensor_groups/${sensorGroupId}/gateways_bulk?gateway_ids=${gatewayIds.join(',')}`,
      );
    },
    onSuccess: onSuccessRemoveGateways,
  });

  // Remove gateways from current group (bulk)
  const removeGatewaysFromGroup = async (gatewayIds: string[]) =>
    await removeGatewaysFromGroupById({ sensorGroupId: sensorGroupId!, gatewayIds });

  return {
    addGatewaysFromOtherGroupToGroup,
    addGatewaysFromOtherGroupToGroupById,
    addGatewaysToGroup,
    addGatewaysToGroupById,
    addGatewayToGroup,
    addGatewayToGroupById,
    gateways,
    isPending: isPending || isLoading || isFetching,
    removeGatewayFromGroupById: removeGatewayFromGroup,
    removeGatewaysFromGroup,
    removeGatewaysFromGroupById,
  };
};
