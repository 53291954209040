import { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { NavigateWithParams } from 'components/wrappers/NavigateWithParams';

const Login = lazy(() => import(/* webpackChunkName: "Login" */ 'pages/Login'));

const Page404 = lazy(() => import(/* webpackChunkName: "Page404" */ 'pages/Page404'));

const routes: RouteObject[] = [
  {
    path: '/',
    Component: (props: any) => <Navigate to="/user/overview" {...props} replace={true} />,
  },
  {
    path: '/login/*',
    Component: Login,
  },
  {
    path: '/signup',
    Component: () => <NavigateWithParams to="/login/signup" replace={true} />,
  },
  {
    path: '/new_password',
    Component: () => <NavigateWithParams to="/login/new_password" replace={true} />,
  },
  {
    path: '/reset',
    Component: () => <NavigateWithParams to="/login/reset" replace={true} />,
  },
  {
    path: '/try',
    Component: () => <NavigateWithParams to="/login/try" replace={true} />,
  },
  {
    path: '*',
    Component: Page404,
  },
];

export default routes;
