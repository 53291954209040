import classNames from 'classnames';

export const SliderLabel = ({
  id,
  label,
  isSelected,
}: {
  id: string;
  label: string;
  isSelected: boolean;
}) => (
  <label
    className={classNames(
      'flex items-center justify-center p-0.5 w-1/3 cursor-pointer z-10 transition-colors duration-300 text-sm',
      isSelected ? 'text-white' : 'brand-gray',
    )}
    htmlFor={id}
  >
    {label}
  </label>
);
