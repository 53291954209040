import { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';

import { LoadingPlot, PlotlyData } from 'components/plots/insights_plots';
import api from 'utils/api';

export const TransmissionsCountPlot: React.FC = () => {
  const [transmissionsCountPlotData, setData] = useState<PlotlyData | null>(null);

  useEffect(() => {
    api.get('/admin/insights/plots/transmissions_count').then(response => {
      setData(response);
    });
  }, []);

  return (
    <>
      {!transmissionsCountPlotData && <LoadingPlot />}
      {transmissionsCountPlotData && (
        <Plot
          className="w-full"
          data={transmissionsCountPlotData.data}
          layout={transmissionsCountPlotData.layout}
        />
      )}
    </>
  );
};
