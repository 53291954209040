import GroupType from 'utils/enums/GroupType';
import GeographicLocation, { parseGeographicLocation } from 'utils/types/GeographicLocation';

type SensorGroup = {
  id: string;

  demo_application_id?: string;
  description?: string;
  sensor_group_file_id: string;
  sensor_group_file_url: string;
  name: string;
  type?: GroupType;

  // Relations
  geographic_location?: GeographicLocation;

  // Hierarchy
  parent_group?: SensorGroup;
  tree_depth: 0 | 1 | 2 | 3;

  created_date: Date;
  created_by: string;
  updated_date?: Date;
  updated_by?: string;
};

export type SensorGroupUpdate = Pick<
  SensorGroup,
  'name' | 'description' | 'geographic_location' | 'type'
>;

export const parseSensorGroup = (sensorGroup: any): SensorGroup => {
  sensorGroup.created_date = new Date(sensorGroup.created_date);
  if (sensorGroup.updated_date) {
    sensorGroup.updated_date = new Date(sensorGroup.updated_date);
  }

  if (sensorGroup.geographic_location) {
    sensorGroup.geographic_location = parseGeographicLocation(sensorGroup.geographic_location);
  }

  if (sensorGroup.parent_group) {
    sensorGroup.parent_group = parseSensorGroup(sensorGroup.parent_group);
  }

  return sensorGroup as SensorGroup;
};

// Cache query keys
export const getQueryKeySensorGroupAlarmRules = (sensorGroupId: string) => [
  'sensorGroupAlarmRules',
  sensorGroupId,
];

export default SensorGroup;
