import { useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { parseEvent } from 'utils/types';

export const useSensorEvents = (sensorId?: string) => {
  const queryKey = ['sensorEvents', sensorId];

  // Sensor Events - Get
  const { data: events, isPending } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/sensors/${sensorId}/events`, {
        _disableErrorNotifications: true,
      }),
    select: (events: any[]) => events.map(parseEvent),
    enabled: !!sensorId,
  });

  return {
    events,
    isPending,
  };
};
