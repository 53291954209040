import classNames from 'classnames';
import React, { HTMLAttributes, ReactEventHandler } from 'react';
import LazyLoad from 'react-lazyload';

import Sticky from 'components/wrappers/Sticky';

export type CardProps = {
  body?: JSX.Element;
  headerClassName?: string;
  bodyClassName?: string;
  roundedTop?: boolean;
  footer?: JSX.Element;
  header?: JSX.Element | string;
  imgClassName?: string;
  imgUrl?: string;
  ImgOnError?: ReactEventHandler<HTMLImageElement>;
  imgHeight?: number;
  imgObjectFit?: 'cover' | 'fit';
  noPaddingBody?: boolean;
  stickyHeaderTop?: number;
  lazyLoadImage?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Card = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      body,
      roundedTop = true,
      headerClassName = 'rounded-xl mb-5',
      bodyClassName,
      className = 'mb-5',
      footer,
      header,
      imgClassName,
      imgUrl,
      ImgOnError,
      imgHeight = 200,
      imgObjectFit = 'fit',
      noPaddingBody,
      stickyHeaderTop,
      style,
      lazyLoadImage = true,
      ...props
    },
    ref,
  ) => {
    const imageElement = (
      <img
        className={classNames(
          'w-full h-full mx-auto absolute top-1/2 -translate-y-1/2',
          {
            'object-cover': imgObjectFit === 'cover',
            'object-fit': imgObjectFit === 'fit',
          },
          imgClassName,
        )}
        alt="img"
        style={{ borderRadius: 'inherit', height: imgHeight }}
        onError={ImgOnError}
        src={imgUrl}
      />
    );

    return (
      <div
        className={classNames(
          {
            'lg:rounded-xl': roundedTop,
            'lg:rounded-t-none lg:rounded-b-xl': !roundedTop,
          },
          'bg-white overflow-x-hidden overflow-y-hidden w-full',
          className,
        )}
        style={{
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1)',
          ...style,
        }}
        ref={ref}
        {...props}
      >
        {imgUrl && (
          <div className="relative" style={{ height: imgHeight }}>
            {lazyLoadImage ? (
              // React doesn't recognize `unmountIfInvisible` even though it's defined in the type. Not sure why. This throws a warning during testing.
              <LazyLoad once offset={100} style={{ height: imgHeight }} unmountIfInvisible>
                {imageElement}
              </LazyLoad>
            ) : (
              imageElement
            )}
          </div>
        )}
        <div
          className={classNames('overflow-hidden', {
            'px-6 lg:px-7 py-4 lg:py-5': !noPaddingBody,
          })}
        >
          {header && (
            <div className={classNames(headerClassName)}>
              {typeof stickyHeaderTop === 'number' ? (
                <Sticky
                  top={stickyHeaderTop}
                  activeClassName="bg-white z-30 pr-3 border-b border-brand-gray-light-3 -ml-7"
                >
                  {header}
                </Sticky>
              ) : (
                header
              )}
            </div>
          )}
          {body && <div className={bodyClassName}>{body}</div>}
          {footer && footer}
        </div>
      </div>
    );
  },
);

export default Card;
