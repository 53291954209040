export enum SensorTagOrientation {
  North = 10,
  NorthWest = 20,
  West = 30,
  SouthWest = 40,
  South = 50,
  SouthEast = 60,
  East = 70,
  NorthEast = 80,
}
