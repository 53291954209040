import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

export const MissingTagCell: React.FC<{
  sensorId?: string;
  setActiveSensorId: (value: React.SetStateAction<string | undefined>) => void;
  setShowSensorTagsModal: (value: React.SetStateAction<boolean>) => void;
}> = ({ sensorId, setActiveSensorId, setShowSensorTagsModal }) => {
  const { t } = useTranslation('components');

  return (
    <FontAwesomeIcon
      onClick={() => {
        setActiveSensorId(sensorId);
        setShowSensorTagsModal(true);
      }}
      data-tooltip-content={t('tables.SensorsPaginatedTable.MissingTagCell.tooltip')}
      data-tooltip-id="route-tooltip"
      className="text-brand-gray-light-3 hover:text-brand-blue-light-2 cursor-pointer"
      icon={faEdit}
      // size="lg"
    />
  );
};
