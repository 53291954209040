import { t } from 'translations';
import AlarmRuleParameter from 'utils/enums/AlarmRuleParameter';

export const getAlarmRuleParameterText = (alarmRuleParameter: AlarmRuleParameter) => {
  switch (alarmRuleParameter) {
    case AlarmRuleParameter.HUMIDITY:
      return t('utils:texts.AlarmRuleParameter.getAlarmRuleParameterText.Humidity');
    case AlarmRuleParameter.MOISTURE:
      return t('utils:texts.AlarmRuleParameter.getAlarmRuleParameterText.Moisture');
    case AlarmRuleParameter.TEMPERATURE:
      return t('utils:texts.AlarmRuleParameter.getAlarmRuleParameterText.Temperature');
    case AlarmRuleParameter.WEATHER_RAIN_1H:
      return t('utils:texts.AlarmRuleParameter.getAlarmRuleParameterText.WeatherRain1H');
    case AlarmRuleParameter.WEATHER_HUMIDITY:
      return t('utils:texts.AlarmRuleParameter.getAlarmRuleParameterText.WeatherHumidity');
    case AlarmRuleParameter.WEATHER_TEMPERATURE:
      return t('utils:texts.AlarmRuleParameter.getAlarmRuleParameterText.WeatherTemperature');
    default:
      return 'n/a';
  }
};

export const getParameterUnit = (parameter: string) => {
  switch (parameter) {
    case AlarmRuleParameter.HUMIDITY:
    case AlarmRuleParameter.MOISTURE:
    case AlarmRuleParameter.WEATHER_HUMIDITY:
      return '%';
    case AlarmRuleParameter.TEMPERATURE:
    case AlarmRuleParameter.WEATHER_TEMPERATURE:
      return '°C';
    case AlarmRuleParameter.WEATHER_RAIN_1H:
      return 'mm';
    default:
      return '';
  }
};
