import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

export const ToggleButton: React.FC<
  {
    onClick: (event: React.MouseEvent<SVGElement>) => void;
  } & HTMLAttributes<SVGElement>
> = ({ onClick, className, ...props }) => (
  <FontAwesomeIcon
    className={classNames(className, 'text-brand-gray max-h-6 cursor-pointer')}
    onClick={e => onClick(e)}
    {...props}
    icon={faBars}
    size="lg"
  />
);
