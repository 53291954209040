import { useEffect, useMemo, Dispatch, SetStateAction } from 'react';

import { KnownQueryKeys } from 'utils/hooks/useQueryParam';
import { useQueryParamInteger } from 'utils/hooks/useQueryParamInteger';

export const useQueryParamBoolean = (
  initialValue: boolean,
  key: KnownQueryKeys,
): [boolean, Dispatch<SetStateAction<boolean>>] => {
  const [valueInteger, setValueInteger] = useQueryParamInteger(key);

  const setValue = (x: SetStateAction<boolean>) => {
    x = x instanceof Function ? x(value) : x;
    setValueInteger(x ? 1 : 0);
  };
  const value = useMemo(() => valueInteger === 1, [valueInteger]);

  useEffect(() => {
    if (valueInteger === undefined) {
      setValue(initialValue);
    }
  }, [valueInteger, initialValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return [value, setValue];
};
