import { useEffect, useState } from 'react';
import Autosuggest, { InputProps } from 'react-autosuggest';
import { useTranslation } from 'react-i18next';

import { useIsMounted } from 'utils/hooks';
import Sensor from 'utils/types/Sensor';
import 'styles/components/_autosuggest.scss';

export const SensorSelectInput: React.FC<{
  sensors?: Sensor[];
  initialSelectedSensorId?: string;
  onSelect: (sensor?: Sensor) => void;
  label?: string | JSX.Element;
  disabled?: boolean;
}> = ({ sensors, initialSelectedSensorId, onSelect, label, disabled = false, ...props }) => {
  const [suggestedInput, setSuggestedInput] = useState<string>('');
  const [suggestions, setSuggestions] = useState(sensors);
  const isMountedComponent = useIsMounted();

  const { t } = useTranslation('components');

  useEffect(() => {
    if (!isMountedComponent()) return;

    if (initialSelectedSensorId) {
      const initialSuggestion = sensors?.find(sensor => sensor.id === initialSelectedSensorId);
      if (initialSuggestion) {
        const initialSuggestionValue = getSuggestionValue(initialSuggestion);
        setSuggestedInput(initialSuggestionValue);
      }
    }
  }, [initialSelectedSensorId, sensors]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFilteredSuggestions = (value: string) => {
    value = value.toLowerCase();
    return sensors?.filter(
      sensor =>
        (sensor.id || '').toLowerCase().includes(value) ||
        (sensor.hardware_id || '').toLowerCase().includes(value) ||
        (sensor.name || '').toLowerCase().includes(value),
    );
  };

  // Autosuggest will call this function every time you need to update suggestions.
  const onSuggestionsFetchRequested = ({ value }: { value: string }) => {
    const sensorsFiltered = getFilteredSuggestions(value);
    setSuggestions(sensorsFiltered);
    return sensorsFiltered;
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onAdminSuggestionsClearRequested = () => setSuggestions([]);

  const getSuggestionValue = (suggestion: Sensor) => suggestion.name || '';

  const onAdminSuggestionSelected = (event: any, { suggestion }: { suggestion: Sensor }) => {
    onSelect(suggestion);
    setSuggestedInput(getSuggestionValue(suggestion));
  };

  const suggestInputProps = {
    onChange: (_, { newValue }) => setSuggestedInput(newValue),
    placeholder: t('inputs.SensorSelectInput.placeholder'),
    value: suggestedInput,
    disabled,
    id: 'sensors-select-input',
  } as InputProps<Sensor>;

  const renderSuggestion = (suggestion: Sensor) => (
    <span>
      {suggestion.name || 'n/a'} (id: {suggestion.hardware_id})
    </span>
  );

  return (
    <>
      {label && <label htmlFor="sensor-select-input">{label}</label>}
      <Autosuggest
        suggestions={suggestions || []}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onAdminSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={suggestInputProps}
        onSuggestionSelected={onAdminSuggestionSelected}
        shouldRenderSuggestions={() => true}
      />
    </>
  );
};
