import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks/useCurrentUser';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import queryClient from 'utils/queries/client';
import { Blueprint, parseBlueprint } from 'utils/types';
import HookOptions from 'utils/types/HookOptions';

type Options = HookOptions & {
  useThumbnail?: boolean;
};

export const useBlueprint = (
  blueprintId?: string,
  { enableGet = true, useThumbnail = false }: Options = {},
) => {
  const { customerId } = useSelectedCustomer();
  const { userId } = useCurrentUser();

  const queryKey = ['blueprint', blueprintId, useThumbnail];

  // Blueprint - Get
  const {
    data: blueprint,
    isPending,
    isFetching,
    isLoading,
  } = useQuery({
    queryKey,
    queryFn: () =>
      api.get(`/blueprints/${blueprintId}`, {
        params: { use_thumbnail: useThumbnail },
      }),
    select: (blueprint: any) => parseBlueprint(blueprint),
    placeholderData: {} as Blueprint,
    enabled: !!blueprintId && enableGet,
  });

  const { mutateAsync: deleteBlueprint } = useMutation<Blueprint>({
    mutationFn: () => api.delete(`/blueprints/${blueprintId}`),
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ['customerBlueprints', customerId],
      });
      await queryClient.refetchQueries({
        queryKey: ['userBlueprints', userId],
      });
    },
  });

  // Blueprint - Update
  const { mutateAsync: updateBlueprintById } = useMutation<Blueprint, Error, Partial<Blueprint>>({
    mutationFn: ({ id, ...data }) => api.put(`/blueprints/${id}`, data),
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: ['customerBlueprints', customerId] });
      await queryClient.refetchQueries({ queryKey: ['blueprint', blueprintId] });
    },
  });
  const updateBlueprint = async (data: Partial<Omit<Blueprint, 'id'>>) =>
    updateBlueprintById({ id: blueprintId, ...data });

  return {
    blueprint,
    isPending: isPending || isLoading || isFetching,
    deleteBlueprint,
    updateBlueprint,
    updateBlueprintById,
  };
};
