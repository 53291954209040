import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EnumSelectInput,
  Props as EnumSelectInputProps,
} from 'components/inputs/SensorTagSelectInput/components';
import { SensorTagProbabilities } from 'utils/types/SensorTagPredictions';
import { SensorTag } from 'utils/types/SensorTags';

export type Props<T extends SensorTag> = Omit<EnumSelectInputProps<T>, 'options'> & {
  sensorTagProbabilities?: SensorTagProbabilities<T>;
  selectedValue?: T;
  options: SensorTag[];
};

export const SensorTagSelectInput = <T extends SensorTag>({
  sensorTagProbabilities,
  selectedValue,
  getEnumTypeText,
  label,
  options,
  ...props
}: Props<T>) => {
  const { t } = useTranslation('components');

  const [topProbabilityKey, topProbability] = useMemo(() => {
    if (!sensorTagProbabilities) return [undefined, undefined];

    const keys = Object.keys(sensorTagProbabilities) as unknown[] as T[];
    if (keys.length === 0) return [undefined, undefined];

    // Extract top probability and key
    const topProbabilityKey = Number(
      keys.reduce((a, b) => (sensorTagProbabilities[a] > sensorTagProbabilities[b] ? a : b)),
    ) as T;
    const topProbability = sensorTagProbabilities[topProbabilityKey] as number;
    return [topProbabilityKey, topProbability];
  }, [sensorTagProbabilities]);

  const message = useMemo(() => {
    if (!topProbabilityKey || !topProbability) return undefined;

    // If top probability key is already selected -> Show nothing
    if (selectedValue === topProbabilityKey) return undefined;

    // If no selection already -> Show recommendation
    const keyName = getEnumTypeText(topProbabilityKey as T);
    const topProbabilityPercentage = (topProbability * 100).toFixed(0);
    const tooltip = t('inputs.SensorTagSelectInput.tooltips.1', {
      percentage: topProbabilityPercentage,
      label: label?.toLowerCase() || '',
      name: keyName.toLowerCase(),
    });
    if (!selectedValue) {
      return (
        <p className="text-brand-orange mt-1">
          <FontAwesomeIcon className="mr-1" icon={faTriangleExclamation} />
          {label} {t('inputs.SensorTagSelectInput.isLikely')}{' '}
          <span
            className="underline font-extrabold"
            data-tooltip-content={tooltip}
            data-tooltip-id="route-tooltip"
          >
            {keyName.toLowerCase()}
          </span>
        </p>
      );
    }

    // If top probability is different from selected value -> Show warning
    const selectedValueName = getEnumTypeText(selectedValue);
    return (
      <p className="text-brand-green mt-1">
        <FontAwesomeIcon className="mr-1" icon={faTriangleExclamation} />
        {t('inputs.SensorTagSelectInput.areYouSureThat')}{' '}
        <span
          className="underline font-extrabold"
          data-tooltip-content={t('inputs.SensorTagSelectInput.tooltips.2')}
          data-tooltip-id="route-tooltip"
        >
          {selectedValueName.toLowerCase()}
        </span>{' '}
        {t('inputs.SensorTagSelectInput.isCorrect')}{' '}
        <span
          className="underline font-extrabold"
          data-tooltip-content={tooltip}
          data-tooltip-id="route-tooltip"
        >
          {keyName.toLowerCase()}
        </span>
      </p>
    );
  }, [topProbabilityKey, topProbability, selectedValue, t, getEnumTypeText, label]);

  const getEnumTypeTextWithProbabilities = useCallback(
    (enumType: T) => {
      const enumTypeText = getEnumTypeText(enumType);
      if (!topProbabilityKey || topProbabilityKey !== enumType) return enumTypeText;
      return `${enumTypeText} (anbefalet)`;
    },
    [topProbabilityKey, getEnumTypeText],
  );

  const sortedOptions = useMemo(() => {
    if (!sensorTagProbabilities) return options;
    const sortedOptions = options.sort((a, b) => {
      const probabilityA = sensorTagProbabilities[a as T];
      const probabilityB = sensorTagProbabilities[b as T];
      const probA = probabilityA === undefined ? -1 : probabilityA;
      const probB = probabilityB === undefined ? -1 : probabilityB;
      return probA < probB ? 1 : -1;
    });
    return sortedOptions;
  }, [options, sensorTagProbabilities]);

  return (
    <div>
      <EnumSelectInput
        getEnumTypeText={getEnumTypeTextWithProbabilities}
        label={label}
        options={sortedOptions}
        {...props}
      />
      {message}
    </div>
  );
};
