import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AttachDropdown } from 'components/GroupHierarchy/components/GroupHierarchyNode/components/GroupHierarchyNodeCard/components';
import SensorGroup from 'utils/types/SensorGroup';

export const AttachButton = ({
  options,
  disabled = false,
  onAttachSubgroup,
}: {
  options: SensorGroup[];
  disabled: boolean;
  onAttachSubgroup: (selectedGroup: SensorGroup) => Promise<void>;
}) => {
  const { t } = useTranslation('components');

  const [isHovering, setIsHovering] = useState(false);
  const [dropdownExistingGroupsOpen, setDropdownExistingGroupsOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <div
      onMouseLeave={() => {
        setDropdownExistingGroupsOpen(false);
      }}
      onMouseEnter={() => {
        if (buttonRef.current) {
          const rect = buttonRef.current.getBoundingClientRect();
          setDropdownPosition({
            top: rect.bottom + window.scrollY,
            left: rect.left + window.scrollX,
          });
        }
        setDropdownExistingGroupsOpen(true);
      }}
    >
      <button
        ref={buttonRef}
        className={classNames('cursor-pointer', {
          'text-brand-gray-light-3 cursor-not-allowed': disabled,
          'text-brand-gray': !isHovering && !disabled,
          'text-brand-blue': isHovering && !disabled,
        })}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
        }}
        data-tooltip-content={
          disabled
            ? t('GroupHierarchy.GroupHierarchyNode.AttachButton.limitReached')
            : t('GroupHierarchy.GroupHierarchyNode.AttachButton.text')
        }
        data-tooltip-id="route-tooltip"
      >
        <FontAwesomeIcon icon={faLink} />
      </button>
      {!disabled && dropdownExistingGroupsOpen && (
        <AttachDropdown
          options={options}
          top={dropdownPosition.top}
          left={dropdownPosition.left}
          onAttachSubgroup={onAttachSubgroup}
          setIsHovering={setIsHovering}
          setDropdownExistingGroupsOpen={setDropdownExistingGroupsOpen}
        />
      )}
    </div>
  );
};
