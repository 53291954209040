import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import Modal from 'components/modals/Modal';
import SensorAlarmRules, {
  Props as SensorAlarmRulesProps,
} from 'components/sensors/SensorAlarmRules';

const UpdateSensorAlarmRulesModal: React.FC<
  {
    show: boolean;
    setShow: Dispatch<boolean>;
  } & SensorAlarmRulesProps
> = ({ show, setShow, ...props }) => {
  const { t } = useTranslation('components');

  return (
    <Modal title={t('modals.UpdateSensorAlarmRulesModal.title')} show={show} setShow={setShow}>
      <SensorAlarmRules {...props} />
    </Modal>
  );
};

export default UpdateSensorAlarmRulesModal;
