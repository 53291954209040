import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import api from 'utils/api';
import { useCurrentUser } from 'utils/hooks';
import SensorFile, { parseSensorFile } from 'utils/types/SensorFile';

export const useAdminSensorFiles = (pageSize = 50) => {
  const queryKey = ['adminSensorFiles'];

  const { isAdmin } = useCurrentUser();

  const { data, fetchNextPage, hasNextPage, isPending, isLoading, isFetching } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) =>
      api.get('/admin/sensor_files', {
        params: { offset: pageSize * pageParam, limit: pageSize },
      }),
    initialPageParam: 0,
    select: ({ pages, pageParams }) => ({
      pages: pages.map(parseSensorFile),
      pageParams,
    }),
    getNextPageParam: (lastPage, pages) =>
      lastPage.length === pageSize ? pages.length : undefined,
    enabled: isAdmin,
  });

  const sensorFiles = useMemo(() => data && ([] as SensorFile[]).concat(...data.pages), [data]);

  return {
    fetchNextPage,
    hasNextPage,
    sensorFiles,
    isPending: isPending || isLoading || isFetching,
  };
};
