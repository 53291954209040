import { useMutation, useQuery } from '@tanstack/react-query';

import api from 'utils/api';
import { useSelectedCustomer } from 'utils/hooks/useSelectedCustomer';
import queryClient from 'utils/queries/client';
import { parseAlarmRule } from 'utils/types/AlarmRule';
import { getQueryKeyCustomerAlarmRuleSensors } from 'utils/types/Customer';
import HookOptions from 'utils/types/HookOptions';
import { getQueryKeySensorGroupAlarmRules } from 'utils/types/SensorGroup';

export const useSensorGroupAlarmRules = (
  sensorGroupId?: string,
  { enableGet = true }: HookOptions = {},
) => {
  const queryKey = getQueryKeySensorGroupAlarmRules(sensorGroupId!);

  const { customerId } = useSelectedCustomer();

  // Get
  const {
    data: alarmRules,
    isPending,
    isLoading,
    isFetching,
  } = useQuery({
    queryKey,
    queryFn: () => api.get(`/sensor_groups/${sensorGroupId}/alarm_rules`),
    select: ({
      enabled_by_all,
      enabled_by_some,
      available,
    }: {
      enabled_by_all: any[];
      enabled_by_some: any[];
      available: any[];
    }) => ({
      enabledByAll: enabled_by_all.map(parseAlarmRule),
      enabledBySome: enabled_by_some.map(parseAlarmRule),
      available: available.map(parseAlarmRule),
    }),
    enabled: enableGet && !!sensorGroupId,
  });

  // Enable alarm rule
  const { mutateAsync: enableAlarmRuleById, isPending: isEnablingAlarmRule } = useMutation({
    mutationFn: (alarmRuleId: string) =>
      api.post(`/sensor_groups/${sensorGroupId}/alarm_rules/${alarmRuleId}`),
    onSuccess: async (data, alarmRuleId) => {
      queryClient.setQueryData(queryKey, data);
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, false),
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, true),
      });
    },
  });

  // Disable alarm rule
  const { mutateAsync: disableAlarmRuleById, isPending: isDisablingAlarmRule } = useMutation({
    mutationFn: (alarmRuleId: string) =>
      api.delete(`/sensor_groups/${sensorGroupId}/alarm_rules/${alarmRuleId}`),
    onSuccess: async (data, alarmRuleId) => {
      queryClient.setQueryData(queryKey, data);
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, false),
      });
      queryClient.invalidateQueries({
        queryKey: getQueryKeyCustomerAlarmRuleSensors(customerId!, alarmRuleId!, true),
      });
    },
  });

  return {
    disableAlarmRuleById,
    enableAlarmRuleById,
    isPending: isPending || isLoading || isFetching,
    alarmRulesEnabledByAll: alarmRules?.enabledByAll,
    alarmRulesEnabledBySome: alarmRules?.enabledBySome,
    availableAlarmRules: alarmRules?.available,
    isEnablingAlarmRule,
    isDisablingAlarmRule,
    isMutating: isEnablingAlarmRule || isDisablingAlarmRule,
  };
};
