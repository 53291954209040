import { Column, Row } from '@tanstack/react-table';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Select from 'components/Select';

export const ColumnFilter = ({
  column,
  rows,
  transform,
  resetPageIndex,
}: {
  column: Column<any, unknown>;
  rows: Row<any>[];
  transform?: (val: any) => string;
  resetPageIndex: (defaultState?: boolean) => void;
}) => {
  const { t } = useTranslation('components');

  const columnFilterValue = column.getFilterValue();
  const { filterVariant } = column.columnDef.meta ?? {};

  const options: string[] = useMemo(() => {
    const options = new Set<string>();
    rows.forEach((row: Row<any>) => options.add(row.getValue(column.id))); //@ts-ignore
    return [...options.values()].filter(val => val).sort();
  }, [column.id, rows]);

  return filterVariant === 'select' ? (
    <Select
      className="py-0 inline w-2/3"
      onClick={e => e.stopPropagation()}
      value={columnFilterValue?.toString()}
      onChange={(e: ChangeEvent<HTMLSelectElement>) => {
        resetPageIndex();
        column.setFilterValue(e.target.value);
      }}
    >
      <option className="text-brand-gray -darkest" value="">
        {t('tables.filters.SelectColumnFilter.all')}
      </option>
      {options.map((option, i) => (
        <option className="text-brand-gray -darkest" key={i} value={option}>
          {typeof transform === 'undefined' ? option : transform(option)}
        </option>
      ))}
    </Select>
  ) : (
    <DebouncedInput
      className="w-36 border shadow rounded"
      onChange={value => {
        resetPageIndex();
        column.setFilterValue(value);
      }}
      placeholder={'Search...'}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />
  );
};

// A typical debounced input react component from react-table V8 documentation
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number;
  onChange: (value: string | number) => void;
  debounce?: number;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return <input {...props} value={value} onChange={e => setValue(e.target.value)} />;
}
