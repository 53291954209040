import * as Sentry from '@sentry/react';

import environment from 'utils/environment';

const DEFAULT_SAMPLE_RATE = 1.0;
const PRODUCTION_SAMPLE_RATE = 0.2;

export const setupSentry = (release: string | undefined) => {
  Sentry.init({
    dsn: 'https://524d0e42811047928a849345bd815722@o974608.ingest.us.sentry.io/5930321',
    integrations: [Sentry.browserTracingIntegration()],
    environment,
    maxBreadcrumbs: 50,
    debug: false, // Will log sentry debug info to Console
    ignoreErrors: [
      'Request failed with status code 401', // Ignore 401 Unauthorized errors
    ],
    tracesSampleRate: environment === 'production' ? PRODUCTION_SAMPLE_RATE : DEFAULT_SAMPLE_RATE,
    release,
  });
};
