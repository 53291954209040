const enum TemperatureState {
  // Below zero
  TEMPERATURE_30_20 = 'TEMPERATURE_30_20',
  TEMPERATURE_20_10 = 'TEMPERATURE_20_10',
  TEMPERATURE_10_0 = 'TEMPERATURE_10_0',

  // Above zero (zero included)
  TEMPERATURE_0_10 = 'TEMPERATURE_0_10',
  TEMPERATURE_10_20 = 'TEMPERATURE_10_20',
  TEMPERATURE_20_30 = 'TEMPERATURE_20_30',
  TEMPERATURE_30_40 = 'TEMPERATURE_30_40',
  TEMPERATURE_40_50 = 'TEMPERATURE_40_50',
  TEMPERATURE_50_60 = 'TEMPERATURE_50_60',

  UNKNOWN = 'UNKNOWN',
}

export const sortOrderTemperatureState: { [key: string]: number } = [
  TemperatureState.UNKNOWN,
  TemperatureState.TEMPERATURE_30_20,
  TemperatureState.TEMPERATURE_20_10,
  TemperatureState.TEMPERATURE_10_0,
  TemperatureState.TEMPERATURE_0_10,
  TemperatureState.TEMPERATURE_10_20,
  TemperatureState.TEMPERATURE_20_30,
  TemperatureState.TEMPERATURE_30_40,
  TemperatureState.TEMPERATURE_40_50,
  TemperatureState.TEMPERATURE_50_60,
]
  .map((state, index) => [state, index])
  .reduce((o: any, [state, index]) => ({ ...o, [state]: index }), {});

export default TemperatureState;
