import { faArrowDown, faFileImport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import uniqBy from 'lodash/uniqBy';
import { useMemo, useState, Dispatch } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';
import Card from 'components/cards/Card';
import Modal from 'components/modals/Modal';
import SensorTagsAll from 'components/sensors/tags/SensorTagsAll';
import { dateToLocaleString, dateToPrettyDate } from 'utils/date';
import { useSensorPrimaryFile } from 'utils/hooks/data';
import Sensor from 'utils/types/Sensor';
import SensorTags from 'utils/types/SensorTags';

type SensorWithTags = Omit<Sensor, 'tags'> & { tags: SensorTags };

const SelectSensorFromTagsModal: React.FC<{
  show: boolean;
  setShow: Dispatch<boolean>;
  onSelect: (sensor: SensorWithTags) => Promise<void>;
  sensors?: Sensor[];
}> = ({ show, setShow, onSelect, sensors }) => {
  const [maxSensors, setMaxSensors] = useState(5);

  const { t } = useTranslation('components');

  const [sensorsWithUniqueTags, availableSensorsCount] = useMemo(() => {
    if (!sensors) return [[], 0];
    const sensorsFiltered = sensors.filter(
      sensor =>
        !!sensor.tags &&
        (sensor.tags.primary_element_category ||
          sensor.tags.primary_element_type ||
          sensor.tags.complementary_element ||
          sensor.tags.orientation ||
          sensor.tags.room_type ||
          sensor.tags.room_type_description ||
          sensor.tags.vertical_placement ||
          sensor.tags.casing ||
          sensor.tags.climate ||
          sensor.tags.construction_phase ||
          sensor.tags.construction_principles ||
          sensor.tags.insulation ||
          sensor.tags.screw_type ||
          sensor.tags.ventilation),
    ) as SensorWithTags[];

    // Sort sensors by setup completed date
    const never = new Date(0);
    const sensorsSorted = sensorsFiltered.sort((a, b) => {
      const dateA = a.setup_completed_at || never;
      const dateB = b.setup_completed_at || never;
      return dateB.getTime() - dateA.getTime();
    });

    // Only keep sensors with unique tags
    const sensorsUnique = uniqBy(sensorsSorted, sensor =>
      [
        sensor.tags.primary_element_category,
        sensor.tags.primary_element_type,
        sensor.tags.complementary_element,
        sensor.tags.orientation,
        sensor.tags.room_type,
        sensor.tags.room_type_description,
        sensor.tags.vertical_placement,
        sensor.tags.casing,
        sensor.tags.climate,
        sensor.tags.construction_phase,
        sensor.tags.construction_principles,
        sensor.tags.insulation,
        sensor.tags.screw_type,
        sensor.tags.ventilation,
      ].join('|'),
    );

    return [sensorsUnique.slice(0, maxSensors), sensorsUnique.length];
  }, [maxSensors, sensors]);

  const onSensorSelect = async (sensor: SensorWithTags) => {
    setShow(false);
    await onSelect(sensor);
  };

  return (
    <Modal
      title={t('modals.SelectSensorFromTagsModal.title')}
      show={show}
      setShow={setShow}
      size="md"
    >
      {sensorsWithUniqueTags.map(sensor => (
        <SensorCard key={sensor.id} sensor={sensor} onSelect={onSensorSelect} />
      ))}

      {availableSensorsCount > sensorsWithUniqueTags.length && (
        <div className="text-center">
          <button
            className="underline text-brand-green hover:text-blue-500"
            onClick={() => setMaxSensors(val => val + 5)}
          >
            <FontAwesomeIcon className="mr-2" icon={faArrowDown} />
            {t('modals.SelectSensorFromTagsModal.seeMoreSensors')}
          </button>
        </div>
      )}
    </Modal>
  );
};

const SensorCard: React.FC<{
  sensor: SensorWithTags;
  onSelect: (sensor: SensorWithTags) => Promise<void>;
}> = ({ sensor, onSelect }) => {
  const { t } = useTranslation();

  const { file } = useSensorPrimaryFile(sensor.id, false);

  const name = sensor.ttn_dev_id || sensor.name;

  return (
    <Card
      className="card-hover-effect mb-32"
      imgUrl={file?.storage_url}
      imgClassName="h-[200px] object-cover w-full"
      lazyLoadImage={false}
      body={
        <div className="mb-2">
          <h2>{name}</h2>
          <p>
            {t('utils:setUp')}{' '}
            <span
              data-tooltip-content={dateToLocaleString(sensor.setup_completed_at)}
              data-tooltip-id="route-tooltip"
            >
              {dateToPrettyDate(sensor.setup_completed_at)}
            </span>
          </p>

          <div className="my-5">
            <p className="text-brand-gray-light-2 mb-1">{t('utils:tags')}:</p>
            <SensorTagsAll
              sensorTags={sensor.tags}
              showEnvironmentTags
              showPrimaryPlacementTags
              showRoomAndOrientationTags
              showTheSensorTags
            />
          </div>
        </div>
      }
      footer={
        <div className="text-center mb-3">
          <Button variant="info" size="lg" onClick={() => onSelect(sensor)}>
            <FontAwesomeIcon className="mr-2" icon={faFileImport} />
            {t('components:modals.SelectSensorFromTagsModal.copyTagsFrom')} {name}
          </Button>
        </div>
      }
    />
  );
};

export default SelectSensorFromTagsModal;
